import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { useGroupQuery } from ".";

export type Result = Partial<{
  data: {
    cid: number;
    content: string;
    deleted: boolean;
    group_id: number;
    id: number;
    status: string;
    _ca: number;
    _cb: any;
    _lma: number;
    _lmb: any;
  };
  statusCode: number;
  success: boolean;
}>;
export type Variables = { postId: number };

type QueryError = {};

const useDeletePostMutation = (
  options?: UseMutationOptions<Result, QueryError, Variables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  const queryClient = useQueryClient();
  const { data: group } = useGroupQuery();
  const groupId = group?.id;
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const groupPostsCacheKey = [`${GATEWAY_URL}/post/${groupId}/${cid}`];

  return useMutation<Result, QueryError, Variables>(
    async ({ postId }) => {
      if (!postId) throw new Error("Post id not found");
      const url = `${GATEWAY_URL}/post/${postId}`;
      return fetchWithAuth<Result>(url, {
        method: "DELETE",
      });
    },
    {
      onSuccess: async ({ data }) => {
        queryClient.invalidateQueries(groupPostsCacheKey);
      },
      ...options,
    }
  );
};
export default useDeletePostMutation;
