import SvgIcon, { SvgIconProps } from "./SvgIcon";

const LocationIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C16.4183 2 20 5.58172 20 10C20 12.41 18.2149 15.5258 14.6446 19.3476L14.0331 19.9911L13.3884 20.6476L12.7107 21.3173C12.4793 21.5427 12.2424 21.7703 12 22C11.7576 21.7703 11.5207 21.5427 11.2893 21.3173L10.6116 20.6476L9.96694 19.9911L9.35537 19.3476C5.78512 15.5258 4 12.41 4 10C4 5.58172 7.58172 2 12 2ZM12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7Z" />
      ) : (
        <path d="M12 2C16.4183 2 20 5.58172 20 10C20 12.41 18.2149 15.5258 14.6446 19.3476L14.0331 19.9911L13.3884 20.6476L12.7107 21.3173C12.4793 21.5427 12.2424 21.7703 12 22C11.7576 21.7703 11.5207 21.5427 11.2893 21.3173L10.6116 20.6476L9.96694 19.9911L9.35537 19.3476C5.78512 15.5258 4 12.41 4 10C4 5.58172 7.58172 2 12 2ZM12 4C8.68629 4 6 6.68629 6 10C6 11.9713 7.89798 15.0423 11.7832 18.9881L12 19.206L12.2168 18.9881C16.0024 15.1434 17.9014 12.1293 17.9963 10.1538L18 10C18 6.68629 15.3137 4 12 4ZM12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7ZM12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9Z" />
      )}
    </SvgIcon>
  );
};

export default LocationIcon;
