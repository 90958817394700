import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResumeIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM14 9C13.4477 9 13 8.55228 13 8V3.5L18.5 9H14Z" />
      ) : (
        <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V8C13 8.55228 13.4477 9 14 9H18V20Z" />
      )}
    </SvgIcon>
  );
};

export default ResumeIcon;
