import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type Blog = Partial<{
  id: number;
  title: string;
  description: string;
  thumbnail: string;
  marketing: {
    id: number;
    url: string;
    meta_title: string;
    meta_description: string;
  };
  contents: Array<{
    value: string;
    country_code: any;
    id: number;
  }>;
}>;

type BlogResult = Partial<{
  success: boolean;
  result: Array<Blog>;
}>;
type QueryError = {};

const useBlogsQuery = (
  options?: UseQueryOptions<BlogResult, QueryError, BlogResult, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/blog/trip/${tid}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<BlogResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      return fetchWithAuth<BlogResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useBlogsQuery;
