import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { TRAVELLER_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Genders = {
  success: boolean;
  result: Array<string>;
};

type QueryError = {};

const useGendersQuery = (
  options?: UseQueryOptions<Genders, QueryError, Genders, QueryKey>
) => {
  const url = `${TRAVELLER_ENDPOINT}/traveller/genders`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Genders, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<Genders>(url);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useGendersQuery;
