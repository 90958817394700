import MuiPaper, { PaperProps as MuiPaperProps } from "@mui/material/Paper";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import cx from "classnames";
import styles from "./Card.module.scss";

const ClickableCard: React.FC<
  MuiButtonProps &
    MuiPaperProps & {
      outlined?: boolean;
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    }
> = ({ className, outlined = false, onClick, ...props }) => {
  return (
    <MuiPaper
      onClick={onClick}
      data-hover-effect={!outlined}
      component={MuiButton}
      tabIndex={0}
      color="info"
      elevation={outlined ? 0 : 2}
      variant={outlined ? "outlined" : "elevation"}
      className={cx("card-root", styles.root, styles.clickableRoot, className)}
      {...props}
    />
  );
};

export default ClickableCard;
