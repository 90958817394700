import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import produce from "immer";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { useGroupQuery } from ".";
import { InfinityResult, Post } from "./useRecentPostsQuery";

type Variable = {
  postId: number;
  likeId: number;
};

type Result = {
  success: boolean;
  statusCode: number;
  data: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useDeleteLikeMutation = (
  { postId, likeId }: Variable,
  options?: UseMutationOptions<Result, QueryError>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  const { data: group } = useGroupQuery();
  const groupId = group?.id;
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const queryClient = useQueryClient();

  const url = `${GATEWAY_URL}/like/${likeId}`;
  const recentPostsCacheKey = [
    `${GATEWAY_URL}/post/${groupId}/${cid}`,
    JSON.stringify({ pinned: false }),
  ];
  const pinnedPostsCacheKey = [
    `${GATEWAY_URL}/post/${groupId}/${cid}`,
    JSON.stringify({ pinned: true, pageSize: 1, pageNum: 1 }),
  ];
  const postCacheKey = [`${GATEWAY_URL}/post/${postId}`];
  const travellerPostsCacheKey = [
    `${GATEWAY_URL}/traveller/${cid}/posts/group/${groupId}/`,
  ];

  return useMutation<Result, QueryError>(
    async () => {
      if (!postId) throw new Error("Post id not found");

      return fetchWithAuth<any>(url, {
        method: "DELETE",
      });
    },
    {
      onMutate: async () => {
        if (!postId || !cid) throw new Error("Post id or cid not found");

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(recentPostsCacheKey);
        await queryClient.cancelQueries(pinnedPostsCacheKey);
        await queryClient.cancelQueries(postCacheKey);
        await queryClient.cancelQueries(travellerPostsCacheKey);

        // Snapshot the previous value
        const prevRecentPosts = queryClient.getQueryData(recentPostsCacheKey);
        const prevPinnedPosts = queryClient.getQueryData(pinnedPostsCacheKey);
        const prevPost = queryClient.getQueryData(postCacheKey);
        const prevTravellerPosts = queryClient.getQueryData(
          travellerPostsCacheKey
        );

        // Optimistically update to the new value
        if (prevRecentPosts)
          queryClient.setQueryData(
            recentPostsCacheKey,
            produce(prevRecentPosts, (cachedResult: InfinityResult) => {
              cachedResult.pages.forEach((eachPage) => {
                (eachPage?.results || []).forEach((eachPost, idx, thePosts) => {
                  if (eachPost.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      likes: {
                        total_likes: (eachPost.likes?.total_likes || 0) - 1,
                        sample_likes: (
                          eachPost.likes?.sample_likes || []
                        ).filter((each) => each.cid !== cid),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevPinnedPosts)
          queryClient.setQueryData(
            pinnedPostsCacheKey,
            produce(prevPinnedPosts, (cachedResult: InfinityResult) => {
              cachedResult.pages.forEach((eachPage) => {
                (eachPage?.results || []).forEach((eachPost, idx, thePosts) => {
                  if (eachPost.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      likes: {
                        total_likes: (eachPost.likes?.total_likes || 0) - 1,
                        sample_likes: (
                          eachPost.likes?.sample_likes || []
                        ).filter((each) => each.cid !== cid),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevTravellerPosts)
          queryClient.setQueryData(
            travellerPostsCacheKey,
            produce(prevTravellerPosts, (cachedResult: InfinityResult) => {
              cachedResult.pages.forEach((eachPage) => {
                (eachPage?.results || []).forEach((eachPost, idx, thePosts) => {
                  if (eachPost.id === postId) {
                    thePosts[idx] = {
                      ...eachPost,
                      likes: {
                        total_likes: (eachPost.likes?.total_likes || 0) - 1,
                        sample_likes: (
                          eachPost.likes?.sample_likes || []
                        ).filter((each) => each.cid !== cid),
                      },
                    };
                  }
                });
              });
            })
          );

        if (prevPost)
          queryClient.setQueryData(
            postCacheKey,
            produce(prevPost, (cachedResult: Post) => {
              cachedResult.likes = {
                total_likes: (cachedResult?.likes?.total_likes || 0) - 1,
                sample_likes: (cachedResult?.likes?.sample_likes || []).filter(
                  (each) => each.cid !== cid
                ),
              };
            })
          );

        // Return a context object with the snapshotted value
        return { prevRecentPosts, prevPost, prevTravellerPosts };
      },
      onError: (err, data, context: any) => {
        console.error(err);
        queryClient.setQueryData(recentPostsCacheKey, context?.prevRecentPosts);
        queryClient.setQueryData(pinnedPostsCacheKey, context?.prevPinnedPosts);
        queryClient.setQueryData(postCacheKey, context?.prevPost);
        queryClient.setQueryData(
          travellerPostsCacheKey,
          context?.prevTravellerPosts
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(recentPostsCacheKey);
        queryClient.invalidateQueries(pinnedPostsCacheKey);
        queryClient.invalidateQueries(postCacheKey);
        queryClient.invalidateQueries(travellerPostsCacheKey);
      },
      ...options,
    }
  );
};

export default useDeleteLikeMutation;
