import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PROGRESS_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type Task = {
  title: string;
  body: string;
  route: string;
  data: {
    invoiceId?: number;
    taskId?: number;
  };
};
export type Phone = Partial<{
  extension: string;
  is_primary: boolean;
  order: number;
  phone: string | number;
  title: string;
}>;
export type Staff = {
  department_id: number;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  office: Array<"Australia" | "Canada" | "United Kingdom">;
  phone: Array<Phone>;
  photo_url: string;
  type: string;
  department: {
    name: string;
    department_id: number;
    stage: string;
    title: string;
    text: Array<string>;
  };
};

type Tasks = {
  percentage: number;
  tasks: Array<Task>;
  staff: Array<Staff>;
};
type QueryError = {};

const useTasksQuery = (
  options?: UseQueryOptions<Tasks, QueryError, Tasks, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${PROGRESS_ENDPOINT}/v2/trips/${tid}/tasks`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Tasks, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      const res = await fetchWithAuth<Tasks>(url);
      return res;
    },
    {
      enabled: !!tid,
      ...options,
    }
  );
};
export default useTasksQuery;
