import theme from "@/theme/theme";
import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CheckboxUncheckedIcon: React.FC<SvgIconProps> = ({
  htmlColor = theme.palette.common.neutral.white,
  color = "error",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      <rect
        x="3"
        y="3"
        width="18"
        height="18"
        rx="3"
        fill={htmlColor}
        stroke={theme.palette.divider}
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default CheckboxUncheckedIcon;
