import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResourceGuideIconV2: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        opacity="0.2"
        d="M26 16L26 49L24.75 49C24.0596 49 23.5 47.9447 23.5 46.6429L23.5 16L26 16Z"
        fill="#0095CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6316 16H45.1579C45.7102 16 46.1579 16.4477 46.1579 17V47C46.1579 47.5523 45.7102 48 45.1579 48H24.6316L24.6316 16ZM22.6316 16H20.1579C19.6056 16 19.1579 16.4477 19.1579 17L20 17C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H19.1579V22H20C20.5523 22 21 22.4477 21 23C21 23.5523 20.5523 24 20 24H19.1579V28H20C20.5523 28 21 28.4477 21 29C21 29.5523 20.5523 30 20 30H19.1579V34H20C20.5523 34 21 34.4477 21 35C21 35.5523 20.5523 36 20 36H19.1579V40H20C20.5523 40 21 40.4477 21 41C21 41.5523 20.5523 42 20 42H19.1579V45H20C20.5523 45 21 45.4477 21 46C21 46.5523 20.5523 47 20 47H19.1579C19.1579 47.5523 19.6056 48 20.1579 48H22.6316L22.6316 16ZM17.1579 47C17.1579 48.6569 18.5011 50 20.1579 50H45.1579C46.8148 50 48.1579 48.6569 48.1579 47V17C48.1579 15.3431 46.8148 14 45.1579 14H20.1579C18.5011 14 17.1579 15.3431 17.1579 17L16 17C15.4477 17 15 17.4477 15 18C15 18.5523 15.4477 19 16 19H17.1579V22H16C15.4477 22 15 22.4477 15 23C15 23.5523 15.4477 24 16 24H17.1579V28H16C15.4477 28 15 28.4477 15 29C15 29.5523 15.4477 30 16 30H17.1579V34H16C15.4477 34 15 34.4477 15 35C15 35.5523 15.4477 36 16 36H17.1579V40H16C15.4477 40 15 40.4477 15 41C15 41.5523 15.4477 42 16 42H17.1579V45H16C15.4477 45 15 45.4477 15 46C15 46.5523 15.4477 47 16 47H17.1579Z"
        fill="#003749"
      />
      <path
        d="M35.6579 24C39.8001 24 43.1579 27.3579 43.1579 31.5C43.1579 35.6421 39.8001 39 35.6579 39C31.5158 39 28.1579 35.6421 28.1579 31.5C28.1579 27.3579 31.5158 24 35.6579 24ZM35.6579 30C35.2437 30 34.9079 30.3358 34.9079 30.75V35.25C34.9079 35.6642 35.2437 36 35.6579 36C36.0721 36 36.4079 35.6642 36.4079 35.25V30.75C36.4079 30.3358 36.0721 30 35.6579 30ZM35.6579 27C35.2437 27 34.9079 27.3358 34.9079 27.75C34.9079 28.1642 35.2437 28.5 35.6579 28.5C36.0721 28.5 36.4079 28.1642 36.4079 27.75C36.4079 27.3358 36.0721 27 35.6579 27Z"
        fill="#0095CD"
      />
    </SvgIcon>
  );
};

export default ResourceGuideIconV2;
