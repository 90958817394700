import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import { CrossIcon } from "../Icon";
import Typography from "../Typography";
import styles from "./RestrictedPageDialog.module.scss";

const RestrictedPageDialog: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Access Denied
      </DialogTitle>
      <DialogContent className={styles.container}>
        <CrossIcon
          variant="contained"
          color="info"
          className={styles.crossIcon}
        />
        <Typography color="text.primary">
          The requested page is not accessible
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RestrictedPageDialog;
