import MuiCheckBox, { CheckboxProps } from "@mui/material/Checkbox";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "@/components/Icon";
import theme from "@/theme/theme";

const Checkbox: React.FC<CheckboxProps> = ({ color = "success", ...props }) => {
  return (
    <MuiCheckBox
      {...props}
      color={color}
      checkedIcon={<CheckboxCheckedIcon />}
      icon={
        <CheckboxUncheckedIcon
          htmlColor={
            props.disabled
              ? theme.palette.divider
              : theme.palette.common.neutral.white
          }
        />
      }
    />
  );
};

export default Checkbox;
