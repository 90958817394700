import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";
import cx from "classnames";
import styles from "./Button.module.scss";

const IconButton: React.FC<IconButtonProps> = ({
  className,
  color = "inherit",
  ...props
}) => {
  return (
    <MuiIconButton
      color={color}
      className={cx({ [styles.grayButton]: color == "info" }, className)}
      {...props}
    />
  );
};

export default IconButton;
