import { FC, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Card from "@/components/Card";
import {
  Post,
  useCreateLikeMutation,
  useDeleteLikeMutation,
} from "@/fetch/social";
import Typography from "@/components/Typography";
import { useCurrentTrip, useResponsive, useDialog, useTrackers } from "@/hooks";
import {
  MoreIcon,
  BinIcon,
  HeartIcon,
  CommentIcon,
  PinIcon,
  InfoIcon,
} from "@/components/Icon";
import Button, { IconButton } from "@/components/Button";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemIcon, ListItemText } from "@/components/List";
import Divider from "@/components/Divider";
import PostDeleteConfirmationDialog from "./PostDeleteConfirmationDialog";
import PostCardMedia from "./PostCardMedia";
import styles from "./PostCard.module.scss";
import RichContentPreview from "@/components/RichContentPreview";
import TravellerNameLink from "./TravellerNameLink";
import DotSeparator from "../DotSeparator";
import theme from "@/theme/theme";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import ReportDialog from "./ReportDialog";
import PostLikesDialog from "./PostLikesDialog";
import SocialAvatar from "./SocialAvatar";

export const getFormattedDate = (
  createdAt: number | undefined,
  isMobile: boolean
) => {
  if (!createdAt) return "";
  const postDate = dayjs((createdAt || 0) * 1000);
  const currentDate = dayjs();
  return currentDate.diff(postDate, "day") > 5
    ? postDate.format(isMobile ? "D MMM YYYY" : "D MMMM YYYY")
    : postDate.fromNow(true);
};

const PostCard: FC<{ post: Post; isPinned?: boolean }> = ({
  post,
  isPinned = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isPostLikesDialogOpen, setIsPostLikesDialogOpen] = useState(false);
  const contentTextContainer = useRef<HTMLDivElement | null>(null);
  const [hasMoreContent, setHasMoreContent] = useState<boolean>(false);
  const [isViewed, setIsViewed] = useState<boolean>(false);

  const { isMobile } = useResponsive();
  const { currentTrip } = useCurrentTrip();
  const { track, captureException } = useTrackers();

  const { ref: postCardRef, inView: isPostInView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isViewed || !isPostInView) return;
    setIsViewed(true);
    track("Post Viewed", {
      postId: post.id,
      eventId: "post-viewed",
    });
  }, [isPostInView, isViewed]);

  const isMenuOpen = Boolean(anchorEl);
  const userCid = currentTrip?.cid;
  const profilePicturePath = post?.s3_profile_pic || "";
  const nickname = post?.nickname || "";
  const division = post?.division || "";
  const cid = post?.cid;
  const date = getFormattedDate(post?._ca, isMobile);
  const content = post?.content || "";
  const hasMedia = Boolean(post.media?.length);
  const isLiked = Boolean(
    post.likes?.sample_likes.find((each) => each.cid === userCid)
  );
  const totalLikes = post?.likes?.total_likes;
  const isAdmin = Boolean(post?.is_admin) || false;
  const adminTitle = post?.admin_title || null;
  const recentComments = post?.comments?.sample_comments
    ?.slice(0, 2)
    ?.reverse();
  const hasMoreComments = Boolean(
    (post?.comments?.total_comments || 0) > (recentComments?.length || 0)
  );

  const isPastTraveller = post?.trip_status === TripStatus.Complete;
  const isSuspended = Boolean(post?.is_suspended);
  const currentLike = post.likes?.sample_likes.find(
    (each) => each.cid === userCid
  );
  const { mutateAsync: createLike, isLoading: isCreateLikeLoading } =
    useCreateLikeMutation(post.id as number);
  const { mutateAsync: deleteLike, isLoading: isDeleteLikeLoading } =
    useDeleteLikeMutation({
      postId: post.id as number,
      likeId: currentLike?.id || 0,
    });
  const { openDialog: openDetailsDialog } = useDialog("post");

  const handleLike = async () => {
    try {
      if (isLiked) {
        await deleteLike();
        track("Post Like Removed", {
          postId: post.id,
          eventId: "post-like-removed",
        });
      } else {
        await createLike();
        track("Post Liked", { postId: post.id, eventId: "post-liked" });
      }
    } catch (error) {
      captureException(error);
    }
  };

  const handleOpenPostDetails = () => {
    openDetailsDialog({ id: post.id });
  };

  const handleClickMedia = (mediaId: number) => {
    openDetailsDialog({ id: post.id, selectedMediaId: mediaId });
  };

  useEffect(() => {
    if (!contentTextContainer?.current || !post?.content?.length) return;
    if (
      contentTextContainer?.current?.scrollHeight >
      contentTextContainer?.current?.offsetHeight
    )
      setHasMoreContent(true);
  }, [contentTextContainer, post]);

  const menuList =
    userCid === post?.cid
      ? [
          {
            title: "Delete post",
            icon: <BinIcon color="inherit" />,
            action: () => {
              setIsDeleteDialogOpen(true);
              setAnchorEl(null);
            },
          },
        ]
      : [
          {
            title: "Report",
            icon: <InfoIcon color="inherit" variant="outlined" />,
            action: () => {
              setIsReportDialogOpen(true);
              setAnchorEl(null);
            },
          },
        ];

  const travellerTitle = isPastTraveller
    ? "Past Traveller"
    : isSuspended
    ? "Deactivated"
    : division;
  const travellerTitleColor =
    isPastTraveller || isSuspended
      ? theme.palette.warning.main
      : "text.secondary";

  return (
    <Card
      className={cx(styles.rootCard, {
        [styles.pinnedCardRoot]: isPinned,
      })}
      ref={postCardRef}
    >
      {isPinned && (
        <div className={styles.pinContainer}>
          <PinIcon color="primary" />
          <Typography variant="subtitle2" color="text.accent">
            Pinned message
          </Typography>
        </div>
      )}
      <div className={styles.header}>
        <SocialAvatar
          cloudinaryPath={profilePicturePath}
          isAdmin={isAdmin}
          adminTitle={adminTitle}
        />
        <div className={styles.headerContent}>
          <div className={styles.title}>
            <TravellerNameLink variant="body1" cid={cid} nickname={nickname}>
              {!isAdmin && (
                <>
                  <Typography
                    variant="body2"
                    color={travellerTitleColor}
                    className={styles.division}
                  >
                    <DotSeparator color="info" size="0.25rem" />
                    {travellerTitle}
                  </Typography>
                </>
              )}
            </TravellerNameLink>
          </div>
          <Typography variant="body2" color="text.secondary">
            {date}
          </Typography>
        </div>
        {Boolean(menuList?.length) && (
          <IconButton
            className={styles.moreButton}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreIcon color="info" />
          </IconButton>
        )}
      </div>
      <div
        className={cx(styles.contentText, {
          [styles.pinnedContentText]: isPinned,
        })}
        ref={contentTextContainer}
      >
        <RichContentPreview content={content} />
      </div>
      {hasMoreContent && !isPinned && (
        <Button
          size="small"
          className={styles.seeMoreButton}
          color="info"
          onClick={handleOpenPostDetails}
        >
          See more
        </Button>
      )}
      {hasMedia && (
        <PostCardMedia media={post.media || []} onClick={handleClickMedia} />
      )}
      {!hasMedia && <Divider className={styles.divider} />}
      <div className={styles.bottomContainer}>
        <IconButton
          onClick={handleLike}
          className={styles.likeButton}
          disabled={isCreateLikeLoading || isDeleteLikeLoading}
        >
          <HeartIcon
            variant={isLiked ? "contained" : "outlined"}
            htmlColor={
              isLiked
                ? theme.palette.common.red.medium
                : theme.palette.common.neutral.black
            }
          />
        </IconButton>
        <Typography
          variant="body2"
          className={styles.likeNumber}
          onClick={() => setIsPostLikesDialogOpen(true)}
        >
          {totalLikes || ""}
        </Typography>
        <IconButton
          onClick={handleOpenPostDetails}
          className={styles.likeButton}
        >
          <CommentIcon variant="outlined" />
        </IconButton>
        <Typography
          variant="body2"
          className={styles.likeNumber}
          onClick={handleOpenPostDetails}
        >
          {post.comments?.total_comments || ""}
        </Typography>
      </div>
      {Boolean(recentComments?.length) && (
        <div>
          {recentComments?.map((comment) => (
            <div key={comment.id} className={styles.recentCommentItem}>
              <Typography
                variant="body1"
                component="span"
                className={styles.recentCommentNickname}
              >
                {comment.nickname}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className={styles.recentCommentContent}
              >
                {comment.content}
              </Typography>
            </div>
          ))}
          {hasMoreComments && (
            <Button
              size="small"
              variant="text"
              onClick={handleOpenPostDetails}
              className={styles.viewAllCommentsButton}
            >
              View all comments
            </Button>
          )}
        </div>
      )}
      {!Boolean(recentComments?.length) && (
        <div className={styles.firstReactionSection}>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.firstReactionText}
            onClick={handleOpenPostDetails}
          >
            {Boolean(totalLikes)
              ? "Be the first to comment"
              : "Be the first to react"}
          </Typography>
        </div>
      )}

      <Menu
        elevation={3}
        id={`post-${post.id}-select-menu`}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuList?.map((item) => (
          <MenuItem key={item.title} onClick={item.action}>
            <ListItemIcon sx={{ color: "text.primary" }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {isDeleteDialogOpen && post?.id && (
        <PostDeleteConfirmationDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          postId={post.id}
        />
      )}
      {isReportDialogOpen && post?.id && (
        <ReportDialog
          onClose={() => setIsReportDialogOpen(false)}
          postId={post.id}
        />
      )}
      {isPostLikesDialogOpen && post?.id && (
        <PostLikesDialog
          onClose={() => setIsPostLikesDialogOpen(false)}
          postId={post.id}
        />
      )}
    </Card>
  );
};
export default PostCard;
