import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResourceGuideIconV4: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        opacity="0.2"
        d="M41.7978 36.8642C41.6451 36.9531 41.4715 37 41.2947 37L24 37C23.4477 37 23 36.5523 23 36C23 35.4477 23.4477 35 24 35L43 35C43.5512 35 43.7479 35.7289 43.2716 36.0062L41.7978 36.8642Z"
        fill="#0095CD"
      />
      <path
        opacity="0.2"
        d="M15.2022 19.1358C15.3549 19.0469 15.5285 19 15.7053 19L33 19C33.5523 19 34 19.4477 34 20C34 20.5523 33.5523 21 33 21L14 21C13.4488 21 13.2521 20.2711 13.7284 19.9938L15.2022 19.1358Z"
        fill="#0095CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3529 12.9858C26.2484 12.9858 25.3529 13.8812 25.3529 14.9858V17.615H27.3529V14.9858H29.0834L29.0834 17.615H31.0834V14.9858C31.0834 13.8812 30.1879 12.9858 29.0834 12.9858H27.3529ZM25.3529 26.6881H15.6352C15.3747 26.6881 15.1245 26.5865 14.9379 26.4049L11.3027 22.8683C10.8991 22.4757 10.8991 21.8274 11.3027 21.4348L14.9379 17.8983C15.1245 17.7166 15.3747 17.615 15.6352 17.615H33.7464C34.2987 17.615 34.7464 18.0627 34.7464 18.615V25.6881C34.7464 26.2404 34.2987 26.6881 33.7464 26.6881H31.0834V29.41L40.8012 29.41C41.0616 29.41 41.3118 29.5116 41.4985 29.6933L45.1336 33.2298C45.5372 33.6224 45.5372 34.2707 45.1336 34.6633L41.4985 38.1999C41.3118 38.3815 41.0616 38.4831 40.8012 38.4831L31.0834 38.4831V51.1854H37.5949C38.5971 51.1854 39.4095 51.9978 39.4095 53H17.0268C17.0268 51.9978 17.8392 51.1854 18.8414 51.1854H25.3529V38.4831L22.6899 38.4831C22.1376 38.4831 21.6899 38.0354 21.6899 37.4831L21.6899 30.41C21.6899 29.8577 22.1376 29.41 22.6899 29.41H25.3529V26.6881ZM27.3529 51.1854H29.0834V38.4831H27.3529V51.1854ZM29.0834 26.6881V29.41H27.3529V26.6881H29.0834ZM13.4341 22.1516L16.0413 19.615H32.7464V24.6881H16.0413L13.4341 22.1516ZM40.395 36.4831L43.0023 33.9466L40.395 31.41L23.6899 31.41L23.6899 36.4831L40.395 36.4831ZM29.1508 22.1515C29.1508 22.6526 28.7332 23.0588 28.2182 23.0588C27.7031 23.0588 27.2855 22.6526 27.2855 22.1515C27.2855 21.6504 27.7031 21.2442 28.2182 21.2442C28.7332 21.2442 29.1508 21.6504 29.1508 22.1515ZM28.2182 34.8538C28.7332 34.8538 29.1508 34.4476 29.1508 33.9465C29.1508 33.4454 28.7332 33.0392 28.2182 33.0392C27.7031 33.0392 27.2855 33.4454 27.2855 33.9465C27.2855 34.4476 27.7031 34.8538 28.2182 34.8538Z"
        fill="#003749"
      />
      <path
        d="M44.7375 12.4805C48.762 12.4805 52.0245 15.7952 52.0245 19.884C52.0245 23.9728 48.762 27.2875 44.7375 27.2875C40.7129 27.2875 37.4504 23.9728 37.4504 19.884C37.4504 15.7952 40.7129 12.4805 44.7375 12.4805ZM44.7375 18.4033C44.335 18.4033 44.0087 18.7348 44.0087 19.1437V23.5858C44.0087 23.9946 44.335 24.3261 44.7375 24.3261C45.1399 24.3261 45.4662 23.9946 45.4662 23.5858V19.1437C45.4662 18.7348 45.1399 18.4033 44.7375 18.4033ZM44.7375 15.4419C44.335 15.4419 44.0087 15.7734 44.0087 16.1823C44.0087 16.5912 44.335 16.9226 44.7375 16.9226C45.1399 16.9226 45.4662 16.5912 45.4662 16.1823C45.4662 15.7734 45.1399 15.4419 44.7375 15.4419Z"
        fill="#0095CD"
      />
    </SvgIcon>
  );
};

export default ResourceGuideIconV4;
