import { useResponsive } from "@/hooks";
import { useRouter } from "next/router";
import React, { useEffect, useReducer, useState } from "react";
import {
  ChatContext,
  ChatContextType,
  chatReducer,
  initialState,
} from "./hooks/useChatContext";

const ChatProvider: React.FC = ({ children }) => {
  const { isMobile } = useResponsive();
  const router = useRouter();
  const [chatState, chatDispatch] = useReducer(chatReducer, initialState);
  const [isChatPopOverOpen, setIsChatPopOverOpen] = useState<boolean>(false);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(false);
  const [showTravellersList, setShowTravellersList] = useState(false);
  const [currentConversationSid, setCurrentConversationSid] =
    useState<string>();
  const chatContext: ChatContextType = {
    state: chatState,
    dispatch: chatDispatch,
    openChat: ({ conversationSid, toggleState = false, loading = false }) => {
      setOverlayLoading(loading);
      setCurrentConversationSid(conversationSid);
      if (isMobile) {
        return router.push("/social/messages");
      } else {
        setIsChatPopOverOpen((prev) => (toggleState ? !prev : true));
      }
    },
    changePopoverState: (open?: boolean | undefined) => {
      setIsChatPopOverOpen((prev) => open ?? !prev);
    },
    overlayLoading,
    setOverlayLoading,
    currentConversationSid,
    setCurrentConversationSid,
    isChatPopOverOpen,
    setIsChatPopOverOpen,
    showTravellersList,
    setShowTravellersList,
  };

  useEffect(() => {
    if (!isChatPopOverOpen) setCurrentConversationSid(undefined);
  }, [isChatPopOverOpen]);

  return (
    <ChatContext.Provider value={chatContext}>{children}</ChatContext.Provider>
  );
};

export default ChatProvider;
