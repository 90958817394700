import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AcademyIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12.9078 2.21989L22.4559 7.11038C22.9473 7.36208 23.1416 7.96442 22.8898 8.45575C22.7942 8.64228 22.6424 8.79406 22.4559 8.88962L18.9735 10.6736C18.9909 10.7798 19 10.8888 19 11V17.7445C19 18.5322 18.5377 19.2466 17.819 19.5692C15.6905 20.5246 13.7508 21.0023 12 21.0023C10.2492 21.0023 8.30951 20.5246 6.18099 19.5692C5.46236 19.2466 5 18.5323 5 17.7446V11C5 10.8893 5.00899 10.7807 5.02629 10.6749L1.54384 8.88946C1.0525 8.6376 0.858414 8.03519 1.11033 7.54396C1.20587 7.35766 1.3575 7.20605 1.54384 7.11053L11.0838 2.22019C11.6563 1.92671 12.3351 1.9266 12.9078 2.21989ZM11.9961 3.99912L4.19161 8L11.9961 12.0009L19.8058 8L11.9961 3.99912Z" />
      ) : (
        <path d="M12.9078 2.21989L22.4559 7.11038C22.9473 7.36209 23.1416 7.96442 22.8898 8.45575C22.7942 8.64228 22.6424 8.79406 22.4559 8.88962L18.9735 10.6736C18.9909 10.7798 19 10.8888 19 11V17.7445C19 18.5322 18.5377 19.2466 17.819 19.5692C15.6905 20.5246 13.7508 21.0023 12 21.0023C10.2492 21.0023 8.30951 20.5246 6.18099 19.5692C5.46236 19.2466 5 18.5323 5 17.7446V11C5 10.8893 5.00899 10.7807 5.02629 10.6749L1.54384 8.88946C1.0525 8.6376 0.858414 8.0352 1.11033 7.54396C1.20587 7.35766 1.3575 7.20605 1.54384 7.11054L11.0838 2.22019C11.6563 1.92671 12.3351 1.9266 12.9078 2.21989ZM17 11.683L12.9078 13.7801C12.3351 14.0734 11.6563 14.0733 11.0838 13.7798L7 11.686V17.7446L7.37445 17.9081C9.10821 18.6444 10.6512 19.0023 12 19.0023C13.3488 19.0023 14.8918 18.6444 16.6256 17.9081L17 17.7446V11.683ZM11.9961 3.99912L4.19161 8L11.9961 12.0009L19.8058 8L11.9961 3.99912Z" />
      )}
    </SvgIcon>
  );
};

export default AcademyIcon;
