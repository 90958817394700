import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import useGroupQuery from "./useGroupQuery";

export type AcceptConditionsResult = string;
type QueryError = {};

const useAcceptConditionsMutation = (
  options?: UseMutationOptions<AcceptConditionsResult, QueryError>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { refetch: refetchGroupQuery } = useGroupQuery();
  return {
    ...useMutation<AcceptConditionsResult, QueryError>(
      async () => {
        return fetchWithAuth<AcceptConditionsResult>(
          `${GATEWAY_URL}/group/accept_conditions`,
          {
            method: "POST",
          }
        );
      },
      {
        ...options,
        onSuccess: () => {
          refetchGroupQuery();
        },
      }
    ),
    cancel,
  };
};
export default useAcceptConditionsMutation;
