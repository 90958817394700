import SvgIcon, { SvgIconProps } from "./SvgIcon";

const PlayCircleIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM10 6.86852C9.48716 6.86852 9.06449 7.25456 9.00673 7.7519L9 7.86852V16.1315C9 16.3289 9.05844 16.5219 9.16795 16.6862C9.45242 17.1129 10.0081 17.2504 10.4539 17.0226L10.5547 16.9635L16.7519 12.8321C16.8618 12.7588 16.956 12.6646 17.0293 12.5547C17.3137 12.128 17.227 11.5622 16.8452 11.2382L16.7519 11.1679L10.5547 7.03647C10.3904 6.92695 10.1974 6.86852 10 6.86852Z" />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM10 6.86852C10.1974 6.86852 10.3904 6.92695 10.5547 7.03647L16.7519 11.1679C17.2115 11.4743 17.3356 12.0952 17.0293 12.5547C16.956 12.6646 16.8618 12.7588 16.7519 12.8321L10.5547 16.9635C10.0952 17.2699 9.4743 17.1457 9.16795 16.6862C9.05844 16.5219 9 16.3289 9 16.1315V7.86852C9 7.31623 9.44772 6.86852 10 6.86852ZM11 9.737V14.262L14.393 12L11 9.737Z" />
      )}
    </SvgIcon>
  );
};

export default PlayCircleIcon;
