import { ClickableCard } from "@/components/Card";
import styles from "./MobileSubroutesMenu.module.scss";
import { ChevronIcon } from "@/components/Icon";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemText } from "@/components/List";
import { useState } from "react";
import { useRoutes } from "@/hooks";
import Link from "next/link";
import { useRouter } from "next/router";
import { Skeleton } from "@/components/Loader";

const MobileSubroutesMenu: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const { getMatchedRoute, getMatchedSubroute } = useRoutes();
  const { pathname } = useRouter();
  const { route } = getMatchedRoute();
  const subroutes = route?.children || [];
  const subroute = getMatchedSubroute();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isLoading && (
        <ClickableCard
          onClick={(event: any) => handleClick(event)}
          outlined
          className={styles.container}
        >
          {subroute?.name}
          <ChevronIcon dir="down" />
        </ClickableCard>
      )}

      {isLoading && <Skeleton variant="rectangular" height="48px" />}

      <Menu
        elevation={3}
        id="subroute-select-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {subroutes?.map((subroute) => (
          <MenuItem
            key={subroute.path}
            selected={subroute.path === pathname}
            className={styles.menuItem}
            disabled={subroute.disabled}
          >
            <Link href={subroute.path} passHref>
              <ListItemText
                aria-selected="true"
                primary={subroute.name}
                onClick={() => handleClose()}
              />
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MobileSubroutesMenu;
