import { useReportMutation, useReportReasonsQuery } from "@/fetch/social";
import Dialog, { DialogContent, DialogTitle } from "../Dialog";
import {
  FormControlLabel,
  FormActions,
  getRemainingCharactersText,
} from "../FormController";
import Grid from "../Grid";
import { Skeleton } from "../Loader";
import Radio, { RadioGroup } from "../Radio";
import Typography from "../Typography";
import styles from "./ReportDialog.module.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar, useTrackers } from "@/hooks";
import TextField from "../TextField";
import { useEffect, useState } from "react";
import { CheckedIcon } from "../Icon";
import Button from "../Button";

const MAX_CHARACTERS = 280;

type ReportFormType = {
  details?: string;
  reasonId?: number;
};

const validationSchema = yup.object({
  details: yup
    .string()
    .nullable()
    .max(
      MAX_CHARACTERS,
      `Details should not contains more than ${MAX_CHARACTERS} characters.`
    ),
  reasonId: yup.number().required("Please specify the reason of report"),
});

const ReportDialog: React.FC<{
  onClose: () => void;
  postId: number;
  commentId?: number;
  type?: "post" | "comment";
}> = ({ onClose, postId, commentId, type = "post" }) => {
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const { data: reasons, isLoading: isReasonsLoading } =
    useReportReasonsQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { captureException, track } = useTrackers();
  const {
    mutateAsync: report,
    isLoading: isReportSubmitting,
    cancel: cancelReport,
  } = useReportMutation();

  const isFormSubmitting = isReportSubmitting;
  const isFormLoading = isReasonsLoading;
  const isFormDisabled = isFormLoading || isFormSubmitting;

  const onSubmit = async (values: ReportFormType) => {
    try {
      if (formik.dirty && values?.reasonId)
        await report({
          post_id: postId,
          comment_id: commentId,
          reason_id: values?.reasonId,
          details: Boolean(values?.details?.length)
            ? values?.details
            : undefined,
        });
      if (type === "comment") {
        track("Comment Reported", {
          eventId: "comment-reported",
          reasonId: values?.reasonId,
        });
      } else {
        track("Post Reported", {
          eventId: "post-reported",
          reasonId: values?.reasonId,
        });
      }
      setIsReportSubmitted(true);
    } catch (error: any) {
      console.error(error);
      captureException(error);
      enqueueSnackbar("Sorry! Something went wrong, please try again later.", {
        variant: "error",
      });
    } finally {
    }
  };

  const initialValues: ReportFormType = {
    details: "",
    reasonId: undefined,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const reasonError =
    formik.touched.reasonId && (formik.errors.reasonId as string);

  useEffect(() => {
    return () => {
      cancelReport();
    };
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle onClose={onClose}>Report an issue</DialogTitle>

      {isReportSubmitted ? (
        <DialogContent className={styles.confirmationRoot}>
          <CheckedIcon
            variant="contained"
            color="success"
            className={styles.checkedIcon}
          />
          <Typography variant="h6" className={styles.confirmationTitle}>
            Thanks for letting us know
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Your report will be reviewed by our team
          </Typography>
          <Button onClick={() => onClose()} className={styles.doneButton}>
            Done
          </Button>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="h5" className={styles.title}>
            Why are you reporting this {type}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Rather than having you figure out what rule someone violated, we
            want to know what you’re experiencing or seeing. This helps us
            figure out what’s going on here and resolve the issue more quickly
            and accurately.
          </Typography>

          <Grid container className={styles.gridContainer}>
            <Grid item xs={12}>
              {isFormLoading ? (
                <RadioGroup>
                  {Array(4)
                    .fill(null)
                    .map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value="yes"
                        sx={{ p: "8px" }}
                        control={
                          <Skeleton
                            variant="circular"
                            width="24px"
                            height="24px"
                          />
                        }
                        label={
                          <Skeleton width={150} style={{ marginLeft: "8px" }} />
                        }
                      />
                    ))}
                </RadioGroup>
              ) : (
                <RadioGroup
                  aria-labelledby="radio-select-reason"
                  name="reason"
                  value={formik.values.reasonId}
                  onChange={(event, value) =>
                    formik.setValues((values) => ({
                      ...values,
                      reasonId: parseInt(value, 10),
                    }))
                  }
                >
                  {reasons?.map((reason) => (
                    <FormControlLabel
                      key={reason.id}
                      value={reason.id}
                      control={<Radio />}
                      label={reason.content}
                      disabled={isFormDisabled}
                    />
                  ))}
                </RadioGroup>
              )}
              {Boolean(reasonError) && (
                <Typography variant="subtitle2" color="error">
                  {reasonError}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" className={styles.title}>
                {isFormLoading ? (
                  <Skeleton width="150px" />
                ) : (
                  " Additional details "
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {isFormLoading ? (
                <Skeleton variant="rectangular" height="84px" />
              ) : (
                <TextField
                  disabled={isFormDisabled}
                  fullWidth
                  multiline
                  maxRows={4}
                  inputProps={{ maxLength: MAX_CHARACTERS }}
                  id="details"
                  name="details"
                  placeholder="Please enter any additional details relevant to your report."
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.details && Boolean(formik.errors.details)
                  }
                  helperText={formik.touched.details && formik.errors.details}
                />
              )}
              <Typography
                variant="body1"
                align="right"
                className={styles.charactersHelper}
              >
                {getRemainingCharactersText(
                  formik.values.details,
                  MAX_CHARACTERS
                )}
              </Typography>
            </Grid>
          </Grid>
          <FormActions
            isDialogAction
            primaryButton={{
              label: "Submit report",
              loadingLabel: "Submitting...",
              action: () => formik.handleSubmit(),
              isLoading: isFormSubmitting,
              isDisabled: isFormDisabled || !formik.values.reasonId,
            }}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ReportDialog;
