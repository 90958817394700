import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum RESOURCE_CATEGORIES {
  Visa = "VISA",
  PDG = "PDG",
  Guides = "GUIDES",
  PostArrivalDocuments = "POST_ARRIVAL_DOCUMENTS",
}

export type Resource = Partial<{
  title: string;
  description: string;
  thumbnail: string;
  resources: Array<{ id: number }>;
  category: RESOURCE_CATEGORIES;
  isFeatured: boolean;
  htmlContent: string;
  id: number;
}>;

type PdgResourcesResult = Partial<{
  success: boolean;
  result: Array<Resource>;
}>;
type QueryError = {};

const useResourcesQuery = (
  categoryType: RESOURCE_CATEGORIES,
  options?: UseQueryOptions<
    PdgResourcesResult,
    QueryError,
    PdgResourcesResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/resources/trip/${tid}/category/${categoryType}`;
  const cacheKey = [url, categoryType];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<PdgResourcesResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      return fetchWithAuth<PdgResourcesResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useResourcesQuery;
