import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogActions, {
  DialogActionsProps,
} from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogContentText from "@mui/material/DialogContentText";
import cx from "classnames";
import { useResponsive } from "@/hooks";
import styles from "./Dialog.module.scss";

const Dialog: React.FC<DialogProps> = (props) => {
  const { isMobile } = useResponsive();
  return (
    <MuiDialog
      fullScreen={isMobile || props.fullScreen}
      {...props}
      PaperProps={{ elevation: 4, ...props.PaperProps }}
    />
  );
};

const DialogActions: React.FC<DialogActionsProps> = (props) => {
  return (
    <MuiDialogActions
      {...props}
      className={cx(styles.dialogActions, props.className)}
    />
  );
};

const DialogContent = MuiDialogContent;
const DialogContentText = MuiDialogContentText;

export default Dialog;
export { DialogContent, DialogActions, DialogContentText };
