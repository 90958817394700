import { FC, ReactElement } from "react";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";
import styles from "./MiniTable.module.scss";

type MiniTableProps = {
  header: string | ReactElement;
  value: string | ReactElement;
  className?: string;
};

const MiniTable: FC<MiniTableProps> = ({ header, value, className }) => {
  return (
    <MuiTableContainer className={className}>
      <MuiTable>
        <MuiTableHead>
          <MuiTableRow>
            <MuiTableCell variant="head" className={styles.headerCell}>
              {header}
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>

        <MuiTableBody>
          <MuiTableRow>
            <MuiTableCell variant="body" className={styles.bodyCell}>
              {value}
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

export default MiniTable;
