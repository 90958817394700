import SvgIcon, { SvgIconProps } from "./SvgIcon";

const TickIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.331 7.25676C18.7415 6.88727 19.3738 6.92051 19.7432 7.33099C20.0843 7.7099 20.0822 8.27781 19.757 8.65355L19.669 8.74326L9.67036 17.7433C9.30549 18.0717 8.76226 18.0835 8.38501 17.7876L8.29436 17.7072L4.29301 13.7072C3.90242 13.3168 3.90232 12.6836 4.29277 12.293C4.6532 11.9325 5.22042 11.9047 5.61277 12.2096L5.70699 12.2928L9.037 15.621L18.331 7.25676Z" />
    </SvgIcon>
  );
};

export default TickIcon;
