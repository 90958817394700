import { useMemo, useCallback, useState } from "react";
import Button, { IconButton } from "@/components/Button";
import cx from "classnames";
import { useDropzone } from "react-dropzone";
import theme from "@/theme/theme";
import Typography from "@/components/Typography";
import CropperDialog, { CropperOptions } from "./CropperDialog";
import Avatar from "@/components/Avatar";
import styles from "./Uploader.module.scss";
import { CameraIcon, EditIcon } from "@/components/Icon";
import {
  UPLOADER_ACCEPT_TYPES,
  UploaderFile,
  UploaderBaseProps,
} from "./Uploader";
import { useCloudinaryUrl } from "@/hooks";
import { Skeleton } from "@/components/Loader";

interface ProfileUploaderProps extends UploaderBaseProps {
  onChangeFile: (acceptedFiles: Array<UploaderFile>, preview?: string) => void;
  cloudinaryPath?: string | null | undefined;
  label?: string;
  cropperAdditionalInfo?: React.ReactNode;
}

const ProfileUploader: React.FC<ProfileUploaderProps> = ({
  maxSize,
  maxFiles = 1,
  accept = [UPLOADER_ACCEPT_TYPES.Image],
  onChangeFile,
  disabled = false,
  multiple = false,
  className,
  isLoading,
  style,
  cloudinaryPath,
  label = "",
  cropperAdditionalInfo,
}) => {
  const { getProfilePictureUrl } = useCloudinaryUrl();
  const [croppedProfileFile, setCroppedProfileFile] = useState<
    UploaderFile | undefined
  >(undefined);

  const [rawProfileFile, setRawProfileFile] = useState<
    UploaderFile | undefined
  >(undefined);

  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [cropperOptions, setCropperOptions] = useState<CropperOptions>();

  const openCropper = useCallback(
    (acceptedFiles?: Array<UploaderFile>) => {
      if (acceptedFiles?.[0]) {
        const preview = URL.createObjectURL(acceptedFiles?.[0]) || undefined;
        setRawProfileFile({
          ...Object.assign(acceptedFiles?.[0], {
            preview: preview,
          }),
        });
        setImageToCrop(preview || null);
        setIsCropDialogOpen(true);
      } else if (rawProfileFile?.preview) {
        setImageToCrop(rawProfileFile?.preview);
        setIsCropDialogOpen(true);
      } else if (cloudinaryPath) {
        const imagePath = getProfilePictureUrl(cloudinaryPath);
        setImageToCrop(imagePath);
        setIsCropDialogOpen(true);
      }
    },
    [cloudinaryPath, getProfilePictureUrl, rawProfileFile?.preview]
  );

  const onCropComplete = useCallback(
    (croppedImageFile: File) => {
      if (croppedImageFile) {
        const preview = URL.createObjectURL(croppedImageFile) || undefined;
        setCroppedProfileFile({
          ...Object.assign(croppedImageFile, {
            preview: preview,
          }),
        });
        onChangeFile([croppedImageFile], preview);
      }
    },
    [onChangeFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    open: openFilePicker,
  } = useDropzone({
    onDrop: openCropper,
    maxFiles,
    maxSize,
    noClick: true,
    accept: (accept || [])?.reduce((obj, curr) => ({ ...obj, ...curr }), {}),
    disabled,
    multiple,
  });

  const ProfileStyle = {
    width: 120,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    borderWidth: `2px`,
    borderStyle: "dashed",
    borderColor: disabled
      ? theme.palette.divider
      : theme.palette.common.blue.light,
    color: theme.palette.text.secondary,
    outline: "none",
    position: "relative" as "relative",
  };

  const RejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const dropzoneContainerStyle = useMemo(
    () => ({
      ...ProfileStyle,
      ...(isDragReject ? RejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragReject, disabled]
  );

  const onCropDialogClose = () => {
    setIsCropDialogOpen(false);
  };

  return (
    <div className={cx(styles.profileRoot, className)} style={{ ...style }}>
      {isLoading ? (
        <>
          <Skeleton width="120px" height="120px" variant="circular" />
          <Skeleton width="120px" style={{ marginLeft: "24px" }} />
        </>
      ) : (
        <>
          <div {...getRootProps({ style: { ...dropzoneContainerStyle } })}>
            <input {...getInputProps()} />
            {croppedProfileFile || cloudinaryPath ? (
              <>
                <IconButton
                  color="info"
                  disabled={disabled}
                  className={styles.cropButton}
                  onClick={(event) => {
                    event.preventDefault();
                    openCropper();
                  }}
                >
                  <EditIcon />
                </IconButton>
                <Avatar
                  src={croppedProfileFile?.preview || ""}
                  cloudinaryPath={
                    !croppedProfileFile?.preview ? cloudinaryPath : undefined
                  }
                  size="xxl"
                />
              </>
            ) : (
              <CameraIcon
                variant="outlined"
                htmlColor={theme.palette.common.grey[300]}
                className={styles.cameraIcon}
              />
            )}
          </div>
          <Button
            size="small"
            variant="text"
            className={styles.pickerButton}
            onClick={openFilePicker}
            disabled={disabled}
          >
            Upload a {label} photo
          </Button>
        </>
      )}

      {isCropDialogOpen && (
        <CropperDialog
          open={isCropDialogOpen}
          onClose={onCropDialogClose}
          image={imageToCrop || ""}
          onSubmit={onCropComplete}
          onCropperOptionsChange={setCropperOptions}
          options={cropperOptions}
          label={label}
        >
          {cropperAdditionalInfo}
        </CropperDialog>
      )}
    </div>
  );
};

export default ProfileUploader;
