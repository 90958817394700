import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { Channel } from "./useNotificationSettingsQuery";
import { useCurrentTrip } from "@/hooks";
import type { Notification as NotificationSchemaType } from "@gwat/notification-schema";

type UpdateNotificationSettingsVariables = {
  settings: Array<{
    intent: NotificationSchemaType["intent"];
    channels: Array<Channel>;
  }>;
};

type UpdateNotificationSettingsResult = {
  message: string;
};

type QueryError = {};

const useUpdateNotificationSettingsMutation = (
  options?: UseMutationOptions<
    UpdateNotificationSettingsResult,
    QueryError,
    UpdateNotificationSettingsVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return {
    ...useMutation<
      UpdateNotificationSettingsResult,
      QueryError,
      UpdateNotificationSettingsVariables
    >(
      async ({ settings }: UpdateNotificationSettingsVariables) => {
        const url = `${GATEWAY_URL}/traveller/${cid}/notification-settings`;
        if (!cid) throw new Error("cid not found");

        return fetchWithAuth<UpdateNotificationSettingsResult>(url, {
          method: "POST",
          body: { settings },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateNotificationSettingsMutation;
