import Sidebar, { SidebarNavigator } from "@/components/Sidebar";
import { Group, useGroupQuery } from "@/fetch/social";
import { useDialog, useResponsive, useRoutes, useTrackers } from "@/hooks";
import styles from "./SocialSidebar.module.scss";
import Typography from "@/components/Typography";
import { AvatarGroup } from "@/components/Avatar";
import compact from "lodash/compact";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import { Skeleton } from "@/components/Loader";
import { useRouter } from "next/router";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import RecentMedia from "./RecentMedia";
import CreatePostDialog from "./CreatePostDialog";

const GroupMembers: React.FC<{
  hideDescription?: boolean;
  hideMemberCounts?: boolean;
}> = ({ hideDescription, hideMemberCounts }) => {
  const { data: group, isLoading: isLoading } = useGroupQuery();
  const { openTopLevelDialog } = useDialog();
  const { track } = useTrackers();
  const membersProfilePicPath = compact(
    group?.recent_members?.map((member) => member?.s3_profile_pic)
  ).slice(0, 5);
  const totalMembers = group?.total_members
    ? group?.total_members - membersProfilePicPath.length
    : 0;

  const description = group?.description;

  const handleOpenGroupMembersDialog = () => {
    track("Travellers List Opened", { eventId: "travellers-list-opened" });
    openTopLevelDialog(TopLevelDialogNames.GroupMembers);
  };

  const tempImgUrls = [
    "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/c_fill,h_48,w_48/f_auto/v1719779400/website/static/header-social-proof/1",
    "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/c_fill,h_48,w_48/f_auto/v1719779400/website/static/header-social-proof/4",
    "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/c_fill,h_48,w_48/f_auto/v1719779400/website/static/header-social-proof/2",
    "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/c_fill,h_48,w_48/f_auto/v1719779400/website/static/header-social-proof/3",
  ];

  return isLoading ? (
    <>
      <div className={styles.groupAvatarLoader}>
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="circular"
              height="36px"
              width="36px"
            />
          ))}
      </div>
      {!hideDescription && (
        <>
          <Skeleton width="100%" />
          <Skeleton width="70%" />
        </>
      )}
    </>
  ) : (
    <>
      <Button
        className={styles.membersButton}
        variant="text"
        color="info"
        onClick={handleOpenGroupMembersDialog}
      >
        <AvatarGroup
          max={6}
          cloudinaryPaths={
            membersProfilePicPath.length < 3 ? [] : membersProfilePicPath
          }
          imageUrls={membersProfilePicPath.length < 3 ? tempImgUrls : undefined}
          className={styles.avatars}
          AvatarProps={{ size: "s" }}
        />
        {!hideMemberCounts && Boolean(totalMembers) && (
          <Typography variant="button" className={styles.moreMembersText}>
            +{totalMembers}
          </Typography>
        )}
      </Button>
      {!hideDescription && (
        <Typography
          className={styles.title}
          variant="subtitle1"
          color="text.secondary"
        >
          {description}
        </Typography>
      )}
    </>
  );
};

const SocialSidebar: React.FC = () => {
  const router = useRouter();
  const pathname = router.pathname;
  const { groupSubroutesByIndex, hasAccessToRoute } = useRoutes();
  const subRouteGroups = groupSubroutesByIndex();
  const hasSocialAccess = hasAccessToRoute("Social");
  const { isMobile } = useResponsive();
  const { data: groupData, isLoading: isGroupLoading } = useGroupQuery();
  const {
    onClose: onDialogClose,
    openDialog: openCreatePostDialog,
    isOpen: isCreatingPostDialogOpen,
  } = useDialog("new-post");

  const onCreatePost = async () => {
    router.push({ pathname: "/social/timeline" });
  };

  return !isMobile && hasSocialAccess ? (
    <Sidebar>
      <div>
        <Typography className={styles.title} variant="h5">
          Your group
        </Typography>
        <GroupMembers />
      </div>
      <Divider className={styles.divider} />
      <SidebarNavigator
        isLoading={isGroupLoading}
        sections={subRouteGroups}
        currentPath={pathname}
        loaderCount={3}
      />
      <Button
        fullWidth
        disabled={isGroupLoading}
        onClick={() => openCreatePostDialog()}
      >
        Post
      </Button>
      {isCreatingPostDialogOpen && (
        <CreatePostDialog
          onClose={onDialogClose}
          onCreatePost={() => onCreatePost()}
        />
      )}
      <RecentMedia />
    </Sidebar>
  ) : null;
};

export default SocialSidebar;
export { GroupMembers };
