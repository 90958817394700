import { ChangeEvent } from "react";

import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import TextField, { FieldLockStatement } from "@/components/TextField";
import { LockIcon } from "@/components/Icon";
import DatePicker from "@/components/DatePicker";
import styles from "./PersonalDetailsForm.module.scss";
import { QuestionTextLoader, RadioLoader } from "./PersonalDetailsFormLoader";
import Radio, { RadioGroup } from "@/components/Radio";
import { FormControlLabel } from "@/components/FormController";
import { ConsumeType, Status } from "@/components/PersonalDetailsForms";
import dayjs from "dayjs";
import theme from "@/theme/theme";

export type PersonalDetailsDates =
  | "personalDetailsCriminalDate"
  | "personalDetailsDrinkDrivingDate"
  | "personalDetailsDrivingOffencesDate"
  | "personalDetailsWorkingHolidayVisaDate";

export const TripleAnswerItem: React.FC<{
  question: string;
  name: string;
  value: ConsumeType | undefined | null;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  locked?: boolean;
  error?: string | boolean;
}> = ({
  question,
  name,
  value,
  onChange,
  disabled,
  isLoading,
  locked,
  error,
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        {isLoading ? (
          QuestionTextLoader
        ) : (
          <>
            <Typography variant="subtitle1" className={styles.question}>
              {locked && (
                <LockIcon
                  fontSize="small"
                  variant="outlined"
                  htmlColor={theme.palette.action.disabled}
                />
              )}
              {question}
            </Typography>
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error">
                {error}
              </Typography>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {isLoading ? (
          <RadioLoader optionCount={3} />
        ) : (
          <RadioGroup
            row
            aria-labelledby={`radio-select-${name}`}
            name={name}
            value={value}
            onChange={onChange}
          >
            <FormControlLabel
              value={ConsumeType.Never}
              control={<Radio />}
              label="Never"
              disabled={disabled || locked}
            />
            <FormControlLabel
              value={ConsumeType.Socially}
              control={<Radio />}
              label="Socially"
              disabled={disabled || locked}
            />
            <FormControlLabel
              value={ConsumeType.Regularly}
              control={<Radio />}
              label="Regularly"
              disabled={disabled || locked}
            />
          </RadioGroup>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export const AnswerItem: React.FC<{
  question: string;
  name: string;
  value: Status | undefined | null;
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  disabled?: boolean;
  locked?: boolean;
  isLoading?: boolean;
  error?: string | boolean;
  description?: {
    question: string;
    error: string | undefined;
    touched: boolean | undefined;
    name: string;
    value: string | undefined;
    locked: boolean;
  };
  bac?: {
    error: string | undefined;
    touched: boolean | undefined;
    name: string;
    value: number | undefined;
    locked: boolean;
  };
  date?: {
    error: string | undefined;
    touched: boolean | undefined;
    name: PersonalDetailsDates;
    value: number | null | undefined;
    locked: boolean;
    label?: string | undefined;
    onChange: (date: dayjs.Dayjs | null, key: PersonalDetailsDates) => void;
  };
}> = ({
  question,
  name,
  value = Status.No,
  onChange,
  disabled,
  locked,
  error,
  isLoading,
  description,
  date,
  bac,
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        {isLoading ? (
          QuestionTextLoader
        ) : (
          <>
            <Typography variant="subtitle1" className={styles.question}>
              {locked && (
                <LockIcon
                  fontSize="small"
                  variant="outlined"
                  htmlColor={theme.palette.action.disabled}
                />
              )}
              {question}
            </Typography>
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error">
                {error}
              </Typography>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {isLoading ? (
          <RadioLoader optionCount={2} />
        ) : (
          <RadioGroup
            row
            aria-labelledby={`radio-select-${name}`}
            name={name}
            value={value}
            onChange={onChange}
          >
            <FormControlLabel
              value={Status.Yes}
              control={<Radio />}
              label="Yes"
              disabled={disabled || locked}
            />
            <FormControlLabel
              value={Status.No}
              control={<Radio />}
              label="No"
              disabled={disabled || locked}
            />
          </RadioGroup>
        )}
      </Grid>
      {!isLoading && value === Status.Yes && description?.name?.length && (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.descriptionQuestion}
          >
            {description?.question}
          </Typography>
          <TextField
            disabled={disabled || description?.locked}
            fullWidth
            multiline
            maxRows={4}
            placeholder="Please provide as much information as possible"
            id={description?.name}
            name={description?.name}
            value={description?.value}
            onChange={onChange}
            error={description?.touched && Boolean(description?.error)}
            helperText={description?.touched && description?.error}
            InputProps={{
              endAdornment: description?.locked ? <FieldLockStatement /> : null,
            }}
          />
        </Grid>
      )}

      {!isLoading && value === Status.Yes && date?.name?.length && (
        <Grid item xs={12} md={6}>
          <DatePicker
            disableFuture
            clearable
            label={date?.label || "Date"}
            views={["year", "month", "day"]}
            disabled={disabled || date?.locked}
            components={{
              OpenPickerIcon: date?.locked
                ? () => <LockIcon variant="outlined" color="disabled" />
                : undefined,
            }}
            value={Number(date?.value) * 1000 || null}
            onChange={(inputDate) =>
              date?.onChange?.(inputDate as dayjs.Dayjs, date?.name)
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={date?.touched && Boolean(date?.error)}
                helperText={date?.touched && date?.error}
              />
            )}
          />
        </Grid>
      )}

      {!isLoading && value === Status.Yes && bac?.name?.length && (
        <Grid item xs={12} md={6}>
          <TextField
            disabled={disabled || bac?.locked}
            fullWidth
            label="BAC%"
            type="number"
            id={bac?.name}
            name={bac?.name}
            value={bac?.value}
            onChange={onChange}
            error={bac?.touched && Boolean(bac?.error)}
            helperText={bac?.touched && bac?.error}
            InputProps={{
              endAdornment: bac?.locked ? <FieldLockStatement /> : null,
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};
