import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

export type EmergencyContact = Partial<{
  cid: number;
  id: number;
  email: string | null;
  full_name: string | null;
  guardian: number | null;
  relationship: string | null;
  _ca: number;
  _cb: number;
  _lma: number | null;
  _lmb: number | null;
}>;

type UpdateEmergencyContactVariables = {
  data: Partial<{
    email: string | null;
    full_name: string | null;
    guardian: number | null;
    relationship: string | null;
    id?: number;
  }>;
  correlationId: string;
};

type UpdateEmergencyContactResult = {
  success: boolean;
  result: { changedRows: number };
};

type QueryError = {};

const useUpdateEmergencyContactMutation = (
  options?: UseMutationOptions<
    UpdateEmergencyContactResult,
    QueryError,
    UpdateEmergencyContactVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      UpdateEmergencyContactResult,
      QueryError,
      UpdateEmergencyContactVariables
    >(
      async (input: UpdateEmergencyContactVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/emergency-contact/${data.id}`;
        delete data.id;
        return fetchWithAuth<UpdateEmergencyContactResult>(url, {
          method: "PATCH",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateEmergencyContactMutation;
