import { Member, useAllGroupMembersQuery } from "@/fetch/social";
import { useCurrentTrip, useDebounce } from "@/hooks";
import { useEffect, useState } from "react";
import { IconButton } from "../Button";
import Divider from "@/components/Divider";
import { ArrowIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import styles from "./NewConversation.module.scss";
import uniqBy from "lodash/uniqBy";
import { SearchTextField } from "@/components/TextField";
import List from "@/components/List";
import NewConversationItem, {
  NewConversationItemLoader,
} from "./NewConversationItem";
import { useTwilioConversation } from "./hooks";
import compact from "lodash/compact";
import { EmptySearchResult } from "@/components/EmptyList";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
const NewConversation: React.FC<{
  onExit: () => void;
}> = ({ onExit }) => {
  const { currentTrip } = useCurrentTrip();
  const thisUserCid = currentTrip?.cid;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
  const isFilterSet = debouncedSearchTerm !== "";

  const {
    data: membersData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useAllGroupMembersQuery(
    isFilterSet
      ? {
          pageNum: 1,
          pageSize: 100,
          searchParam: debouncedSearchTerm,
        }
      : undefined
  );
  const { openChatPopup, setOverlayLoading } = useTwilioConversation();
  const isTripCompleted = currentTrip?.status === TripStatus.Complete;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersData]);

  const membersList = compact(
    uniqBy(
      membersData?.pages?.map((page) => page?.results)?.flat(1) || [],
      "cid"
    ).filter((each) => each?.cid !== thisUserCid)
  );

  const onItemClick = async (member: Member) => {
    try {
      setOverlayLoading?.(true);
      await openChatPopup(member?.cid, "New Message Page");
    } catch (error) {
      console.error(error);
    } finally {
      setOverlayLoading?.(false);
      onExit();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IconButton onClick={onExit} className={styles.backButton}>
          <ArrowIcon dir="left" />
        </IconButton>
        <Typography
          variant="subtitle1"
          component="button"
          className={styles.title}
        >
          Start new conversation
        </Typography>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.contentContainer}>
        <SearchTextField
          className={styles.searchBox}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
        />
        <List className={styles.conversationsListRoot}>
          {!Boolean(membersList?.length) &&
            !isFilterSet &&
            !isLoading &&
            !isFetchingNextPage && (
              <Typography color="text.secondary" className={styles.emptyList}>
                {isTripCompleted
                  ? "You can only chat with members when Social is active. Social is not currently active as your trip is complete."
                  : "You can only chat with members when Social is active. Social will become active 10 days prior to your trip."}
              </Typography>
            )}

          {isFilterSet &&
            !Boolean(membersList?.length) &&
            !isLoading &&
            !isFetchingNextPage && <EmptySearchResult />}

          {!isLoading &&
            membersList?.map((member, index) => (
              <NewConversationItem
                member={member}
                key={index}
                onClick={() => onItemClick(member)}
              />
            ))}

          {(isLoading || (isFetchingNextPage && hasNextPage)) &&
            Array(isLoading ? 3 : 1)
              .fill(null)
              .map((_, index) => <NewConversationItemLoader key={index} />)}
        </List>
      </div>
    </div>
  );
};
export default NewConversation;
