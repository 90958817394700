import { useMutation, UseMutationOptions } from "react-query";
import { CANCELLATION_ENDPOINT } from "@/fetch/cancellation/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum CancellationOptions {
  OnHold = "On Hold",
  Transfer = "Transfer",
  Active = "Active",
}

type SelectCancellationOptionVariables = {
  status: CancellationOptions;
};

type SelectCancellationOptionResult = {
  success: boolean;
  result: {
    tid: number;
    cid: number;
    status: string;
    deposit_complete: number;
    _ca: number;
  };
};

type QueryError = {};

const useSelectCancellationOptionMutation = (
  options?: UseMutationOptions<
    SelectCancellationOptionResult,
    QueryError,
    SelectCancellationOptionVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      SelectCancellationOptionResult,
      QueryError,
      SelectCancellationOptionVariables
    >(
      async ({ status }: SelectCancellationOptionVariables) => {
        const url = `${CANCELLATION_ENDPOINT}/trip/${tid}/status`;

        if (!tid) throw new Error("tid not found");

        return fetchWithAuth<SelectCancellationOptionResult>(url, {
          method: "PATCH",
          body: { status },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useSelectCancellationOptionMutation;
