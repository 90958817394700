import { FC, useState } from "react";
import cx from "classnames";
import { ClickableCard } from "@/components/Card";
import Typography from "@/components/Typography";
import {
  CheckedIcon,
  ChevronIcon,
  GlobalDollarColoredIcon,
  PendingIcon,
  CrossIcon,
} from "@/components/Icon";
import {
  SubmissionTypeItem,
  SubmissionItem,
  SubmissionStatuses,
  useAllClaimablesListQuery,
  ClaimableItemTypes,
} from "@/fetch/rewards";
import { useFormatCurrency } from "@/hooks";
import styles from "./RewardCard.module.scss";

type Props = {
  submission?: SubmissionItem;
  isInvitation?: boolean;
  type?: "photo" | "video" | "youtube";
  onOpen: () => void;
  currency: string;
  currencyName: string;
  submissionType?: SubmissionTypeItem;
};

const RewardCard: FC<Props> = ({
  isInvitation,
  submission,
  type,
  onOpen,
  currency,
  currencyName,
  submissionType,
}) => {
  const [openClaimDialog, setOpenClaimDialog] = useState<boolean>(false);
  const [openPendingDialog, setOpenPendingDialog] = useState<boolean>(false);

  const { format } = useFormatCurrency();
  const hasSubmission = Boolean(submission);
  const isSubmissionApproved =
    submission?.status === SubmissionStatuses.APPROVED;
  const isSubmissionDeclined =
    submission?.status === SubmissionStatuses.CHANGE_REQUESTED;
  const isSubmissionClaimed = submission?.status === SubmissionStatuses.CLAIMED;
  const isSubmissionPending = submission?.status === SubmissionStatuses.PENDING;

  const statusTexts = {
    [SubmissionStatuses.APPROVED]: "Approved",
    [SubmissionStatuses.CHANGE_REQUESTED]: "Changes Requested",
    [SubmissionStatuses.CLAIMED]: "Claimed",
    [SubmissionStatuses.PENDING]: "Pending",
  };
  const { data: claimableList } = useAllClaimablesListQuery();

  const amount = (
    submissionType as { [key: string]: string | number | null }
  )?.[`${currencyName}`];

  const referralClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.REFERRAL
  );

  const referralAmount = referralClaimable?.global_dollar_amount || "100";

  const photoClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.PHOTO
  );
  const videoClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.VIDEO_AD
  );
  const youtubeClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.YOUTUBE
  );

  const RewardTypes = [
    {
      isInvitation: true,
      title: `Get ${referralAmount}, Give ${referralAmount}`,
      description: `${`Share your unique code with those you know. When they book using your code you'll score ${referralAmount} Global Dollars and they'll score ${referralAmount} off their trip.`}`,
      image: "invite-friends.svg",
      value: referralAmount,
    },
    {
      type: "photo",
      title: `Share your Photos`,
      description: `It's one thing to hear about the exciting things you've been up to on your travels, but always a thousand times better to actually see them! Send us at least 10 of your favourite photos, along with a brief review of your trip.`,
      image: "review-and-photo.svg",
      value: photoClaimable?.global_dollar_amount || "",
    },
    {
      type: "video",
      title: `Make an Ad`,
      description: `Make a 30-45 second ad suitable for social media showcasing where you are, what you're doing, and why someone should travel with Global. If accepted, we'll run it around the world!`,
      image: "photo-and-video.svg",
      value: videoClaimable?.global_dollar_amount || "",
    },
    {
      type: "youtube",
      title: `Create a YouTube Video`,
      description: `Lights, camera, action! Create a YouTube video (minimum 60 seconds) sharing your travel story and some highlights about the experience.`,
      image: "blog.svg",
      value: youtubeClaimable?.global_dollar_amount || "",
    },
  ];

  const currentRewardType = isInvitation
    ? RewardTypes.find((each) => each.isInvitation)
    : RewardTypes.find((each) => each.type === type);

  return (
    <>
      <ClickableCard
        onClick={onOpen}
        className={cx(styles.container, {
          [styles.invitationContainer]: isInvitation,
          [styles.claimedContainer]: hasSubmission,
        })}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={
            hasSubmission
              ? `/images/disable-${currentRewardType?.image}`
              : `/images/${currentRewardType?.image}`
          }
          alt="icon"
          className={styles.icon}
        />
        <div className={styles.content}>
          <Typography variant="h6">{currentRewardType?.title}</Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.text}
          >
            {currentRewardType?.description}
          </Typography>
          <div className={styles.bottomContainer}>
            {hasSubmission ? (
              <>
                {(isSubmissionClaimed || isSubmissionApproved) && (
                  <CheckedIcon className={styles.checkIcon} />
                )}
                {isSubmissionDeclined && (
                  <CrossIcon className={styles.crossIcon} />
                )}
                {isSubmissionPending && (
                  <PendingIcon className={styles.pendingIcon} />
                )}
                <Typography variant="body2">
                  {
                    statusTexts?.[
                      `${submission?.status || SubmissionStatuses.CLAIMED}`
                    ]
                  }
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="text.secondary">
                  Reward
                </Typography>

                <GlobalDollarColoredIcon className={styles.dollarIcon} />

                <Typography variant="h6" className={styles.rewardValue}>
                  {`x${currentRewardType?.value}`}
                </Typography>
                {isInvitation && (
                  <Typography variant="body2" color="text.secondary">
                    per person
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
        <ChevronIcon className={styles.chevronIcon} />
      </ClickableCard>
    </>
  );
};

export default RewardCard;
