import SvgIcon, { SvgIconProps } from "./SvgIcon";

const DragIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <rect x="4.5" y="8" rx="1" height="2" width="15" />
      <rect x="4.5" rx="1" height="2" width="15" y="14" />
    </SvgIcon>
  );
};

export default DragIcon;
