import { FC, useState, Fragment, useEffect } from "react";
import cx from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import Image from "next/image";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import Button, { IconButton } from "@/components/Button";
import Alert from "@/components/Alert";
import Uploader, {
  UploaderFile,
  UPLOADER_ACCEPT_TYPES,
} from "@/components/Uploader";
import { FormControlLabel } from "@/components/FormController";
import TextField from "@/components/TextField";
import Checkbox from "@/components/Checkbox";
import {
  FacebookIcon,
  TwitterIcon,
  MailIcon,
  GlobalDollarColoredIcon,
  FileIcon,
  BinIcon,
  DownloadIcon,
} from "@/components/Icon";
import { Skeleton } from "@/components/Loader";
import Rating from "@/components/Rating";
import {
  useDialog,
  useCurrentTrip,
  useTrackers,
  useSnackbar,
  useOpenExternalURL,
} from "@/hooks";
import {
  RewardItem,
  useCreateSubmissionHandler,
  useDeleteMediaMutation,
  useSubmissionQuery,
  useSubmissionMediaListQuery,
  MediaItem,
  useUpdateSubmissionHandler,
  SubmissionStatuses,
  useAllClaimablesListQuery,
} from "@/fetch/rewards";
import { LinearProgress } from "@/components/Loader";
import RichContentPreview from "@/components/RichContentPreview";
import { ListItem } from "@/components/List";
import PendingReward from "./PendingReward";
import ClaimedReward from "./ClaimedReward";
import SuccessfulSubmission from "./SuccessfulSubmission";

import styles from "./YoutubeDialog.module.scss";

type YoutubeSubmissionFormType = {
  rating: number | null;
  experience: string;
  media_description: string;
  rewards_id: number | undefined;
  condition: boolean;
};

const validationSchema = yup.object({
  rating: yup
    .number()
    .required(`Rate your time abroad with us out of 5 stars.`)
    .typeError("Rate your time abroad with us out of 5 stars."),
  experience: yup.string(),
  media_description: yup
    .string()
    .required(`Please place the link to your YouTube video.`),
  condition: yup
    .bool()
    .oneOf([true], "Please tick to prove you have read the above and agree."),
});

type Props = {};

const YoutubeDialog: FC<Props> = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const bannerImg =
    "https://res.cloudinary.com/gwatco/image/upload/f_auto,q_auto,ar_100:30,w_3000,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/Reward.jpg";
  const baseRewardUrl =
    "https://dvhvji4glt0rj.cloudfront.net/img/reward-centre/";
  const { onClose, query } = useDialog();
  const { currentTrip } = useCurrentTrip();
  const { track, captureException } = useTrackers();
  const { enqueueSnackbar } = useSnackbar();
  const openExternalLink = useOpenExternalURL();
  const {
    handleCreateSubmission,
    isCreatingMedias,
    isCreatingSubmission,
    isFinalizing,
    isUploading,
  } = useCreateSubmissionHandler();

  const {
    handleUpdateSubmission,
    isCreatingMedias: isCreatingMediasResubmission,
    isUpdatingSubmission,
    isUploading: isUploadingResubmission,
  } = useUpdateSubmissionHandler();

  const submissionId = Number(query?.submissionId);
  const hasSubmission = Boolean(submissionId);
  const { data: submissionData, isLoading: isLoadingSubmission } =
    useSubmissionQuery(submissionId);
  const { data: claimableList } = useAllClaimablesListQuery();

  const youtubeClaimable = claimableList?.result.find((each) => each.id === 5);

  const currentSubmission = submissionData?.result;

  const isSubmissionApproved =
    currentSubmission?.status === SubmissionStatuses.APPROVED;
  const isSubmissionDeclined =
    currentSubmission?.status === SubmissionStatuses.CHANGE_REQUESTED;
  const isSubmissionClaimed =
    currentSubmission?.status === SubmissionStatuses.CLAIMED;
  const isSubmissionPending =
    currentSubmission?.status === SubmissionStatuses.PENDING;

  const { mutateAsync: deleteMedia, isLoading: isDeletingMedia } =
    useDeleteMediaMutation(submissionId as number);

  const isLoading =
    isCreatingMedias ||
    isCreatingSubmission ||
    isFinalizing ||
    isUploading ||
    isCreatingMediasResubmission ||
    isUpdatingSubmission ||
    isUploadingResubmission;

  const onSubmit = async () => {
    try {
      if (hasSubmission) {
        const data = {
          experience: formik.values.experience || "youtube experience",
          media_description:
            formik.values.media_description ||
            "There is none due to uploading file",
          rating: `${formik.values.rating}`,
          submissionId: currentSubmission?.id as number,
        };
        const newFiles: Array<UploaderFile> = [];
        await handleUpdateSubmission({
          files: newFiles,
          submissionInput: data,
        });
        track("Youtube Reward Resubmitted", {
          eventId: "youtube-reward-resubmitted",
        });
      } else {
        const data = {
          type: 5,
          tid: currentTrip?.id,
          cid: currentTrip?.cid,
          experience: formik.values.experience || "youtube experience",
          media_description:
            formik.values.media_description ||
            "There is none due to uploading file",
          rating: `${formik.values.rating}`,
        };
        await handleCreateSubmission({
          files: [] as Array<UploaderFile>,
          submissionInput: data,
        });
        track("Youtube Reward Submitted", {
          eventId: "youtube-reward-submitted",
        });
      }

      setShowSuccess(true);
    } catch (error) {
      captureException(error);
      enqueueSnackbar("Sorry, could not upload files.", {
        variant: "error",
      });
    }
  };

  const initialValues = {
    rating: currentSubmission?.rating || null,
    experience: currentSubmission?.experience || "",
    media_description: currentSubmission?.media_description || "",
    condition: hasSubmission,
  } as YoutubeSubmissionFormType;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!currentSubmission) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubmission]);

  const isLoadingExistingSubmission = hasSubmission && isLoadingSubmission;

  const handleClose = () => {
    onClose({ submissionId });
  };

  const openYouTubeLink = () => {
    const youTubeLink = "https://www.youtube.com/watch?v=Ks2XBZicUkA";
    openExternalLink(youTubeLink);
  };

  return (
    <Dialog
      open
      onClose={isLoading ? undefined : handleClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      {isLoadingExistingSubmission && (
        <>
          <DialogTitle hasCloseButton onClose={handleClose}>
            <Skeleton variant="text" width="60%" />
          </DialogTitle>
          <DialogContent>
            <Skeleton variant="rectangular" height={200} />
          </DialogContent>
        </>
      )}
      {!(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission &&
        !showSuccess && (
          <DialogTitle hasCloseButton={!isLoading} onClose={handleClose}>
            {`Create a YouTube Video`}
          </DialogTitle>
        )}
      {isLoading && (
        <>
          <LinearProgress />
          <DialogContent className={styles.loadingContent}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/reward-loading.svg`}
              alt="icon"
              className={styles.loadingIcon}
            />
            <Typography>
              {`You're nearly there, please wait while we upload your files!`}
            </Typography>
          </DialogContent>
        </>
      )}
      {!isLoading &&
        !showSuccess &&
        !(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission && (
          <>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.imageContainer}>
                <Image
                  src={bannerImg}
                  objectFit="cover"
                  alt="banner"
                  layout="fill"
                />
              </div>

              <div className={styles.content}>
                {hasSubmission && (
                  <>
                    {isLoadingExistingSubmission ? (
                      <Skeleton
                        variant="rectangular"
                        height="96px"
                        className={styles.reasonAlert}
                      />
                    ) : (
                      <Alert
                        variant="standard"
                        color="error"
                        className={styles.reasonAlert}
                      >
                        {currentSubmission?.reason || ""}
                      </Alert>
                    )}
                  </>
                )}
                <Typography variant="body1">
                  Please follow our simple guidelines for your video:
                </Typography>
                <div>
                  <ListItem className={styles.listItem}>
                    {`Video must be minimum 60 seconds in length`}
                  </ListItem>
                  <ListItem className={styles.listItem}>
                    {`Video must be uploaded on YouTube`}
                  </ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`Video must be set to 'Public' & live for at least 12 months (Global Dollars will be issued as soon as your video is live and approved).`}</ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`Video must include 'Global Work & Travel' in the title & description`}</ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`Video is subject to approval by our team`}</ListItem>
                </div>
                <Typography variant="body1">Tell us:</Typography>
                <div>
                  <ListItem className={styles.listItem}>
                    {`Why did you choose Global?`}
                  </ListItem>
                  <ListItem className={styles.listItem}>
                    {`What were your favourite parts about the trip or destination?`}
                  </ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`What did you gain from this experience?`}</ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`What advice do you have for others considering doing a trip like this?`}</ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`Anything else you'd like to add!`}</ListItem>
                </div>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  className={styles.linkText}
                >
                  See a sample&nbsp;
                  <Typography component="a" onClick={() => openYouTubeLink()}>
                    here
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  How was your experience with us?
                </Typography>
                <div className={styles.ratingContainer}>
                  {isLoadingExistingSubmission ? (
                    <Skeleton
                      variant="rectangular"
                      height="32px"
                      width="240px"
                    />
                  ) : (
                    <Rating
                      value={formik.values.rating}
                      onChange={(event, newValue) =>
                        formik.setFieldValue("rating", newValue)
                      }
                    />
                  )}
                </div>
                {Boolean(formik.errors.rating) &&
                  Boolean(formik.touched.rating) && (
                    <Typography
                      variant="subtitle2"
                      color="error"
                      className={styles.photosErrorText}
                    >
                      {formik.errors.rating}
                    </Typography>
                  )}
                <Typography variant="body1">
                  Send us your YouTube link:
                </Typography>

                {isLoadingExistingSubmission ? (
                  <Skeleton
                    variant="rectangular"
                    height="154px"
                    className={styles.textfield}
                  />
                ) : (
                  <TextField
                    id="media_description"
                    name="media_description"
                    value={formik.values.media_description}
                    placeholder="Submit URL link"
                    onChange={formik.handleChange}
                    className={styles.textfield}
                    multiline
                    rows={6}
                    error={
                      Boolean(formik.errors.media_description) &&
                      formik.touched.media_description
                    }
                    helperText={formik.errors.media_description}
                  />
                )}
                {isLoadingExistingSubmission ? (
                  <Skeleton variant="text" />
                ) : (
                  <div className={styles.conditionContainer}>
                    <Checkbox
                      checked={formik.values.condition}
                      onChange={formik.handleChange}
                      className={styles.checkbox}
                      name="condition"
                      color="primary"
                    />
                    <Typography variant="subtitle2">
                      {`You confirm you have read and agree to our`}{" "}
                      <a
                        href={`https://www.globalworkandtravel.com/terms-and-conditions?tab=ca`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className={styles.termsCondition}>
                          {`Media Release & Copyright Agreement`}
                        </span>
                      </a>{" "}
                      {`in conjunction with your booking terms and conditions.`}
                    </Typography>
                  </div>
                )}
                {Boolean(formik.errors.condition) && formik.touched.condition && (
                  <Typography
                    variant="subtitle2"
                    color="error"
                    className={styles.errorText}
                  >
                    {formik.errors.condition}
                  </Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions className={styles.actionContainer}>
              <Button
                onClick={() => formik.handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isLoadingExistingSubmission || isDeletingMedia}
              >
                {hasSubmission ? "Resubmit" : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      {!isLoading &&
        showSuccess &&
        !(isSubmissionClaimed || isSubmissionPending) && (
          <SuccessfulSubmission
            onClose={handleClose}
            globalDollarAmount={youtubeClaimable?.global_dollar_amount || 0}
          />
        )}
      {!isLoadingExistingSubmission &&
        !showSuccess &&
        (isSubmissionClaimed || isSubmissionPending) && (
          <PendingReward onClose={handleClose} />
        )}
      {!isLoadingExistingSubmission && isSubmissionApproved && (
        <ClaimedReward
          onClose={handleClose}
          globalDollarAmount={youtubeClaimable?.global_dollar_amount || 0}
        />
      )}
    </Dialog>
  );
};

export default YoutubeDialog;
