import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AppliedWorkIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <>
          <g clipPath="url(#clip0_1905_105)">
            <path d="M17.7619 10.4286C19.3229 10.4286 20.7686 10.921 21.9524 11.7486V7.28571C21.9524 6.12286 21.02 5.19048 19.8571 5.19048H15.6667V3.09524C15.6667 1.93238 14.7343 1 13.5714 1H9.38095C8.2181 1 7.28571 1.93238 7.28571 3.09524V5.19048H3.09524C1.93238 5.19048 1.01048 6.12286 1.01048 7.28571L1 18.8095C1 19.9724 1.93238 20.9048 3.09524 20.9048H11.141C10.6905 19.9514 10.4286 18.8933 10.4286 17.7619C10.4286 13.7076 13.7076 10.4286 17.7619 10.4286ZM9.38095 3.09524H13.5714V5.19048H9.38095V3.09524Z" />
            <path d="M17.762 12.5238C14.8706 12.5238 12.5239 14.8705 12.5239 17.7619C12.5239 20.6533 14.8706 23 17.762 23C20.6535 23 23.0001 20.6533 23.0001 17.7619C23.0001 14.8705 20.6535 12.5238 17.762 12.5238ZM19.4906 20.2238L17.2382 17.9714V14.619H18.2858V17.5419L20.2239 19.48L19.4906 20.2238Z" />
          </g>
          <defs>
            <clipPath id="clip0_1905_105">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_1905_104)">
            <path d="M3.09524 18.8095V7.28571H19.8571V10.7324C20.6114 10.9629 21.3238 11.2981 21.9524 11.7486V7.28571C21.9524 6.12286 21.02 5.19048 19.8571 5.19048H15.6667V3.09524C15.6667 1.93238 14.7343 1 13.5714 1H9.38095C8.2181 1 7.28571 1.93238 7.28571 3.09524V5.19048H3.09524C1.93238 5.19048 1.01048 6.12286 1.01048 7.28571L1 18.8095C1 19.9724 1.93238 20.9048 3.09524 20.9048H11.141C10.8267 20.2552 10.6171 19.5533 10.5124 18.8095H3.09524ZM9.38095 3.09524H13.5714V5.19048H9.38095V3.09524Z" />
            <path d="M17.762 12.5238C14.8706 12.5238 12.5239 14.8705 12.5239 17.7619C12.5239 20.6533 14.8706 23 17.762 23C20.6535 23 23.0001 20.6533 23.0001 17.7619C23.0001 14.8705 20.6535 12.5238 17.762 12.5238ZM19.4906 20.2238L17.2382 17.9714V14.619H18.2858V17.5419L20.2239 19.48L19.4906 20.2238Z" />
          </g>
          <defs>
            <clipPath id="clip0_1905_104">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </SvgIcon>
  );
};

export default AppliedWorkIcon;
