import SvgIcon, { SvgIconProps } from "./SvgIcon";

const BinIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M15.382 1C15.7607 1 16.107 1.214 16.2764 1.55279L17 3H20C21.1046 3 22 3.89543 22 5V7C22 7.79649 21.5344 8.48424 20.8605 8.80592C20.8679 8.86937 20.8711 8.93432 20.8711 9L20.8672 9.12476L20.1172 21.1248C20.0513 22.1788 19.1772 23 18.1211 23H5.8789C4.82278 23 3.94868 22.1788 3.8828 21.1248L3.1328 9.12476C3.12603 9.01653 3.12801 8.90978 3.13811 8.80519C2.4656 8.48424 2 7.79649 2 7V5C2 3.89543 2.89543 3 4 3H7L7.72361 1.55279C7.893 1.214 8.23926 1 8.61803 1H15.382ZM8 10C7.48716 10 7.06449 10.386 7.00673 10.8834L7 11V19C7 19.5523 7.44772 20 8 20C8.51284 20 8.93551 19.614 8.99327 19.1166L9 19V11C9 10.4477 8.55228 10 8 10ZM12 10C11.4872 10 11.0645 10.386 11.0067 10.8834L11 11V19C11 19.5523 11.4477 20 12 20C12.5128 20 12.9355 19.614 12.9933 19.1166L13 19V11C13 10.4477 12.5523 10 12 10ZM16 10C15.4872 10 15.0645 10.386 15.0067 10.8834L15 11V19C15 19.5523 15.4477 20 16 20C16.5128 20 16.9355 19.614 16.9933 19.1166L17 19V11C17 10.4477 16.5523 10 16 10ZM14.764 3H9.235L8.23607 5H4V7H20V5H15.7639L14.764 3Z" />
      ) : (
        <path d="M15.382 1C15.7607 1 16.107 1.214 16.2764 1.55279L17 3H20C21.1046 3 22 3.89543 22 5V7C22 7.79649 21.5344 8.48424 20.8605 8.80592C20.8679 8.86937 20.8711 8.93432 20.8711 9L20.8672 9.12476L20.1172 21.1248C20.0513 22.1788 19.1772 23 18.1211 23H5.8789C4.82278 23 3.94868 22.1788 3.8828 21.1248L3.1328 9.12476C3.12603 9.01653 3.12801 8.90978 3.13811 8.80519C2.4656 8.48424 2 7.79649 2 7V5C2 3.89543 2.89543 3 4 3H7L7.72361 1.55279C7.893 1.214 8.23926 1 8.61803 1H15.382ZM18.871 9H5.1289L5.8789 21H18.1211L18.871 9ZM8 10C8.55228 10 9 10.4477 9 11V19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19V11C7 10.4477 7.44772 10 8 10ZM12 10C12.5523 10 13 10.4477 13 11V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V11C11 10.4477 11.4477 10 12 10ZM16 10C16.5523 10 17 10.4477 17 11V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V11C15 10.4477 15.4477 10 16 10ZM14.764 3H9.235L8.23607 5H4V7H20V5H15.7639L14.764 3Z" />
      )}
    </SvgIcon>
  );
};

export default BinIcon;
