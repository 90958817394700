import { useEffect } from "react";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import ConversationsList from "./ConversationsList";
import { useTwilioConversation } from "./hooks";
import styles from "./RecentSidebar.module.scss";
const RecentSidebar: React.FC = () => {
  const {
    state,
    isConversationsLoading,
    updateConversations,
    setCurrentConversationSid,
    sessionsToDisplay,
    setIsChatPopOverOpen,
  } = useTwilioConversation();

  useEffect(() => {
    /* initially update the conversations*/
    updateConversations(!state?.sessions?.length);
  }, []);
  const onConversationClick = (sid: string) => {
    setIsChatPopOverOpen?.(true);
    setCurrentConversationSid?.(sid);
  };

  const selectedSessionsToDisplay = sessionsToDisplay.filter(
    (_, index) => index <= 1
  );
  const hasMore =
    (sessionsToDisplay.length || 0) > (selectedSessionsToDisplay.length || 0);
  const seeMoreConversations = () => {
    setIsChatPopOverOpen?.(true);
    setCurrentConversationSid?.(undefined);
  };
  return !isConversationsLoading &&
    Boolean(selectedSessionsToDisplay.length) ? (
    <div className={styles.recentConversationsRoot}>
      <Typography color="text.secondary" variant="body1">
        Recent messages
      </Typography>
      <ConversationsList
        sessions={selectedSessionsToDisplay}
        isConversationsLoading={isConversationsLoading}
        onConversationClick={onConversationClick}
        smallAvatar
      />
      {hasMore && !isConversationsLoading && (
        <Button
          variant="text"
          size="small"
          className={styles.moreButton}
          onClick={seeMoreConversations}
        >
          See more
        </Button>
      )}
    </div>
  ) : null;
};

export default RecentSidebar;
