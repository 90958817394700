import Link from "next/link";
import Tabs, { Tab } from "@/components/Tabs";
import styles from "./TabNavigation.module.scss";
import cx from "classnames";
import { useEffect, useState } from "react";
import { Route, useRoutes, RouteFeature } from "@/hooks";
import Tooltip from "@/components/Tooltip";

const a11yProps = (index: number) => {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
};

const TabNavigation: React.FC<{
  routes: Array<Route>;
}> = ({ routes }) => {
  const routesToDisplay = routes.filter((route) =>
    route.features?.includes(RouteFeature.Tab)
  );
  const [tab, setTab] = useState<number | boolean>(0);
  const { getMatchedRoute } = useRoutes();
  const initialTab = getMatchedRoute(routesToDisplay).index;

  useEffect(() => {
    if (initialTab < 0) {
      setTab(false);
      return;
    }
    setTab(initialTab);
  }, [initialTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Tabs className={styles.tabs} value={tab} onChange={handleTabChange}>
      {routesToDisplay.map((item, index) => (
        <Link key={item.path} href={item?.disabled ? "" : item.path} passHref>
          <Tooltip title={item.tooltipMessage || ""} arrow>
            <span>
              <Tab
                aria-selected={index === tab}
                label={item.name}
                component="a"
                tabIndex={0}
                disabled={item?.disabled}
                className={cx({ [styles.selectedTab]: tab === index })}
                {...a11yProps(index)}
              />
            </span>
          </Tooltip>
        </Link>
      ))}
    </Tabs>
  );
};

export default TabNavigation;
