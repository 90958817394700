import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import TextField from "@/components/TextField";
import { FormActions } from "@/components/FormController";
import DatePicker from "@/components/DatePicker";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import {
  QuestionTextLoader,
  RadioLoader,
  TextFieldLoader as FormLoader,
} from "./PersonalDetailsFormLoader";
import Radio, { RadioGroup } from "@/components/Radio";
import { FormControlLabel } from "@/components/FormController";
import {
  WorkingHolidayVisaFormType,
  Status,
} from "@/components/PersonalDetailsForms";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
} from "@/components/PersonalDetailsForms/hooks";
import { AnswerItem } from "./PersonalDetailsQuestionHelper";
import dayjs from "dayjs";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
}

function WorkingHolidayVisaFormTemplateWithRef<
  T extends WorkingHolidayVisaFormType
>(
  { isFormSubmitting, formik, isFormDataLoading }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const onDateChange = (date: dayjs.Dayjs | null) => {
    formik.setValues((values) => ({
      ...values,
      workingHolidayVisaDate: date ? date.utc().startOf("day").unix() : null,
    }));
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing="16px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <AnswerItem
          question="Do you have an approved Working Holiday Visa?"
          name="workingHolidayVisaStatus"
          value={formik.values.workingHolidayVisaStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.workingHolidayVisaStatus &&
            (formik.errors.workingHolidayVisaStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.workingHolidayVisaDescription?.length
          )}
          description={{
            question: "Name/Visa category",
            name: "workingHolidayVisaDescription",
            value: formik.values.workingHolidayVisaDescription,
            error: formik.errors.workingHolidayVisaDescription as string,
            touched: formik.touched.workingHolidayVisaDescription as boolean,
            locked: Boolean(
              formik.initialValues?.workingHolidayVisaDescription?.length
            ),
          }}
          date={{
            name: "personalDetailsWorkingHolidayVisaDate",
            value: formik.values.workingHolidayVisaDate,
            error: formik.errors.workingHolidayVisaDate as string,
            touched: formik.touched.workingHolidayVisaDate as boolean,
            onChange: onDateChange,
            label: "Date of Issue",
            locked: Boolean(formik.initialValues?.workingHolidayVisaDate),
          }}
        />
      </Grid>
    </div>
  );
}

const WorkingHolidayVisaForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();

  const { workingHolidayVisaValidationSchema } =
    usePersonalDetailsFormValidation();

  const { submitWorkingHolidayVisaForm, isUpdatingWorkingHolidayVisa } =
    usePersonalDetailsFormSubmission();

  const { workingHolidayVisaInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingWorkingHolidayVisa || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const onSubmit = async (values: WorkingHolidayVisaFormType) => {
    try {
      if (formik.dirty) {
        await submitWorkingHolidayVisaForm(values, correlationId);
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const initialValues = workingHolidayVisaInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: workingHolidayVisaValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <WorkingHolidayVisaFormTemplate
        formik={formik}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};
const WorkingHolidayVisaFormTemplate = forwardRef(
  WorkingHolidayVisaFormTemplateWithRef
);

export { WorkingHolidayVisaFormTemplate };
export default WorkingHolidayVisaForm;
