import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type Group = {
  description: string;
  accept_conditions: number;
  end_date: number;
  id: number;
  recent_members: Array<
    Partial<{
      cid: number;
      country: string;
      country_code: string;
      last_location: string | null;
      last_user_ip: string | number | null;
      nickname: string | null;
      s3_profile_pic: string | null;
      s3_resume_pic: string | null;
      is_suspended: number;
      is_admin: number;
    }>
  >;
  trips: Array<
    Partial<{
      country_code: string;
      country_name: string;
      division: string;
      id: number;
    }>
  >;
  start_date: number;
  total_members: number;
  trip_id: number;
};

type Result = {
  data: Group;
  statusCode: number;
  success: boolean | string;
};

type QueryError = {};

const useGroupQuery = (
  options?: UseQueryOptions<Group, QueryError, Group, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tripId = currentTrip?.trip_id;
  const cid = currentTrip?.cid;

  const url = `${GATEWAY_URL}/group/${tripId}/${cid}`;
  const cacheKey = [url];

  return useQuery<Group, QueryError>(
    cacheKey,
    async () => {
      if (!tripId || !cid) throw new Error("trip id or cid not found");

      const result = await fetchWithAuth<Result>(url);
      return result.data;
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(cid && tripId),
    }
  );
};
export default useGroupQuery;
