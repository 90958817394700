import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { InvoiceStatusText } from "./useInvoicesQuery";

type Invoice = Partial<{
  cid: number;
  currency: string;
  currency_symbol: string;
  credit: number;
  department_id: number;
  deposit: number;
  deposit_paid_at: number;
  destination: string;
  discount: number;
  division: string;
  expires_at: number;
  expires_at_formatted: number;
  id: number;
  invoiceStatus: string;
  is_expired: number;
  markup: number;
  net: number;
  outstanding: number;
  paid: number;
  staff_id: number;
  status: string;
  status_data: { status: string; statusText: InvoiceStatusText };
  tax: number;
  tid: number;
  time_remaining: number;
  total: number;
  trip_name: string;
  type: "trip" | "travel";
  use_excess: number;
  pending_refund: number;
  processed_refund: number;
}>;

export type PaymentPlan = Partial<{
  paid_amount: number;
  id: number;
  transaction_id: string;
  invoice_id: number;
  status: "Pending" | "Paid" | "Declined" | "Auto-charge attempt failed";
  date: number;
  amount: number;
  method: string;
  is_expired: number;
  time_remaining: number;
}>;

type Promotion = {
  item: string;
  id: number;
  tid: number;
  promotion_id: number;
  category: "g_voucher";
  value: number;
  comment: string;
  currency: string;
  code: string;
};

type Result = {
  invoice: Invoice;
  paymentPlan: Array<PaymentPlan>;
  promotions: Array<Promotion>;
  inclusions: Array<{
    id: number;
    type: string;
    name: string;
    net: number;
    markup: number;
    tax: number;
    total: number;
    details: Array<string>;
  }>;
  success: true;
};
type QueryError = {};

const useInvoiceQuery = (
  invoiceId: number | undefined,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/invoice`;
  const { fetchWithAuth } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;
  const cacheKey = [url, cid, invoiceId];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!cid || !invoiceId) throw new Error();

      const res = await fetchWithAuth<Result>(url, {
        params: { invoiceId, cid },
      });

      return res;
    },
    {
      enabled: !!invoiceId && !!cid,
      ...options,
    }
  );
};
export default useInvoiceQuery;
