import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import {
  cacheKey as notificationsCacheKey,
  Notification,
} from "./useNotificationsQuery";
import { cacheKey as statisticsCacheKey } from "./useNotificationStatisticsQuery";
import produce from "immer";

type MakeAllNotificationSeenResult = {
  acknowledged: boolean;
  modifiedCount: number;
  upsertedId: unknown;
  upsertedCount: number;
  matchedCount: number;
};

type QueryError = {};

const useMakeAllNotificationSeenMutation = (
  options?: UseMutationOptions<MakeAllNotificationSeenResult, QueryError>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const queryClient = useQueryClient();

  return {
    ...useMutation<MakeAllNotificationSeenResult, QueryError>(
      async () => {
        const url = `${ENDPOINT}/v2/seen/all`;

        return fetchWithAuth<MakeAllNotificationSeenResult>(url, {
          method: "POST",
          body: {},
        });
      },
      {
        ...options,
        onMutate: () => {
          queryClient.cancelQueries(statisticsCacheKey);
          queryClient.setQueryData(statisticsCacheKey, { unseen: 0 });

          queryClient.cancelQueries(notificationsCacheKey);
          queryClient.setQueryData(notificationsCacheKey, (notifications) => {
            return produce(notifications, (draft: any) => {
              draft.pages = draft.pages.map((page: Array<Notification>) => {
                return page.map((notification) => {
                  return {
                    ...notification,
                    status: { ...notification.status, seen: true },
                  };
                });
              });
            });
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries(statisticsCacheKey);
          queryClient.invalidateQueries(notificationsCacheKey);
        },
      }
    ),
    cancel,
  };
};

export default useMakeAllNotificationSeenMutation;
