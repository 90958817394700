import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

export type Address = Partial<{
  id: number;
  cid: number;
  street: string;
  suburb: string;
  post_code: string;
  state: string;
  country: string;
  _ca: number;
  _cb: number;
  _lma: number | null;
  _lmb: number | null;
}>;

type CreateAddressesVariables = {
  data: Partial<{
    street: string | null;
    suburb: string | null;
    post_code: string | null;
    state: string | null;
    country: string | null;
  }>;
  correlationId: string;
};

type CreateAddressesResult = {
  success: boolean;
  result: Address;
};

type QueryError = {};

const useCreateAddressMutation = (
  options?: UseMutationOptions<
    CreateAddressesResult,
    QueryError,
    CreateAddressesVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<CreateAddressesResult, QueryError, CreateAddressesVariables>(
      async (input: CreateAddressesVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/address`;

        return fetchWithAuth<CreateAddressesResult>(url, {
          method: "POST",
          body: { ...data, cid: cid, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateAddressMutation;
