import { useMutation, UseMutationOptions } from "react-query";
import { TRAVELLER_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type DeleteEmergencyContactMethodVariables = {
  contactId: number;
  emergencyContactId: number;
};

type DeleteEmergencyContactMethodResult = {
  success: boolean;
  result: {
    affectedRows: number;
  };
};

type QueryError = {};

const useDeleteEmergencyContactMethodMutation = (
  options?: UseMutationOptions<
    DeleteEmergencyContactMethodResult,
    QueryError,
    DeleteEmergencyContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      DeleteEmergencyContactMethodResult,
      QueryError,
      DeleteEmergencyContactMethodVariables
    >(
      async ({
        contactId,
        emergencyContactId,
      }: DeleteEmergencyContactMethodVariables) => {
        if (!contactId) throw new Error("id not found");
        const url = `${TRAVELLER_ENDPOINT}/contact-method/${contactId}/emergency-contact/${emergencyContactId}`;
        return fetchWithAuth<DeleteEmergencyContactMethodResult>(url, {
          method: "DELETE",
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useDeleteEmergencyContactMethodMutation;
