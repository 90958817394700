/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import styles from "./NotificationSnackbarContent.module.scss";
import { Notification } from "@/fetch/notifications";
import Card from "../Card";
import { NotificationItem } from "../Notification";
import Button from "../Button";

const NotificationSnackbarContent = forwardRef<
  HTMLDivElement,
  {
    id: number | string;
    notification: Notification;
    onClose?: () => void;
  }
>(({ id, notification, onClose }, ref) => {
  const onCloseClicked: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    onClose?.();
  };
  return (
    <div ref={ref} id={`${id}`}>
      <Card className={styles.previewRootCard}>
        <NotificationItem notification={notification} variant="preview" />
        <Button
          size="small"
          variant="text"
          color="info"
          onClick={onCloseClicked}
        >
          Close
        </Button>
      </Card>
    </div>
  );
});

export default NotificationSnackbarContent;
