import SvgIcon, { SvgIconProps } from "./SvgIcon";

const LockIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12.5 2C14.9853 2 17 4.01472 17 6.5V9H18C19.1046 9 20 9.89543 20 11V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V11C4 9.89543 4.89543 9 6 9H7V6.5C7 4.01472 9.01472 2 11.5 2H12.5ZM12.5 4H11.5C10.1745 4 9.08996 5.03154 9.00532 6.33562L9 6.5V9H15V6.5C15 5.17452 13.9685 4.08996 12.6644 4.00532L12.5 4Z" />
      ) : (
        <path d="M12.5 2C14.9853 2 17 4.01472 17 6.5V9H18C19.1046 9 20 9.89543 20 11V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V11C4 9.89543 4.89543 9 6 9H7V6.5C7 4.01472 9.01472 2 11.5 2H12.5ZM18 11H6V20H18V11ZM12.5 4H11.5C10.1745 4 9.08996 5.03154 9.00532 6.33562L9 6.5V9H15V6.5C15 5.17452 13.9685 4.08996 12.6644 4.00532L12.5 4Z" />
      )}
    </SvgIcon>
  );
};

export default LockIcon;
