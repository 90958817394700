import { ListItemButton } from "@/components/List";
import { usePublicProfileQuery } from "@/fetch/profiles/traveller";
import dayjs from "dayjs";
import { ChatSession, ConversationAttributes } from "./hooks";
import styles from "./ConversationItem.module.scss";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import Badge from "@/components/Badge";
import { useCurrentTrip } from "@/hooks";
import { Skeleton } from "@/components/Loader";
import RichContentPreview from "@/components/RichContentPreview";
import { useGroupQuery } from "@/fetch/social";
import { SocialAvatar } from "@/components/Social";

const ConversationItemLoader: React.FC<{
  smallAvatar?: boolean;
}> = ({ smallAvatar = false }) => {
  const size = smallAvatar ? "48px" : "56px";
  return (
    <>
      <div className={styles.loaderRoot}>
        <Skeleton variant="circular" width={size} height={size} />
        <div className={styles.loaderTextContainer}>
          <Skeleton width="100px" />
          <Skeleton width="150px" />
        </div>
      </div>
      <Divider variant="middle" />
    </>
  );
};

const ConversationItem: React.FC<{
  session: ChatSession;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  selected?: boolean;
  smallAvatar?: boolean;
}> = ({ session, onClick, selected = false, smallAvatar = false }) => {
  const { currentTrip } = useCurrentTrip();
  const { data: group } = useGroupQuery();
  const thisUserCid = currentTrip?.cid;

  const cid = session?.travellerInfo?.cid;
  const { data: publicProfile } = usePublicProfileQuery({ cid });
  const userProfile = publicProfile?.[0];
  const profilePicturePath = userProfile?.s3_profile_pic;

  const isDetailsLoading = session?.isDetailsLoading;

  //   const isOnline = Boolean(session?.user?.isOnline);
  const lastReadMessageIndexByThisUserBeforeLeave = (
    session?.conversation?.attributes as ConversationAttributes
  )?.participants?.find(
    (item) => item?.cid === thisUserCid
  )?.lastReadMessageIndex;
  const lastMessage =
    !lastReadMessageIndexByThisUserBeforeLeave ||
    (session?.lastMessage?.index || 0) >
      lastReadMessageIndexByThisUserBeforeLeave
      ? session?.lastMessage
      : undefined;
  const date = lastMessage?.dateUpdated
    ? dayjs(lastMessage?.dateUpdated).fromNow(true)
    : "";
  const body = lastMessage?.body;
  const name = session?.travellerInfo?.name;
  const isLastMessageFromMe = lastMessage?.author === thisUserCid?.toString();
  const unreadMessageCount = session?.unreadMessageCount;
  const hasNewMessage = Boolean(unreadMessageCount && unreadMessageCount > 0);
  const isAdmin =
    Boolean(
      userProfile?.is_admins?.find((item) => item.group_id === group?.id)
        ?.is_admin
    ) || false;
  const adminTitle =
    userProfile?.is_admins?.find((item) => item.group_id === group?.id)
      ?.admin_title || null;

  if (!session?.conversationSid?.length) return null;

  return isDetailsLoading ? (
    <ConversationItemLoader smallAvatar={smallAvatar} />
  ) : (
    <>
      <ListItemButton onClick={onClick} selected={selected}>
        <SocialAvatar
          isAdmin={isAdmin}
          adminTitle={adminTitle}
          cloudinaryPath={profilePicturePath}
          size={smallAvatar ? "m" : "l"}
        />
        <div className={styles.container}>
          <div className={styles.title}>
            <RichContentPreview content={name} className={styles.name} />
            {hasNewMessage && !isLastMessageFromMe && (
              <Badge
                variant="dot"
                color="error"
                componentsProps={{
                  badge: { className: styles.badge },
                  root: { className: styles.badgeRoot },
                }}
              />
            )}
          </div>
          <div className={styles.title}>
            <RichContentPreview
              content={body || ""}
              variant="body2"
              color="text.secondary"
              className={styles.body}
            />
            <Typography
              variant="body2"
              className={styles.date}
              color="text.secondary"
            >
              {date}
            </Typography>
          </div>
        </div>
      </ListItemButton>
      <Divider variant="middle" />
    </>
  );
};

export default ConversationItem;
export { ConversationItemLoader };
