import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { SignificantDate } from "./useTripDetailsQuery";

type SignificantDates = Partial<{
  tentative_departure: SignificantDate;
  trip_start: SignificantDate;
  arrival_date: SignificantDate;
}>;

type TripDetails = Partial<{
  banner_image: string;
  banner_image_public_id: string;
  orientation_attended_task_id: number;
  orientation_video_signed_url: {
    message: string;
    urls: {
      poster: string;
      "video/original": string;
      "video/480"?: string;
      "video/720": string;
      "video/1080": string;
    };
  };
  cid: number;
  companions: Array<string>;
  countryGuide: string;
  departure_guide: any;
  deposit_complete: number;
  destination: string;
  division: string;
  duration: string;
  id: number;
  link: string;
  region: string;
  sales_register_id: number;
  sales_register_status: string;
  significant_dates: SignificantDates;
  status: string;
  travellers: any;
  trip_code: string;
  trip_group_id: number;
  trip_id: number;
  trip_name: string;
  under_age: number;
  user_ip: any;
  user_selected: number;
  visaDetails: any;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
}>;
type QueryError = {};

const useDetailsTripQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<TripDetails, QueryError, TripDetails, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/trip/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<TripDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<TripDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useDetailsTripQuery;
