import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CalendarCheckIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 2C16.4477 2 16 2.4264 16 2.95238V3.90476H8V2.95238C8 2.4264 7.55228 2 7 2C6.44772 2 6 2.4264 6 2.95238V3.90476H4C2.89543 3.90476 2 4.75755 2 5.80952V20.0952C2 21.1472 2.89543 22 4 22H20C21.1046 22 22 21.1472 22 20.0952V5.80952C22 4.75755 21.1046 3.90476 20 3.90476H18V2.95238C18 2.4264 17.5523 2 17 2ZM4 5.80952H20V8.66667H4V5.80952ZM11.4698 15.2702L15.7197 11.2099C16.0127 10.93 16.4873 10.93 16.7803 11.2099C17.0732 11.4898 17.0732 11.9433 16.7803 12.2232L12.0001 16.7901C11.8536 16.93 11.6617 17 11.4698 17C11.278 17 11.0861 16.93 10.9396 16.7901L8.21973 14.1916C7.92676 13.9117 7.92676 13.4582 8.21973 13.1783C8.5127 12.8984 8.9873 12.8984 9.28027 13.1783L11.4698 15.2702Z"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 2C16.4477 2 16 2.4264 16 2.95238V3.90476H8V2.95238C8 2.4264 7.55228 2 7 2C6.44772 2 6 2.4264 6 2.95238V3.90476H4C2.89543 3.90476 2 4.75755 2 5.80952V20.0952C2 21.1472 2.89543 22 4 22H20C21.1046 22 22 21.1472 22 20.0952V5.80952C22 4.75755 21.1046 3.90476 20 3.90476H18V2.95238C18 2.4264 17.5523 2 17 2ZM4 5.80952H20V8.66667H4V5.80952ZM11.4698 15.2702L15.7197 11.2099C16.0127 10.93 16.4873 10.93 16.7803 11.2099C17.0732 11.4898 17.0732 11.9433 16.7803 12.2232L12.0001 16.7901C11.8536 16.93 11.6617 17 11.4698 17C11.278 17 11.0861 16.93 10.9396 16.7901L8.21973 14.1916C7.92676 13.9117 7.92676 13.4582 8.21973 13.1783C8.5127 12.8984 8.9873 12.8984 9.28027 13.1783L11.4698 15.2702Z"
        />
      )}
    </SvgIcon>
  );
};

export default CalendarCheckIcon;
