import { FC, MouseEvent, ElementType } from "react";
import MuiTablePagination, {
  TablePaginationProps as MuiTablePaginationProps,
} from "@mui/material/TablePagination";
import { TablePaginationActionsProps as MuiTablePaginationActionProps } from "@mui/material/TablePagination/TablePaginationActions";
import { IconButton } from "@/components/Button";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import styles from "./DataTable.module.scss";

type TablePaginationProps = MuiTablePaginationProps;

const TablePaginationActions: ElementType<MuiTablePaginationActionProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const totalPages = Math.ceil(count / rowsPerPage);

  return (
    <div className={styles.paginationContainer}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {<ChevronIcon dir="left" />}
      </IconButton>
      <Typography variant="body1">{`${page + 1}/${totalPages}`}</Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {<ChevronIcon dir="right" />}
      </IconButton>
    </div>
  );
};

const TablePagination: FC<TablePaginationProps> = ({ ...props }) => {
  return (
    <MuiTablePagination
      {...props}
      rowsPerPageOptions={[]}
      labelDisplayedRows={() => ""}
      ActionsComponent={TablePaginationActions}
      classes={{ toolbar: styles.paginationToolbar }}
    />
  );
};

export default TablePagination;
