import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResourcePostArrivalIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        opacity="0.2"
        d="M50.2548 39.0927L50.2873 40.4246C50.3065 41.2147 50.1041 41.9009 49.8353 41.9573L49.8006 41.961H37.2573C36.9878 41.961 36.7693 41.3189 36.7693 40.5268L36.7706 40.4246L36.8025 39.1056L50.2548 39.0927Z"
        fill="#0095CD"
      />
      <path
        d="M28.1273 21V14.5654C28.1273 13.1486 29.2758 12 30.6926 12H48.4346C49.8514 12 51 13.1486 51 14.5654V40.621C51 42.0378 49.8514 43.1864 48.4346 43.1864H36"
        stroke="#003749"
        strokeWidth="2"
      />
      <path
        opacity="0.2"
        d="M17.5576 42.3351L15.4229 39.0927L15.4229 44.8293L17.5576 42.3351Z"
        fill="#0095CD"
      />
      <path
        opacity="0.2"
        d="M32.5 47.6755L30.3235 49.8377H35.7647L32.5 47.6755Z"
        fill="#0095CD"
      />
      <rect
        x="15"
        y="21.8137"
        width="20.8727"
        height="29.1864"
        rx="1.56538"
        stroke="#003749"
        strokeWidth="2"
      />
      <path d="M15 38.5L25.8888 50.9666" stroke="#003749" strokeWidth="2" />
      <path d="M29.3638 12.9185L34.5 22" stroke="#003749" strokeWidth="2" />
      <line
        y1="-1"
        x2="7.9489"
        y2="-1"
        transform="matrix(-0.763582 0.645711 -0.639859 -0.768492 20.7348 45.2292)"
        stroke="#003749"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="7.9489"
        y2="-1"
        transform="matrix(-0.763582 -0.645711 0.639859 -0.768492 36.215 50.3529)"
        stroke="#003749"
        strokeWidth="2"
      />
      <path d="M25.5788 51.0283L36 38.5" stroke="#003749" strokeWidth="2" />
      <path
        d="M40.3269 29.1838H46.3814"
        stroke="#003749"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.4363 26.2372C24.0842 26.2372 22.9787 26.7974 22.0999 27.5605C19.7168 29.6298 20.5126 33.2465 22.409 35.7693L25.3003 39.6155C25.3687 39.7065 25.5039 39.7065 25.5723 39.6155L28.4636 35.7693C30.36 33.2465 31.1558 29.6298 28.7727 27.5605C27.894 26.7974 26.7884 26.2372 25.4363 26.2372Z"
        fill="#0095CD"
      />
      <ellipse
        cx="25.4364"
        cy="30.9827"
        rx="2.01818"
        ry="2.03389"
        fill="white"
      />
      <rect
        x="41.3269"
        y="20.0143"
        width="4.05454"
        height="4.10168"
        rx="1.56538"
        stroke="#003749"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default ResourcePostArrivalIcon;
