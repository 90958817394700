import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type SubmissionTypeItem = Partial<{
  id: number;
  name: string;
  gbp: number;
  aud: number;
  cad: number;
  usd: number;
  _ca: number;
  _cb: any;
  _lma: number;
  _lmb: any;
}>;

type SubmissionTypesList = {
  result: Array<SubmissionTypeItem>;
  success: boolean;
};

type QueryError = {};

const useSubmissionTypesListQuery = (
  options?: UseQueryOptions<
    SubmissionTypesList,
    QueryError,
    SubmissionTypesList,
    QueryKey
  >
) => {
  const URL = `${REWARD_ENDPOINT}/submission-types`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<SubmissionTypesList, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<SubmissionTypesList>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useSubmissionTypesListQuery;
