import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import Button from "../Button";
import { communityGuidelines, coordinatorCid } from "../Social";
import Typography from "../Typography";
import styles from "./ViolationDialog.module.scss";

const ViolationDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const router = useRouter();
  const tripIdQueryParam =
    Number((router.query as ParsedUrlQueryInput)?.tripId ?? 0) || null;

  const openCommunityManagerProfile = () => {
    router.push({
      pathname: "/social/traveller/" + coordinatorCid,
      query: tripIdQueryParam ? { tripId: tripIdQueryParam } : undefined,
    });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Community Guidelines
      </DialogTitle>
      <DialogContent className={styles.container} dividers>
        <Typography color="text.primary">
          Your post/comment has been removed as it violates our community
          guidelines.
        </Typography>

        <Typography color="text.secondary" className={styles.subtitle}>
          You may be removed from your Social Group if any further posts violate
          our guidelines.
        </Typography>

        <Typography>Community Guidelines:</Typography>
        <ul className={styles.rulesList}>
          {communityGuidelines.map((rule) => (
            <Typography key={rule} component="li" color="text.secondary">
              {rule}
            </Typography>
          ))}
        </ul>

        <Button
          variant="text"
          size="small"
          onClick={openCommunityManagerProfile}
        >
          Speak to your Community Manager
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViolationDialog;
