import { usePublicProfileQuery } from "@/fetch/profiles/traveller";
import { useRecentPostsQuery, useTravellerPostsQuery } from "@/fetch/social";
import { useCurrentTrip } from "@/hooks";
import Dialog, { DialogContent, DialogTitle } from "@/components/Dialog";
import { ProfileInfoForm } from "@/components/PersonalDetailsForms";
import { useTrackers } from "@/hooks";
import styles from "./EditProfileDialog.module.scss";

const EditProfileDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const { refetch: refetchUserPosts } = useTravellerPostsQuery({ cid });
  const { refetch: refetchPublicProfile } = usePublicProfileQuery({ cid });
  const { refetch: refetchRecentPosts } = useRecentPostsQuery();
  const { track } = useTrackers();
  const onSubmit = async () => {
    track("Profile Info Submitted", { eventId: "profile-info-submitted" });
    try {
      await Promise.all([
        refetchUserPosts(),
        refetchPublicProfile(),
        refetchRecentPosts(),
      ]);
    } catch (error) {}
    onClose();
  };
  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{ className: styles.formDialogRoot }}
      scroll="paper"
    >
      <DialogTitle onClose={onClose}>Edit Profile</DialogTitle>
      <DialogContent className={styles.formDialogContent} dividers>
        <ProfileInfoForm
          onSubmitted={onSubmit}
          secondaryButtonAction={onClose}
          secondaryButtonLabel="Cancel"
          hasAccountSettingLink
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileDialog;
