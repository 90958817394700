import { FC, useRef } from "react";
// @ts-ignore
import { Image, Video, Transformation } from "cloudinary-react";
import { Media } from "@/fetch/social";
import Typography from "@/components/Typography";
import { PlayIcon } from "@/components/Icon";
import { useResponsive } from "@/hooks";
import styles from "./PostCardMedia.module.scss";

type Props = {
  onClick: (mediaId: number) => void;
  media: Array<Media>;
};

const PostCardMedia: FC<Props> = ({ media, onClick }) => {
  const { isMobile } = useResponsive();

  const showingMedia = isMobile ? media.slice(0, 4) : media.slice(0, 3);
  const isMoreThanShowing = isMobile ? media.length > 4 : media.length > 3;
  const lastShowingIndex = isMobile ? 3 : 2;
  const isOneMedia = media.length === 1;
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = containerRef.current?.offsetWidth || 669;
  const mediaWidth = isOneMedia
    ? containerWidth
    : isMobile
    ? Math.round(containerWidth / 2)
    : Math.round(containerWidth / showingMedia.length);
  const mediaHeight = isMobile && showingMedia.length > 2 ? "152" : "304";

  return (
    <div className={styles.container} ref={containerRef}>
      {showingMedia.map((eachMedia, idx) => (
        <div
          key={eachMedia.id}
          role="button"
          onClick={() => onClick(eachMedia.id || 0)}
          className={styles.item}
          style={{ maxWidth: mediaWidth }}
        >
          {eachMedia.type === "Image" && (
            <Image cloudName="gwatco" publicId={eachMedia.public_id} alt="">
              <Transformation
                height={mediaHeight}
                width={`${mediaWidth}`}
                crop="thumb"
                gravity="auto"
              />
            </Image>
          )}
          {eachMedia.type === "Video" && (
            <>
              <Video
                cloudName="gwatco"
                publicId={eachMedia.public_id}
                alt=""
                controls={false}
                perload="true"
                crop="scale"
              >
                <Transformation aspectRatio="1.0" gravity="auto" />
              </Video>
              <div className={styles.playIconContainer}>
                <PlayIcon variant="contained" className={styles.playIcon} />
              </div>
            </>
          )}
          {idx === lastShowingIndex && isMoreThanShowing && (
            <div className={styles.plusCard}>
              <Typography variant="h3" color="text.contrast">{`+${
                isMobile ? media.length - 4 : media.length - 3
              }`}</Typography>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PostCardMedia;
