import * as yup from "yup";
import { useCurrentTrip } from "@/hooks";
import { Division } from "@/fetch/gworld";

type Input = {
  isInSetupPage: boolean;
};

const usePersonalDetailsFormValidation = (input?: Input) => {
  const { currentTrip } = useCurrentTrip();

  const isWorkingHolidayTripDivision =
    currentTrip?.division === Division.WorkingHoliday;

  const profileInfoValidationSchema = yup.object({
    profileInfoNickname: yup.string().required("Required"),
    profileInfoBio: yup
      .string()
      .max(120, `This field should not contains more than 120 characters.`),
  });

  const generalInfoValidationSchema = yup.object({
    generalInfoFirstName: yup.string().required("Required"),
    generalInfoMiddleName: yup.string(),
    generalInfoLastName: yup.string().required("Required"),
    generalInfoDateOfBirth: yup
      .number()
      .nullable()
      .required("Required")
      .typeError("Please use the date picker to enter a valid date"),
    generalInfoNationality: yup.object().shape({
      label: yup.string().required("Required"),
    }),
    generalInfoGender: yup.string().required("Required"),
  });

  const guardianDetailsValidationSchema = yup.object().shape(
    {
      guardianDetailsName: yup.string().required("Required"),
      guardianDetailsRelationSelect: yup.string().required("Required"),
      guardianDetailsRelationTextField: yup
        .string()
        .nullable()
        .when("guardianDetailsRelationSelect", {
          is: "Other",
          then: (schema) => schema.required("Required"),
        }),
      guardianDetailsEmail: yup
        .string()
        .email("Enter a valid email")
        .required("Required"),
      guardianDetailsPrimaryPhone: yup
        .number()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .required("Required"),
      guardianDetailsPrimaryCountryCode: yup.object().shape({
        label: yup.string().required("Required"),
        dial_code: yup.string(),
        code: yup.string(),
      }),
      guardianDetailsSecondaryPhone: yup
        .number()
        .nullable()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .when("guardianDetailsSecondaryCountryCode", {
          is: (val: any) => Boolean(val?.label?.length),
          then: (schema) => schema.required("Required, code is entered"),
        })
        .when(
          [
            "guardianDetailsPrimaryPhone",
            "guardianDetailsPrimaryCountryCode",
            "guardianDetailsSecondaryCountryCode",
          ],
          // problem with type and functionality, type suggests that values must elements
          // of an array but when values are taken from the array, validation doesn't work
          // @ts-ignore
          (
            primaryPhoneVal: any,
            primaryCountryCodeVal: any,
            secondaryCountryCodeVal: any,
            schema: any
          ) => {
            if (
              secondaryCountryCodeVal?.dial_code ===
              primaryCountryCodeVal?.dial_code
            ) {
              return schema.notOneOf(
                [primaryPhoneVal],
                "Enter a new phone number"
              );
            }
            return schema;
          }
        ),
      guardianDetailsSecondaryCountryCode: yup
        .object()
        .when("guardianDetailsSecondaryPhone", {
          is: (val: any) => Boolean(val),
          then: yup.object().shape({
            label: yup.string().required("Required, number is entered"),
            dial_code: yup.string(),
            code: yup.string(),
          }),
          otherwise: yup.object().shape({
            label: yup.string(),
            dial_code: yup.string(),
            code: yup.string(),
          }),
        }),
    },
    [["guardianDetailsSecondaryCountryCode", "guardianDetailsSecondaryPhone"]]
  );

  const emergencyContactValidationSchema = yup.object().shape(
    {
      emergencyContactName: yup.string().required("Required"),
      emergencyContactRelationSelect: yup.string().required("Required"),
      emergencyContactRelationTextField: yup
        .string()
        .nullable()
        .when("emergencyContactRelationSelect", {
          is: "Other",
          then: (schema) => schema.required("Required"),
        }),
      emergencyContactEmail: yup
        .string()
        .email("Enter a valid email")
        .required("Required"),
      emergencyContactPrimaryPhone: yup
        .number()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .required("Required"),
      emergencyContactPrimaryCountryCode: yup.object().shape({
        label: yup.string().required("Required"),
        dial_code: yup.string(),
        code: yup.string(),
      }),
      emergencyContactSecondaryPhone: yup
        .number()
        .nullable()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .when("emergencyContactSecondaryCountryCode", {
          is: (val: any) => Boolean(val?.label?.length),
          then: (schema) => schema.required("Required, code is entered"),
        })
        .when(
          [
            "emergencyContactPrimaryPhone",
            "emergencyContactPrimaryCountryCode",
            "emergencyContactSecondaryCountryCode",
          ],
          // problem with type and functionality, type suggests that values must elements
          // of an array but when values are taken from the array, validation doesn't work
          // @ts-ignore
          (
            primaryPhoneVal: any,
            primaryCountryCodeVal: any,
            secondaryCountryCodeVal: any,
            schema: any
          ) => {
            if (
              secondaryCountryCodeVal?.dial_code ===
              primaryCountryCodeVal?.dial_code
            ) {
              return schema.notOneOf(
                [primaryPhoneVal],
                "Enter a new phone number"
              );
            }
            return schema;
          }
        ),
      emergencyContactSecondaryCountryCode: yup
        .object()
        .when("emergencyContactSecondaryPhone", {
          is: (val: any) => Boolean(val),
          then: yup.object().shape({
            label: yup.string().required("Required, number is entered"),
            dial_code: yup.string(),
            code: yup.string(),
          }),
          otherwise: yup.object().shape({
            label: yup.string(),
            dial_code: yup.string(),
            code: yup.string(),
          }),
        }),
    },
    [["emergencyContactSecondaryPhone", "emergencyContactSecondaryCountryCode"]]
  );

  const contactDetailsValidationSchema = yup.object().shape(
    {
      contactDetailsEmail: yup
        .string()
        .email("Enter a valid email")
        .required("Required"),
      contactDetailsPrimaryPhone: yup
        .number()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .required("Required"),
      contactDetailsPrimaryCountryCode: yup.object().shape({
        label: yup.string().required("Required"),
        dial_code: yup.string(),
        code: yup.string(),
      }),
      contactDetailsSecondaryPhone: yup
        .number()
        .nullable()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .when("contactDetailsSecondaryCountryCode", {
          is: (val: any) => Boolean(val?.label?.length),
          then: (schema) => schema.required("Required, code is entered"),
        })
        .when(
          [
            "contactDetailsPrimaryPhone",
            "contactDetailsPrimaryCountryCode",
            "contactDetailsSecondaryCountryCode",
          ],
          // problem with type and functionality, type suggests that values must elements
          // of an array but when values are taken from the array, validation doesn't work
          // @ts-ignore
          (
            primaryPhoneVal: any,
            primaryCountryCodeVal: any,
            secondaryCountryCodeVal: any,
            schema: any
          ) => {
            if (
              secondaryCountryCodeVal?.dial_code ===
              primaryCountryCodeVal?.dial_code
            ) {
              return schema.notOneOf(
                [primaryPhoneVal],
                "Enter a new phone number"
              );
            }
            return schema;
          }
        ),
      contactDetailsSecondaryCountryCode: yup
        .object()
        .when("contactDetailsSecondaryPhone", {
          is: (val: any) => Boolean(val),
          then: yup.object().shape({
            label: yup.string().required("Required, number is entered"),
            dial_code: yup.string(),
            code: yup.string(),
          }),
          otherwise: yup.object().shape({
            label: yup.string(),
            dial_code: yup.string(),
            code: yup.string(),
          }),
        }),
      contactDetailsTravelPhone: yup
        .number()
        .nullable()
        .integer("Enter a valid phone number")
        .positive("Enter a valid phone number")
        .when("contactDetailsTravelCountryCode", {
          is: (val: any) => Boolean(val?.label?.length),
          then: (schema) => schema.required("Required, code is entered"),
        }),
      contactDetailsTravelCountryCode: yup
        .object()
        .when("contactDetailsTravelPhone", {
          is: (val: any) => Boolean(val),
          then: yup.object().shape({
            label: yup.string().required("Required, number is entered"),
            dial_code: yup.string(),
            code: yup.string(),
          }),
          otherwise: yup.object().shape({
            label: yup.string(),
            dial_code: yup.string(),
            code: yup.string(),
          }),
        }),
      contactDetailsStreet: yup.string().required("Required"),
      contactDetailsSuburb: yup.string().required("Required"),
      contactDetailsState: yup.string().required("Required"),
      contactDetailsCountry: yup.object().shape({
        label: yup.string().required("Required"),
        dial_code: yup.string(),
        code: yup.string(),
      }),
      contactDetailsPostCode: yup.mixed().required("Required"),
    },
    [
      ["contactDetailsSecondaryCountryCode", "contactDetailsSecondaryPhone"],
      ["contactDetailsTravelCountryCode", "contactDetailsTravelPhone"],
    ]
  );

  const passportValidationSchema = yup.object({
    passportStatus: yup.string().required("Required"),
    passportExpiryDate: yup
      .number()
      .nullable()
      .when("passportStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    passportIssueDate: yup
      .number()
      .nullable()
      .when("passportStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    passportIssueLocation: yup.string().when("passportStatus", {
      is: "yes",
      then: (schema) => schema.required("Required"),
    }),
    passportNationality: yup
      .object()
      .shape({
        label: yup.string(),
      })
      .when("passportStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),
    passportNumber: yup.string().when("passportStatus", {
      is: "yes",
      then: (schema) => schema.required("Required"),
    }),
  });

  const personalDetailsValidationSchema = yup.object({
    personalDetailsHeight: yup
      .number()
      .positive("Height must be a positive number")
      .required("Required")
      .typeError("Please enter a valid number"),
    personalDetailsWeight: yup
      .number()
      .positive("Weight must be a positive number")
      .required("Required")
      .typeError("Please enter a valid number"),
    personalDetailsAlcohol: yup.string().nullable().required("Required"),
    personalDetailsSmoke: yup.string().nullable().required("Required"),
    personalDetailsIllicit: yup.string().nullable().required("Required"),

    personalDetailsCovidStatus: yup.string().nullable().required("Required"),
    personalDetailsCovidDescription: yup
      .string()
      .nullable()
      .when("personalDetailsCovidStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsDietStatus: yup.string().nullable().required("Required"),
    personalDetailsDietDescription: yup
      .string()
      .when("personalDetailsDietStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsMedicationStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsMedicationDescription: yup
      .string()
      .when("personalDetailsMedicationStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsMentalHealthStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsMentalHealthDescription: yup
      .string()
      .when("personalDetailsMentalHealthStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsPhysicalLimitationsStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsPhysicalLimitationsDescription: yup
      .string()
      .when("personalDetailsPhysicalLimitationsStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsTattoosPiercingsStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsTattoosPiercingsDescription: yup
      .string()
      .when("personalDetailsTattoosPiercingsStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsCriminalStatus: yup.string().nullable().required("Required"),
    personalDetailsCriminalDate: yup
      .number()
      .nullable()
      .when("personalDetailsCriminalStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    personalDetailsCriminalDescription: yup
      .string()
      .when("personalDetailsCriminalStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsDrinkDrivingStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsDrinkDrivingDate: yup
      .number()
      .nullable()
      .when("personalDetailsDrinkDrivingStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    personalDetailsDrinkDrivingBac: yup
      .number()
      .positive()
      .nullable()
      .min(0, "BAC can not be below 0")
      .max(100, "BAC can not be above 100")
      .when("personalDetailsDrinkDrivingStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please enter a valid number"),
    personalDetailsDrinkDrivingDescription: yup
      .string()
      .when("personalDetailsDrinkDrivingStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsDrivingOffencesStatus: yup
      .string()
      .nullable()
      .required("Required"),
    personalDetailsDrivingOffencesDate: yup
      .number()
      .nullable()
      .when("personalDetailsDrivingOffencesStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    personalDetailsDrivingOffencesDescription: yup
      .string()
      .when("personalDetailsDrivingOffencesStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),

    personalDetailsWorkingHolidayVisaStatus:
      isWorkingHolidayTripDivision && input?.isInSetupPage
        ? yup.string().nullable().required("Required")
        : yup.string().nullable(),
    personalDetailsWorkingHolidayVisaDate: yup
      .number()
      .nullable()
      .when("personalDetailsWorkingHolidayVisaStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    personalDetailsWorkingHolidayVisaDescription: yup
      .string()
      .when("personalDetailsWorkingHolidayVisaStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),
  });

  const workingHolidayVisaValidationSchema = yup.object({
    workingHolidayVisaStatus: yup.string().nullable().required("Required"),
    workingHolidayVisaDate: yup
      .number()
      .nullable()
      .when("workingHolidayVisaStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      })
      .typeError("Please use the date picker to enter a valid date"),
    workingHolidayVisaDescription: yup
      .string()
      .when("workingHolidayVisaStatus", {
        is: "yes",
        then: (schema) => schema.required("Required"),
      }),
  });

  const departureDateValidationSchema = yup.object({
    departureDate: yup
      .number()
      .nullable()
      .typeError("Please use the date picker to enter a valid date"),
  });

  return {
    profileInfoValidationSchema,
    generalInfoValidationSchema,
    emergencyContactValidationSchema,
    contactDetailsValidationSchema,
    passportValidationSchema,
    guardianDetailsValidationSchema,
    personalDetailsValidationSchema,
    workingHolidayVisaValidationSchema,
    departureDateValidationSchema,
  };
};

export default usePersonalDetailsFormValidation;
