import { QueryKey, UseQueryOptions, useQueryClient } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useState } from "react";

type Response = {
  file: {
    bucket: string;
    key: string;
    pdf: string;
    url: string;
  };
};
type QueryError = {};

const useInvoicePDFQuery = (
  options?: UseQueryOptions<Response, QueryError, Response, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const getInvoicePDF = async (invoiceId: number) => {
    const url = `${PAYMENT_ENDPOINT}/invoice/pdf/${invoiceId}`;
    const cacheKey = [url];

    return queryClient.fetchQuery<Response, QueryError, Response, QueryKey>(
      cacheKey,
      async () => {
        setIsLoading(true);
        const result = await fetchWithAuth<Response>(url);
        setIsLoading(false);
        return result;
      },
      {
        ...options,
      }
    );
  };

  return { isLoading, getInvoicePDF };
};
export default useInvoicePDFQuery;
