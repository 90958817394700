import MuiList from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import MuiListItemText from "@mui/material/ListItemText";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemButton from "@mui/material/ListItemButton";

const List = MuiList;
const ListItem = MuiListItem;
const ListItemText = MuiListItemText;
const ListItemIcon = MuiListItemIcon;
const ListItemButton = MuiListItemButton;

export default List;

export { ListItem, ListItemText, ListItemIcon, ListItemButton };
