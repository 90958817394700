import { FC } from "react";
import { DialogContent, DialogTitle, DialogActions } from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import styles from "./PendingReward.module.scss";

type Props = {
  onClose: () => void;
};

const PendingReward: FC<Props> = ({ onClose }) => {
  return (
    <>
      <DialogTitle hasCloseButton onClose={onClose}></DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.content}>
          <Typography variant="h6">{`You've already made a submission.`}</Typography>

          <Typography variant="subtitle1" className={styles.text}>
            We will review your submission and get back to you within 7 days
          </Typography>
        </div>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          className={styles.button}
        >
          Sounds good
        </Button>
      </DialogContent>
    </>
  );
};

export default PendingReward;
