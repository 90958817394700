import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResourceGuideIconV1: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        d="M31.8057 16.2596L31.9489 16.2862L46.1679 19.3568C46.8274 19.4992 47.4003 19.9075 47.7537 20.4898L48.1607 21.1639L48.7324 22.1249L49.0784 22.718L49.3889 23.2616L49.6768 23.7807L49.989 24.362L50.3197 24.9936L50.8414 26.0147L51.3814 27.0968L51.7447 27.8366C51.9639 28.2854 52.0437 28.7892 51.9776 29.2834L51.946 29.4681L48.1869 47.4356C47.9127 48.7466 46.6797 49.6005 45.392 49.4006L45.2488 49.3741L44.2534 49.159C43.9465 50.1743 43.0459 50.9256 41.9631 50.9948L41.7993 51H20.9266C19.5596 51 18.4422 49.9144 18.3642 48.5454L18.3598 48.3922L18.3602 31.2724C18.8044 31.3534 19.2591 31.4033 19.7222 31.4201L20.071 31.4264V48.3922C20.071 48.838 20.4013 49.2054 20.8268 49.2556L20.9266 49.2615H41.7993C42.2382 49.2615 42.5998 48.9259 42.6492 48.4937L42.655 48.3923V29.5237L39.9854 29.5243C37.8947 29.5243 36.1885 27.8516 36.0927 25.7516L36.0885 25.5652L36.0881 21.6463L29.6966 21.647C29.6966 20.3943 29.4648 19.1966 29.0423 18.0958L29.0107 18.2248C29.285 16.9137 30.518 16.0598 31.8057 16.2596ZM30.7104 18.492L30.6846 18.5863L30.4077 19.9077L36.266 19.9085C36.9064 19.9085 37.5203 20.1507 37.9892 20.5861L38.1133 20.7096L38.6282 21.2601L39.36 22.0518L40.0236 22.783L40.5969 23.4316L41.0271 23.9346L41.4904 24.4897L41.9804 25.0881L42.4911 25.7213L43.0159 26.3806L43.8163 27.3997C44.1336 27.8066 44.3213 28.2996 44.3591 28.8149L44.3662 29.009L44.3661 47.4056L45.6045 47.6735C45.9866 47.756 46.3625 47.5337 46.4873 47.1683L46.5131 47.0741L50.2722 29.1066C50.3077 28.9367 50.2864 28.762 50.2118 28.6092L49.8542 27.8811L49.3227 26.8161L48.9781 26.1383L48.4864 25.194L48.1818 24.6267L48.0382 24.3655L47.783 23.9123L47.3397 23.1466L46.8387 22.3011L46.2971 21.4022C46.2078 21.2551 46.0717 21.1442 45.9112 21.0859L45.8121 21.0574L31.5931 17.9868C31.2111 17.9043 30.8351 18.1266 30.7104 18.492ZM37.7993 23.1483L37.7997 25.5652C37.7997 26.6934 38.628 27.6252 39.7004 27.767L39.8417 27.781L39.9854 27.7857L41.6649 27.785L37.7993 23.1483Z"
        fill="#003749"
      />
      <path
        d="M20.7446 14.4805C24.7691 14.4805 28.0317 17.7952 28.0317 21.884C28.0317 25.9728 24.7691 29.2875 20.7446 29.2875C16.7201 29.2875 13.4575 25.9728 13.4575 21.884C13.4575 17.7952 16.7201 14.4805 20.7446 14.4805ZM20.7446 20.4033C20.3421 20.4033 20.0159 20.7348 20.0159 21.1437V25.5858C20.0159 25.9946 20.3421 26.3261 20.7446 26.3261C21.1471 26.3261 21.4733 25.9946 21.4733 25.5858V21.1437C21.4733 20.7348 21.1471 20.4033 20.7446 20.4033ZM20.7446 17.4419C20.3421 17.4419 20.0159 17.7734 20.0159 18.1823C20.0159 18.5912 20.3421 18.9226 20.7446 18.9226C21.1471 18.9226 21.4733 18.5912 21.4733 18.1823C21.4733 17.7734 21.1471 17.4419 20.7446 17.4419Z"
        fill="#0095CD"
      />
      <path
        d="M23.9246 34.8067H30.6889"
        stroke="#003749"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.9246 39.6523H36.6439"
        stroke="#003749"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        opacity="0.2"
        d="M19.9495 46.1136H43.0032V47.7289C43.0032 48.621 42.2659 49.3442 41.3565 49.3442H19.9495V46.1136Z"
        fill="#0095CD"
      />
    </SvgIcon>
  );
};

export default ResourceGuideIconV1;
