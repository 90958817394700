import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@/components/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { ListItem, ListItemButton, ListItemText } from "../List";

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null;
} = { current: null };
const placesService: { current: google.maps.places.PlacesService | null } = {
  current: null,
};
const libraries = ["places"] as Libraries;

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type GoogleAddress = {
  streetNumber: string | null;
  streetName: string | null;
  suburb: string | null;
  postCode: string | null;
  country: string | null;
  countryCode: string | null;
  state: string | null;
  stateCode: string | null;
};

export type Address = {
  street: string | null;
  suburb: string | null;
  state: string | null;
  country: {
    name: string;
    code: string;
    filename: string;
  } | null;
  postCode: string | null;
} | null;

const LocationSelector: React.FC<{
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  label?: string;
  placeholder?: string;
  onAddressSelect: (address: Address) => void;
  onCantFindAddressClicked: () => void;
}> = ({
  disabled,
  touched,
  error,
  label = "Full Address",
  placeholder,
  onAddressSelect,
  onCantFindAddressClicked,
}) => {
  const [value, setValue] = useState<AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly AutocompletePrediction[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API || "",
    libraries,
  });

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!placesService.current && (window as any).google) {
      placesService.current = new (
        window as any
      ).google.maps.places.PlacesService(
        document.getElementById("location-selector")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue },
      (results?: readonly AutocompletePrediction[]) => {
        if (active) {
          let newOptions: readonly AutocompletePrediction[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    const getFormattedAddress = (
      place: google.maps.places.PlaceResult,
      description: string
    ): Address => {
      if (!place.address_components) {
        return null;
      }

      let locationObj: GoogleAddress = {
        streetNumber: null,
        streetName: null,
        suburb: null,
        postCode: null,
        country: null,
        countryCode: null,
        state: null,
        stateCode: null,
      };

      for (let index in place.address_components) {
        let address_component = place.address_components[index];

        if (address_component["types"].indexOf("locality") > -1) {
          locationObj["suburb"] = address_component["long_name"];
        } else if (
          address_component["types"].indexOf("administrative_area_level_1") > -1
        ) {
          locationObj["state"] = address_component["long_name"];
          locationObj["stateCode"] = address_component["short_name"];
        } else if (address_component["types"].indexOf("street_number") > -1) {
          locationObj["streetNumber"] = address_component["short_name"];
        } else if (address_component["types"].indexOf("route") > -1) {
          locationObj["streetName"] = address_component["long_name"];
        } else if (address_component["types"].indexOf("country") > -1) {
          locationObj["countryCode"] = address_component["short_name"];
          locationObj["country"] = address_component["long_name"];
        } else if (address_component["types"].indexOf("postal_code") > -1) {
          locationObj["postCode"] = address_component["short_name"];
        }
      }

      const street = (() => {
        if (!locationObj.streetNumber || !locationObj.streetName) {
          return null;
        }

        const indexOfStreetNumber = description.indexOf(
          locationObj.streetNumber
        );
        const indexOfStreetName = description.indexOf(locationObj.streetName);

        if (indexOfStreetName > indexOfStreetNumber) {
          return description
            .substring(0, description.indexOf(",", indexOfStreetName))
            .trim();
        } else {
          return description
            .substring(0, description.indexOf(",", indexOfStreetNumber))
            .trim();
        }
      })();

      return {
        street: street,
        suburb: locationObj.suburb,
        state: locationObj.state,
        country:
          locationObj.countryCode && locationObj.country
            ? {
                name: locationObj.country,
                code: locationObj.countryCode,
                filename: locationObj.country.toLowerCase(),
              }
            : null,
        postCode: locationObj.postCode,
      };
    };

    const request = {
      placeId: value?.place_id || "",
      fields: ["address_components"],
    };

    if (request.placeId) {
      placesService.current?.getDetails(
        request,
        (placeResult: google.maps.places.PlaceResult | null) => {
          if (placeResult) {
            const result = getFormattedAddress(
              placeResult,
              value?.description || ""
            );
            onAddressSelect(result);
          }
        }
      );
    } else {
      onAddressSelect({
        country: null,
        postCode: null,
        state: null,
        street: null,
        suburb: null,
      });
    }
  }, [value]);

  return (
    <Autocomplete
      id="location-selector"
      blurOnSelect="mouse"
      disabled={disabled}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={
        <ListItemText
          sx={{ cursor: "pointer" }}
          onClick={() => onCantFindAddressClicked()}
          primary="Can't find your address?"
        />
      }
      onChange={(event: any, newValue: AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={touched && Boolean(error)}
          helperText={touched && error}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const isLastOption =
          ((props as any)["data-option-index"] || 0) === options.length - 1;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <>
            <ListItem {...props}>
              <ListItemText
                primary={parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                secondary={option.structured_formatting.secondary_text}
              />
            </ListItem>
            {isLastOption && (
              <ListItemButton onClick={() => onCantFindAddressClicked()}>
                <ListItemText primary="Can't find your address?" />
              </ListItemButton>
            )}
          </>
        );
      }}
    />
  );
};

export default LocationSelector;
