import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type Reason = {
  id: number;
  type: "Delete" | "Report";
  content: string;
};

type Reasons = Array<Reason>;

type Result = {
  data: { results: Reasons };
  statusCode: number;
  success: boolean | string;
};

type QueryError = {};

const useReportReasonsQuery = (
  options?: UseQueryOptions<Reasons, QueryError, Reasons, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  const url = `${GATEWAY_URL}/reason/Report`;
  const cacheKey = [url];

  return useQuery<Reasons, QueryError>(
    cacheKey,
    async () => {
      const result = await fetchWithAuth<Result>(url);
      return result.data.results;
    },
    {
      ...options,
    }
  );
};
export default useReportReasonsQuery;
