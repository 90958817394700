export const COLONS_REGEX = /(:[^\s:^\/]+(?:::skin-tone-[2-6])?:)/;
export const IMAGE_TAG_REGEX = /(<img.*?[^\>]+>)/;

const decomposeEmoji = (input?: string) =>
  input
    ?.split(COLONS_REGEX)
    .map((item) => item.split(IMAGE_TAG_REGEX))
    .flat(2);

export default decomposeEmoji;
