import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useGroupQuery from "./useGroupQuery";

import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreatePostMutationResult = Partial<{
  cid: number;
  content: string;
  group_id: number;
  id: number;
  status: string;
}>;

export type CreatePostResult = {
  data: CreatePostMutationResult;
  statusCode: number;
  success: boolean;
};

export type CreatePostVariables = { content: string | undefined };
type QueryError = {};

const useCreatePostMutation = (
  options?: UseMutationOptions<
    CreatePostMutationResult,
    QueryError,
    CreatePostVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: group } = useGroupQuery();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const groupId = group?.id;
  const url = `${GATEWAY_URL}/post`;

  return {
    ...useMutation<CreatePostMutationResult, QueryError, CreatePostVariables>(
      async ({ content }) => {
        if (!cid || !groupId) throw new Error("Group id not found");

        const result = await fetchWithAuth<CreatePostResult>(url, {
          method: "POST",
          body: {
            group_id: groupId,
            cid,
            content,
          },
        });
        return result.data;
      },
      options
    ),
    cancel,
  };
};
export default useCreatePostMutation;
