import { useMutation, UseMutationOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type GenerateTxPDFVariables = {
  invoiceId: number;
  transactionId: number;
};

type GenerateTxPDFResult = {
  file: {
    bucket: string;
    key: string;
    pdf: string;
    url: string;
  };
  statusCode: 200;
  success: true;
};

type QueryError = {};

const useGenerateTxPDFMutation = (
  options?: UseMutationOptions<
    GenerateTxPDFResult,
    QueryError,
    GenerateTxPDFVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<GenerateTxPDFResult, QueryError, GenerateTxPDFVariables>(
      async ({ invoiceId, transactionId }: GenerateTxPDFVariables) => {
        const url = `${PAYMENT_ENDPOINT}/transaction/pdf/${invoiceId}/${transactionId}`;

        return fetchWithAuth<GenerateTxPDFResult>(url);
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useGenerateTxPDFMutation;
