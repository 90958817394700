import SvgIcon, { SvgIconProps } from "./SvgIcon";

const SourceIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M4.81626 21.6924L2.74561 9.26851H21.2543L19.1837 21.6924H4.81626ZM9.99996 15.3196H14C14.2826 15.3196 14.5183 15.2211 14.707 15.024C14.8958 14.8269 14.9902 14.5906 14.9902 14.3153C14.9902 14.0326 14.8958 13.797 14.707 13.6082C14.5183 13.4194 14.2826 13.325 14 13.325H9.99996C9.73401 13.325 9.50248 13.4194 9.30538 13.6082C9.10828 13.797 9.00973 14.0326 9.00973 14.3153C9.00973 14.5906 9.10828 14.8269 9.30538 15.024C9.50248 15.2211 9.73401 15.3196 9.99996 15.3196ZM5.99996 7.76851C5.73401 7.76851 5.50248 7.67231 5.30538 7.47991C5.10828 7.28751 5.00973 7.05363 5.00973 6.77826C5.00973 6.49566 5.10828 6.25998 5.30538 6.07121C5.50248 5.88243 5.73401 5.78803 5.99996 5.78803H18C18.2826 5.78803 18.5183 5.88243 18.707 6.07121C18.8958 6.25998 18.9902 6.49566 18.9902 6.77826C18.9902 7.05363 18.8958 7.28751 18.707 7.47991C18.5183 7.67231 18.2826 7.76851 18 7.76851H5.99996ZM7.99996 4.28803C7.73401 4.28803 7.50248 4.18948 7.30538 3.99238C7.10828 3.79528 7.00973 3.56376 7.00973 3.29781C7.00973 3.01519 7.10828 2.77715 7.30538 2.58368C7.50248 2.3902 7.73401 2.29346 7.99996 2.29346H16C16.2826 2.29346 16.5183 2.3902 16.707 2.58368C16.8958 2.77715 16.9902 3.01519 16.9902 3.29781C16.9902 3.56376 16.8958 3.79528 16.707 3.99238C16.5183 4.18948 16.2826 4.28803 16 4.28803H7.99996Z" />
      ) : (
        <path d="M4.81626 21.6924L2.74561 9.26851H21.2543L19.1837 21.6924H4.81626ZM6.49018 19.6978H17.5239L18.8641 11.249H5.14998L6.49018 19.6978ZM9.99996 15.3196H14C14.2826 15.3196 14.5183 15.2211 14.707 15.024C14.8958 14.8269 14.9902 14.5906 14.9902 14.3153C14.9902 14.0326 14.8958 13.797 14.707 13.6082C14.5183 13.4194 14.2826 13.325 14 13.325H9.99996C9.73401 13.325 9.50248 13.4194 9.30538 13.6082C9.10828 13.797 9.00973 14.0326 9.00973 14.3153C9.00973 14.5906 9.10828 14.8269 9.30538 15.024C9.50248 15.2211 9.73401 15.3196 9.99996 15.3196ZM5.99996 7.76851C5.73401 7.76851 5.50248 7.67231 5.30538 7.47991C5.10828 7.28751 5.00973 7.05362 5.00973 6.77826C5.00973 6.49566 5.10828 6.25998 5.30538 6.07121C5.50248 5.88243 5.73401 5.78803 5.99996 5.78803H18C18.2826 5.78803 18.5183 5.88243 18.707 6.07121C18.8958 6.25998 18.9902 6.49566 18.9902 6.77826C18.9902 7.05362 18.8958 7.28751 18.707 7.47991C18.5183 7.67231 18.2826 7.76851 18 7.76851H5.99996ZM7.99996 4.28803C7.73401 4.28803 7.50248 4.18948 7.30538 3.99238C7.10828 3.79528 7.00973 3.56376 7.00973 3.29781C7.00973 3.01519 7.10828 2.77715 7.30538 2.58368C7.50248 2.3902 7.73401 2.29346 7.99996 2.29346H16C16.2826 2.29346 16.5183 2.3902 16.707 2.58368C16.8958 2.77715 16.9902 3.01519 16.9902 3.29781C16.9902 3.56376 16.8958 3.79528 16.707 3.99238C16.5183 4.18948 16.2826 4.28803 16 4.28803H7.99996Z" />
      )}
    </SvgIcon>
  );
};

export default SourceIcon;
