import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FaqIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <>
          <g clipPath="url(#clip0_2369_107)">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18.5C13 18.7761 12.7761 19 12.5 19H11.5C11.2239 19 11 18.7761 11 18.5V17.5C11 17.2239 11.2239 17 11.5 17H12.5C12.7761 17 13 17.2239 13 17.5V18.5ZM15.07 11.25L14.17 12.17C13.5358 12.813 13.1111 13.3551 13.0189 14.5002C12.9967 14.7754 12.7761 15 12.5 15H11.5C11.2239 15 11 14.7761 11 14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C11.0706 7 10.284 7.64245 10.0624 8.50517C9.99363 8.77262 9.77614 9 9.5 9H8.5C8.22386 9 7.99672 8.77502 8.03079 8.50099C8.27622 6.52671 9.959 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z" />
          </g>
          <defs>
            <clipPath id="clip0_2369_107">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_2369_106)">
            <path d="M11 17.5C11 17.7761 11.2239 18 11.5 18H12.5C12.7761 18 13 17.7761 13 17.5V16.5C13 16.2239 12.7761 16 12.5 16H11.5C11.2239 16 11 16.2239 11 16.5V17.5ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.959 6 8.27622 7.52671 8.03079 9.50099C7.99672 9.77502 8.22386 10 8.5 10H9.5C9.77614 10 9.99363 9.77262 10.0624 9.50517C10.284 8.64245 11.0706 8 12 8C13.1 8 14 8.9 14 10C14 11.8911 11.3179 11.7706 11.0257 14.4992C10.9963 14.7737 11.2239 15 11.5 15H12.5C12.7761 15 12.9931 14.7724 13.053 14.5029C13.4545 12.6966 16 12.3028 16 10C16 7.79 14.21 6 12 6Z" />
          </g>
          <defs>
            <clipPath id="clip0_2369_106">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </SvgIcon>
  );
};

export default FaqIcon;
