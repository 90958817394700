const getVideoSources = (
  publicKey: string
): { poster: string; video: Array<{ src: string; type: string }> } => {
  if (!publicKey?.length) return { poster: "", video: [] };
  const template = `https://res.cloudinary.com/gwatco/video/upload/v2/${publicKey}`;
  return {
    poster: `${template}.jpg`,
    video: [
      {
        src: `${template}.webm`,
        type: "video/webm",
      },
      {
        src: `${template}.mp4`,
        type: "video/mp4",
      },
      {
        src: `${template}.ogg`,
        type: "video/ogg",
      },
    ],
  };
};

export { getVideoSources };
