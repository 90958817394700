import { MouseEventHandler, useRef } from "react";

import { FormActions } from "@/components/FormController";
import Player, { APITypes, getVideoSources, MediaType } from "../Player";
import styles from "./PersonalDetailsForm.module.scss";

const videoSources = getVideoSources(
  "q_auto/gwat.gworld/assets/gworld-onboarding"
);
const source = {
  type: "video" as MediaType,
  sources: videoSources.video,
};

const OnboardingForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
}) => {
  const playerRef = useRef<APITypes | null>(null);

  const onSubmit = () => {
    onSubmitted?.();
  };
  return (
    <>
      <div className={styles.onboardingVideoContainer}>
        <Player
          ref={playerRef}
          source={source}
          trackerData={{
            content_asset_id: "onboarding_form",
          }}
        />
      </div>

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => onSubmit(),
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

export default OnboardingForm;
