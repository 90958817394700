import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "../constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";

export type PublicProfile = Partial<{
  s3_profile_pic: string;
  nickname: string;
  cid: number;
  gender: "Male" | "Female";
  country: string | null;
  division: string | null;
  bio: string | null;
  trip_status: TripStatus;
  group_ids: Array<string>;
  is_suspendeds: Array<string>;
  is_admins: Array<{
    group_id: number;
    is_admin: number;
    admin_title?: string | null;
    is_suspended?: number;
  }>;
}>;

type PublicProfileResult = Array<PublicProfile>;

type QueryError = {};

const usePublicProfileQuery = (
  { cid }: { cid?: number },
  options?: UseQueryOptions<
    PublicProfileResult,
    QueryError,
    PublicProfileResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const url = `${GATEWAY_URL}/traveller/public-profiles${
    cid ? `?cids=${cid}` : ""
  }`;
  const cacheKey = [url];

  return useQuery<PublicProfileResult, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error("cid not found");

      return fetchWithAuth<PublicProfileResult>(url);
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(cid),
    }
  );
};
export default usePublicProfileQuery;
