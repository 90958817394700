/* eslint-disable react-hooks/exhaustive-deps */
import { usePublicProfileQuery } from "@/fetch/profiles/traveller";
import { useBrowserSettings, useCurrentTrip, useTrackers } from "@/hooks";
import { Message } from "@twilio/conversations";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  ConversationAttributes,
  OutgoingMessage,
  useTwilioConversation,
} from "./hooks";
import styles from "./Conversation.module.scss";
import Button, { IconButton } from "@/components/Button";
import {
  ArrowIcon,
  BinIcon,
  ChevronIcon,
  MoreIcon,
  SendIcon,
} from "@/components/Icon";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemIcon, ListItemText } from "@/components/List";
import Badge from "@/components/Badge";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import { CircularProgress } from "@/components/Loader";
import MessageBubble from "./MessageBubble";
import RichEditor from "@/components/RichEditor";
import { SocialAvatar, TravellerNameLink } from "@/components/Social";
import ConversationDeleteConfirmationDialog from "./ConversationDeleteConfirmationDialog";
import cx from "classnames";
import { useGroupQuery } from "@/fetch/social";
import TextField from "../TextField";

const Conversation: React.FC<{
  conversationSid: string;
  onExit?: () => void;
  onTogglePopover?: () => void;
}> = ({ conversationSid, onExit, onTogglePopover }) => {
  const { track } = useTrackers();
  const { isAndroid } = useBrowserSettings();
  const upperMessageBubble = useRef<Element | null>(null);
  const lastElement = useRef<HTMLDivElement | null>(null);
  const hasInitiallyJump = useRef<boolean>(false);
  const editorRef = useRef<any>(null);
  const [newMessage, setNewMessage] = useState("");
  const [shouldEmptyField, setShouldEmptyField] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [isFetchingMoreMessages, setIsFetchingMoreMessages] = useState(false);
  const [isWarnDiagOpen, setIsWarnDialogOpen] = useState(false);
  const { currentTrip } = useCurrentTrip();
  const { data: group } = useGroupQuery();
  const thisUserCid = currentTrip?.cid;
  const { ref: bottomElementRef, inView: isBottomElementInView } = useInView({
    threshold: 0,
  });
  const {
    state,
    getInitialMessages,
    setAllMessagesRead,
    getMoreMessages,
    isMessagesLoading,
    sendMessage,
    leaveConversation,
    deletingConversationSid,
    prepareTwilioConversationMutation,
  } = useTwilioConversation();
  const session = state?.sessions.find(
    (item) => item?.conversationSid === conversationSid
  );

  const { data: thisUserPublicProfile } = usePublicProfileQuery({
    cid: thisUserCid,
  });

  const isThisUserSuspended =
    thisUserPublicProfile?.length === 0 ||
    !Boolean(group) ||
    Boolean(
      thisUserPublicProfile?.[0]?.is_admins?.find(
        (item) => item?.group_id === group?.id
      )?.is_suspended
    ) ||
    false;

  const peerCid = session?.travellerInfo?.cid;

  const { data: publicProfile } = usePublicProfileQuery({ cid: peerCid });
  const userProfile = publicProfile?.[0];
  const profilePicturePath = userProfile?.s3_profile_pic;

  const isAdmin =
    Boolean(
      userProfile?.is_admins?.find((item) => item.group_id === group?.id)
        ?.is_admin
    ) || false;
  const isSuspended =
    Boolean(
      userProfile?.is_admins?.find((item) => item?.group_id === group?.id)
        ?.is_suspended
    ) || false;

  const adminTitle =
    userProfile?.is_admins?.find((item) => item.group_id === group?.id)
      ?.admin_title || null;
  // the last read message index that participant saved before his/her leave
  const lastReadMessageIndexByLeavedParticipant = (
    session?.conversation?.attributes as ConversationAttributes
  )?.participants?.find((item) => item?.cid === peerCid)?.lastReadMessageIndex;

  const lastReadMessageIndexByThisUserBeforeLeave = (
    session?.conversation?.attributes as ConversationAttributes
  )?.participants?.find(
    (item) => item?.cid === thisUserCid
  )?.lastReadMessageIndex;

  // if participant does not exist, read last message index from stored value
  const lastReadMessageIndex =
    session?.participant?.lastReadMessageIndex ||
    lastReadMessageIndexByLeavedParticipant;

  const messages =
    session?.messages?.filter((item) => {
      if (
        lastReadMessageIndexByThisUserBeforeLeave === undefined ||
        item?.index > lastReadMessageIndexByThisUserBeforeLeave
      ) {
        return true;
      }
      return false;
    }) || [];

  const outgoingMessages = session?.outgoingMessages || [];
  const messagesToShow: (Message | OutgoingMessage)[] = [
    ...messages,
    ...outgoingMessages,
  ];
  const hasPrevPage = lastReadMessageIndexByThisUserBeforeLeave
    ? session?.messagesPaginator?.hasPrevPage &&
      Boolean(messages.length) &&
      messages?.[0]?.index - 1 !== lastReadMessageIndexByThisUserBeforeLeave
    : session?.messagesPaginator?.hasPrevPage;
  const isTyping = session?.participant?.isTyping;
  const isOnline = Boolean(session?.user?.isOnline);

  const isConversationDeleting =
    deletingConversationSid === session?.conversationSid;

  const scrollToEnd = (behavior: ScrollBehavior = "smooth") => {
    lastElement?.current?.scrollIntoView({ behavior });
  };

  const onGetMoreMessagesClick = async () => {
    setIsFetchingMoreMessages(true);
    const firstBubble = document.querySelector(".message-bubble");
    upperMessageBubble.current = firstBubble;
    await getMoreMessages(conversationSid);
    setIsFetchingMoreMessages(false);
  };

  const onMessageSend = async (clearInput = true) => {
    const value = newMessage;
    setNewMessage("");
    try {
      if (!value?.length) {
        //prevent to send empty strings
        return;
      }

      if (clearInput) setShouldEmptyField(true);
      await sendMessage(value, conversationSid);
      if (!session?.participant?.sid && peerCid) {
        await prepareTwilioConversationMutation({
          travellerCid: peerCid,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (clearInput) setShouldEmptyField(false);
    }
  };

  const removeChat = async () => {
    // remove user from the conversation and back to the conversations list
    try {
      if (!session?.conversationSid) return;
      await leaveConversation(session?.conversationSid, peerCid);
      onExit?.();
    } catch (error) {
      console.error(error);
    }
    setIsWarnDialogOpen(false);
  };

  useEffect(() => {
    // initially get fist page of messages
    const getFirstPage = async () => {
      await getInitialMessages(conversationSid);
    };
    if (!session?.messages?.length) {
      getFirstPage();
    }
  }, [conversationSid]);

  useEffect(() => {
    // Make all messages read whenever the user is in this page
    const updateReadStatus = async () => {
      await setAllMessagesRead(conversationSid);
    };
    updateReadStatus();
  }, [session]);

  useEffect(() => {
    // restore the scroll view position on getting more messages
    if (isFetchingMoreMessages) {
      upperMessageBubble?.current?.scrollIntoView({
        block: "center",
      });
    }
  }, [messages, isFetchingMoreMessages]);

  useEffect(() => {
    // jump to the end when messages are initiated for the first time
    if (!hasInitiallyJump?.current && messages?.length) {
      scrollToEnd("auto");
      hasInitiallyJump.current = true;
    }
  }, [messages]);

  useEffect(() => {
    // reset the component for other conversation to load with sid

    scrollToEnd("auto");
    hasInitiallyJump.current = false;
  }, [conversationSid]);

  useEffect(() => {
    // focus on last received message when it's close to end
    if (isFetchingMoreMessages) return;
    if (
      Number(messages?.[messages?.length - 1]?.author) === peerCid &&
      hasInitiallyJump
    ) {
      if (isBottomElementInView) scrollToEnd("smooth");
    }
  }, [messages?.length]);

  useEffect(() => {
    // jump to end when user sent a message
    if (outgoingMessages?.length) {
      scrollToEnd("smooth");
    }
  }, [outgoingMessages]);

  useEffect(() => {
    // handle rudderstack event on enter
    track("Conversation Opened", {
      eventId: "conversation-opened",
      conversationSid: session?.conversationSid,
      travellerCid: thisUserCid,
      peerCid,
    });
  }, []);

  const onKeyDown = (event: any) => {
    session?.conversation?.typing();
  };

  const menuList = [
    {
      title: "Delete chat",
      icon: <BinIcon color="inherit" />,
      action: () => {
        setIsWarnDialogOpen(true);
        setMenuAnchorEl(null);
      },
    },
  ];

  const handleReturn = (clearInput: () => void) => {
    onMessageSend(false);
    clearInput();
  };

  const onAndroidKeyDown = (event: any) => {
    session?.conversation?.typing();
    if (event.keyCode == 13) {
      onMessageSend();
    }
  };

  return (
    <div className={styles.conversationRoot}>
      <div className={styles.header}>
        <IconButton onClick={onExit} className={styles.backButton}>
          <ArrowIcon dir="left" />
        </IconButton>
        <Badge
          color="success"
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
          badgeContent=" "
          invisible={!isOnline}
          classes={{ badge: styles.onlineBadge }}
        >
          <SocialAvatar
            cloudinaryPath={profilePicturePath}
            isAdmin={isAdmin}
            adminTitle={adminTitle}
            size="m"
          />
        </Badge>
        <span className={styles.nameContainer}>
          <TravellerNameLink
            variant="body1"
            cid={session?.travellerInfo?.cid}
            nickname={session?.travellerInfo?.name}
          />

          {isTyping && (
            <Typography variant="body2" color="text.secondary">
              is typing...
            </Typography>
          )}
        </span>
        <IconButton
          className={styles.moreButton}
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          <MoreIcon color="info" />
        </IconButton>
        {Boolean(onTogglePopover) && (
          <IconButton
            onClick={onTogglePopover}
            className={styles.togglePopoverButton}
          >
            <ChevronIcon dir="down" />
          </IconButton>
        )}
        <Menu
          elevation={3}
          id="conversation-menu"
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={() => setMenuAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {menuList?.map((item) => (
            <MenuItem key={item.title} onClick={item.action}>
              <ListItemIcon sx={{ color: "text.primary" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Divider />
      <div className={styles.contentContainer}>
        {hasPrevPage && !isMessagesLoading && !isFetchingMoreMessages && (
          <Button
            onClick={onGetMoreMessagesClick}
            variant="text"
            size="small"
            className={styles.moreMessagesButton}
          >
            More messages
          </Button>
        )}
        {!hasPrevPage &&
          !isMessagesLoading &&
          !isFetchingMoreMessages &&
          messagesToShow?.length > 0 && (
            <Typography
              variant="body2"
              color="text.secondary"
              className={styles.conversationStart}
            >
              Conversation start
            </Typography>
          )}
        {isFetchingMoreMessages && (
          <span className={styles.conversationStart}>
            <CircularProgress />
          </span>
        )}
        {messagesToShow?.map((message, index) => {
          let status = (message as OutgoingMessage).status;
          if (
            lastReadMessageIndex &&
            (message as Message)?.index <= lastReadMessageIndex
          )
            status = "delivered";

          return (
            <MessageBubble
              key={
                (message as Message).index?.toString() ||
                (message as OutgoingMessage).dateCreated?.toString()
              }
              date={message?.dateCreated}
              text={message?.body || ""}
              previousMessageCreatedDate={
                messagesToShow?.[index - 1]?.dateCreated
              }
              leftBubble={Boolean(
                Number((message as Message).author) === peerCid
              )}
              status={status}
            />
          );
        })}
        {Boolean(!messagesToShow?.length) &&
          !isFetchingMoreMessages &&
          !isMessagesLoading && (
            <Typography
              variant="body2"
              color="text.secondary"
              className={styles.emptyListText}
            >
              No messages here yet
            </Typography>
          )}

        {Boolean(!messagesToShow?.length) && isMessagesLoading && (
          <span className={styles.emptyListText}>
            <CircularProgress />
          </span>
        )}
        <div
          className={styles.bottomItem}
          ref={(ref) => {
            bottomElementRef(ref);
            lastElement.current = ref;
          }}
        />
      </div>

      <Divider />
      <div className={styles.footer}>
        {!isBottomElementInView && (
          <Button
            className={styles.jumpToBottom}
            color="primary"
            size="small"
            onClick={() => scrollToEnd()}
          >
            <ChevronIcon dir="down" />
          </Button>
        )}
        {isSuspended || isThisUserSuspended ? (
          <div className={styles.actionContainer}>
            <Typography
              color="text.secondary"
              variant="body2"
              style={{ marginBottom: "16px" }}
            >
              {isThisUserSuspended
                ? "Your account has been deactivated. You are currently viewing your chat archives."
                : "You are viewing the archives of a deactivated account."}
            </Typography>
          </div>
        ) : (
          <>
            <div
              className={styles.actionContainer}
              style={{ marginBottom: isAndroid ? "12px" : "0px" }}
            >
              {isAndroid ? (
                <TextField
                  sx={{ width: "100%" }}
                  value={newMessage}
                  placeholder="Message"
                  onChange={(event) => setNewMessage(event.target.value)}
                  onKeyDown={onAndroidKeyDown}
                />
              ) : (
                <RichEditor
                  onChange={(newText: string) => setNewMessage(newText)}
                  classes={{
                    root: styles.editorRoot,
                    editor: cx(styles.editor, "fs-exclude"),
                    emojiPickerButton: styles.emojiButton,
                  }}
                  placeholder="Message"
                  ref={(ref) => (editorRef.current = ref)}
                  shouldEmptyField={shouldEmptyField}
                  handleReturn={handleReturn}
                  newLineOnReturn={false}
                  onKeyDown={onKeyDown}
                />
              )}
              <IconButton
                color="primary"
                onClick={() => onMessageSend()}
                disabled={!newMessage?.length}
                className={styles.sendButton}
              >
                <SendIcon />
              </IconButton>
            </div>
            {!isAndroid && (
              <Typography
                className={styles.sendHelperText}
                variant="body2"
                color="text.secondary"
              >
                <b>Shift + Return</b> to add a new line
              </Typography>
            )}
          </>
        )}
      </div>
      {isWarnDiagOpen && (
        <ConversationDeleteConfirmationDialog
          onClose={() => setIsWarnDialogOpen(false)}
          isLoading={isConversationDeleting}
          onDelete={removeChat}
        />
      )}
    </div>
  );
};

export default Conversation;
