const useCloudinaryUrl = () => {
  const getProfilePictureUrl = (
    profilePicKey: string,
    autoGravity?: boolean
  ): string => {
    return `https://res.cloudinary.com/gwatco/image/upload/ar_1.0,c_thumb,dpr_2.0,${
      autoGravity ? "g_auto," : ""
    }q_auto,w_200/v1/${profilePicKey}`;
  };

  return { getProfilePictureUrl };
};

export default useCloudinaryUrl;
