import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export enum ContactMethodMethods {
  Phone = "phone",
  Skype = "skype",
}

export enum ContactMethodTypes {
  Original = "original",
  Travel = "travel",
}

export type ContactMethod = Partial<{
  id: number | null;
  method: ContactMethodMethods | null;
  priority: number | null;
  reference: string | null;
  region_code: string | null;
  type: ContactMethodTypes | null;
}>;

type Conviction = Partial<{
  bac: string | null;
  cid: number | null;
  date_occurred: number | null;
  description: string | null;
  id: number | null;
  type: string | null;
  user_answer: string | null;
}>;

type HealthItem = Partial<{
  cid: number | null;
  description: string | null;
  id: number | null;
  type: string | null;
  user_answer: string | null;
}>;

export type ContactMethods = Partial<{
  primary?: ContactMethod;
  secondary?: ContactMethod;
  skype?: ContactMethod;
  travel?: ContactMethod;
}>;

export type Traveller = Partial<{
  first_name: string | null;
  last_name: string | null;
  id: number | null;
  age: number | null;
  date_of_birth: number | null;
  email: string | null;
  gender: string | null;
  height: string | null;
  weight: string | null;
  last_location: string | null;
  last_user_ip: number | null;
  middle_name: string | null;
  nationality: string | null;
  preferred_name: string | null;
  office_id: number | null;
  office_name: string | null;
  on_board: number | null;
  referral_code: string | null;
  s3_profile_pic: string | null;
  status: number | null;
  updated_profile: number | null;
  valid_passport: number | null;
  bio: string | null;
  nickname: string | null;
  _ca: null | number;
}>;

export type Addresses = Partial<{
  country_code?: string | null;
  country?: string | null;
  id: number;
  post_code: string | null;
  state: string | null;
  street: string | null;
  suburb: string | null;
}>;

export type EmergencyContact = Partial<{
  contact_id: number | null;
  contacts: Array<ContactMethod>;
  email: string | null;
  full_name: string | null;
  guardian: number | null;
  relationship: string | null;
}>;

export type Passport = Partial<{
  id: number | null;
  passport: string | null;
  passport_expiry_date: number | null;
  passport_issue_date: number | null;
  passport_issue_location: string | null;
  passport_nationality: string | null;
  passport_number: string | null;
}>;

export type Convictions = Partial<{
  criminal: Conviction;
  drink_driving: Conviction;
  driving_offences: Conviction;
}>;

export type Health = Partial<{
  alcohol: HealthItem;
  covid_19_vaccination: HealthItem;
  diet: HealthItem;
  illicit: HealthItem;
  medication: HealthItem;
  mental_health: HealthItem;
  physical_limitations: HealthItem;
  smoke: HealthItem;
  tattoos_piercings: HealthItem;
}>;

export type PersonalDetails = {
  addresses: Addresses;
  contactMethods: Array<ContactMethod>;
  convictions: Convictions;
  emergencyContacts: Array<EmergencyContact>;
  guardianContact: Array<EmergencyContact>;
  health: Health;
  passport: Passport;
  traveller: Traveller;
};
type QueryError = {};

const usePersonalDetailsQuery = (
  options?: UseQueryOptions<
    PersonalDetails,
    QueryError,
    PersonalDetails,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/traveller/${cid}/personal-details`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<PersonalDetails, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<PersonalDetails>(url);
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(cid),
    }
  );
};
export default usePersonalDetailsQuery;
