import styles from "./CreatePostCard.module.scss";
import Card from "@/components/Card";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import Avatar from "@/components/Avatar";
import Typography from "@/components/Typography";
import { IconButton } from "@/components/Button";
import { PictureIcon, VideoIcon } from "@/components/Icon";
import { Skeleton } from "@/components/Loader";
import CreatePostDialog from "./CreatePostDialog";
import { useDialog } from "@/hooks";
import cx from "classnames";
import { useState } from "react";

const CreatePostCard: React.FC<{
  className?: string;
  onCreatePost?: () => Promise<any>;
}> = ({ className, onCreatePost }) => {
  const { data: personalDetailsData, isLoading: isPersonalDetailsLoading } =
    usePersonalDetailsQuery();
  const profilePicturePath = personalDetailsData?.traveller?.s3_profile_pic;
  const disabled = isPersonalDetailsLoading;
  const [mediaPickerInitiallyOpen, setMediaPickerInitiallyOpen] =
    useState(false);

  const {
    onClose,
    openDialog: openCreatePostDialog,
    isOpen: isCreatingPostDialogOpen,
  } = useDialog("new-post");

  const handleOpenNewPostDialog = () => {
    openCreatePostDialog();
  };

  const onMediaPickerButtonsClick = () => {
    setMediaPickerInitiallyOpen(true);
    openCreatePostDialog();
  };

  const onDialogClose = () => {
    setMediaPickerInitiallyOpen(false);
    onClose();
  };

  return (
    <Card className={cx(styles.rootCard, className)}>
      {isPersonalDetailsLoading ? (
        <Skeleton variant="circular" width="48px" height="48px" />
      ) : (
        <Avatar cloudinaryPath={profilePicturePath} />
      )}
      <div className={styles.content}>
        <Typography
          variant="h6"
          color={disabled ? "action.disabled" : "text.secondary"}
          component="button"
          tabIndex={1}
          className={styles.title}
          onClick={handleOpenNewPostDialog}
          disabled={disabled}
        >
          What&apos;s going on today?
        </Typography>
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={onMediaPickerButtonsClick}
        >
          <VideoIcon />
        </IconButton>
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={onMediaPickerButtonsClick}
        >
          <PictureIcon />
        </IconButton>
      </div>
      {isCreatingPostDialogOpen && (
        <CreatePostDialog
          onClose={onDialogClose}
          onCreatePost={onCreatePost}
          mediaPickerInitiallyOpen={mediaPickerInitiallyOpen}
        />
      )}
    </Card>
  );
};
export default CreatePostCard;
