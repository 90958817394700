import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import {
  useDeletePostMutation,
  useRecentPostsQuery,
  useTravellerPostsQuery,
} from "@/fetch/social";
import { CircularProgress } from "@/components/Loader";
import { useCurrentTrip, useSnackbar, useTrackers } from "@/hooks";
import { useState } from "react";

const PostDeleteConfirmationDialog: React.FC<{
  onClose: () => void;
  postId: number;
}> = ({ onClose, postId }) => {
  const { currentTrip } = useCurrentTrip();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { track, captureException } = useTrackers();
  const { mutateAsync: deletePost } = useDeletePostMutation();
  const { refetch: refetchTimeLinePosts } = useRecentPostsQuery();
  const thisUserCid = currentTrip?.cid || 0;
  const { refetch: refetchPosts } = useTravellerPostsQuery({
    cid: thisUserCid,
  });

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await deletePost({ postId });
      await refetchPosts();
      await refetchTimeLinePosts();
      track("Post Deleted", { postId, eventId: "post-deleted" });
      onClose();
    } catch (error) {
      enqueueSnackbar(`Could not delete the post.`, {
        variant: "error",
      });
      console.error({ error });
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle onClose={onClose}>Delete confirmation</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="text.secondary">
          Do you want to delete this post?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="info"
          size="small"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          size="small"
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress color="inherit" />}
        >
          Delete post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostDeleteConfirmationDialog;
