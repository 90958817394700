import { ListItemButton } from "@/components/List";
import styles from "./NewConversationItem.module.scss";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import { Skeleton } from "@/components/Loader";
import RichContentPreview from "@/components/RichContentPreview";
import { AllGroupMember, useGroupQuery } from "@/fetch/social";
import Flag from "@/components/Flag";
import { SocialAvatar } from "@/components/Social";

const NewConversationItemLoader: React.FC = () => {
  return (
    <>
      <div className={styles.loaderRoot}>
        <Skeleton variant="circular" width="56px" height="56px" />
        <div className={styles.loaderTextContainer}>
          <Skeleton width="110px" />
          <Skeleton width="80px" />
        </div>
      </div>
      <Divider variant="middle" />
    </>
  );
};

const NewConversationItem: React.FC<{
  member: AllGroupMember;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}> = ({ member, onClick }) => {
  const { data: group } = useGroupQuery();
  const isAdmin =
    Boolean(
      member?.is_admins?.find((item) => item.group_id === group?.id)?.is_admin
    ) || false;
  const adminTitle =
    member?.is_admins?.find((item) => item.group_id === group?.id)
      ?.admin_title || null;

  return (
    <>
      <ListItemButton onClick={onClick}>
        <div className={styles.avatarContainer}>
          <SocialAvatar
            isAdmin={isAdmin}
            adminTitle={adminTitle}
            cloudinaryPath={member?.s3_profile_pic}
            size="l"
          />
          {Boolean(member.country_code) && !isAdmin && (
            <Flag
              countryCode={member.country_code || ""}
              className={styles.flag}
              size="1.5em"
            />
          )}
        </div>
        <div className={styles.contentContainer}>
          <RichContentPreview content={member?.nickname || ""} />
          {!isAdmin && (
            <Typography variant="caption" color="text.secondary">
              {member?.division}
            </Typography>
          )}
        </div>
      </ListItemButton>
      <Divider variant="middle" />
    </>
  );
};

export default NewConversationItem;
export { NewConversationItemLoader };
