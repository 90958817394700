import SvgIcon, { SvgIconProps } from "./SvgIcon";

const PictureIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M20 3C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20ZM14.3137 11L5.312 19H20V16.687L14.3137 11ZM20 5H4V17.484L12.8995 9.58579C13.6805 8.80474 14.9469 8.80474 15.7279 9.58579L20 13.859V5ZM8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7Z" />
    </SvgIcon>
  );
};

export default PictureIcon;
