import { forwardRef } from "react";
import MuiAvatar, { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";
import cx from "classnames";
import styles from "./Avatar.module.scss";
import { FaceIcon } from "@/components/Icon";
import { useCloudinaryUrl } from "@/hooks";

interface AvatarProps extends MuiAvatarProps {
  cloudinaryPath?: string | null | undefined;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "huge";
}

// eslint-disable-next-line react/display-name
const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ cloudinaryPath, size = "m", className, src, ...props }, ref) => {
    const { getProfilePictureUrl } = useCloudinaryUrl();
    return (
      <MuiAvatar
        {...props}
        src={(cloudinaryPath && getProfilePictureUrl(cloudinaryPath)) || src}
        classes={{
          root: cx(
            {
              [styles.extraSmall]: size === "xs",
              [styles.small]: size === "s",
              [styles.large]: size === "l",
              [styles.extraLarge]: size === "xl",
              [styles.twoExtraLarge]: size === "xxl",
              [styles.huge]: size === "huge",
            },
            className
          ),
        }}
        ref={ref}
      >
        <FaceIcon className={styles.avatarIcon} />
      </MuiAvatar>
    );
  }
);

export default Avatar;
export type { AvatarProps };
