/* eslint-disable @next/next/no-img-element */
import Typography from "@/components/Typography";
import styles from "./EmptySearchResult.module.scss";

const EmptySocialPost: React.FC<{ subtitle?: string }> = ({
  subtitle = "",
}) => {
  return (
    <div className={styles.root}>
      <img src="/images/empty-social-post.svg" alt="icon" />

      <Typography variant="h6" className={styles.text}>
        No posts yet
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {subtitle}
      </Typography>
    </div>
  );
};

export default EmptySocialPost;
