import MuiSvgIcon, {
  SvgIconProps as MuiSvgIconProps,
} from "@mui/material/SvgIcon";

export type SvgIconProps = MuiSvgIconProps & {
  /**  Not all icons support the **direction** */
  dir?: "up" | "down" | "left" | "right";
  /**  Not all icons support the **variant** */
  variant?: "outlined" | "contained";
};

const SvgIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return <MuiSvgIcon {...props} />;
};

export default SvgIcon;
