import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

type FaqParamsType = {
  category_id: number;
};

export type Faq = Partial<{
  id: number;
  category_id: number;
  type_id: number;
  question: string;
  answer: string;
  position: number;
  is_default: number;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
  platform: string;
}>;

type FaqsResult = Partial<{
  success: boolean;
  answeredFAQs: Array<Faq>;
}>;
type QueryError = {};

const useFaqsQuery = (
  params?: FaqParamsType,
  options?: UseQueryOptions<FaqsResult, QueryError, FaqsResult, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/faqs/trip/${tid}`;
  const cacheKey = [url, JSON.stringify(params)];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<FaqsResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<FaqsResult>(url, { params: { ...params } });
    },
    {
      ...options,
    }
  );
};
export default useFaqsQuery;
