import { useMutation, UseMutationOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { Convictions } from "../usePersonalDetailsQuery";

type UpdateConvictionVariables = {
  convictions: Convictions;
  correlationId: string;
};

type UpdateConvictionResult = {
  result: Array<{ message: string }>;
  success: boolean;
};

type QueryError = {};

const useUpdateConvictionMutation = (
  options?: UseMutationOptions<
    UpdateConvictionResult,
    QueryError,
    UpdateConvictionVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return {
    ...useMutation<
      UpdateConvictionResult,
      QueryError,
      UpdateConvictionVariables
    >(
      async ({ convictions, correlationId }: UpdateConvictionVariables) => {
        const url = `${GATEWAY_URL}/traveller/${cid}/conviction`;

        return fetchWithAuth<UpdateConvictionResult>(url, {
          method: "POST",
          body: { ...convictions, contexts: { active_trip: currentTrip?.id } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateConvictionMutation;
