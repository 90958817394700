import Typography from "@/components/Typography";
import { SearchIcon } from "@/components/Icon";
import styles from "./EmptySearchResult.module.scss";

const EmptySearchResult: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src="/images/empty-search-result.svg" alt="icon" />
        <div className={styles.searchIconContainer}>
          <SearchIcon className={styles.searchIcon} />
        </div>
      </div>
      <Typography variant="h6" className={styles.text}>
        No results found
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Please try another option
      </Typography>
    </div>
  );
};

export default EmptySearchResult;
