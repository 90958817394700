import { FC, useEffect, useRef } from "react";
import { IconButton } from "@/components/Button";
import { CloseIcon } from "@/components/Icon";
import Alert from "@/components/Alert";
import Typography from "@/components/Typography";
import {
  useTrackers,
  useLock,
  useRoutes,
  useFeatureFlags,
  useFeatureTraits,
} from "@/hooks";
import styles from "./SuggestionBanner.module.scss";
import theme from "@/theme/theme";
import { useRouter } from "next/router";
import { RouteFeature, SubrouteFeature } from "@/hooks/useRoutes";

const SuggestionContent = () => {
  const { track } = useTrackers();
  const router = useRouter();
  const {
    flags: { eligibleForFullPaymentBanner, eligibleForPaymentPlanBanner },
  } = useFeatureFlags();

  const {
    traits: { bannerAction, bannerText },
  } = useFeatureTraits();

  const goToTripInvoice = () =>
    router.push({
      pathname: "/experience/payments/invoice/trip",
      query: router.query,
    });
  const handleClickPaymentPlan = () => {
    goToTripInvoice();
    track("Payment Plan suggestion Banner Clicked", {
      eventId: "payment-plan-suggestion-banner-clicked",
    });
  };
  const handleClickFullPayment = () => {
    goToTripInvoice();
    track("Full pay suggestion Banner Clicked", {
      eventId: "full-pay-suggestion-banner-clicked",
    });
  };

  const handleClickBanner = () => {
    const gwoRldRoute = bannerAction.slice(bannerAction.indexOf(".com") + 4);
    router.push(gwoRldRoute);
  };

  // if (eligibleForPaymentPlanBanner)
  //   return (
  //     <Typography color="text.primary" className={styles.text}>
  //       It is important that you set up your
  //       <Typography role="link" component="a" onClick={handleClickPaymentPlan}>
  //         {" payment plan "}
  //       </Typography>
  //       as soon as possible, so we can secure your spot and get you abroad!
  //     </Typography>
  //   );
  // else if (eligibleForFullPaymentBanner)
  //   return (
  //     <Typography color="text.primary" className={styles.text}>
  //       It is important that you pay your
  //       <Typography role="link" component="a" onClick={handleClickFullPayment}>
  //         {" trip balance "}
  //       </Typography>
  //       in full as soon as you can so we can secure your spot and get you
  //       abroad!
  //     </Typography>
  //   );
  if (bannerText)
    return (
      <Typography
        color="text.primary"
        className={styles.text}
        onClick={handleClickBanner}
      >
        {bannerText}
      </Typography>
    );
  else return null;
};

const SuggestionBanner: FC = () => {
  const { track } = useTrackers();
  const { hasSuggestionBanner, closeSuggestionBanner } = useLock();
  const { getMatchedSubroute, getMatchedRoute } = useRoutes();
  const { route } = getMatchedRoute();
  const subroute = getMatchedSubroute();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const hideSuggestionBannerForRoute =
    subroute?.features?.includes(SubrouteFeature.HideSuggestionBanner) ??
    route?.features?.includes(RouteFeature.HideSuggestionBanner);

  const showSuggestionBanner =
    !hideSuggestionBannerForRoute && hasSuggestionBanner;

  const handleCloseBanner = () => {
    closeSuggestionBanner();
    track("Suggestion Banner Closed", {
      eventId: "suggestion-banner-closed",
    });
  };

  useEffect(() => {
    // Change header mixin variable based on suggestionBanner state
    if (showSuggestionBanner) {
      const suggestionBannerHeight = bannerRef.current?.clientHeight;

      document.documentElement.style.setProperty(
        `--mixins-header-minHeight`,
        `calc(${theme.mixins.toolbar.minHeight} + ${suggestionBannerHeight}px)`
      );
    } else if (!showSuggestionBanner) {
      document.documentElement.style.setProperty(
        `--mixins-header-minHeight`,
        theme.mixins.toolbar.minHeight
      );
    }
  }, [showSuggestionBanner, bannerRef.current]);

  return showSuggestionBanner ? (
    <Alert
      color="warning"
      icon={false}
      className={styles.root}
      classes={{ message: styles.container, root: styles.paperRoot }}
      ref={bannerRef}
    >
      <SuggestionContent />
      <IconButton className={styles.closeButton} onClick={handleCloseBanner}>
        <CloseIcon className={styles.closeIcon} />
      </IconButton>
    </Alert>
  ) : null;
};

export default SuggestionBanner;
