import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField from "@/components/TextField";
import { FormActions } from "@/components/FormController";
import CountryCodeSelector from "@/components/CountryCodeSelector";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import { CountryCode } from "@/hooks";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
  useFormatContactMethod,
} from "@/components/PersonalDetailsForms/hooks";
import {
  GuardianDetailsFormType,
  EmergencyContactFormType,
} from "@/components/PersonalDetailsForms";
import Button from "@/components/Button";
import { EditIcon } from "@/components/Icon";
import { MenuItem } from "@/components/Menu";
import { GuardianRelationItems } from "./types";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  onCountriesLoaded: (countries?: Array<CountryCode>) => void;
  emergencyFormData: EmergencyContactFormType;
}
function GuardianDetailsFormTemplateWithRef<T extends GuardianDetailsFormType>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    onCountriesLoaded,
    emergencyFormData: emergency,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const fillSameAsEmergencyContact = () => {
    formik.setValues((values) => ({
      ...values,
      guardianDetailsEmail: emergency?.emergencyContactEmail,
      guardianDetailsName: emergency?.emergencyContactName,
      guardianDetailsPrimaryCountryCode:
        emergency?.emergencyContactPrimaryCountryCode,
      guardianDetailsPrimaryPhone: emergency?.emergencyContactPrimaryPhone,
      guardianDetailsRelationSelect: Boolean(
        GuardianRelationItems.find(
          (each) => each === emergency?.emergencyContactRelationSelect
        )
      )
        ? emergency?.emergencyContactRelationSelect
        : "Other",
      guardianDetailsRelationTextField: Boolean(
        emergency?.emergencyContactRelationTextField
      )
        ? emergency?.emergencyContactRelationTextField
        : Boolean(
            GuardianRelationItems.find(
              (each) => each === emergency?.emergencyContactRelationSelect
            )
          )
        ? emergency?.emergencyContactRelationTextField
        : emergency?.emergencyContactRelationSelect,
      guardianDetailsSecondaryCountryCode:
        emergency?.emergencyContactSecondaryCountryCode,
      guardianDetailsSecondaryPhone: emergency?.emergencyContactSecondaryPhone,
    }));
  };
  return (
    <div ref={ref}>
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Button
            variant="text"
            onClick={fillSameAsEmergencyContact}
            startIcon={<EditIcon />}
          >
            Same as Emergency Contact
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="guardianDetailsName"
              name="guardianDetailsName"
              label="Full Name"
              value={formik.values.guardianDetailsName}
              onChange={formik.handleChange}
              error={
                formik.touched.guardianDetailsName &&
                Boolean(formik.errors.guardianDetailsName)
              }
              helperText={
                formik.touched.guardianDetailsName &&
                formik.errors.guardianDetailsName
              }
            />
          )}
        </Grid>
        <Grid
          container
          spacing="24px"
          columnSpacing="12px"
          item
          xs={12}
          md={12}
        >
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <Grid item xs={6} md={6}>
              <TextField
                select
                disabled={isFormSubmitting}
                fullWidth
                id="guardianDetailsRelationSelect"
                name="guardianDetailsRelationSelect"
                label="Relationship"
                value={formik.values.guardianDetailsRelationSelect}
                onChange={formik.handleChange}
                error={
                  formik.touched.guardianDetailsRelationSelect &&
                  Boolean(formik.errors.guardianDetailsRelationSelect)
                }
                helperText={
                  formik.touched.guardianDetailsRelationSelect &&
                  formik.errors.guardianDetailsRelationSelect
                }
              >
                {GuardianRelationItems.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {formik.values.guardianDetailsRelationSelect === "Other" && (
            <Grid item xs={6} md={6}>
              <TextField
                disabled={isFormSubmitting}
                fullWidth
                id="guardianDetailsRelationTextField"
                name="guardianDetailsRelationTextField"
                label="Relationship Details"
                value={formik.values.guardianDetailsRelationTextField}
                onChange={formik.handleChange}
                error={
                  formik.touched.guardianDetailsRelationTextField &&
                  Boolean(formik.errors.guardianDetailsRelationTextField)
                }
                helperText={
                  formik.touched.guardianDetailsRelationTextField &&
                  formik.errors.guardianDetailsRelationTextField
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="guardianDetailsEmail"
              name="guardianDetailsEmail"
              label="Email"
              value={formik.values.guardianDetailsEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.guardianDetailsEmail &&
                Boolean(formik.errors.guardianDetailsEmail)
              }
              helperText={
                formik.touched.guardianDetailsEmail &&
                formik.errors.guardianDetailsEmail
              }
            />
          )}
        </Grid>
        <Grid item lg={3} xs={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <CountryCodeSelector
              label="Code"
              disabled={isFormSubmitting}
              value={formik.values.guardianDetailsPrimaryCountryCode}
              onChange={(event, newValue) => {
                formik.setValues((state) => ({
                  ...state,
                  guardianDetailsPrimaryCountryCode: newValue as CountryCode,
                }));
              }}
              error={
                (formik.errors.guardianDetailsPrimaryCountryCode as any)?.label
              }
              touched={
                formik.touched.guardianDetailsPrimaryCountryCode as boolean
              }
              onCountriesLoaded={onCountriesLoaded}
            />
          )}
        </Grid>
        <Grid item lg={4} xs={7}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="guardianDetailsPrimaryPhone"
              name="guardianDetailsPrimaryPhone"
              label="Phone Number"
              type="phoneNumber"
              value={formik.values.guardianDetailsPrimaryPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.guardianDetailsPrimaryPhone &&
                Boolean(formik.errors.guardianDetailsPrimaryPhone)
              }
              helperText={
                formik.touched.guardianDetailsPrimaryPhone &&
                formik.errors.guardianDetailsPrimaryPhone
              }
            />
          )}
        </Grid>

        <Grid item lg={3} xs={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <CountryCodeSelector
              label="Code"
              placeholder="Optional"
              disabled={isFormSubmitting}
              disableClearable={false}
              value={formik.values.guardianDetailsSecondaryCountryCode}
              onChange={(event, newValue) => {
                formik.setValues((state) => ({
                  ...state,
                  guardianDetailsSecondaryCountryCode: newValue as CountryCode,
                }));
              }}
              error={
                (formik.errors.guardianDetailsSecondaryCountryCode as any)
                  ?.label
              }
              touched={
                formik.touched.guardianDetailsSecondaryCountryCode as boolean
              }
              onCountriesLoaded={onCountriesLoaded}
            />
          )}
        </Grid>
        <Grid item lg={3} xs={7}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="guardianDetailsSecondaryPhone"
              name="guardianDetailsSecondaryPhone"
              label="2nd Contact Number"
              placeholder="Optional"
              type="phoneNumber"
              value={formik.values.guardianDetailsSecondaryPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.guardianDetailsSecondaryPhone &&
                Boolean(formik.errors.guardianDetailsSecondaryPhone)
              }
              helperText={
                formik.touched.guardianDetailsSecondaryPhone &&
                formik.errors.guardianDetailsSecondaryPhone
              }
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const GuardianDetailsForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();
  const { formatContactMethodToGworld } = useFormatContactMethod();
  const rawGuardianDetails = personalDetailsData?.guardianContact?.[0];
  const guardianDetails = {
    ...rawGuardianDetails,
    data: formatContactMethodToGworld(rawGuardianDetails?.contacts || []),
  };

  const { guardianDetailsValidationSchema } =
    usePersonalDetailsFormValidation();

  const { submitGuardianDetailsForm, isUpdatingGuardianDetails } =
    usePersonalDetailsFormSubmission();

  const {
    guardianDetailsInitialValues,
    emergencyContactInitialValues,
    isLoading: isInitialDataLoading,
  } = usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingGuardianDetails || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const refetchData = async (count: number) => {
    const refetchResult = await personalDetailsRefetch({});
    const isProfileUpdated = Boolean(
      refetchResult?.data?.traveller?.updated_profile
    );
    if (isProfileUpdated) return;
    if (count <= 0) throw new Error("Profile update did not perform");
    else await refetchData(count - 1);
  };

  const delay = () => new Promise((res) => setTimeout(res, 3000));

  const onSubmit = async (values: GuardianDetailsFormType) => {
    try {
      if (formik.dirty) {
        await submitGuardianDetailsForm(values, correlationId);
      }

      setRefetchingForm(true);
      // we need to wait for couple of seconds for submitting personal details event to fire and update the updated_profile field
      await delay();
      await refetchData(10);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const onCountriesLoaded = (countries?: Array<CountryCode>) => {
    if (!countries) return;
    formik.setValues((values) => ({
      ...values,
      guardianDetailsPrimaryCountryCode: countries?.find(
        (item) => item.dial_code === guardianDetails?.data?.primary?.region_code
      ),
      guardianDetailsSecondaryCountryCode: countries?.find(
        (item) =>
          item.dial_code === guardianDetails?.data?.secondary?.region_code
      ),
    }));
  };

  const initialValues = guardianDetailsInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: guardianDetailsValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <GuardianDetailsFormTemplate
        formik={formik}
        onCountriesLoaded={onCountriesLoaded}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
        emergencyFormData={emergencyContactInitialValues}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const GuardianDetailsFormTemplate = forwardRef(
  GuardianDetailsFormTemplateWithRef
);

export { GuardianDetailsFormTemplate };
export default GuardianDetailsForm;
