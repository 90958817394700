import dayjs from "dayjs";
import React from "react";
import { MessageStatus } from "./hooks/useChatContext";
import cx from "classnames";
import styles from "./MessageBubble.module.scss";
import { DoubleCheckIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import RichContentPreview from "@/components/RichContentPreview";
import { WarningIcon } from "@/components/Icon";
import { CircularProgress } from "../Loader";

const isSameDay = (date1?: Date, date2?: Date) => {
  if (!date2) {
    return false;
  }
  const currentCreatedAt = dayjs(date1);
  const diffCreatedAt = dayjs(date2);
  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }
  return currentCreatedAt.isSame(diffCreatedAt, "day");
};

const Bubble: React.FC<{
  status?: MessageStatus;
  text?: string | null;
  leftBubble?: boolean;
  date?: Date | null;
}> = ({ status = "sent", text, leftBubble, date }) => {
  const time = dayjs(date).format("HH:mm");
  return (
    <div
      className={cx(styles.messageBubble, "message-bubble", {
        [styles.sentBubble]: !leftBubble,
        [styles.receivedBubble]: leftBubble,
      })}
    >
      <RichContentPreview content={text || ""} className="fs-exclude" />
      <span className={styles.messageTime}>
        {!leftBubble && status === "sending" && (
          <CircularProgress size="xxs" className={styles.sendingSpinner} />
        )}
        {!leftBubble && status === "notSent" && (
          <WarningIcon
            color="error"
            fontSize="inherit"
            variant="outlined"
            className={styles.notSentIcon}
          />
        )}
        {!leftBubble && status === "sent" && (
          <DoubleCheckIcon color="info" fontSize="small" />
        )}
        {!leftBubble && status === "delivered" && (
          <DoubleCheckIcon color="primary" fontSize="small" />
        )}
        <Typography
          variant="caption"
          color={leftBubble ? "text.contrast" : "text.secondary"}
        >
          {time}
        </Typography>
      </span>
    </div>
  );
};

const MessageBubble: React.FC<{
  leftBubble?: boolean;
  previousMessageCreatedDate?: Date | null;
  text?: string | null;
  date?: Date | null;
  status?: MessageStatus;
}> = ({ leftBubble, previousMessageCreatedDate, text, date, status }) => {
  const sameDay =
    date && previousMessageCreatedDate
      ? isSameDay(date, previousMessageCreatedDate)
      : false;
  const messageDate = dayjs(date).format("D MMM YYYY");
  let formattedDate;

  if (dayjs(date).isToday()) formattedDate = "Today";
  else if (dayjs(date).isYesterday()) formattedDate = "Yesterday";
  else {
    formattedDate = messageDate;
  }

  return (
    <>
      {!sameDay && (
        <Typography
          color="text.secondary"
          variant="body2"
          className={styles.messagesDate}
        >
          {formattedDate}
        </Typography>
      )}

      <Bubble text={text} leftBubble={leftBubble} date={date} status={status} />
    </>
  );
};

export default MessageBubble;
