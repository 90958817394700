import { FC } from "react";
import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from "@mui/material/TableRow";
import TableCell from "./TableCell";

type TableRowProps = MuiTableRowProps & {};

const TableRow: FC<TableRowProps> = ({ children, ...props }) => {
  return <MuiTableRow {...props}>{children}</MuiTableRow>;
};

export default TableRow;
