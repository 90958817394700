import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreateFaqVariables = {
  question: string;
};

type CreateFaqResult = {
  success: true;
  result: {
    insertId: number;
    affectedRows: number;
  };
};

type QueryError = {};

const useCreateFaqMutation = (
  options?: UseMutationOptions<CreateFaqResult, QueryError, CreateFaqVariables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<CreateFaqResult, QueryError, CreateFaqVariables>(
      async (inputObj: CreateFaqVariables) => {
        const url = `${GATEWAY_URL}/api/faqs`;
        const inputData = {
          ...inputObj,
          tid,
        };

        return fetchWithAuth<CreateFaqResult>(url, {
          method: "POST",
          body: inputData,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateFaqMutation;
