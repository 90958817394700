import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

type TripDetails = Partial<{
  address: any;
  check_in_time: any;
  check_out_time: any;
  city: any;
  comments: string;
  confirmation_number: string;
  country: any;
  date_from: number;
  date_to: number;
  description: string;
  email: any;
  id: number;
  is_included: number;
  name: any;
  phone: any;
  state: any;
  status: any;
  supplier: string;
  suppliers_id: any;
  tid: number;
  transfer: number;
  type_id: any;
  website: any;
  _ca: any;
  _cb: any;
  _lma: any;
  _lmb: any;
}>;
type QueryError = {};

const useTripAccommodationQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<TripDetails, QueryError, TripDetails, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/accommodation/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<TripDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<TripDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useTripAccommodationQuery;
