// From https://usehooks.com/useWindowSize/
//
import { useState, useEffect } from "react";
import theme from "@/theme/theme";
// Define general type for useWindowSize hook, which includes width and height
type Size = {
  width: number;
  height: number;
};

// Hook
function useResponsive(): { windowSize: Size; isMobile: boolean } {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return {
    windowSize,
    isMobile: windowSize.width < theme.breakpoints.values.md,
  };
}

export default useResponsive;
