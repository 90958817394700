import { useRouter } from "next/router";
import Badge from "../Badge";
import { IconButton } from "../Button";
import { useTwilioConversation } from "../Chat/hooks";
import { ChatIcon } from "../Icon";
import styles from "./Header.module.scss";
import cx from "classnames";
import { useLock, useResponsive, useCurrentTrip } from "@/hooks";
import { Division } from "@/fetch/gworld";

const ChatIconButton: React.FC<{
  className?: string;
  stickToEnd?: boolean;
}> = ({ className, stickToEnd }) => {
  const router = useRouter();
  const { totalUnreadConversationCount } = useTwilioConversation();
  const { isAllowedToNavigate } = useLock();
  const { isMobile } = useResponsive();
  const { currentTrip } = useCurrentTrip();

  const isInMessagesPage = router.pathname.includes("/social/messages");
  return !isInMessagesPage &&
    isAllowedToNavigate &&
    isMobile &&
    currentTrip?.division !== Division.Tour ? (
    <IconButton
      onClick={() => router.push("/social/messages")}
      className={cx({ [styles.stickToEnd]: stickToEnd }, className)}
    >
      <Badge
        badgeContent={totalUnreadConversationCount}
        color="error"
        invisible={!Boolean(totalUnreadConversationCount)}
      >
        <ChatIcon variant="outlined" />
      </Badge>
    </IconButton>
  ) : (
    <div className={cx({ [styles.stickToEnd]: stickToEnd && isMobile })} />
  );
};

export default ChatIconButton;
