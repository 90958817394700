// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import SentryFullStory from "@sentry/fullstory";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  integrations: [
    new SentryFullStory("G202W"),
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: ["globalworkandtravel.com", "gwat.co", "api.gwat.co"],
      shouldCreateSpanForRequest: (url) => {
        // eslint-disable-next-line
        return !url.match(/login.globalworkandtravel.com/);
      },
      beforeNavigate: (context) => {
        let pathName = window.location.pathname;
        if (!pathName.includes("payment/invoice/")) return context;

        pathName = pathName.replace(/\/\d+/g, "/<invoiceId>");

        return {
          ...context,
          name: pathName,
        };
      },
    }),
  ],

  dsn:
    SENTRY_DSN ||
    "https://2ead818fe1e845ceaf0fbcb72a77fa76@o32955.ingest.sentry.io/159164",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment:
    process.env.NEXT_PUBLIC_ENVIRONMENT === "live" ? "Live" : "Development",
  release: process.env.NEXT_PUBLIC_VERSION,
  ignoreErrors: [
    "you are locked",
    "payment lock",
    "Access denied",
    "profile is not setup",
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
