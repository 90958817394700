import { FC, useState } from "react";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import Typography from "@/components/Typography";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";

export type Columns<T> = Array<{
  headerName: string;
  selector: (row: T, index: number) => React.ReactNode;
  style?: { [key: string]: any };
}>;

type DataTableProps<T> = {
  rows: Array<T>;
  columns: Columns<T>;
  showPagination?: boolean;
  rowsPerPage?: number;
  className?: string;
  isLoading?: boolean;
  showEmptyText?: boolean;
};

function DataTable<T>({
  rows,
  columns,
  showPagination,
  rowsPerPage = 5,
  className,
  isLoading,
  showEmptyText = true,
}: DataTableProps<T>) {
  const [page, setPage] = useState<number>(0);
  return (
    <MuiTableContainer className={className}>
      <MuiTable>
        <TableHead columns={columns} />
        <TableBody
          showPagination={showPagination}
          page={page}
          rowsPerPage={rowsPerPage}
          rows={rows}
          columns={columns}
          isLoading={isLoading}
        />
        {showPagination && !isLoading && (
          <TableFooter
            count={rows.length || 0}
            page={page}
            onChangePage={(newPage: number) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            colSpan={columns.length}
          />
        )}
      </MuiTable>
      {showEmptyText && !isLoading && rows.length === 0 && (
        <Typography
          style={{ textAlign: "center" }}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          Nothing to display
        </Typography>
      )}
    </MuiTableContainer>
  );
}

export default DataTable;
