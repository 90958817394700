import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type MediaItem = Partial<{
  id: number;
  file_name: string;
  mime: string;
  submission_id: number;
  size: number;
  is_profile: number;
  is_feature: number;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
  s3_key: string;
  preSignedGetUrl: string;
  upload_status: "PENDING" | "FINAL";
}>;

export type MediaList = {
  result: Array<MediaItem>;
  success: boolean;
};

type QueryError = {};

const useSubmissionsMediaListQuery = (
  submissionId: number,
  options?: UseQueryOptions<MediaList, QueryError, MediaList, QueryKey>
) => {
  const URL = `${REWARD_ENDPOINT}/media/submission/${submissionId}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<MediaList, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<MediaList>(URL, {
        params: { add_url: true },
      });
      return res;
    },
    {
      enabled: !!submissionId,
      ...options,
    }
  );
};
export default useSubmissionsMediaListQuery;
