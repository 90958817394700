import SvgIcon, { SvgIconProps } from "./SvgIcon";

const DoubleCheckIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="m16.49768,6.75674c0.4105,-0.36949 1.0428,-0.33625 1.4122,0.07423c0.3411,0.37891 0.339,0.94682 0.0138,1.32256l-0.088,0.08971l-9.99864,9.00004c-0.36487,0.3284 -0.9081,0.3402 -1.28535,0.0443l-0.09065,-0.0804l-4.00135,-3.99997c-0.39059,-0.39045 -0.39069,-1.02362 -0.00024,-1.41421c0.36043,-0.36054 0.92765,-0.38837 1.32,-0.08341l0.09422,0.08317l3.33001,3.32823l9.294,-8.36425z" />
      <path d="m20.99768,6.92341c0.4105,-0.36949 1.0428,-0.33625 1.4122,0.07423c0.3411,0.37891 0.339,0.94682 0.0138,1.32256l-0.088,0.08971l-9.99864,9.00004c-0.36487,0.3284 -0.9081,0.3402 -1.28535,0.0443l-0.09065,-0.0804l-4.00135,-3.99997c-0.39059,-0.39045 -0.39069,-1.02362 -0.00024,-1.41421c0.36043,-0.36054 0.92765,-0.38837 1.32,-0.08341l0.09422,0.08317l3.33001,3.32823l9.294,-8.36425z" />
    </SvgIcon>
  );
};

export default DoubleCheckIcon;
