import { QueryKey, useQueryClient, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Result = {
  success: true;
  statusCode: 200;
  id: string;
  object: "payment_intent";
  amount: number;
  amount_received: number;
  client_secret: string;
  confirmation_method: string;
  created: number;
  currency: string;
  customer: string;
  metadata: {
    cid: string;
  };
  payment_method: string;
  status: "succeeded";
};
type QueryError = {};

const useRetrieveIntent = (
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const queryClient = useQueryClient();

  return async ({
    currency,
    intentId,
  }: {
    currency: string;
    intentId: string;
  }) => {
    const url = `${PAYMENT_ENDPOINT}/transaction/intent/${currency}/${intentId}`;
    const cacheKey = [currency, intentId];

    return queryClient.fetchQuery<Result, QueryError, Result, QueryKey>(
      cacheKey,
      async () => {
        const res = await fetchWithAuth<Result>(url);
        return res;
      },
      {
        enabled: (!!currency || !!intentId) && (options?.enabled ?? true),
        ...options,
      }
    );
  };
};
export default useRetrieveIntent;
