import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MagicIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M20.3 6.42501L19.45 4.55001L17.575 3.70001L19.45 2.85001L20.3 0.975006L21.15 2.85001L23.025 3.70001L21.15 4.55001L20.3 6.42501ZM8.24998 6.42501L7.39998 4.55001L5.52498 3.70001L7.39998 2.85001L8.24998 0.975006L9.09998 2.85001L10.975 3.70001L9.09998 4.55001L8.24998 6.42501ZM20.3 18.475L19.45 16.6L17.575 15.75L19.45 14.9L20.3 13.025L21.15 14.9L23.025 15.75L21.15 16.6L20.3 18.475ZM4.67498 21.7L2.29999 19.325C2.11665 19.1417 2.01665 18.9292 1.99999 18.6875C1.98332 18.4458 2.08332 18.2167 2.29999 18L13.55 6.75001C13.75 6.55001 13.9917 6.45001 14.275 6.45001C14.5583 6.45001 14.8 6.55001 15 6.75001L17.25 9C17.45 9.2 17.55 9.44167 17.55 9.72501C17.55 10.0083 17.45 10.25 17.25 10.45L5.99998 21.7C5.79998 21.9 5.57915 22 5.33748 22C5.09582 22 4.87498 21.9 4.67498 21.7ZM5.24998 20.275L13.075 12.45L11.525 10.9L3.69998 18.725L5.24998 20.275Z" />
    </SvgIcon>
  );
};

export default MagicIcon;
