/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import Typography from "../Typography";
import CircularProgress from "./CircularProgress";
import styles from "./InitialLoader.module.scss";
const InitialLoader: React.FC = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((value) => (value < 99 ? value + 1 : 99));
    }, 120);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.fixedLoader}>
        <div className={styles.loaderContainer}>
          <CircularProgress
            customSize={78}
            maskColor="white"
            variant="determinate"
            value={progress}
          />
          <Typography variant="h6" className={styles.progress}>
            {progress}%
          </Typography>
        </div>
      </div>
      <svg
        width="265"
        height="215"
        viewBox="0 0 265 215"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.image}
      >
        <rect
          width="218.178"
          height="124.709"
          rx="8"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 0 51.83)"
          fill="#FAFAFA"
        />
        <path
          d="M1.45334 59.6969C0.650681 55.3521 3.47919 50.9791 7.77099 49.9295L204.161 1.90046C208.453 0.850862 212.583 3.52211 213.385 7.86687L213.908 10.6948L1.97577 62.5248L1.45334 59.6969Z"
          fill="#F0F0F0"
        />
        <rect
          width="209.247"
          height="102.232"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 7.36719 67.1677)"
          fill="white"
        />
        <rect
          width="47.846"
          height="17.4012"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 63.8154 106.272)"
          fill="#F5F5F5"
        />
        <rect
          width="47.846"
          height="17.4012"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 68.0298 129.088)"
          fill="#F5F5F5"
        />
        <rect
          width="47.846"
          height="17.4012"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 125.164 91.2689)"
          fill="#F5F5F5"
        />
        <rect
          width="13.3969"
          height="14.501"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 182.74 83.8948)"
          fill="#F5F5F5"
        />
        <rect
          width="13.3969"
          height="14.501"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 185.902 101.006)"
          fill="#F5F5F5"
        />
        <rect
          width="13.3969"
          height="14.501"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 198.233 80.1054)"
          fill="#F5F5F5"
        />
        <rect
          width="13.3969"
          height="14.501"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 201.394 97.2173)"
          fill="#F5F5F5"
        />
        <rect
          width="47.846"
          height="17.4012"
          rx="4"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 129.379 114.084)"
          fill="#F5F5F5"
        />
        <rect
          width="20.4143"
          height="3.62525"
          rx="1.81263"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 62.498 99.1422)"
          fill="#F5F5F5"
        />
        <rect
          width="14.6728"
          height="2.9002"
          rx="1.4501"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 190.539 45.4727)"
          fill="#F5F5F5"
        />
        <rect
          width="15.3107"
          height="2.9002"
          rx="1.4501"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 181.555 77.4776)"
          fill="#F5F5F5"
        />
        <circle
          r="1.75"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 8.01391 55.6391)"
          fill="white"
        />
        <circle
          r="1.75"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 12.9714 54.4267)"
          fill="white"
        />
        <circle
          r="1.75"
          transform="matrix(0.971373 -0.237559 0.181667 0.98336 17.929 53.2143)"
          fill="white"
        />
        <rect
          x="32"
          y="40.5838"
          width="232.295"
          height="134.416"
          rx="8"
          fill="#EBEBEB"
        />
        <path
          d="M32 48.5838C32 44.1655 35.5817 40.5838 40 40.5838H256.295C260.713 40.5838 264.295 44.1655 264.295 48.5838V52.3061H32V48.5838Z"
          fill="#C7C7C7"
        />
        <rect
          x="36.7544"
          y="58.5576"
          width="222.785"
          height="110.19"
          rx="4"
          fill="white"
        />
        <rect
          x="42.188"
          y="69.0861"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="69.8676"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="42.188"
          y="101.909"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="102.691"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="42.188"
          y="80.0271"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="80.8088"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="42.188"
          y="112.85"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="113.631"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="42.188"
          y="90.9681"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="91.7494"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="42.188"
          y="123.791"
          width="4.75457"
          height="5.47043"
          rx="2"
          fill="#F5F5F5"
        />
        <rect
          x="50.3384"
          y="124.572"
          width="24.4521"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="88.3755"
          y="67.9354"
          width="118.185"
          height="28.9151"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="88.6108"
          y="114"
          width="55"
          height="19"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="88.6108"
          y="139"
          width="55"
          height="19"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="151.611"
          y="114"
          width="55"
          height="19"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="214.611"
          y="121"
          width="17"
          height="17"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="214.611"
          y="141"
          width="17"
          height="17"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="234.611"
          y="121"
          width="17"
          height="17"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="234.611"
          y="141"
          width="17"
          height="17"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="151.611"
          y="139"
          width="55"
          height="19"
          rx="4"
          fill="#F5F5F5"
        />
        <rect
          x="88.3755"
          y="106.229"
          width="21.7352"
          height="3.90745"
          rx="1.95372"
          fill="#F5F5F5"
        />
        <rect
          x="214.611"
          y="114"
          width="16.3014"
          height="3.12596"
          rx="1.56298"
          fill="#F5F5F5"
        />
        <circle cx="39.5044" cy="46.2409" r="1.75" fill="white" />
        <circle cx="44.938" cy="46.2409" r="1.75" fill="white" />
        <circle cx="50.3721" cy="46.2409" r="1.75" fill="white" />
        <circle
          r="39.3365"
          transform="matrix(-1 0 0 1 142.336 172.337)"
          fill="white"
        />
        <circle
          r="39.3365"
          transform="matrix(-1 0 0 1 154.703 175.313)"
          fill="#E0F9FF"
        />
      </svg>

      <Typography variant="h6" align="center" sx={{ mt: "32px" }}>
        Putting all the pieces together for you.
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        align="center"
        sx={{ mt: "12px" }}
      >
        We won&apos;t be long.
      </Typography>
    </div>
  );
};

export default InitialLoader;
