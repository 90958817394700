import cx from "classnames";
import SvgIcon, { SvgIconProps } from "../Icon/SvgIcon";
import styles from "./DotSeparator.module.scss";
const DotSeparator: React.FC<SvgIconProps & { size?: string }> = ({
  size = "0.3rem",
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 100 100"
      className={cx(styles.dotSeparatorRoot, props.className)}
      style={{ width: size, height: size }}
    >
      <circle cx="50" cy="50" r="50" />
    </SvgIcon>
  );
};

export default DotSeparator;
