import SvgIcon, { SvgIconProps } from "./SvgIcon";

const VisibleIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 5C15.6746 5 19.2622 7.15258 22.7682 11.3598C23.0773 11.7307 23.0773 12.2693 22.7682 12.6402C19.2622 16.8474 15.6746 19 12 19C8.32544 19 4.73781 16.8474 1.23178 12.6402C0.92274 12.2693 0.92274 11.7307 1.23178 11.3598C4.73781 7.15258 8.32544 5 12 5ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9Z" />
      ) : (
        <path d="M12 5C15.6746 5 19.2622 7.15258 22.7682 11.3598C23.0773 11.7307 23.0773 12.2693 22.7682 12.6402C19.2622 16.8474 15.6746 19 12 19C8.32544 19 4.73781 16.8474 1.23178 12.6402C0.92274 12.2693 0.92274 11.7307 1.23178 11.3598C4.73781 7.15258 8.32544 5 12 5ZM12 7C9.2799 7 6.48796 8.52679 3.62819 11.654L3.317 12L3.62819 12.346C6.29731 15.2647 8.90734 16.7893 11.455 16.9796L11.7278 16.9949L12 17C14.7201 17 17.512 15.4732 20.3718 12.346L20.682 12L20.3718 11.654C17.7027 8.73527 15.0927 7.21069 12.545 7.02038L12.2722 7.00509L12 7ZM12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9Z" />
      )}
    </SvgIcon>
  );
};

export default VisibleIcon;
