import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Player, {
  APITypes,
  MediaType,
  getVideoSources,
} from "@/components/Player";
import styles from "./OnboardingTourDialog.module.scss";
import { useRef } from "react";

const videoSources = getVideoSources(
  "q_auto/gwat.gworld/assets/gworld-onboarding"
);
const source = {
  type: "video" as MediaType,
  sources: videoSources.video,
};

const OnboardingTourDialog: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const playerRef = useRef<APITypes | null>(null);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{ paper: styles.dialogPaper }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        gWorld Welcome
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Player
          ref={playerRef}
          source={source}
          trackerData={{
            content_asset_id: "gworld_tour",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingTourDialog;
