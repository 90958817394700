import {
  ContactDetailsFormType,
  EmergencyContactFormType,
  GeneralInfoFormType,
  PassportFormType,
  GuardianDetailsFormType,
  PersonalDetailsFormType,
  ProfileInfoFormType,
  GuardianRelationItems,
  EmergencyRelationItems,
  WorkingHolidayVisaFormType,
  DepartureDateFormType,
} from "@/components/PersonalDetailsForms";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useVisaQuery } from "@/fetch/trip";
import { useTripDetailsQuery } from "@/fetch/gworld";
import {
  useCountryCodes,
  useNationalities,
  useCurrentTrip,
  useLock,
} from "@/hooks";
import useFormatContactMethod from "./useFormatContactMethod";

const usePersonalDetailsFormInitialValues = () => {
  const { currentTrip } = useCurrentTrip();
  const { isSuperliteTrip } = useLock();
  const { data: countries, isLoading: isCountriesLoading } = useCountryCodes();
  const { data: nationalities, isLoading: isNationalitiesLoading } =
    useNationalities();
  const { data: personalDetailsData, isLoading: isPersonalDetailsLoading } =
    usePersonalDetailsQuery();
  const { data: visaData, isLoading: isVisaLoading } = useVisaQuery();
  const { data: tripDetails, isLoading: isLoadingTrip } = useTripDetailsQuery(
    currentTrip?.id
  );

  const { formatContactMethodToGworld } = useFormatContactMethod();

  const rawEmergencyContact = personalDetailsData?.emergencyContacts?.[0];
  const rawGuardianDetails = personalDetailsData?.guardianContact?.[0];
  const emergencyContact = {
    ...rawEmergencyContact,
    data: formatContactMethodToGworld(rawEmergencyContact?.contacts || []),
  };
  const guardianDetails = {
    ...rawGuardianDetails,
    data: formatContactMethodToGworld(rawGuardianDetails?.contacts || []),
  };
  const traveller = personalDetailsData?.traveller;
  const contactMethods = formatContactMethodToGworld(
    personalDetailsData?.contactMethods || []
  );
  const addresses = personalDetailsData?.addresses;
  const passport = personalDetailsData?.passport;
  const health = personalDetailsData?.health;
  const convictions = personalDetailsData?.convictions;
  const s3ProfilePicturePath = personalDetailsData?.traveller?.s3_profile_pic;
  const name = `${traveller?.first_name || ""} ${traveller?.last_name || ""}`;

  const workingHolidayVisa = visaData?.result;

  const profileInfoInitialValues = {
    profileInfoNickname: traveller?.nickname || name,
    profileInfoBio: traveller?.bio || "",
    profileInfoProfilePictureUrl: s3ProfilePicturePath || "",
  } as ProfileInfoFormType;

  const generalInfoInitialValues = {
    generalInfoFirstName: traveller?.first_name || "",
    generalInfoLastName: traveller?.last_name || "",
    generalInfoMiddleName: traveller?.middle_name || "",
    generalInfoDateOfBirth: Number(traveller?.date_of_birth) || null,
    generalInfoNationality:
      nationalities?.find((item) => item.label === traveller?.nationality) ||
      traveller?.nationality?.length
        ? { label: traveller?.nationality }
        : { label: "" },

    generalInfoGender: traveller?.gender || "",
  } as GeneralInfoFormType;

  const emergencyContactInitialValues = {
    emergencyContactName: emergencyContact?.full_name || "",
    emergencyContactRelationSelect: Boolean(emergencyContact?.relationship)
      ? Boolean(
          EmergencyRelationItems.find(
            (each) => each === emergencyContact?.relationship
          )
        )
        ? emergencyContact?.relationship
        : "Other"
      : "",
    emergencyContactRelationTextField:
      Boolean(emergencyContact?.relationship) &&
      Boolean(
        EmergencyRelationItems.find(
          (each) => each === emergencyContact?.relationship
        )
      )
        ? ""
        : emergencyContact?.relationship,
    emergencyContactEmail: emergencyContact?.email || "",

    emergencyContactPrimaryPhone:
      emergencyContact?.data?.primary?.reference || "",
    emergencyContactPrimaryCountryCode:
      countries?.find(
        (item) =>
          item.dial_code === emergencyContact?.data?.primary?.region_code
      ) || {},

    emergencyContactSecondaryPhone:
      emergencyContact?.data?.secondary?.reference || "",
    emergencyContactSecondaryCountryCode:
      countries?.find(
        (item) =>
          item.dial_code === emergencyContact?.data?.secondary?.region_code
      ) || {},
  } as EmergencyContactFormType;

  const guardianDetailsInitialValues = {
    guardianDetailsName: guardianDetails?.full_name || "",
    guardianDetailsRelationSelect: Boolean(guardianDetails?.relationship)
      ? Boolean(
          GuardianRelationItems.find(
            (each) => each === guardianDetails?.relationship
          )
        )
        ? guardianDetails?.relationship
        : "Other"
      : "",
    guardianDetailsRelationTextField:
      Boolean(guardianDetails?.relationship) &&
      Boolean(
        GuardianRelationItems.find(
          (each) => each === guardianDetails?.relationship
        )
      )
        ? ""
        : guardianDetails?.relationship,
    guardianDetailsEmail: guardianDetails?.email || "",

    guardianDetailsPrimaryPhone:
      guardianDetails?.data?.primary?.reference || "",
    guardianDetailsPrimaryCountryCode:
      countries?.find(
        (item) => item.dial_code === guardianDetails?.data?.primary?.region_code
      ) || {},

    guardianDetailsSecondaryPhone:
      guardianDetails?.data?.secondary?.reference || "",
    guardianDetailsSecondaryCountryCode:
      countries?.find(
        (item) =>
          item.dial_code === guardianDetails?.data?.secondary?.region_code
      ) || {},
  } as GuardianDetailsFormType;

  const contactDetailsInitialValues = {
    contactDetailsEmail: traveller?.email || "",
    contactDetailsPrimaryPhone: contactMethods?.primary?.reference || "",
    contactDetailsPrimaryCountryCode:
      countries?.find(
        (item) => item.dial_code === contactMethods?.primary?.region_code
      ) || {},
    contactDetailsSecondaryPhone: contactMethods?.secondary?.reference || "",
    contactDetailsSecondaryCountryCode:
      countries?.find(
        (item) => item.dial_code === contactMethods?.secondary?.region_code
      ) || {},
    contactDetailsTravelPhone: contactMethods?.travel?.reference || "",
    contactDetailsTravelCountryCode:
      countries?.find(
        (item) => item.dial_code === contactMethods?.travel?.region_code
      ) || {},
    contactDetailsStreet: addresses?.street || "",
    contactDetailsSuburb: addresses?.suburb || "",
    contactDetailsPostCode: addresses?.post_code || "",
    contactDetailsState: addresses?.state || "",
    contactDetailsCountry:
      countries?.find(
        (item) =>
          item.label?.toLowerCase() === addresses?.country?.toLowerCase()
      ) || {},
  } as ContactDetailsFormType;

  const passportInitialValues = {
    passportStatus: Number(passport?.passport) === 0 ? "no" : "yes",
    passportExpiryDate: Number(passport?.passport_expiry_date) || null,
    passportIssueDate: Number(passport?.passport_issue_date) || null,
    passportIssueLocation: passport?.passport_issue_location || "",
    passportNationality:
      nationalities?.find(
        (item) => item.label === passport?.passport_nationality
      ) || passport?.passport_nationality?.length
        ? { label: passport?.passport_nationality }
        : { label: "" },
    passportNumber: passport?.passport_number || "",
  } as PassportFormType;

  const personalDetailsInitialValues = {
    personalDetailsHeight: Number(traveller?.height) || "",
    personalDetailsWeight: Number(traveller?.weight) || "",
    personalDetailsAlcohol: health?.alcohol?.description || null,
    personalDetailsSmoke: health?.smoke?.description || null,
    personalDetailsIllicit: health?.illicit?.description || null,

    personalDetailsCovidStatus: health?.covid_19_vaccination
      ? Number(health?.covid_19_vaccination?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsCovidDescription:
      health?.covid_19_vaccination?.description || "",

    personalDetailsDietStatus: health?.diet
      ? Number(health?.diet?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsDietDescription: health?.diet?.description || "",

    personalDetailsMedicationStatus: health?.medication
      ? Number(health?.medication?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsMedicationDescription: health?.medication?.description || "",

    personalDetailsMentalHealthStatus: health?.mental_health
      ? Number(health?.mental_health?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsMentalHealthDescription:
      health?.mental_health?.description || "",

    personalDetailsPhysicalLimitationsStatus: health?.physical_limitations
      ? Number(health?.physical_limitations?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsPhysicalLimitationsDescription:
      health?.physical_limitations?.description || "",

    personalDetailsTattoosPiercingsStatus: health?.tattoos_piercings
      ? Number(health?.tattoos_piercings?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsTattoosPiercingsDescription:
      health?.tattoos_piercings?.description || "",

    personalDetailsCriminalStatus: convictions?.criminal
      ? Number(convictions?.criminal?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsCriminalDate:
      Number(convictions?.criminal?.date_occurred) || null,
    personalDetailsCriminalDescription:
      convictions?.criminal?.description || "",

    personalDetailsDrinkDrivingStatus: convictions?.drink_driving
      ? Number(convictions?.drink_driving?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsDrinkDrivingDate:
      Number(convictions?.drink_driving?.date_occurred) || null,
    personalDetailsDrinkDrivingBac:
      Number(convictions?.drink_driving?.bac) || null,
    personalDetailsDrinkDrivingDescription:
      convictions?.drink_driving?.description || "",

    personalDetailsDrivingOffencesStatus: convictions?.driving_offences
      ? Number(convictions?.driving_offences?.user_answer) === 1
        ? "yes"
        : "no"
      : null,
    personalDetailsDrivingOffencesDate:
      Number(convictions?.driving_offences?.date_occurred) || null,
    personalDetailsDrivingOffencesDescription:
      convictions?.driving_offences?.description || "",

    personalDetailsWorkingHolidayVisaStatus: Boolean(workingHolidayVisa)
      ? "yes"
      : null,
    personalDetailsWorkingHolidayVisaDate:
      Number(workingHolidayVisa?.issue_date) || null,
    personalDetailsWorkingHolidayVisaDescription:
      workingHolidayVisa?.user_description || "",
  } as PersonalDetailsFormType;

  const workingHolidayVisaInitialValues = {
    workingHolidayVisaStatus: Boolean(workingHolidayVisa) ? "yes" : null,
    workingHolidayVisaDate: Number(workingHolidayVisa?.issue_date) || null,
    workingHolidayVisaDescription: workingHolidayVisa?.user_description || "",
  } as WorkingHolidayVisaFormType;

  const departureDateInitialValues = {
    departureDate: isSuperliteTrip
      ? tripDetails?.data?.significantDates.tentative_departure?.date
      : tripDetails?.data?.significantDates.trip_start?.date ??
        tripDetails?.data?.significantDates.arrival_date?.date ??
        tripDetails?.data?.significantDates.tentative_departure?.date ??
        null,
  } as DepartureDateFormType;

  return {
    profileInfoInitialValues,
    generalInfoInitialValues,
    emergencyContactInitialValues,
    contactDetailsInitialValues,
    passportInitialValues,
    guardianDetailsInitialValues,
    personalDetailsInitialValues,
    workingHolidayVisaInitialValues,
    departureDateInitialValues,
    isLoading:
      isPersonalDetailsLoading ||
      isCountriesLoading ||
      isNationalitiesLoading ||
      isVisaLoading ||
      isLoadingTrip,
  };
};

export default usePersonalDetailsFormInitialValues;
