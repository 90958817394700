import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ResourceVisaIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        d="M35.4566 17.8751V16.1378C35.4566 14.5245 33.9669 13.3235 32.3904 13.6657L14.0993 17.6364C13.4577 17.7757 13 18.3434 13 19V19"
        stroke="#003749"
        strokeWidth="2"
      />
      <path
        d="M32.7408 52.0002H25.3334H14.5296C13.1326 52.0002 12 50.8676 12 49.4705V20.7798C12 19.3827 13.1326 18.2502 14.5296 18.2502H36.1371C37.5342 18.2502 38.6667 19.3827 38.6667 20.7798V26.6877V28.0939V29.5002"
        stroke="#003749"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8134 32.5933C20.9314 34.4342 21.4337 36.1707 22.25 37.5189C20.4722 36.5368 19.1933 34.7308 18.9242 32.5933H20.8134ZM22.5037 32.5933C22.6226 34.2085 23.0777 35.6686 23.7465 36.7327C24.0848 37.271 24.313 37.5647 24.4901 37.7339V32.5933H22.5037ZM24.4901 30.9068H22.5038C22.6227 29.2919 23.0778 27.8321 23.7465 26.7682C24.071 26.2518 24.3001 25.923 24.4901 25.7068V30.9068ZM26.1765 32.5933V37.6537C26.3881 37.4735 26.6323 37.1902 26.92 36.7324C27.5871 35.671 28.1185 34.1739 28.2754 32.5933H26.1765ZM28.292 30.9068H26.1765V25.8466C26.3881 26.0268 26.6323 26.3101 26.92 26.7679C27.6204 27.8823 28.1608 29.3093 28.292 30.9068ZM29.9688 32.5933C29.8125 34.4404 29.2137 36.2066 28.4163 37.519C30.1942 36.5369 31.4732 34.7309 31.7423 32.5933H29.9688ZM31.7423 30.9068H29.9833C29.8522 29.0135 29.2341 27.3194 28.4166 25.9814C30.1943 26.9635 31.4731 28.7694 31.7423 30.9068ZM20.8134 30.9068H18.9242C19.1934 28.7691 20.4726 26.963 22.2507 25.981C21.4341 27.3291 20.9316 29.0657 20.8134 30.9068ZM25.3333 40.0001C29.8334 40.0001 33.4814 36.3065 33.4814 31.7501C33.4814 27.1938 29.8334 23.5001 25.3333 23.5001C20.8331 23.5001 17.1851 27.1938 17.1851 31.7501C17.1851 36.3065 20.8331 40.0001 25.3333 40.0001Z"
        fill="#003749"
      />
      <path
        d="M18.6666 43.3375C18.6666 43.1512 18.8176 43.0002 19.0039 43.0002L31.6626 43.0002C31.8489 43.0002 31.9999 43.1512 31.9999 43.3375L31.9999 46.4129C31.9999 46.5992 31.8489 46.7502 31.6626 46.7502H19.0039C18.8176 46.7502 18.6666 46.5992 18.6666 46.4129L18.6666 43.3375Z"
        fill="#0095CD"
      />
      <path
        opacity="0.2"
        d="M44.5925 30.9999C43.0694 30.9999 42.5902 33.2145 42.4394 34.608C42.3602 35.34 42.4667 36.0744 42.6571 36.7857L43.3455 39.3571L43.8208 42.3406C43.8813 42.7204 44.2089 42.9999 44.5935 42.9999C44.9772 42.9999 45.3043 42.7217 45.3658 42.3429L45.8507 39.3571L46.5341 36.7746C46.7204 36.0706 46.8244 35.3439 46.7468 34.6199C46.5975 33.2272 46.1199 30.9999 44.5925 30.9999Z"
        fill="#0095CD"
      />
      <path
        d="M42.7407 30.9999C40.4508 30.9999 39.448 32.4892 39.0088 33.7937C38.5978 35.0144 38.9617 36.3154 39.5292 37.4717L39.8136 38.0511C40.2401 38.9201 40.591 39.8243 40.8624 40.7535L41.3413 42.3931C41.4463 42.7527 41.776 42.9999 42.1507 42.9999H43.3342C43.7072 42.9999 44.0359 42.7548 44.1423 42.3973L44.656 40.672C44.9165 39.7968 45.2477 38.9443 45.6463 38.1227L45.9691 37.4574C46.5256 36.3102 46.8818 35.0228 46.4791 33.8131C46.0433 32.504 45.0419 30.9999 42.7407 30.9999Z"
        stroke="#003749"
        strokeWidth="2"
      />
      <path
        d="M40.0135 43.1864C40.0135 42.5897 40.4972 42.106 41.0939 42.106H44.3877C44.9844 42.106 45.4681 42.5897 45.4681 43.1864V44.6439H40.0135V43.1864Z"
        fill="white"
        stroke="#003749"
        strokeWidth="1.21212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0111 46.0736H49.4704C49.8633 46.0736 50.1818 46.3921 50.1818 46.785V47.3285H35.2997V46.785C35.2997 46.3921 35.6182 46.0736 36.0111 46.0736ZM33.4815 46.785C33.4815 45.388 34.614 44.2554 36.0111 44.2554H49.4704C50.8675 44.2554 52 45.388 52 46.785V48.3035C52 48.7692 51.6225 49.1467 51.1568 49.1467H50.3054C50.3025 49.1602 50.2994 49.1736 50.2963 49.187C49.9233 50.7988 48.4787 52.0001 46.7536 52.0001L38.7283 52.0001C37.0032 52.0001 35.5586 50.7988 35.1856 49.187C35.1825 49.1736 35.1794 49.1602 35.1765 49.1467H34.3247C33.859 49.1467 33.4815 48.7692 33.4815 48.3035V46.785ZM46.7536 50.1819L38.7283 50.1819C38.0205 50.1819 37.4072 49.7775 37.1068 49.187L48.3751 49.187C48.0747 49.7775 47.4614 50.1819 46.7536 50.1819Z"
        fill="#003749"
      />
    </SvgIcon>
  );
};

export default ResourceVisaIcon;
