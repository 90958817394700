import { useMutation, UseMutationOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { getBasePayload } from "./useInitTxMutation";

type Variables = {
  invoiceType: "travel" | "trip";
  invoiceStaffId: number | undefined;
  invoiceId: number;
  amount: number;
  isFinal: boolean;
  paymentPlanId: number | undefined;
  cardId: number;
  promoCodes: Array<string> | undefined;
};

type Result = {
  transactions: Array<{
    cid: number;
    method_display: string;
    invoice_id: number;
    transaction_id: string;
    currency: string;
    method: string;
    amount: number;
    excess_amount: number;
    transaction_date: string;
    deposit: number;
    final: number;
    card_id: string;
    processor: "";
    department_id: number;
    collector_staff_id: number;
    _cb: number;
    comment: string;
    pan_suffix: string;
    id: number;
    success: boolean;
    result: string;
    transaction_audit: {
      success: boolean;
      id: number;
      status: number;
      transaction_no: number;
      transaction_id: string;
      invoice_id: number;
    };
    calculated_invoice: {
      id: number;
      deposit: number;
      discount: number;
      total: number;
      paid: number;
      outstanding: number;
      cid: number;
      tid: number;
      currency: string;
      type: string;
      success: boolean;
      total_deposit_paid: number;
      deposit_date: null;
    };
    payment_plan: {
      id: number;
      provider: string;
      status: string;
      success: boolean;
    } | null;
  }>;
  statusCode: 200;
  success: true;
};

type QueryError = {};

function createPayload({
  invoiceType,
  invoiceStaffId,
  invoiceId,
  amount,
  isFinal,
  paymentPlanId,
  cid,
  cardId,
  promoCodes,
}: {
  invoiceType: "travel" | "trip";
  invoiceStaffId: number | undefined;
  invoiceId: number;
  amount: number;
  isFinal: boolean;
  paymentPlanId: number | undefined;
  cid: number;
  cardId: number;
  promoCodes: Array<string> | undefined;
}) {
  let data = getBasePayload({
    invoiceType,
    invoiceStaffId,
    invoiceId,
    amount,
    isFinal,
    paymentPlanId,
    promoCodes,
    cid,
  });

  data = { ...data, card_id: `${cardId}` };

  return data;
}

const useExecuteCardPaymentMutation = (
  options?: UseMutationOptions<Result, QueryError, Variables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;

  return {
    ...useMutation<Result, QueryError, Variables>(
      async ({
        amount,
        isFinal,
        invoiceId,
        paymentPlanId,
        invoiceStaffId,
        invoiceType,
        cardId,
        promoCodes,
      }: Variables) => {
        if (!cid || !amount || !invoiceId || !invoiceType) throw new Error();

        const url = `${PAYMENT_ENDPOINT}/card/execute`;
        const payload = createPayload({
          amount,
          isFinal,
          invoiceId,
          paymentPlanId,
          invoiceStaffId,
          invoiceType,
          cid,
          cardId,
          promoCodes,
        });
        return fetchWithAuth<Result>(url, { method: "POST", body: payload });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useExecuteCardPaymentMutation;
