import { useMutation, UseMutationOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { SubmissionStatuses } from "./useSubmissionsListQuery";

export type UpdateSubmissionVariables = {
  submissionId?: number;
  upload_status?: "FINAL" | "PENDING";
  status?: SubmissionStatuses;
  rewards_id?: number;
  rating?: string;
  experience?: string;
  media_description?: string;
  media_count?: number;
};

type UpdateSubmissionResult = {
  success: boolean;
  result: {
    affectedRows: number;
  };
};

type QueryError = {};

const useUpdateSubmissionMutation = (
  options?: UseMutationOptions<
    UpdateSubmissionResult,
    QueryError,
    UpdateSubmissionVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      UpdateSubmissionResult,
      QueryError,
      UpdateSubmissionVariables
    >(
      async (submissionInput: UpdateSubmissionVariables) => {
        const { submissionId } = submissionInput;
        const url = `${REWARD_ENDPOINT}/v2/submission/${submissionId}`;
        delete submissionInput.submissionId;

        return fetchWithAuth<UpdateSubmissionResult>(url, {
          method: "PATCH",
          body: { ...submissionInput },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateSubmissionMutation;
