import create from "zustand";
import { persist } from "zustand/middleware";

type FcmToken = {
  token: string;
  deviceId: string;
  expiresIn: number;
};

type FcmTokenState = {
  fcmToken: FcmToken | null | undefined;
  setFcmToken: (state: FcmToken | null | undefined) => void;
};

const useFcmTokenStore = create(
  persist<FcmTokenState>(
    (set) => ({
      fcmToken: null,
      setFcmToken: (fcmToken) => set({ fcmToken }),
    }),
    {
      name: "fcm-token",
    }
  )
);

const useFcmToken = () => {
  const { fcmToken, setFcmToken } = useFcmTokenStore();

  return { fcmToken, setFcmToken };
};

export default useFcmTokenStore;
