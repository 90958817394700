import { useState } from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/nextjs";

const useAxiosWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const [abortController, setAbortController] = useState<AbortController>(
    new AbortController()
  );

  const axiosWithAuth = async <T>(
    url: string,
    options?: {
      method?: "get" | "post" | "delete";
      data?: any;
      params?: URLSearchParams;
      headers?: AxiosRequestHeaders;
    }
  ) => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);

      const response: any = await axios({
        method: options?.method || "get",
        url: `${url}${options?.params ? "?" : ""}${new URLSearchParams(
          options?.params
        )}`,
        data: options?.data,
        headers: {
          //   Authorization: `Bearer ${token}`,
          // "Content-Type": "application/json",
          ...options?.headers,
        },
        signal: abortController.signal,
        onUploadProgress: (progressEvent: any) => {
          setProgress(
            Math.round((100 * progressEvent?.loaded) / progressEvent?.total)
          );
        },
      });
      setLoading(false);

      if (response.status === 204) {
        return response;
      }
      if (response.json) {
        const res = await response.json();
        return res as T;
      }
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      throw error;
    }
  };

  const cancel = () => {
    abortController.abort();
    setAbortController(new AbortController());
  };

  return { axiosWithAuth, cancel, loading, progress };
};

export default useAxiosWithAuth;
