import { useGroupMembersQuery } from "@/fetch/social";
import Avatar from "@/components/Avatar";
import Dialog, { DialogContent, DialogTitle } from "@/components/Dialog";
import { Skeleton } from "@/components/Loader";
import Typography from "@/components/Typography";
import styles from "./GroupMembersDialog.module.scss";
import compact from "lodash/compact";
import { Member } from "@/fetch/social";
import Grid from "@/components/Grid";
import Divider from "@/components/Divider";
import cx from "classnames";
import Button from "@/components/Button";
import Emoji from "@/components/Emoji";
import { useCurrentTrip, useDebounce, useDialog, useResponsive } from "@/hooks";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import RichContentPreview from "@/components/RichContentPreview";
import TravellerNameLink from "./TravellerNameLink";
import { useTwilioConversation } from "@/components/Chat/hooks";
import { SearchTextField } from "../TextField";
import { EmptySearchResult } from "../EmptyList";
import SocialAvatar from "./SocialAvatar";

const MemberItemLoader: React.FC = () => {
  const { isMobile } = useResponsive();
  const buttonLoader = (
    <Skeleton variant="rectangular" height="46px" className={styles.sayHi} />
  );
  return (
    <Grid item className={styles.loaderContainer}>
      <div className={styles.memberItemRoot}>
        <Skeleton variant="circular" className={styles.avatarLoader} />
        <div className={styles.memberItemContent}>
          <div className={cx(styles.memberInfoTitle)}>
            <Skeleton width={180} />
          </div>
          <Skeleton width={100} className={styles.country} />
          <Skeleton width="100%" className={styles.bio} />
          <Skeleton width="100%" className={styles.bio} />

          {isMobile && buttonLoader}
        </div>
        {!isMobile && buttonLoader}
      </div>
      <Divider className={styles.divider} />
    </Grid>
  );
};

const MemberItem: React.FC<{
  member: Member;
}> = ({ member }) => {
  const { isMobile } = useResponsive();
  const { openChatPopup } = useTwilioConversation();
  const [isOpeningChat, setIsOpeningChat] = useState(false);
  const { onClose } = useDialog();

  const openChat = async (travellerCid?: number) => {
    try {
      setIsOpeningChat(true);
      await openChatPopup(travellerCid, "Group Members Dialog");
      !isMobile && onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpeningChat(false);
    }
  };
  const nickname = member.nickname || "";
  const country = member.country || "";
  // const division = member.division || "";
  const bio = member.bio || "";
  const isAdmin = Boolean(member.is_admin) || false;
  const adminTitle = member.admin_title || null;

  const SayHiButton = (
    <>
      <Button
        className={styles.sayHi}
        variant="outlined"
        onClick={() => openChat(member?.cid)}
        disabled={isOpeningChat}
      >
        {isOpeningChat ? (
          "Initializing..."
        ) : (
          <>
            <Emoji shortcodes=":wave::skin-tone-2:" size="1.2em" />
            Say hi
          </>
        )}
      </Button>
    </>
  );

  return (
    <>
      <div className={styles.memberItemRoot}>
        <SocialAvatar
          isAdmin={isAdmin}
          adminTitle={adminTitle}
          badgeContentClassName={styles.adminBadgeContent}
          badgeClassName={styles.adminBadge}
          cloudinaryPath={member.s3_profile_pic}
          size={isMobile ? "l" : "xl"}
          className={styles.avatar}
        />
        <div className={styles.memberItemContent}>
          <div
            className={cx(styles.memberInfoTitle, {
              [styles.noBioTitle]: !Boolean(bio.length),
            })}
          >
            <TravellerNameLink
              variant="h6"
              cid={member.cid}
              nickname={nickname}
            />
          </div>
          <Typography variant="body2" className={styles.country}>
            {country || ""}
          </Typography>
          <RichContentPreview
            content={bio || ""}
            variant="body1"
            className={styles.bio}
          />

          {isMobile && SayHiButton}
        </div>
        {!isMobile && SayHiButton}
      </div>

      <Divider className={styles.divider} />
    </>
  );
};

const GroupMembersDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { currentTrip } = useCurrentTrip();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
  const isFilterSet = debouncedSearchTerm !== "";
  const {
    data: groupMembersData,
    isLoading: isGroupMembersLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGroupMembersQuery(
    isFilterSet
      ? {
          pageNum: 1,
          pageSize: 100,
          searchParam: debouncedSearchTerm,
        }
      : undefined
  );
  const { ref: loaderContainerRef, inView: isLoaderInView } = useInView({
    threshold: 0,
  });

  const cid = currentTrip?.cid;
  const members = compact(
    groupMembersData?.pages?.map((page) => page?.results)?.flat(2)
  ).filter((item) => item.cid !== cid);
  const hasMembers = Boolean(
    (groupMembersData?.pages?.[0]?.totalCount || 0) > 0
  );

  useEffect(() => {
    if (
      !isLoaderInView ||
      !hasNextPage ||
      isGroupMembersLoading ||
      isFetchingNextPage
    )
      return;

    fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoaderInView,
    isGroupMembersLoading,
  ]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Your group members
      </DialogTitle>
      <SearchTextField
        className={styles.searchBox}
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
      />
      <DialogContent className={styles.dialogContentRoot} dividers>
        <Grid container rowGap="24px">
          {!hasMembers && !isGroupMembersLoading && (
            <Grid item xs={12}>
              <EmptySearchResult />
            </Grid>
          )}
          {!hasMembers &&
            isGroupMembersLoading &&
            Array(3)
              .fill(null)
              .map((item, index) => <MemberItemLoader key={index} />)}
          {members?.map((member) => (
            <Grid item xs={12} key={member?.cid}>
              <MemberItem member={member} />
            </Grid>
          ))}
          {hasMembers && hasNextPage && (
            <Grid item xs={12}>
              <div ref={loaderContainerRef}>
                {isFetchingNextPage && <MemberItemLoader />}
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GroupMembersDialog;
