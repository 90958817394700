import Typography from "@/components/Typography";
import { SearchIcon } from "@/components/Icon";
import styles from "./EmptyRewardCards.module.scss";

const EmptyRewardCards: React.FC = () => {
  return (
    <div className={styles.root}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src="/images/empty-rewards.svg" alt="icon" />
      <Typography variant="h6" className={styles.text}>
        No rewards to collect at the moment
      </Typography>
      <Typography variant="body2" color="text.secondary">
        There are no rewards defined for this trip. Check back later for
        updates.
      </Typography>
    </div>
  );
};

export default EmptyRewardCards;
