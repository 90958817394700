import { FC, useEffect } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { DialogContent, DialogTitle, DialogActions } from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import RichContentPreview from "@/components/RichContentPreview";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import { useConfetti } from "@/hooks";
import styles from "./SuccessfulSubmission.module.scss";

type Props = {
  onClose: () => void;
  globalDollarAmount: number;
};

const SuccessfulSubmission: FC<Props> = ({ onClose, globalDollarAmount }) => {
  const { fire, canvasStyles, getInstance } = useConfetti();

  useEffect(() => {
    fire();
  }, []);

  return (
    <>
      <DialogTitle hasCloseButton onClose={onClose}></DialogTitle>
      <DialogContent className={styles.successContent}>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        <RichContentPreview content="🥳 Thank you for sharing the fun!" />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={`/images/gift.svg`} alt="icon" className={styles.giftIcon} />
        <Typography variant="body1" className={styles.successText}>
          You will receive
          <GlobalDollarColoredIcon className={styles.globalDollarIcon} />x
          {globalDollarAmount} once your submission has been reviewed &
          approved.
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          className={styles.button}
        >
          Close
        </Button>
      </DialogContent>
    </>
  );
};

export default SuccessfulSubmission;
