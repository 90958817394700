import { QueryKey, useQuery, UseQueryOptions } from "react-query";

export type Nationality = {
  label: string;
};

type Nationalities = Array<Nationality>;

type QueryError = {};

const getNationalities = async (url: string) => {
  const result = await fetch(url);
  if (!result.ok) {
    console.error(JSON.stringify(result));
    throw new Error("Network response was not ok");
  }
  const nationalities = await result.json();
  return nationalities as Nationalities;
};

const useNationalities = (
  options?: UseQueryOptions<Nationalities, QueryError, Nationalities, QueryKey>
) => {
  const url = "/nationalities.json";

  return useQuery<Nationalities, QueryError>(
    [url],
    async () => {
      return getNationalities(url);
    },
    {
      ...options,
    }
  );
};

export default useNationalities;
