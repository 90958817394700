import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import Typography from "@/components/Typography";
import styles from "./HelpAndSupportDialog.module.scss";
import Select from "@/components/Select";
import { RedirectIcon } from "@/components/Icon";
import { useState, useEffect } from "react";
import { MenuItem } from "@/components/Menu";
import Tag from "@/components/Tag";
import Divider from "@/components/Divider";
import Image from "next/image";
import { useOpenExternalURL, useResponsive, useTrackers } from "@/hooks";
import Button from "@/components/Button";
import { usePersonalDetailsQuery } from "@/fetch/profiles";

export const phoneData = [
  {
    name: "Australia & New Zealand",
    office_di: 1,
    id: "au",
    address: [
      "Level 16, 50 Caville Ave",
      "Surfers Paradise, Queensland",
      "Australia, 4217",
    ],
    phones: [
      {
        tel: "1300 40 11 90",
        name: "1300-40-11-90",
        tagLabel: "AU",
      },
      {
        tel: "09 925 0387",
        name: "09-925-0387",
        tagLabel: "NZ",
      },
    ],
    hours: "Monday to Friday, 9am - 6pm AEST",
  },
  {
    name: "Canada & USA",
    office_di: 2,
    id: "ca-usa",
    address: [
      "Level 8, 333 Seymour St,",
      "Vancouver, British Columbia, ",
      "Canada, V6B 5A6",
    ],
    phones: [
      {
        tel: "1877 744 5622",
        name: "1877-744-5622",
        tagLabel: "Toll Free",
      },
    ],
    hours: "Monday to Friday, 9am - 6pm PST/PDT",
  },
  {
    name: "UK & Europe",
    office_di: 3,
    id: "uk-eu",
    address: [
      "3 Waterhouse Square, 138 Holborn,",
      "London, England,",
      "EC1N 2SW, UK",
    ],
    phones: [
      {
        tel: "020 3002 2138",
        name: "020-3002-2138",
        tagLabel: "UK",
      },
      {
        tel: "01 513 5536",
        name: "01-513-5536",
        tagLabel: "IE",
      },
      {
        tel: "02 320 55 07",
        name: "02-320-55-07",
        tagLabel: "BE",
      },
      {
        tel: "020 532 0028",
        name: "020-532-0028",
        tagLabel: "NL",
      },
      {
        tel: "0800 000 2735",
        name: "0800-000-2735",
        tagLabel: "DE",
      },
      {
        tel: "+44 20 3002 2138",
        name: "+44-20-3002-2138",
        tagLabel: "EU",
      },
    ],
    hours: "Monday to Friday, 9am - 6pm BST/GMT",
  },
  {
    name: "Rest of the World",
    id: "world",
    address: [
      "If you live in any other country,",
      "please contact us at the",
      "number below.",
    ],
    phones: [
      {
        tel: "+61 7 5528 4813",
        name: "+61-7-5528-4813",
        tagLabel: undefined,
      },
    ],
    hours: "Monday to Friday, 9am - 6pm AEST",
  },
];
const HelpAndSupportDialog: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { data: personalDetails } = usePersonalDetailsQuery();
  const officeId = personalDetails?.traveller.office_id || undefined;
  const [contact, setContact] = useState(phoneData[0]);
  const openExternalURL = useOpenExternalURL();
  const onPhoneChange = (id: string) => {
    const selectedPhone = phoneData.find((item) => item.id === id);
    if (selectedPhone) setContact(selectedPhone);
  };
  const { track } = useTrackers();
  const { windowSize, isMobile } = useResponsive();
  const bannerWidth = isMobile ? windowSize.width : 350;
  const bannerHeight = isMobile ? Math.round(0.5 * bannerWidth) : 350;

  useEffect(() => {
    if (officeId === undefined) return;
    const defaultPhone = phoneData.find((item) => item.office_di === officeId);

    if (defaultPhone) setContact(defaultPhone);
  }, [officeId]);

  const handleClickPhoneNumber = (telephoneNumber: string) => {
    track("Phone Number Clicked", {
      phone_number: telephoneNumber,
      eventId: "phone-number-clicked",
    });
  };

  const handleClickEmergencyPhoneNumber = () => {
    track("Emergency Line Clicked", { eventId: "emergency-line-clicked" });
  };

  const handleClickFAQLink = () => {
    openExternalURL(
      "https://www.globalworkandtravel.com/frequently-asked-questions"
    );
    track("FAQ Clicked", { eventId: "faq-clicked" });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Help &amp; Support
      </DialogTitle>
      <DialogContent className={styles.container}>
        <div className={styles.infoContainer}>
          <Select
            fullWidth
            value={contact.id}
            onChange={(event) => onPhoneChange(event?.target.value)}
          >
            {phoneData.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            className={styles.title}
          >
            {contact.hours}
          </Typography>
          {contact.phones.map((phone) => (
            <div key={phone.name} className={styles.telNumber}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                href={`tel:${phone.tel}`}
                component="a"
                onClick={() => handleClickPhoneNumber(phone.tel)}
              >
                {phone.name}
              </Typography>
              {Boolean(phone.tagLabel) && (
                <Tag
                  color="primary"
                  label={phone.tagLabel}
                  variant="filled"
                  size="small"
                  className={styles.telTag}
                />
              )}
            </div>
          ))}
          <Divider className={styles.divider} />
          <Typography
            variant="subtitle1"
            color="text.secondary"
            className={styles.title}
          >
            24/5 (Mon-Fri) Global Emergency line
          </Typography>
          <div className={styles.telNumber}>
            <Typography
              variant="subtitle1"
              color="text.primary"
              href="tel:+1 647 694 0188"
              component="a"
              onClick={handleClickEmergencyPhoneNumber}
            >
              +1-647-694-0188
            </Typography>
            <Tag
              color="error"
              label="Emergency"
              variant="filled"
              size="small"
              className={styles.telTag}
            />
          </div>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            className={styles.title}
          >
            Answer to your questions
          </Typography>

          <Button
            endIcon={<RedirectIcon />}
            variant="text"
            color="info"
            onClick={handleClickFAQLink}
          >
            Go to FAQs
          </Button>
        </div>
        <div className={styles.imageContainer}>
          <Image
            alt="help"
            src={`https://gwatco-res.cloudinary.com/image/upload/c_fill,g_auto,dpr_2.0,w_${bannerWidth},h_${bannerHeight},ar_2:1/gwat.gworld/assets/help.jpg`}
            objectFit="cover"
            width={bannerWidth}
            height={bannerWidth}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HelpAndSupportDialog;
