import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Result = {
  publishable: string;
  plaid: { client_id: string; public_key: string; secret: string };
};
type QueryError = {};

const useStripePublishableKeys = (
  currency: string | undefined,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/stripe/publishable-key/${currency}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, currency];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!currency) throw new Error();

      const res = await fetchWithAuth<Result>(url);

      return res;
    },
    {
      enabled: !!currency,
      ...options,
    }
  );
};
export default useStripePublishableKeys;
