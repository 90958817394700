import SvgIcon, { SvgIconProps } from "./SvgIcon";

const DropdownIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M16 9H7.99999C7.10909 9 6.66292 10.0771 7.29288 10.7071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.7071 10.7071C17.3371 10.0771 16.8909 9 16 9Z" />
    </SvgIcon>
  );
};

export default DropdownIcon;
