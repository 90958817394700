import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CalendarIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M16 2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V3H20C21.1046 3 22 3.89543 22 5V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V5C2 3.89543 2.89543 3 4 3H6V2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V3H16V2ZM20 5H4V8H20V5Z" />
      ) : (
        <path d="M16 2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V3H20C21.1046 3 22 3.89543 22 5V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V5C2 3.89543 2.89543 3 4 3H6V2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V3H16V2ZM20 10H4V20H20V10ZM20 5H4V8H20V5Z" />
      )}
    </SvgIcon>
  );
};

export default CalendarIcon;
