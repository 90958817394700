import Header, { ChatIconButton, Logo, ProfileMenu } from "@/components/Header";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useRouter } from "next/router";
import Avatar from "@/components/Avatar";
import { IconButton } from "@/components/Button";
import { Skeleton } from "@/components/Loader";
import styles from "./SocialMobileHeader.module.scss";
import { useRoutes } from "@/hooks";

const SocialMobileHeader: React.FC = () => {
  const router = useRouter();
  const { data: personalDetails, isLoading: isPersonalDetailsLoading } =
    usePersonalDetailsQuery();
  const profilePictureCloudinaryPath =
    personalDetails?.traveller?.s3_profile_pic;
  const { hasAccessToRoute } = useRoutes();
  const hasSocialAccess = hasAccessToRoute("Social");
  return (
    <Header className={styles.header}>
      <Logo />
      {hasSocialAccess ? (
        <IconButton
          className={styles.stickToEnd}
          onClick={() => router.push("/social/profile")}
        >
          {isPersonalDetailsLoading ? (
            <Skeleton variant="circular" width="32px" height="32px" />
          ) : (
            <Avatar cloudinaryPath={profilePictureCloudinaryPath} size="s" />
          )}
        </IconButton>
      ) : (
        <div className={styles.stickToEnd} />
      )}

      <ChatIconButton />
      <ProfileMenu />
    </Header>
  );
};
export default SocialMobileHeader;
