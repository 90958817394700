/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import Alert from "@/components/Alert";
import { VariantType } from "notistack";
import styles from "./SnackbarContent.module.scss";

const Snackbar = forwardRef<
  HTMLDivElement,
  {
    message: string | undefined | React.ReactNode;
    id: number | string;
    variant?: VariantType;
  }
>(({ message, id, variant }, ref) => {
  return (
    <div ref={ref} id={`${id}`}>
      <Alert
        elevation={2}
        variant="standard"
        color={variant === "default" ? "info" : variant}
        className={styles.alert}
      >
        {message}
      </Alert>
    </div>
  );
});

export default Snackbar;
