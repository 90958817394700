import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import useGroupQuery from "./useGroupQuery";
import { Post } from "@/fetch/social";

const PAGE_SIZE = 30;

type TravellerPostsVariables = {
  cid: number | undefined;
};

type TravellerPostsParam = {
  pageSize: number;
  pageNum?: number;
};

type TravellerPostsQueryResult = {
  results?: Array<Post>;
  totalCount?: number;
};

type TravellerPostsFetchResult = {
  data: TravellerPostsQueryResult;
  statusCode: number;
  success: boolean | string;
};

export type InfinityResult = {
  pages: Array<TravellerPostsQueryResult>;
  pageParam: any[];
};
type QueryError = {};

const useTravellerPostsQuery = (
  variables: TravellerPostsVariables,
  params: TravellerPostsParam = { pageSize: PAGE_SIZE, pageNum: 1 },
  options?: UseInfiniteQueryOptions<
    TravellerPostsQueryResult,
    any,
    TravellerPostsQueryResult,
    TravellerPostsQueryResult,
    QueryKey
  >
) => {
  const { data: group, isLoading: isGroupLoading } = useGroupQuery();
  const { fetchWithAuth } = useFetchWithAuth();
  const groupId = group?.id;
  const cid = variables?.cid;

  const url = `${GATEWAY_URL}/traveller/${cid}/posts/group/${groupId}/`;
  const cacheKey = [url];

  const queryResult = useInfiniteQuery<TravellerPostsQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!groupId || !cid) throw new Error("Group id or cid not found");

      const result = await fetchWithAuth<TravellerPostsFetchResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
      return result.data;
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(groupId && cid),
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.totalCount || 0) >= params.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult, isLoading: queryResult.isLoading || isGroupLoading };
};
export default useTravellerPostsQuery;
