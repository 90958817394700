import SvgIcon, { SvgIconProps } from "./SvgIcon";

const RotateLeftIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M14.3294 3.30667C19.1306 4.59315 21.9798 9.52818 20.6934 14.3294C19.4069 19.1306 14.4718 21.9798 9.67065 20.6933C9.13718 20.5504 8.8206 20.0021 8.96354 19.4686C9.10649 18.9351 9.65482 18.6185 10.1883 18.7615C13.9226 19.7621 17.7609 17.546 18.7615 13.8117C19.7621 10.0775 17.546 6.23912 13.8118 5.23853C10.3812 4.31932 6.86284 6.1148 5.53743 9.30813L8.62863 8.07153C9.10197 7.88219 9.63426 8.08015 9.87468 8.51504L9.9285 8.62862C10.1178 9.10195 9.91988 9.63424 9.48499 9.87466L9.37141 9.92848L4.37141 11.9285C3.89807 12.1178 3.36578 11.9199 3.12537 11.485L3.07155 11.3714L1.07155 6.3714C0.866432 5.85861 1.11585 5.27664 1.62863 5.07153C2.10197 4.88219 2.63426 5.08015 2.87468 5.51504L2.9285 5.62862L3.90323 8.06658C5.74802 4.25842 10.0909 2.17096 14.3294 3.30667Z" />
    </SvgIcon>
  );
};

export default RotateLeftIcon;
