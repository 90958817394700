import SvgIcon, { SvgIconProps } from "./SvgIcon";

const BellIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.0076 18.98C15.4514 19.3086 15.5448 19.9349 15.2161 20.3787C14.4671 21.3902 13.2834 22 12 22C10.7166 22 9.53293 21.3902 8.7839 20.3787C8.45522 19.9349 8.54858 19.3086 8.99241 18.98C9.43625 18.6513 10.0625 18.7446 10.3912 19.1885C10.7669 19.6959 11.3572 20 12 20C12.6428 20 13.2331 19.6959 13.6088 19.1885C13.9375 18.7446 14.5637 18.6513 15.0076 18.98ZM12 3C15.7854 3 18.8691 6.00478 18.9959 9.75935L19 10V13.697L20.832 16.4453C21.2318 17.0449 20.883 17.8281 20.2244 17.9755L20.1118 17.9941L20 18H4C3.23934 18 2.77102 17.1926 3.11085 16.5417L3.16795 16.4453L5 13.696V10C5 6.29509 7.87828 3.26244 11.5207 3.01615L11.7594 3.00406L12 3ZM12 5C9.31124 5 7.11818 7.12231 7.00462 9.78311L7 10V14C7 14.1579 6.9626 14.3131 6.89157 14.4529L6.83204 14.5547L5.86799 16H18.131L17.168 14.5547C17.1022 14.4561 17.0549 14.3472 17.0276 14.2332L17.007 14.1177L17 14V10C17 7.23858 14.7614 5 12 5Z" />
    </SvgIcon>
  );
};

export default BellIcon;
