import { useFlagsmith } from "flagsmith/react";

const useFeatureTraits = () => {
  const flagsmith = useFlagsmith();
  const bannerText = (flagsmith.getTrait("banner_text") || "") as string;
  const bannerAction = (flagsmith.getTrait("banner_action") || "") as string;

  const isLoading = Boolean(flagsmith.getAllTraits() === null);

  return {
    traits: {
      bannerText,
      bannerAction,
    },
    isLoading,
  };
};

export default useFeatureTraits;
