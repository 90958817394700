import { ReactNode, forwardRef } from "react";
import MuiPaper from "@mui/material/Paper";
import cx from "classnames";
import styles from "./Card.module.scss";

type Props = {
  className?: string;
  outlined?: boolean;
  style?: React.CSSProperties;
  id?: string;
  children?: ReactNode;
};

const Card = forwardRef<HTMLDivElement, Props>(function CardComponent(
  { className, children, outlined = false, style, id },
  ref
) {
  return (
    <MuiPaper
      id={id}
      component="div"
      elevation={outlined ? 0 : 1}
      variant={outlined ? "outlined" : "elevation"}
      className={cx("card-root", styles.root, className)}
      style={style}
      ref={ref}
    >
      {children}
    </MuiPaper>
  );
});

export default Card;
