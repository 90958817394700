import { useRecentLikesQuery } from "@/fetch/social";
import Dialog, { DialogContent, DialogTitle } from "@/components/Dialog";
import { Skeleton } from "@/components/Loader";
import Typography from "@/components/Typography";
import styles from "./PostLikesDialog.module.scss";
import Grid from "@/components/Grid";
import Divider from "@/components/Divider";
import cx from "classnames";
import Button from "@/components/Button";
import Emoji from "@/components/Emoji";
import { useCurrentTrip, useDebounce, useDialog, useResponsive } from "@/hooks";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import TravellerNameLink from "./TravellerNameLink";
import { useTwilioConversation } from "@/components/Chat/hooks";
import { SearchTextField } from "../TextField";
import { EmptySearchResult } from "../EmptyList";
import DotSeparator from "../DotSeparator";
import theme from "@/theme/theme";
import compact from "lodash/compact";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
import SocialAvatar from "./SocialAvatar";
import { Like } from "@/fetch/social/useRecentLikesQuery";

const LikeItemLoader: React.FC = () => {
  const { isMobile } = useResponsive();
  const buttonLoader = (
    <Skeleton variant="rectangular" height="46px" className={styles.sayHi} />
  );
  return (
    <Grid item className={styles.loaderContainer} xs={12}>
      <div className={styles.likeItemRoot}>
        <Skeleton variant="circular" className={styles.avatarLoader} />
        <div className={styles.likeItemContent}>
          <div className={cx(styles.likeInfoTitle)}>
            <Skeleton width={180} />
          </div>
          <Skeleton width={100} className={styles.country} />

          {isMobile && buttonLoader}
        </div>
        {!isMobile && buttonLoader}
      </div>
      <Divider className={styles.divider} />
    </Grid>
  );
};

const LikeItem: React.FC<{
  like: Like;
  canChat?: boolean;
  onClose: () => void;
}> = ({ like, canChat = true, onClose }) => {
  const { isMobile } = useResponsive();
  const { openChatPopup } = useTwilioConversation();
  const [isOpeningChat, setIsOpeningChat] = useState(false);
  const { onClose: onDialogClose } = useDialog();
  const isPastTraveller = like.status === TripStatus.Complete;
  const isAdmin = Boolean(like.is_admin);
  const adminTitle = like.admin_title || null;

  const openChat = async (travellerCid?: number) => {
    try {
      setIsOpeningChat(true);
      await openChatPopup(travellerCid, "Post Likes Dialog");
      if (!isMobile) {
        onDialogClose();
        onClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpeningChat(false);
    }
  };
  const nickname = like.nickname || "";
  const country = like.country || "";
  // const division = like.division || "";

  const SayHiButton = (
    <>
      <Button
        className={styles.sayHi}
        variant="outlined"
        onClick={() => openChat(like?.cid)}
        disabled={isOpeningChat}
      >
        {isOpeningChat ? (
          "Initializing..."
        ) : (
          <>
            <Emoji shortcodes=":wave::skin-tone-2:" size="1.2em" />
            Say hi
          </>
        )}
      </Button>
    </>
  );

  return (
    <>
      <div className={styles.likeItemRoot}>
        <SocialAvatar
          isAdmin={isAdmin}
          adminTitle={adminTitle}
          cloudinaryPath={like.s3_profile_pic}
          size={isMobile ? "m" : "l"}
          className={styles.avatar}
        />
        <div className={styles.likeItemContent}>
          <TravellerNameLink
            variant="h6"
            cid={like.cid}
            nickname={nickname}
            className={styles.likeInfoTitle}
          >
            {!isMobile && !isAdmin && (
              <Typography
                variant="body2"
                color={
                  isPastTraveller
                    ? theme.palette.warning.main
                    : "text.secondary"
                }
                className={styles.inlineDivision}
              >
                <DotSeparator color="info" size="0.25rem" />
                {isPastTraveller ? "Past Traveller" : like.division}
              </Typography>
            )}
          </TravellerNameLink>
          {isMobile && !isAdmin && (
            <Typography
              variant="body2"
              color={
                isPastTraveller ? theme.palette.warning.main : "text.secondary"
              }
              className={styles.division}
            >
              {isPastTraveller ? "Past Traveller" : like.division}
            </Typography>
          )}
          <Typography variant="body2" className={styles.country}>
            {country || ""}
          </Typography>

          {isMobile && canChat && SayHiButton}
        </div>
        {!isMobile && canChat && SayHiButton}
      </div>

      <Divider className={styles.divider} />
    </>
  );
};

const PostLikesDialog: React.FC<{ onClose: () => void; postId: number }> = ({
  onClose,
  postId,
}) => {
  const { currentTrip } = useCurrentTrip();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
  const isFilterSet = debouncedSearchTerm !== "";

  const {
    data: likesData,
    isLoading: isLikesLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useRecentLikesQuery(
    postId,
    isFilterSet
      ? {
          pageNum: 1,
          pageSize: 30,
          searchParam: debouncedSearchTerm,
        }
      : undefined
  );

  const { ref: loaderContainerRef, inView: isLoaderInView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!isLoaderInView || !hasNextPage || isLikesLoading || isFetchingNextPage)
      return;

    fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoaderInView,
    isLikesLoading,
  ]);

  const likes = compact(
    likesData?.pages?.map((page) => page?.results)?.flat(2)
  );
  const total_likes = likesData?.pages?.[0]?.totalCount || 0;
  const cid = currentTrip?.cid;
  const hasLikes = Boolean(total_likes > 0);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle hasCloseButton onClose={onClose}>
        Likes
      </DialogTitle>
      <SearchTextField
        className={styles.searchBox}
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
      />
      <DialogContent className={styles.dialogContentRoot} dividers>
        <Grid container rowGap="24px">
          {!hasLikes && !isLikesLoading && (
            <Grid item xs={12}>
              <EmptySearchResult />
            </Grid>
          )}
          {!hasLikes &&
            isLikesLoading &&
            Array(3)
              .fill(null)
              .map((item, index) => <LikeItemLoader key={index} />)}
          {likes?.map((like) => (
            <Grid item xs={12} key={like?.cid}>
              <LikeItem
                onClose={onClose}
                like={like}
                canChat={like?.cid !== cid}
              />
            </Grid>
          ))}
          {hasLikes && hasNextPage && (
            <Grid item xs={12}>
              <div ref={loaderContainerRef}>
                {isFetchingNextPage && <LikeItemLoader />}
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PostLikesDialog;
