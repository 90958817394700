import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type FinalizePostMutationResult = Partial<{
  cid: number;
  content: string;
  group_id: number;
  id: number;
  status: string;
}>;

export type FinalizePostResult = {
  data: FinalizePostMutationResult;
  statusCode: number;
  success: boolean;
};

export type FinalizePostVariables = { id: number; content?: string };

type QueryError = {};

const useFinalizePostMutation = (
  options?: UseMutationOptions<
    FinalizePostMutationResult,
    QueryError,
    FinalizePostVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      FinalizePostMutationResult,
      QueryError,
      FinalizePostVariables
    >(
      async ({ id: postId }) => {
        if (!postId) throw new Error("Post id not found");
        const url = `${GATEWAY_URL}/post/finalize/${postId}`;
        const result = await fetchWithAuth<FinalizePostResult>(url, {
          method: "POST",
        });
        return result.data;
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};
export default useFinalizePostMutation;
