import { FC } from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import Tag from "@/components/Tag";
import Typography from "@/components/Typography";
import { TransactionItem } from "@/fetch/rewards";
import styles from "./GlobalDollarTransactionCard.module.scss";

type Props = {
  transaction: TransactionItem;
};

const GlobalDollarTransactionCard: FC<Props> = ({ transaction }) => {
  const isRedeemed = transaction.amount < 0;

  const transactionDate = dayjs(transaction._ca * 1000).format("DD MMM, YYYY");

  return (
    <div
      className={cx(styles.container, {
        [styles.redeemedContainer]: isRedeemed,
      })}
    >
      <div className={styles.leftColumn}>
        <Typography variant="h6">{transaction.title}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className={styles.description}
        >
          {transaction.message}
        </Typography>
        <div className={styles.globalDollarContainer}>
          <GlobalDollarColoredIcon className={styles.dollarIcon} />
          <Typography variant="h6">{`x${Math.abs(
            transaction.amount
          )}`}</Typography>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <Typography
          variant="body2"
          color="text.secondary"
          className={styles.date}
        >
          {transactionDate}
        </Typography>
        <Tag
          color={isRedeemed ? "error" : "success"}
          label={isRedeemed ? "Spent" : "Earned"}
          variant="filled"
          size="small"
        />
      </div>
    </div>
  );
};

export default GlobalDollarTransactionCard;
