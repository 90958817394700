import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type LeaveTwilioConversationMutationResult = {
  message?: string;
};
export type LeaveTwilioConversationVariables = {
  conversationSid: string;
  lastReadMessageIndex?: number;
};
export type LeaveTwilioConversationFetchResult = {
  data: LeaveTwilioConversationMutationResult;
  statusCode: number;
  success: boolean;
};
type QueryError = {};

const useLeaveTwilioConversationMutation = (
  options?: UseMutationOptions<
    LeaveTwilioConversationMutationResult,
    QueryError,
    LeaveTwilioConversationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/chat/remove-participant`;

  return {
    ...useMutation<
      LeaveTwilioConversationMutationResult,
      QueryError,
      LeaveTwilioConversationVariables
    >(async ({ conversationSid, lastReadMessageIndex }) => {
      if (!cid) throw new Error(`Could not find cid:${cid}`);
      if (!conversationSid)
        throw new Error(`Could not find conversationSid:${conversationSid}`);

      const result = await fetchWithAuth<LeaveTwilioConversationFetchResult>(
        url,
        {
          method: "POST",
          body: {
            cid,
            conversation_sid: conversationSid,
            last_read_message_index: Number.isInteger(lastReadMessageIndex)
              ? lastReadMessageIndex
              : undefined,
          },
        }
      );
      return result.data;
    }, options),
    cancel,
  };
};
export default useLeaveTwilioConversationMutation;
