import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

type UpdateAddressesVariables = {
  data: Partial<{
    street: string | null;
    suburb: string | null;
    post_code: string | null;
    state: string | null;
    country: string | null;
    id?: number;
  }>;
  correlationId: string;
};

type UpdateAddressesResult = {
  success: boolean;
  result: { changedRows: number };
};

type QueryError = {};

const useUpdateAddressMutation = (
  options?: UseMutationOptions<
    UpdateAddressesResult,
    QueryError,
    UpdateAddressesVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<UpdateAddressesResult, QueryError, UpdateAddressesVariables>(
      async (input: UpdateAddressesVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/address/${data.id}`;
        delete data.id;
        return fetchWithAuth<UpdateAddressesResult>(url, {
          method: "PATCH",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateAddressMutation;
