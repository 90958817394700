const useFormatCurrency = () => {
  const format = (
    number: number | undefined,
    currency: string = "AUD",
    symbol?: string
  ) => {
    if (symbol)
      return (number || 0) < 0
        ? `- ${symbol}${Math.abs(number || 0)?.toLocaleString("en") || 0}`
        : `${symbol}${number?.toLocaleString("en") || 0}`;

    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: currency,
      currencyDisplay: "narrowSymbol",
      maximumFractionDigits: 0,
    }).format(number || 0);
  };

  return { format };
};

export default useFormatCurrency;
