/* eslint-disable @next/next/no-html-link-for-pages */
import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField from "@/components/TextField";
import {
  FormActions,
  getRemainingCharactersText,
} from "@/components/FormController";
import { UploaderFile, ProfileUploader } from "@/components/Uploader";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
} from "@/components/PersonalDetailsForms/hooks";
import { ProfileInfoFormType } from "@/components/PersonalDetailsForms";
import Typography from "../Typography";
import Link from "next/link";
import Alert from "../Alert";
import { useRouter } from "next/router";
import { useRoutes } from "@/hooks";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
}

function ProfileInfoFormTemplateWithRef<T extends ProfileInfoFormType>(
  { isFormSubmitting, formik, isFormDataLoading }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const handleSelectProfilePicture = async (
    inputFiles: Array<UploaderFile>
  ) => {
    const currentFile = inputFiles?.[0];
    formik.setValues((values) => ({
      ...values,
      profileInfoProfilePictureFile: currentFile,
    }));
  };
  const router = useRouter();
  const { hasAccessToRoute } = useRoutes();
  const isResumeAvailable = hasAccessToRoute("Experience", "Resume/CV");

  return (
    <div ref={ref}>
      {isResumeAvailable && (
        <Alert variant="standard" color="info" sx={{ mt: "16px" }}>
          This is for your profile and social profile. You will need to upload
          another professional photo in the{" "}
          <a
            onClick={() => router.push("/experience/resume")}
            className={styles.resumeLink}
          >
            resume section
          </a>
          .
        </Alert>
      )}
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} className={styles.profileUploader}>
          <ProfileUploader
            isLoading={isFormDataLoading}
            onChangeFile={handleSelectProfilePicture}
            disabled={isFormSubmitting}
            cloudinaryPath={formik.values.profileInfoProfilePictureUrl}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="profileInfoNickname"
              name="profileInfoNickname"
              label="Nickname"
              value={formik.values.profileInfoNickname}
              onChange={formik.handleChange}
              error={
                formik.touched.profileInfoNickname &&
                Boolean(formik.errors.profileInfoNickname)
              }
              helperText={
                formik.touched.profileInfoNickname &&
                formik.errors.profileInfoNickname
              }
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <>
              <TextField
                disabled={isFormSubmitting}
                fullWidth
                multiline
                maxRows={4}
                inputProps={{ maxLength: 120 }}
                id="profileInfoBio"
                name="profileInfoBio"
                label="About You"
                value={formik.values.profileInfoBio}
                onChange={formik.handleChange}
                error={
                  formik.touched.profileInfoBio &&
                  Boolean(formik.errors.profileInfoBio)
                }
                helperText={
                  formik.touched.profileInfoBio && formik.errors.profileInfoBio
                }
              />
              <Typography
                variant="body1"
                align="right"
                className={styles.charactersHelper}
              >
                {getRemainingCharactersText(formik.values.profileInfoBio, 120)}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const ProfileInfoForm: React.FC<{
  onSubmitted?: () => Promise<void>;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  hasAccountSettingLink?: boolean;
  correlationId?: string;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  hasAccountSettingLink = false,
  correlationId = "",
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();

  const { profileInfoValidationSchema } = usePersonalDetailsFormValidation();

  const {
    submitProfileInfoForm,
    isUpdatingProfileInfo,
    profilePictureUploadProgress,
    isProfilePictureUploading,
  } = usePersonalDetailsFormSubmission();

  const { profileInfoInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting =
    isUpdatingProfileInfo || isProfilePictureUploading || isRefetchingForm;

  const onSubmit = async (values: ProfileInfoFormType) => {
    try {
      if (formik.dirty) {
        await submitProfileInfoForm(values, correlationId);
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);
      await onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const initialValues = profileInfoInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: profileInfoValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({
      values: initialValues,
      submitCount: formik.submitCount, // to indicate the "saved" state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <ProfileInfoFormTemplate
        formik={formik}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
      />
      {hasAccountSettingLink && (
        <Link href="/account/general">Account Settings</Link>
      )}
      <FormActions
        isDialogAction
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
          loadingLabel: isProfilePictureUploading
            ? `Uploading ${profilePictureUploadProgress || 0}%`
            : undefined,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const ProfileInfoFormTemplate = forwardRef(ProfileInfoFormTemplateWithRef);

export { ProfileInfoFormTemplate };
export default ProfileInfoForm;
