import { useEffect, useState } from "react";
import MuiPaper from "@mui/material/Paper";
import MuiBottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import styles from "./BottomNavigation.module.scss";
import { useRouter } from "next/router";
import { Route, RouteFeature, useLock, useRoutes } from "@/hooks";
import Badge from "../Badge";
import { useTwilioConversation } from "@/components/Chat/hooks";
import Tooltip from "@/components/Tooltip";
import { useNotificationStatisticsQuery } from "@/fetch/notifications";
import { object } from "yup/lib/locale";

const BottomNavigation: React.FC = () => {
  const { totalUnreadConversationCount } = useTwilioConversation();
  const [tab, setTab] = useState<number | boolean>(0);
  const { isAllowedToNavigate } = useLock();
  const router = useRouter();
  const { routes } = useRoutes();
  const routesToDisplay = routes.filter((route) =>
    route.features?.includes(RouteFeature.MobileTab)
  );
  const { getMatchedRoute } = useRoutes();
  const initialTab = getMatchedRoute(routesToDisplay).index;
  const { data: notificationStatistics } = useNotificationStatisticsQuery();
  const unseenNotificationsCount = notificationStatistics?.unseen || 0;

  useEffect(() => {
    if (initialTab < 0) {
      setTab(false);
      return;
    }
    setTab(initialTab);
  }, [initialTab]);

  const navigate = (path: string) => {
    router.push(path);
  };

  const badgeConfig = (path: string) => {
    // if (path.includes("social")) {
    //   return {
    //     count: totalUnreadConversationCount,
    //     invisible: !Boolean(totalUnreadConversationCount),
    //   };
    // }
    if (path.includes("notifications")) {
      return {
        count: unseenNotificationsCount,
        invisible: unseenNotificationsCount === 0,
      };
    }
    return {
      count: 0,
      invisible: true,
    };
  };

  return (
    <MuiPaper elevation={3} className={styles.root}>
      <MuiBottomNavigation
        showLabels
        value={tab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
      >
        {isAllowedToNavigate
          ? routesToDisplay.map(
              ({ path, name, icon: Icon, disabled, tooltipMessage }) => (
                <Tooltip
                  key={path}
                  title={tooltipMessage || ""}
                  arrow
                  enterTouchDelay={0}
                >
                  <MuiBottomNavigationAction
                    label={name}
                    icon={
                      Icon && (
                        <Badge
                          badgeContent={badgeConfig(path).count}
                          color="error"
                          variant="dot"
                          invisible={badgeConfig(path).invisible}
                        >
                          <Icon variant="outlined" />
                        </Badge>
                      )
                    }
                    onClick={() => navigate(path)}
                    disabled={disabled}
                  />
                </Tooltip>
              )
            )
          : null}
      </MuiBottomNavigation>
    </MuiPaper>
  );
};

export default BottomNavigation;
