import { useMutation, UseMutationOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type CreateSubmissionVariables = Partial<{
  experience?: string;
  media_description: string;
  rating: string;
  rewards_id: number;
  tid: number;
  type: number;
  cid: number;
}>;

type CreateSubmissionResult = {
  result: {
    id: number;
    affectedRows: number;
  };
  success: boolean;
};

type QueryError = {};

const useCreateSubmissionMutation = (
  options?: UseMutationOptions<
    CreateSubmissionResult,
    QueryError,
    CreateSubmissionVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      CreateSubmissionResult,
      QueryError,
      CreateSubmissionVariables
    >(
      async (InputObj: CreateSubmissionVariables) => {
        const url = `${REWARD_ENDPOINT}/submission`;

        return fetchWithAuth<CreateSubmissionResult>(url, {
          method: "POST",
          body: InputObj,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateSubmissionMutation;
