import Typography from "@/components/Typography";
import styles from "./FormController.module.scss";
import { CircularProgress } from "@/components/Loader";
import { IconButton } from "@/components/Button";
import { MultiOptionIcon, InfoIcon } from "@/components/Icon";
import theme from "@/theme/theme";
import { useResponsive } from "@/hooks";
import { MouseEventHandler } from "react";
import cx from "classnames";

const FormTitle: React.FC<{
  title?: string;
  subtitle?: string | React.ReactNode;
  step?: number;
  onReorderButtonClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  isReorderButtonDisabled?: boolean;
  totalSteps?: number;
  className?: string;
  id?: string;
  isFirstInList?: boolean;
  stepPreviewType?: "percentage" | "fractional";
  onClickInfo?: () => void;
}> = ({
  title,
  subtitle,
  step,
  onReorderButtonClick,
  isReorderButtonDisabled = false,
  totalSteps = 7,
  className,
  id,
  isFirstInList = false,
  stepPreviewType = "percentage",
  onClickInfo,
}) => {
  const { isMobile } = useResponsive();

  return (
    <div
      className={cx(
        styles.formTitleBar,
        {
          [styles.firstInList]: isFirstInList,
        },
        className
      )}
      id={id}
    >
      {isMobile && step && (
        <CircularProgress
          className={styles.circularProgress}
          size="xl"
          isDark
          variant="determinate"
          value={step ? (100 / totalSteps) * (step - 1) : 0}
          hasProgressText
          progressText={
            stepPreviewType === "fractional"
              ? `${step}/${totalSteps}`
              : undefined
          }
        />
      )}
      <div className={styles.formTitleText}>
        <div className={styles.formTitleContainer}>
          <Typography variant="h6">{title} </Typography>
          {isMobile && Boolean(onClickInfo) && (
            <InfoIcon
              className={styles.infoIcon}
              onClick={onClickInfo}
              variant="outlined"
            />
          )}
        </div>
        {Boolean(subtitle) && (
          <Typography variant="subtitle1" color={theme.palette.text.secondary}>
            {subtitle}
          </Typography>
        )}
      </div>
      {Boolean(onReorderButtonClick) && (
        <IconButton
          className={styles.reorderDialogOpener}
          onClick={onReorderButtonClick}
          disabled={isReorderButtonDisabled}
        >
          <MultiOptionIcon />
        </IconButton>
      )}
    </div>
  );
};

export default FormTitle;
