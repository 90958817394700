import styles from "./TimelineFeed.module.scss";
import Grid from "@/components/Grid";
import { useRecentPostsQuery } from "@/fetch/social";
import { useInView } from "react-intersection-observer";
import { PostCard } from "@/components/Social";
import { Skeleton } from "@/components/Loader";
import { useEffect } from "react";
import compact from "lodash/compact";
import Typography from "@/components/Typography";
import { EmptySocialPost } from "@/components/EmptyList";
import { useDialog } from "@/hooks";
import PostDialog from "./PostDialog";
import MeetOtherTravellerCard from "./MeetOtherTravellerCard";
import InstagramPostsCard from "./InstagramPostsCard";

const postLoader = <Skeleton variant="rectangular" height="180px" />;

const TimelineFeed: React.FC<{ variant: "feed" | "highlight" }> = ({
  variant,
}) => {
  const isFeed = variant === "feed";
  const params = isFeed
    ? {
        pinned: false,
        adminPosts: false,
      }
    : {
        adminPosts: true,
      };
  const {
    data: recentPostsData,
    isFetchingNextPage,
    isLoading: isRecentPostsLoading,
    fetchNextPage,
    hasNextPage,
  } = useRecentPostsQuery(params);
  const { data: pinnedPostsData, isLoading: isPinnedPostsLoading } =
    useRecentPostsQuery({ pinned: true, pageSize: 1, pageNum: 1 });
  const { ref: loaderContainerRef, inView: isLoaderInView } = useInView({
    threshold: 0,
  });
  const { isOpen: isPostDialogOpen, query } = useDialog("post");

  useEffect(() => {
    if (
      !isLoaderInView ||
      !hasNextPage ||
      isRecentPostsLoading ||
      isFetchingNextPage
    )
      return;

    fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoaderInView,
    isRecentPostsLoading,
  ]);

  const posts = compact(
    recentPostsData?.pages?.map((page) => page?.results)?.flat(2)
  );
  const hasPosts = Boolean((recentPostsData?.pages?.[0]?.totalCount || 0) > 0);

  const pinnedPost = pinnedPostsData?.pages?.[0]?.results?.[0];

  return (
    <Grid
      container
      spacing={{ md: "24px", xs: "16px" }}
      className={styles.gridContainer}
    >
      {!hasPosts && !isRecentPostsLoading && (
        <Grid item xs={12}>
          <EmptySocialPost subtitle="Share your first post by selecting the blue button from the left menu" />
        </Grid>
      )}

      {((!hasPosts && isRecentPostsLoading) ||
        (!pinnedPost && isPinnedPostsLoading)) && (
        <Grid item xs={12}>
          {postLoader}
        </Grid>
      )}

      {pinnedPost && !isRecentPostsLoading && isFeed && (
        <Grid item xs={12}>
          <PostCard post={pinnedPost} isPinned />
        </Grid>
      )}

      {isFeed && pinnedPost && (
        <Grid item xs={12}>
          <MeetOtherTravellerCard />
        </Grid>
      )}

      {posts?.map((post, idx) => (
        <>
          <Grid item xs={12} key={post?.id}>
            <PostCard post={post} />
          </Grid>
          {/* {isFeed && pinnedPost && idx === 0 && (
            <Grid item xs={12}>
              <InstagramPostsCard />
            </Grid>
          )} */}
          {isFeed && !pinnedPost && idx === 0 && (
            <Grid item xs={12}>
              <MeetOtherTravellerCard />
            </Grid>
          )}
          {/* {isFeed && !pinnedPost && idx === 1 && (
            <Grid item xs={12}>
              <InstagramPostsCard />
            </Grid>
          )} */}
        </>
      ))}

      {hasPosts && (
        <Grid item xs={12}>
          {hasNextPage ? (
            <div ref={loaderContainerRef}>
              {isFetchingNextPage && postLoader}
            </div>
          ) : (
            <Typography
              color="text.secondary"
              textAlign="center"
              className={styles.listInfo}
            >
              You&apos;ve seen it all!
            </Typography>
          )}
        </Grid>
      )}

      {isPostDialogOpen && query.id && <PostDialog />}
    </Grid>
  );
};

export default TimelineFeed;
