import SvgIcon, { SvgIconProps } from "./SvgIcon";

const NewIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M10 4C10.5523 4 11 4.44772 11 5C11 5.55228 10.5523 6 10 6H5V20H19V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4H10ZM18.762 2.47081L20.2882 3.76424C21.1312 4.47857 21.2411 5.74779 20.5338 6.5991L12.5685 16.1861C12.3336 16.4689 12.0261 16.681 11.6798 16.7991L8.31498 17.9468C7.79372 18.1246 7.22844 17.842 7.05238 17.3156C7.00146 17.1633 6.9874 17.001 7.01136 16.8421L7.54626 13.2962C7.60132 12.9312 7.75471 12.5886 7.98967 12.3058L15.955 2.71883C16.6623 1.86752 17.919 1.75648 18.762 2.47081ZM14.9199 7.09514L9.51595 13.5992L9.22828 15.5107L11.0422 14.8927L16.4461 8.38856L14.9199 7.09514ZM17.4813 4.01226L16.2006 5.5537L17.7268 6.84712L19.0075 5.30568L17.4813 4.01226Z" />
    </SvgIcon>
  );
};

export default NewIcon;
