import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FolderIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M9.17157 3C9.70201 3 10.2107 3.21071 10.5858 3.58578L12.7071 5.70711C12.8946 5.89464 13.149 6 13.4142 6H21C22.1046 6 23 6.89543 23 8V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3H9.17157Z" />
      ) : (
        <path d="M9.17157 3C9.70201 3 10.2107 3.21071 10.5858 3.58579L12.7071 5.70711C12.8946 5.89464 13.149 6 13.4142 6H21C22.1046 6 23 6.89543 23 8V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3H9.17157ZM9.17157 5H3V19H21V8H13.4142C12.6849 8 11.9829 7.73441 11.4376 7.2568L11.2929 7.12132L9.17157 5Z" />
      )}
    </SvgIcon>
  );
};

export default FolderIcon;
