import { useState } from "react";
import { useQueryClient } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useCreateMediaBatchMutation from "./useCreateMediaBatchMutation";
import useCreateSubmissionMutation, {
  CreateSubmissionVariables,
} from "./useCreateSubmissionMutation";
import useUpdateSubmissionMutation from "./useUpdateSubmissionMutation";
import useUpdateMediaMutation from "./useUpdateMediaMutation";
import useAxiosWithAuth from "@/fetch/axiosWithAuth";
import { useCurrentTrip } from "@/hooks";

interface FileInput extends File {
  preview?: string;
}
type SubmissionInput = {
  files: Array<FileInput>;
  submissionInput: CreateSubmissionVariables;
};

const useCreateSubmissionHandler = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { currentTrip } = useCurrentTrip();
  const queryClient = useQueryClient();
  const { axiosWithAuth, cancel, progress } = useAxiosWithAuth();
  const {
    mutateAsync: createMediaBatch,
    isLoading: isCreatingMedias,
    cancel: cancelCreateMedia,
  } = useCreateMediaBatchMutation();
  const {
    mutateAsync: createSubmission,
    isLoading: isCreatingSubmission,
    cancel: cancelCreateSubmission,
  } = useCreateSubmissionMutation();
  const {
    mutateAsync: updateSubmission,
    isLoading: isFinalizing,
    cancel: cancelUpdateSubmission,
  } = useUpdateSubmissionMutation();

  const {
    mutateAsync: updateMedia,
    isLoading: isFinalizingMedia,
    cancel: cancelUpdateMedia,
  } = useUpdateMediaMutation();

  const submissionCacheKey = [
    `${REWARD_ENDPOINT}/submission/trip/${currentTrip?.id}`,
  ];

  const handleCreateSubmission = async (inputSubmission: SubmissionInput) => {
    try {
      const { files, submissionInput } = inputSubmission;
      const submissionResponse = await createSubmission(submissionInput);
      const submissionId = submissionResponse.result.id;
      const medias = files.map((eachFile) => ({
        file_name: eachFile.name,
        mime: eachFile.type,
        size: eachFile.size,
        is_profile: 0,
        is_feature: 0,
      }));
      const mediasResponse = await createMediaBatch({ medias, submissionId });
      setIsUploading(true);
      const mediaPromises = mediasResponse.result.map(
        async (eachMedia, idx) => {
          const { fields } = eachMedia.preSignedPostUrl;
          const form = new FormData();
          Object.keys(fields).forEach((key: string) => {
            form.append(key, fields[`${key}`]);
          });
          form.append("file", files[idx]);

          const { url } = eachMedia.preSignedPostUrl;
          const mediaId = eachMedia.media.id;
          try {
            await axiosWithAuth(url, {
              method: "post",
              data: form,
            });
            return updateMedia({ mediaId, upload_status: "FINAL" });
          } catch (error) {
            throw error;
          }
        }
      );
      await Promise.all(mediaPromises);
      setIsUploading(false);
      const updateResponse = await updateSubmission({
        submissionId,
        upload_status: "FINAL",
      });
      queryClient.invalidateQueries(submissionCacheKey);
      return updateResponse;
    } catch (error) {
      throw new Error("Network response was not ok");
    } finally {
      setIsUploading(false);
    }
  };

  const cancelCreatingAction = () => {
    cancelCreateMedia();
    cancelCreateSubmission();
    cancelUpdateSubmission();
    cancelCreateMedia();
  };

  return {
    handleCreateSubmission,
    isCreatingMedias,
    isCreatingSubmission,
    isFinalizing,
    isUploading,
    cancelCreatingAction,
  };
};

export default useCreateSubmissionHandler;
