import { useRouter } from "next/router";
import { ClickableCard } from "@/components/Card";
import { GlobalDollarColoredIcon, PlusIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { Skeleton } from "@/components/Loader";
import { useGlobalDollarBalanceQuery } from "@/fetch/rewards";
import styles from "./Header.module.scss";

const RewardMenu: React.FC = () => {
  const { push } = useRouter();

  const {
    data: globalDollarBalanceData,
    isLoading: isLoadingGlobalDollarBalance,
  } = useGlobalDollarBalanceQuery();

  const balance = globalDollarBalanceData?.result.balance || "0";

  const goToReward = () => {
    push("/rewards?tab=reward-center");
  };

  return (
    <>
      {isLoadingGlobalDollarBalance ? (
        <Skeleton
          className={styles.rewardMenuLoading}
          width="96px"
          height="36px"
          variant="rectangular"
        />
      ) : (
        <ClickableCard
          outlined
          className={styles.rewardMenu}
          onClick={goToReward}
        >
          <GlobalDollarColoredIcon />
          <Typography variant="button" align="left" className={styles.text}>
            {balance}
          </Typography>
          <PlusIcon />
        </ClickableCard>
      )}
    </>
  );
};

export default RewardMenu;
