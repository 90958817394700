import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AvatarIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 13.6878 4.52266 15.2535 5.41496 16.5441C7.09927 14.9372 9.46598 14 12 14C14.534 14 16.9007 14.9372 18.5843 16.543C19.4773 15.2535 20 13.6878 20 12C20 7.58172 16.4183 4 12 4ZM12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5Z" />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 16C9.95226 16 8.06063 16.7595 6.74966 18.0349C8.1551 19.2595 9.99108 20 12 20C14.0089 20 15.8449 19.2595 17.2499 18.0366C15.9394 16.7595 14.0477 16 12 16ZM12 4C7.58172 4 4 7.58172 4 12C4 13.6878 4.52266 15.2535 5.41496 16.5441C7.09927 14.9372 9.46598 14 12 14C14.534 14 16.9007 14.9372 18.5843 16.543C19.4773 15.2535 20 13.6878 20 12C20 7.58172 16.4183 4 12 4ZM12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5ZM12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7Z" />
      )}
    </SvgIcon>
  );
};

export default AvatarIcon;
