import { useEffect, useState } from "react";
import { ClickableCard } from "@/components/Card";
import { MultiOptionIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import Menu, { MenuItem } from "@/components/Menu";
import styles from "./Header.module.scss";
import { Trip, useTripsQuery } from "@/fetch/gworld";
import { useCurrentTrip, useLock, useTrackers } from "@/hooks";
import { ListItemText } from "@/components/List";
import { Skeleton } from "@/components/Loader";
import { useRouter } from "next/router";

const TripSelector: React.FC = () => {
  const { currentTrip, setCurrentTrip } = useCurrentTrip();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { data: trips, isLoading: isTripsLoading } = useTripsQuery();
  const { isTripLocked } = useLock();
  const { track } = useTrackers();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectTrip = async (trip: Trip) => {
    if (trip.id) setCurrentTrip(trip);
    try {
      // We set query param, so that we can check this query param and
      // bypass the route lock restriction condition in useLock
      await router.replace({
        pathname: "/experience/dashboard",
        query: { tripChanged: true },
      });
      router.replace({
        pathname: "/experience/dashboard",
      });
    } catch (error) {
    } finally {
      track("Trip Switched", {
        eventId: "trip-switched",
        trip_id: trip.trip_id,
        total_trips: trip.count,
        country_code: trip.country_code,
        cid: trip.cid,
        destination: trip.destination,
        division: trip.division,
        duration: trip.duration,
        region: trip.region,
        sales_register_id: trip.sales_register_id,
        sales_register_status: trip.sales_register_status,
        status: trip.status,
        trip_code: trip.trip_code,
        trip_name: trip.trip_name,
      });
      handleClose();
    }
  };

  return (
    <>
      <ClickableCard
        outlined
        className={styles.tripSelector}
        onClick={(event: any) => handleClick(event)}
      >
        {/* https://github.com/vercel/next.js/discussions/17443#discussioncomment-896968 */}
        <div className={styles.text} suppressHydrationWarning>
          {Boolean(currentTrip?.id && process.browser) ? (
            <>
              <Typography variant="body1" noWrap>
                {currentTrip?.destination}
              </Typography>
              <Typography variant="body2" noWrap>
                {currentTrip?.division}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton width="100%" />
              <Skeleton width="85%" />
            </>
          )}
        </div>
        <MultiOptionIcon />
      </ClickableCard>
      <Menu
        elevation={3}
        id="trip-select-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          className: styles.tripSelectorMenu,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {isTripsLoading && <Skeleton height={80} />}
        {trips?.map((trip) => (
          <MenuItem
            selected={trip.id === currentTrip?.id}
            disabled={isTripLocked(trip)}
            className={styles.menuItem}
            key={trip.id}
            onClick={() => selectTrip(trip)}
          >
            <ListItemText
              aria-selected="true"
              primary={trip.destination || ""}
              secondary={trip.division}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TripSelector;
