import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreateVisaVariables = {
  issue_date?: number | null;
  user_description?: string;
  type: string;
};

type CreateVisaResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useCreateVisaMutation = (
  options?: UseMutationOptions<
    CreateVisaResult,
    QueryError,
    CreateVisaVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<CreateVisaResult, QueryError, CreateVisaVariables>(
      async (inputObj: CreateVisaVariables) => {
        const url = `${TRIP_ENDPOINT}/visa`;
        const inputData = {
          ...inputObj,
          tid,
        };

        return fetchWithAuth<CreateVisaResult>(url, {
          method: "POST",
          body: inputData,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateVisaMutation;
