import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useCurrentTrip } from "@/hooks";

type InvoicePaymentPlans = Partial<{
  amount: number;
  date: number;
  diff: number;
  id: number;
  invoice_id: number;
  is_expired: number;
  method: string;
  status: "Pending";
  time_remaining: number;
  transaction_id: string;
}>;

type InvoiceSummary = Partial<{
  cid: number;
  currency: string;
  currency_symbol: string;
  department_id: number;
  deposit: number;
  deposit_paid_at: null;
  discount: number;
  expires_at: number | null;
  expires_at_formatted: string | null;
  id: number;
  is_expired: number;
  markup: number;
  net: number;
  outstanding: number;
  paid: number;
  status: InvoiceStatus;
  tax: number;
  tid: number;
  total: number;
  type: "type" | "travel";
  use_excess: number;
}>;

enum InvoiceStatus {
  Issued = "Issued",
  PaymentPlan = "Payment Plan",
}

export enum InvoiceStatusText {
  Pending = "Pending",
  Paid = "Paid",
  OnHold = "On Hold",
  Cancelled = "Cancelled",
  Expired = "Expired",
}

export type Invoice = Partial<{
  cid: number;
  currency: string;
  department_id: number;
  destination: string;
  division: string;
  expires_at: null;
  id: number;
  status_data: {
    status: string;
    statusText: InvoiceStatusText;
  };
  payment_plans: InvoicePaymentPlans;
  status: InvoiceStatus;
  summary: InvoiceSummary;
  tid: number;
  type: "trip" | "travel";
  use_excess: number;
}>;
type Invoices = Array<Invoice>;
type QueryError = {};

const useInvoicesQuery = (
  options?: UseQueryOptions<Invoices, QueryError, Invoices, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/invoices`;
  const { fetchWithAuth } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const { currentTrip } = useCurrentTrip();
  const cid = personalDetails?.traveller.id;
  const tid = currentTrip?.id;
  const cacheKey = [url, cid, tid];

  return useQuery<Invoices, QueryError>(
    cacheKey,
    async () => {
      if (!cid || !tid) throw new Error();

      const res = await fetchWithAuth<{ invoices?: Invoices }>(url, {
        params: { tid, cid },
      });

      if (!res.invoices) throw new Error();

      return res.invoices;
    },
    {
      enabled: !!tid && !!cid,
      ...options,
    }
  );
};
export default useInvoicesQuery;
