import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

export type EmergencyContact = {
  cid: number;
  id: number;
  email: string | null;
  full_name: string | null;
  guardian: number | null;
  relationship: string | null;
  _ca: number;
  _cb: number;
  _lma: number | null;
  _lmb: number | null;
};

type CreateEmergencyContactVariables = {
  data: Partial<{
    email: string | null;
    full_name: string | null;
    guardian: number | null;
    relationship: string | null;
  }>;
  correlationId: string;
};

type CreateEmergencyContactResult = {
  success: boolean;
  result: EmergencyContact;
};

type QueryError = {};

const useCreateEmergencyContactMutation = (
  options?: UseMutationOptions<
    CreateEmergencyContactResult,
    QueryError,
    CreateEmergencyContactVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      CreateEmergencyContactResult,
      QueryError,
      CreateEmergencyContactVariables
    >(
      async (input: CreateEmergencyContactVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/emergency-contact`;

        return fetchWithAuth<CreateEmergencyContactResult>(url, {
          method: "POST",
          body: { ...data, cid: cid, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateEmergencyContactMutation;
