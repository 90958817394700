import { Skeleton } from "@/components/Loader";
import { RadioGroup } from "@/components/Radio";
import { FormControlLabel } from "@/components/FormController";

const TextFieldLoader = <Skeleton variant="rectangular" height="56px" />;

const QuestionTextLoader = <Skeleton style={{ marginTop: "10px" }} />;

const RadioLoader = ({ optionCount = 3 }) => (
  <RadioGroup row style={{ marginTop: "8px" }}>
    {Array(optionCount)
      .fill(null)
      .map((_, index) => (
        <FormControlLabel
          key={index}
          value="yes"
          control={<Skeleton variant="circular" width="24px" height="24px" />}
          label={<Skeleton width={50} style={{ marginLeft: "8px" }} />}
        />
      ))}
  </RadioGroup>
);

export { TextFieldLoader, QuestionTextLoader, RadioLoader };
