import { useMutation, UseMutationOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Variables = {
  invoiceType: "travel" | "trip";
  invoiceStaffId: number | undefined;
  invoiceId: number;
  amount: number;
  isFinal: boolean;
  paymentPlanId: number | undefined;
  promoCodes: Array<string> | undefined;
};

type Result = {
  clientSecret: string;
  transactionToken: string;
  statusCode: 200;
  success: true;
};

type QueryError = {};

function getReturnOrCancelUrl({
  isCancelUrl,
  invoiceId,
  final,
  paymentPlanId,
}: {
  isCancelUrl: boolean;
  invoiceId: number;
  paymentPlanId: number | undefined;
  final: 1 | 0;
}): string {
  const baseUrl = window.location.origin;
  const basicUrl = `${baseUrl}/experience/payments/invoice/${invoiceId}?dialog=pay&final=${final}&cancelled=${isCancelUrl}`;
  if (paymentPlanId) return `${basicUrl}&payment_plan=${paymentPlanId}`;
  return basicUrl;
}

export const getBasePayload = ({
  invoiceType,
  invoiceStaffId,
  invoiceId,
  amount,
  isFinal,
  paymentPlanId,
  promoCodes,
  cid,
}: {
  invoiceType: "travel" | "trip";
  invoiceStaffId: number | undefined;
  invoiceId: number;
  amount: number;
  isFinal: boolean;
  paymentPlanId: number | undefined;
  promoCodes: Array<string> | undefined;
  cid: number;
}) => {
  const final = isFinal ? 1 : 0;
  let data: any = {
    collector_staff_id: invoiceStaffId || 5,
    comment: "Transaction from gWorld",
    created_by: 5, // Traveller in crm_config.legacy_users
    department_id: invoiceType === "travel" ? 4 : 3,
    cid: cid,
    timezone_offset: new Date().getTimezoneOffset(),
    invoices: [
      {
        invoice_id: invoiceId,
        amount: amount,
        deposit: 0,
        final,
        payment_plan_id: paymentPlanId || null,
      },
    ],
  };

  data.invoices.forEach((invoice: any) => {
    if (!invoice.payment_plan_id) {
      delete invoice.payment_plan_id;
    }
  });

  if (promoCodes) {
    data.invoices[0].promo_codes = promoCodes;
  }

  // const { autoPromotion } = this.rootScope;

  // if (
  //   this.payObject.paymentOption === "full" &&
  //   autoPromotion &&
  //   autoPromotion.invoiceId === this.payObject.invoice.id
  // ) {
  //   data.invoices[0].promo_codes = [autoPromotion.code];
  // }

  return data;
};

function createSourceData({
  invoiceType,
  invoiceStaffId,
  invoiceId,
  amount,
  isFinal,
  paymentPlanId,
  miscData = {},
  cid,
  method = "card",
  promoCodes,
}: {
  invoiceType: "travel" | "trip";
  invoiceStaffId: number | undefined;
  invoiceId: number;
  amount: number;
  isFinal: boolean;
  paymentPlanId: number | undefined;
  miscData?: any;
  cid: number;
  promoCodes: Array<string> | undefined;
  method?: "card";
}) {
  let data = getBasePayload({
    invoiceType,
    invoiceStaffId,
    invoiceId,
    amount,
    isFinal,
    paymentPlanId,
    promoCodes,
    cid,
  });

  data = {
    ...data,
    provider: ["poli", "humm", "paypal"].includes(method) ? method : "stripe",
    misc: {
      returnUrl: getReturnOrCancelUrl({
        isCancelUrl: false,
        invoiceId,
        final: data.final,
        paymentPlanId,
      }),
      cancelUrl: getReturnOrCancelUrl({
        isCancelUrl: true,
        invoiceId,
        final: data.final,
        paymentPlanId,
      }),
      ...miscData,
    },
  };

  if (method === "card") {
    data.misc.method = "stripe_card";
  }

  // if (data.provider === "stripe") data.misc.method = method;
  // if (this.method === SOFORT) {
  //   const { profile } = this.rootScope;

  //   const profileCountry = profile.addresses.country;
  //   const countryCodes = {
  //     Austria: "AT",
  //     Belgium: "BE",
  //     Germany: "DE",
  //     Italy: "IT",
  //     Netherlands: "NL",
  //     Spain: "ES",
  //   };

  //   const sofortCountry = countryCodes[profileCountry];
  //   data.misc.sofortCountry = sofortCountry;
  // }

  return data;
}

const useInitTxMutation = (
  options?: UseMutationOptions<Result, QueryError, Variables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;

  return {
    ...useMutation<Result, QueryError, Variables>(
      async ({
        amount,
        invoiceId,
        paymentPlanId,
        invoiceStaffId,
        invoiceType,
        isFinal,
        promoCodes,
      }: Variables) => {
        if (!cid || !amount || !invoiceId || !invoiceType) throw new Error();

        const url = `${PAYMENT_ENDPOINT}/transaction/initialize`;
        const sourceData = createSourceData({
          amount,
          isFinal,
          invoiceId,
          paymentPlanId,
          invoiceStaffId,
          invoiceType,
          cid,
          promoCodes,
        });
        return fetchWithAuth<Result>(url, { method: "POST", body: sourceData });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useInitTxMutation;
