import { QueryKey, useQuery, UseQueryOptions } from "react-query";

export type CountryCode = {
  label: string;
  dial_code: string;
  code: string;
};

type CountryCodes = Array<CountryCode>;

type QueryError = {};

const getCountries = async (url: string) => {
  const result = await fetch(url);
  if (!result.ok) {
    console.error(JSON.stringify(result));
    throw new Error("Network response was not ok");
  }
  const countries = await result.json();
  return countries as CountryCodes;
};

const useCountryCodes = (
  options?: UseQueryOptions<CountryCodes, QueryError, CountryCodes, QueryKey>
) => {
  const url = "/countries.json";

  return useQuery<CountryCodes, QueryError>(
    [url],
    async () => {
      return getCountries(url);
    },
    {
      ...options,
    }
  );
};

export default useCountryCodes;
