import Avatar from "../Avatar";
import Badge from "../Badge";
import { GlobalIcon } from "../Icon";
import Tooltip from "../Tooltip";
import styles from "./SocialAvatar.module.scss";
import cx from "classnames";

const SocialAvatar: React.FC<
  {
    isAdmin?: boolean;
    adminTitle?: string | null;
    badgeClassName?: string;
    badgeContentClassName?: string;
  } & React.ComponentProps<typeof Avatar>
> = ({
  isAdmin = false,
  adminTitle,
  badgeClassName,
  badgeContentClassName,
  ...avatarProps
}) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      classes={{ badge: cx(styles.globalBadge, badgeClassName) }}
      badgeContent={
        isAdmin ? (
          <Tooltip title={adminTitle ?? "Community Manager"} arrow>
            <div
              className={cx(styles.globalBadgeContent, badgeContentClassName)}
            >
              <GlobalIcon color="primary" />
            </div>
          </Tooltip>
        ) : null
      }
    >
      <Avatar {...avatarProps} />
    </Badge>
  );
};

export default SocialAvatar;
