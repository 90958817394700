import { useMutation, UseMutationOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type UpdateMediaVariables = {
  mediaId: number;
  upload_status: "FINAL" | "PENDING";
};

type UpdateMediaResult = {
  success: boolean;
  result: {
    affectedRows: number;
  };
};

type QueryError = {};

const useUpdateMediaMutation = (
  options?: UseMutationOptions<
    UpdateMediaResult,
    QueryError,
    UpdateMediaVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<UpdateMediaResult, QueryError, UpdateMediaVariables>(
      async ({ mediaId, upload_status }: UpdateMediaVariables) => {
        const url = `${REWARD_ENDPOINT}/media/${mediaId}`;

        return fetchWithAuth<UpdateMediaResult>(url, {
          method: "PATCH",
          body: { upload_status },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateMediaMutation;
