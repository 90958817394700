import { useInvoicesQuery } from "@/fetch/payment";
import { useRouter } from "next/router";

const useTripInvoice = () => {
  const { data: invoices, isLoading } = useInvoicesQuery();
  const { replace } = useRouter();
  const tripInvoice = invoices?.find((i) => i.type === "trip");

  const goToTripInvoice = () => {
    if (tripInvoice) {
      replace(`/experience/payments/invoice/${tripInvoice.id}`);
    }
  };

  return { goToTripInvoice, isLoading, tripInvoice };
};

export default useTripInvoice;
