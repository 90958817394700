import SvgIcon, { SvgIconProps } from "./SvgIcon";

const getRotate = (dir: string): number => {
  if (dir === "up") return -90;
  if (dir === "left") return 180;
  if (dir === "down") return 90;
  else return 0;
};

const ChevronIcon: React.FC<SvgIconProps> = ({ dir = "right", ...props }) => {
  const rotate = getRotate(dir);
  return (
    <SvgIcon {...props} style={{ transform: `rotate(${rotate}deg)` }}>
      <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.93241 4.65338 7.90468 5.22061 8.20971 5.6129L8.29289 5.70711L14.585 12L8.29289 18.2929C7.93241 18.6534 7.90468 19.2206 8.20971 19.6129L8.29289 19.7071C8.65338 20.0676 9.22061 20.0953 9.6129 19.7903L9.70711 19.7071L16.7071 12.7071C17.0676 12.3466 17.0953 11.7794 16.7903 11.3871L16.7071 11.2929L9.70711 4.29289Z" />
    </SvgIcon>
  );
};

export default ChevronIcon;
