import { FC } from "react";
import MuiTableBody, {
  TableBodyProps as MuiTableBodyProps,
} from "@mui/material/TableBody";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import { Skeleton } from "@/components/Loader";
import type { Columns } from "./DataTable";

type TableBodyProps<T> = MuiTableBodyProps & {
  rows: Array<T>;
  columns: Columns<T>;
  isLoading?: boolean;
  page: number;
  rowsPerPage: number;
  showPagination: boolean | undefined;
};

function TableBody<T>({
  rows,
  columns,
  isLoading,
  page,
  rowsPerPage,
  showPagination,
}: TableBodyProps<T>) {
  // Preserve indices in pagination
  let rowsToDisplay = rows.map((row, index) => ({ row, index }));

  if (showPagination) {
    rowsToDisplay = rowsToDisplay.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  }

  return (
    <MuiTableBody>
      {isLoading && (
        <TableRow>
          {columns.map((eachCol, idx) => (
            <TableCell key={idx} variant="body">
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      )}
      {!isLoading && (
        <>
          {rowsToDisplay.map(({ row, index }) => (
            <TableRow key={index + 1}>
              {columns.map((eachCol, idx) => (
                <TableCell key={idx} variant="body" style={eachCol.style}>
                  {eachCol.selector(row, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      )}
    </MuiTableBody>
  );
}

export default TableBody;
