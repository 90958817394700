import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MultiOptionIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.832 14.4453C19.1165 14.872 19.0298 15.4378 18.648 15.7618L18.5547 15.8321L12.5547 19.8321C12.2561 20.0311 11.8772 20.0532 11.5607 19.8984L11.4453 19.8321L5.44528 15.8321C4.98576 15.5257 4.86158 14.9048 5.16793 14.4453C5.4524 14.0186 6.00806 13.881 6.45392 14.1089L6.55468 14.1679L12 17.797L17.4453 14.1679C17.9048 13.8616 18.5257 13.9858 18.832 14.4453ZM12.4392 4.1016L12.5547 4.16795L18.5547 8.16795C19.0142 8.4743 19.1384 9.09517 18.832 9.5547C18.5476 9.98141 17.9919 10.119 17.5461 9.89114L17.4453 9.83205L12 6.202L6.55468 9.83205C6.12798 10.1165 5.56216 10.0298 5.23822 9.648L5.16793 9.5547C4.88346 9.128 4.97021 8.56217 5.35198 8.23824L5.44528 8.16795L11.4453 4.16795C11.7439 3.9689 12.1228 3.94678 12.4392 4.1016Z" />
    </SvgIcon>
  );
};

export default MultiOptionIcon;
