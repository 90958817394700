import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import useGroupQuery from "./useGroupQuery";
import { useCurrentTrip } from "@/hooks";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";

const PAGE_SIZE = 30;

export type SampleLike = Partial<{
  cid: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  post_id?: number;
  id?: number;
  s3_profile_pic?: string;
  s3_resume_pic?: string;
  country?: string;
  division?: string;
  nickname?: string;
  status?: TripStatus;
  is_suspended: number;
}>;

export type SampleComment = Partial<{
  id: number;
  country: string;
  division: string;
  email: string;
  post_id: number;
  cid: number;
  content: string;
  nickname?: string;
  s3_profile_pic: string;
  s3_resume_pic: string;
  is_suspended: number;
  status: TripStatus;
}>;

export type Media = Partial<{
  id: number;
  post_id: number;
  type: "Image" | "Video";
  public_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  s3_profile_pic?: string;
  cid: number;
}>;

export type Post = Partial<{
  nickname: string;
  group_id: number;
  division: string;
  cid: number;
  content?: string;
  id: number;
  is_admin: boolean | number;
  admin_title?: string | null;
  is_suspended: number;
  likes: { total_likes: number; sample_likes: SampleLike[] };
  comments: { total_comments: number; sample_comments: SampleComment[] };
  media: Media[];
  status?: "FINAL" | "DRAFT";
  trip_status?: TripStatus;
  s3_profile_pic: string;
  s3_resume_pic: string;
  country: string;
  _ca: number;
}>;

type RecentPostsParam = {
  pageSize?: number;
  pageNum?: number;
  withMedia?: boolean;
  pinned?: boolean;
  adminPosts?: boolean;
};

export type RecentPostsQueryResult = {
  results?: Array<Post>;
  totalCount?: number;
};

export type RecentPostsFetchResult = {
  data: RecentPostsQueryResult;
  statusCode: number;
  success: boolean | string;
};

export type InfinityResult = {
  pages: Array<RecentPostsQueryResult>;
  pageParam: any[];
};
type QueryError = {};

const useRecentPostsQuery = (
  params?: RecentPostsParam,
  options?: UseInfiniteQueryOptions<
    RecentPostsQueryResult,
    any,
    RecentPostsQueryResult,
    RecentPostsQueryResult,
    QueryKey
  >
) => {
  const { data: group, isLoading: isGroupLoading } = useGroupQuery();
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const groupId = group?.id;
  const cid = currentTrip?.cid;

  const url = `${GATEWAY_URL}/post/${groupId}/${cid}`;
  const cacheKey = [url, JSON.stringify(params)];

  const queryResult = useInfiniteQuery<RecentPostsQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!groupId || !cid) throw new Error("Group id or cid not found");

      const result = await fetchWithAuth<RecentPostsFetchResult>(url, {
        params: {
          ...params,
          pageNum: pageParam,
          pageSize: params?.pageSize ?? PAGE_SIZE,
        },
      });
      return result.data;
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(groupId && cid),
      getNextPageParam: (lastPage, pages) => {
        if (
          (lastPage?.totalCount || 0) >=
          (params?.pageSize ?? PAGE_SIZE) * pages.length
        )
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult, isLoading: queryResult.isLoading || isGroupLoading };
};
export default useRecentPostsQuery;
