import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type PrepareTwilioConversationMutationResult = {
  message?: string;
  conversation_sid: string;
};
export type PrepareTwilioConversationVariables = {
  travellerCid: number;
};

export type PrepareTwilioConversationFetchResult = {
  data: PrepareTwilioConversationMutationResult;
  statusCode: number;
  success: boolean;
};
type QueryError = {};

const usePrepareTwilioConversationMutation = (
  options?: UseMutationOptions<
    PrepareTwilioConversationMutationResult,
    QueryError,
    PrepareTwilioConversationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/chat/prepare-conversaion`;

  return {
    ...useMutation<
      PrepareTwilioConversationMutationResult,
      QueryError,
      PrepareTwilioConversationVariables
    >(async ({ travellerCid }) => {
      if (!cid || !travellerCid)
        throw new Error(
          `Could not prepare chat, cid1:${cid},cid2:${travellerCid}`
        );

      const result = await fetchWithAuth<PrepareTwilioConversationFetchResult>(
        url,
        {
          method: "POST",
          body: {
            first_cid: cid,
            second_cid: travellerCid,
          },
        }
      );
      return result.data;
    }, options),
    cancel,
  };
};
export default usePrepareTwilioConversationMutation;
