import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField from "@/components/TextField";
import { FormActions } from "@/components/FormController";
import CountryCodeSelector from "@/components/CountryCodeSelector";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import { MenuItem } from "@/components/Menu";
import { CountryCode } from "@/hooks";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
  useFormatContactMethod,
} from "@/components/PersonalDetailsForms/hooks";
import {
  EmergencyContactFormType,
  GuardianDetailsFormType,
} from "@/components/PersonalDetailsForms";
import Button from "@/components/Button";
import { EditIcon } from "@/components/Icon";
import { EmergencyRelationItems } from "./types";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  onCountriesLoaded: (countries?: Array<CountryCode>) => void;
}

function EmergencyContactFormTemplateWithRef<
  T extends EmergencyContactFormType
>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    onCountriesLoaded,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} md={12}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="emergencyContactName"
              name="emergencyContactName"
              label="Full Name"
              value={formik.values.emergencyContactName}
              onChange={formik.handleChange}
              error={
                formik.touched.emergencyContactName &&
                Boolean(formik.errors.emergencyContactName)
              }
              helperText={
                formik.touched.emergencyContactName &&
                formik.errors.emergencyContactName
              }
            />
          )}
        </Grid>
        <Grid
          container
          spacing="24px"
          columnSpacing="12px"
          item
          xs={12}
          md={12}
        >
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <Grid item xs={6} md={6}>
              <TextField
                select
                disabled={isFormSubmitting}
                fullWidth
                id="emergencyContactRelationSelect"
                name="emergencyContactRelationSelect"
                label="Relationship"
                value={formik.values.emergencyContactRelationSelect}
                onChange={formik.handleChange}
                error={
                  formik.touched.emergencyContactRelationSelect &&
                  Boolean(formik.errors.emergencyContactRelationSelect)
                }
                helperText={
                  formik.touched.emergencyContactRelationSelect &&
                  formik.errors.emergencyContactRelationSelect
                }
              >
                {EmergencyRelationItems.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {formik.values.emergencyContactRelationSelect === "Other" && (
            <Grid item xs={6} md={6}>
              <TextField
                disabled={isFormSubmitting}
                fullWidth
                id="emergencyContactRelationTextField"
                name="emergencyContactRelationTextField"
                label="Relationship Details"
                value={formik.values.emergencyContactRelationTextField}
                onChange={formik.handleChange}
                error={
                  formik.touched.emergencyContactRelationTextField &&
                  Boolean(formik.errors.emergencyContactRelationTextField)
                }
                helperText={
                  formik.touched.emergencyContactRelationTextField &&
                  formik.errors.emergencyContactRelationTextField
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="emergencyContactEmail"
              name="emergencyContactEmail"
              label="Email"
              value={formik.values.emergencyContactEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.emergencyContactEmail &&
                Boolean(formik.errors.emergencyContactEmail)
              }
              helperText={
                formik.touched.emergencyContactEmail &&
                formik.errors.emergencyContactEmail
              }
            />
          )}
        </Grid>
        <Grid item lg={3} xs={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <CountryCodeSelector
              label="Code"
              disabled={isFormSubmitting}
              value={formik.values.emergencyContactPrimaryCountryCode}
              onChange={(event, newValue) => {
                formik.setValues((state) => ({
                  ...state,
                  emergencyContactPrimaryCountryCode: newValue as CountryCode,
                }));
              }}
              error={
                (formik.errors.emergencyContactPrimaryCountryCode as any)?.label
              }
              touched={
                formik.touched.emergencyContactPrimaryCountryCode as boolean
              }
              onCountriesLoaded={onCountriesLoaded}
            />
          )}
        </Grid>
        <Grid item lg={4} xs={7}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="emergencyContactPrimaryPhone"
              name="emergencyContactPrimaryPhone"
              label="Phone Number"
              type="phoneNumber"
              value={formik.values.emergencyContactPrimaryPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.emergencyContactPrimaryPhone &&
                Boolean(formik.errors.emergencyContactPrimaryPhone)
              }
              helperText={
                formik.touched.emergencyContactPrimaryPhone &&
                formik.errors.emergencyContactPrimaryPhone
              }
            />
          )}
        </Grid>

        <Grid item lg={3} xs={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <CountryCodeSelector
              label="Code"
              placeholder="Optional"
              disabled={isFormSubmitting}
              disableClearable={false}
              value={formik.values.emergencyContactSecondaryCountryCode}
              onChange={(event, newValue) => {
                formik.setValues((state) => ({
                  ...state,
                  emergencyContactSecondaryCountryCode: newValue as CountryCode,
                }));
              }}
              error={
                (formik.errors.emergencyContactSecondaryCountryCode as any)
                  ?.label
              }
              touched={
                formik.touched.emergencyContactSecondaryCountryCode as boolean
              }
              onCountriesLoaded={onCountriesLoaded}
            />
          )}
        </Grid>
        <Grid item lg={3} xs={7}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="emergencyContactSecondaryPhone"
              name="emergencyContactSecondaryPhone"
              label="2nd Contact Number"
              placeholder="Optional"
              type="phoneNumber"
              value={formik.values.emergencyContactSecondaryPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.emergencyContactSecondaryPhone &&
                Boolean(formik.errors.emergencyContactSecondaryPhone)
              }
              helperText={
                formik.touched.emergencyContactSecondaryPhone &&
                formik.errors.emergencyContactSecondaryPhone
              }
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const EmergencyContactForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  hasGuardianSection?: boolean;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  hasGuardianSection,
  correlationId = "",
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();
  const { formatContactMethodToGworld } = useFormatContactMethod();
  const rawEmergencyContact = personalDetailsData?.emergencyContacts?.[0];
  const emergencyContact = {
    ...rawEmergencyContact,
    data: formatContactMethodToGworld(rawEmergencyContact?.contacts || []),
  };

  const { emergencyContactValidationSchema } =
    usePersonalDetailsFormValidation();

  const { submitEmergencyContactForm, isUpdatingEmergencyContact } =
    usePersonalDetailsFormSubmission();

  const { emergencyContactInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingEmergencyContact || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const refetchData = async (count: number) => {
    const refetchResult = await personalDetailsRefetch({});
    const isProfileUpdated = Boolean(
      refetchResult?.data?.traveller?.updated_profile
    );
    if (isProfileUpdated) return;
    if (count <= 0) throw new Error("Profile update did not perform");
    else await refetchData(count - 1);
  };

  const delay = () => new Promise((res) => setTimeout(res, 3000));

  const onSubmit = async (values: EmergencyContactFormType) => {
    try {
      if (formik.dirty) {
        await submitEmergencyContactForm(values, correlationId);
      }

      setRefetchingForm(true);
      // we need to wait for couple of seconds for submitting personal details event to fire and update the updated_profile field
      await delay();
      if (!hasGuardianSection) await refetchData(10);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const onCountriesLoaded = (countries?: Array<CountryCode>) => {
    if (!countries) return;
    formik.setValues((values) => ({
      ...values,
      emergencyContactPrimaryCountryCode: countries?.find(
        (item) =>
          item.dial_code === emergencyContact?.data?.primary?.region_code
      ),
      emergencyContactSecondaryCountryCode: countries?.find(
        (item) =>
          item.dial_code === emergencyContact?.data?.secondary?.region_code
      ),
    }));
  };

  const initialValues = emergencyContactInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: emergencyContactValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <EmergencyContactFormTemplate
        formik={formik}
        onCountriesLoaded={onCountriesLoaded}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const EmergencyContactFormTemplate = forwardRef(
  EmergencyContactFormTemplateWithRef
);

export { EmergencyContactFormTemplate };
export default EmergencyContactForm;
