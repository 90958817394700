import { FC } from "react";
import MuiTableHead, {
  TableHeadProps as MuiTableHeadProps,
} from "@mui/material/TableHead";
import TableRow from "./TableRow";
import TableCell from "./TableCell";

type TableHeadProps = MuiTableHeadProps & {
  columns: Array<{ headerName: string; style?: { [key: string]: any } }>;
};

const TableHead: FC<TableHeadProps> = ({ columns, ...props }) => {
  return (
    <MuiTableHead {...props}>
      <TableRow>
        {columns.map((eachCol, idx) => (
          <TableCell key={idx} variant="head" style={eachCol.style}>
            {eachCol.headerName}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
