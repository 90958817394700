import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type FaqCategory = Partial<{
  id: number;
  name: string;
  platform: string;
  position: number;
  type_id: number;
  _ca: number;
  _cb: number;
  _lma: any;
  _lmb: any;
}>;

type FaqCategoriesResult = Partial<{
  success: boolean;
  faqCategories: Array<FaqCategory>;
}>;
type QueryError = {};

const useFaqCategoriesQuery = (
  options?: UseQueryOptions<
    FaqCategoriesResult,
    QueryError,
    FaqCategoriesResult,
    QueryKey
  >
) => {
  const url = `${GATEWAY_URL}/api/faqs/categories`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<FaqCategoriesResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<FaqCategoriesResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useFaqCategoriesQuery;
