import SvgIcon, { SvgIconProps } from "./SvgIcon";

const OfferedWorkIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <>
          <g clipPath="url(#clip0_1905_152)">
            <path d="M17.7273 10.4091C19.285 10.4091 20.7277 10.9005 21.9091 11.7264V7.27273C21.9091 6.11227 20.9786 5.18182 19.8182 5.18182H15.6364V3.09091C15.6364 1.93045 14.7059 1 13.5455 1H9.36364C8.20318 1 7.27273 1.93045 7.27273 3.09091V5.18182H3.09091C1.93045 5.18182 1.01045 6.11227 1.01045 7.27273L1 18.7727C1 19.9332 1.93045 20.8636 3.09091 20.8636H11.12C10.6705 19.9123 10.4091 18.8564 10.4091 17.7273C10.4091 13.6814 13.6814 10.4091 17.7273 10.4091ZM9.36364 3.09091H13.5455V5.18182H9.36364V3.09091Z" />
            <path d="M17.4999 12.0833C20.4915 12.0833 22.9166 14.5085 22.9166 17.5C22.9166 20.4916 20.4915 22.9167 17.4999 22.9167C14.5084 22.9167 12.0833 20.4916 12.0833 17.5C12.0833 14.5085 14.5084 12.0833 17.4999 12.0833ZM20.6195 15.5218C20.4248 15.2946 20.0828 15.2684 19.8557 15.4631L16.4442 18.3873L15.1745 17.1178L15.1235 17.0727C14.9109 16.9076 14.6037 16.9227 14.4085 17.118C14.197 17.3296 14.1971 17.6725 14.4087 17.884L16.0337 19.5081L16.0861 19.5542C16.2865 19.7089 16.5722 19.7051 16.7691 19.5362L20.5608 16.2855L20.6096 16.2381C20.7907 16.0389 20.7992 15.7314 20.6195 15.5218Z" />
          </g>
          <defs>
            <clipPath id="clip0_1905_152">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_1905_147)">
            <path d="M3.05253 18.8947V7.31579H19.8946V10.7789C20.6525 11.0105 21.3683 11.3474 21.9999 11.8V7.31579C21.9999 6.14737 21.0631 5.21053 19.8946 5.21053H15.6841V3.10526C15.6841 1.93684 14.7473 1 13.5788 1H9.36832C8.1999 1 7.26306 1.93684 7.26306 3.10526V5.21053H3.05253C1.88411 5.21053 0.957792 6.14737 0.957792 7.31579L0.947266 18.8947C0.947266 20.0632 1.88411 21 3.05253 21H11.1367C10.821 20.3474 10.6104 19.6421 10.5052 18.8947H3.05253ZM9.36832 3.10526H13.5788V5.21053H9.36832V3.10526Z" />
            <path d="M17.4999 12.0833C20.4915 12.0833 22.9166 14.5085 22.9166 17.5C22.9166 20.4916 20.4915 22.9167 17.4999 22.9167C14.5084 22.9167 12.0833 20.4916 12.0833 17.5C12.0833 14.5085 14.5084 12.0833 17.4999 12.0833ZM20.6195 15.5218C20.4248 15.2946 20.0828 15.2684 19.8557 15.4631L16.4442 18.3873L15.1745 17.1178L15.1235 17.0727C14.9109 16.9076 14.6037 16.9227 14.4085 17.118C14.197 17.3296 14.1971 17.6725 14.4087 17.884L16.0337 19.5081L16.0861 19.5542C16.2865 19.7089 16.5722 19.7051 16.7691 19.5362L20.5608 16.2855L20.6096 16.2381C20.7907 16.0389 20.7992 15.7314 20.6195 15.5218Z" />
          </g>
          <defs>
            <clipPath id="clip0_1905_147">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </SvgIcon>
  );
};

export default OfferedWorkIcon;
