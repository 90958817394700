import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CardIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M20 4C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20ZM20 12H4V18H20V12ZM10 15C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H6C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15H10ZM20 6H4V8H20V6Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default CardIcon;
