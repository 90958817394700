import {
  QueryKey,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type Comment = {
  id: number;
  post_id: number;
  cid: number;
  content: string;
  _ca: number;
  nickname?: string;
  s3_profile_pic: string;
};

type RecentCommentsParam = {
  pageSize: number;
  pageNum?: number;
};

type RecentCommentsQueryResult = {
  data: {
    results: Comment[];
    totalCount: number;
  };
};

type QueryError = {};

export type InfinityResult = {
  pages: Array<RecentCommentsQueryResult>;
};

const useRecentCommentsQuery = (
  postId: number,
  params: RecentCommentsParam = { pageSize: 30, pageNum: 1 },
  options?: UseInfiniteQueryOptions<
    RecentCommentsQueryResult,
    any,
    RecentCommentsQueryResult,
    RecentCommentsQueryResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const url = `${GATEWAY_URL}/comment/${postId}`;
  const cacheKey = [url];

  return useInfiniteQuery<RecentCommentsQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!postId) throw new Error("Post id not found");

      return fetchWithAuth<RecentCommentsQueryResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.data.totalCount || 0) > params.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
};

export default useRecentCommentsQuery;
