import TextField, { TextFieldProps } from "@/components/TextField";
import { InputAdornment } from "@/components/TextField";
import { SearchIcon } from "@/components/Icon";

const SearchTextField: React.FC<TextFieldProps> = (props) => (
  <TextField
    placeholder={props.placeholder || "Search"}
    variant={props.variant || "filled"}
    InputProps={{
      endAdornment: (
        <>
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        </>
      ),
    }}
    {...props}
  />
);

export default SearchTextField;
