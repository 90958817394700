import SvgIcon, { SvgIconProps } from "./SvgIcon";

const PlayIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M8 3.83167C8.19175 3.83167 8.37946 3.8868 8.54076 3.99049L19.6915 11.1588C20.1561 11.4575 20.2906 12.0762 19.9919 12.5408C19.9145 12.6613 19.812 12.7637 19.6915 12.8412L8.54076 20.0095C8.07619 20.3082 7.45747 20.1737 7.15882 19.7091C7.05513 19.5478 7 19.3601 7 19.1683V4.83167C7 4.27938 7.44772 3.83167 8 3.83167Z" />
      ) : (
        <path d="M8 3.83167C8.19175 3.83167 8.37946 3.8868 8.54076 3.99049L19.6915 11.1588C20.1561 11.4575 20.2906 12.0762 19.9919 12.5408C19.9145 12.6613 19.812 12.7637 19.6915 12.8412L8.54076 20.0095C8.07619 20.3082 7.45747 20.1737 7.15882 19.7091C7.05513 19.5478 7 19.3601 7 19.1683V4.83167C7 4.27938 7.44772 3.83167 8 3.83167ZM9 6.663V17.336L17.3 12L9 6.663Z" />
      )}
    </SvgIcon>
  );
};

export default PlayIcon;
