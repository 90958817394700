import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type LegalDeclarations = Array<{
  id: number;
  priority: number;
  required: number;
  declaration: string;
  active: number | boolean;
  condition: string | null;
  exclude: number | boolean;
  validation_message: string | null;
  control_type: string;
  platform: string;
}>;

type LegalDeclarationsFetchResult = {
  success: boolean;
  result: LegalDeclarations;
};

type QueryError = {};

const useLegalDeclarationsQuery = (
  options?: UseQueryOptions<
    LegalDeclarations,
    QueryError,
    LegalDeclarations,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/legal-declarations/trip/${tid}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<LegalDeclarations, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error("tid not found");
      const fetchResult = await fetchWithAuth<LegalDeclarationsFetchResult>(
        url
      );
      return fetchResult?.result;
    },
    {
      enabled: Boolean(tid),
      ...options,
    }
  );
};
export default useLegalDeclarationsQuery;
