import { useInfiniteQuery } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import dayjs from "dayjs";
import type { Notification as NotificationSchemaType } from "@gwat/notification-schema";

export type Notification = NotificationSchemaType;
type NotificationsResult = Array<Notification>;

type QueryError = {};

const URL = `${ENDPOINT}/v2/notifications`;
export const cacheKey = [URL];

const useNotificationsQuery = () => {
  const { fetchWithAuth } = useFetchWithAuth();

  const limit = 20;
  const fetchNotifications = async ({ pageParam = 0 }) => {
    const res = await fetchWithAuth<NotificationsResult>(URL, {
      params: { limit, before: pageParam || dayjs().valueOf() },
    });
    return [...res].reverse();
  };

  return useInfiniteQuery<NotificationsResult, QueryError>(
    cacheKey,
    fetchNotifications,
    {
      getNextPageParam: (lastPage, pages) => {
        const nextParam = lastPage[limit - 1]?.createdAt;
        return nextParam;
      },
    }
  );
};
export default useNotificationsQuery;
