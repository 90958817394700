import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type TripCurrencyInformation = {
  result: { currency: string; currencyName: string };
};

type QueryError = {};

const useTripCurrencyInformationQuery = (
  options?: UseQueryOptions<
    TripCurrencyInformation,
    QueryError,
    TripCurrencyInformation,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const URL = `${REWARD_ENDPOINT}/general/currency/trip/${tid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<TripCurrencyInformation, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      const res = await fetchWithAuth<TripCurrencyInformation>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useTripCurrencyInformationQuery;
