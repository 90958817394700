import { useEffect, useState } from "react";
import { Division, Trip } from "@/fetch/gworld";

const VolunteerOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const WorkingHolidayOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const StudyOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const InternshipOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const SummerCampOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const TutorOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const TourOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const TeachOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const RemoteOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const SuperliteOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 15 }, // trip details
      { id: "1-7", order: 14 }, // flight details
      { id: "1-8", order: 13 }, // jobs
      { id: "1-9", order: 12 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 9 }, // tours
      { id: "1-13", order: 8 }, // insurance
      { id: "1-14", order: 7 }, // visa
      { id: "1-15", order: 6 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const AuPairOrders = [
  {
    //experience
    id: "1",
    order: 1,
    children: [
      { id: "1-1", order: 1 }, // dashboard
      { id: "1-2", order: 2 }, // payments
      { id: "1-3", order: 3 }, // documents
      { id: "1-4", order: 4 }, // resume/cv
      { id: "1-5", order: 5 }, // au pair/tutor profile
      { id: "1-6", order: 6 }, // trip details
      { id: "1-7", order: 7 }, // flight details
      { id: "1-8", order: 8 }, // jobs
      { id: "1-9", order: 9 }, // arrival
      { id: "1-10", order: 10 }, // accommodation
      { id: "1-11", order: 11 }, // host family
      { id: "1-12", order: 12 }, // tours
      { id: "1-13", order: 13 }, // insurance
      { id: "1-14", order: 14 }, // visa
      { id: "1-15", order: 15 }, // resources
    ],
  },
  { id: "2", order: 2 }, // marketplace
  { id: "3", order: 3 }, // academy
  {
    // social
    id: "4",
    order: 4,
    children: [
      { id: "4-1", order: 1 }, // timeline
      { id: "4-2", order: 2 }, // profile
      { id: "4-3", order: 3 }, // messages
    ],
  },
  { id: "5", order: 5 }, // notification
  {
    // account
    id: "6",
    order: 6,
    children: [
      { id: "6-1", order: 1 }, // profile
      { id: "6-2", order: 2 }, // contact
      { id: "6-3", order: 3 }, // personal
      { id: "6-4", order: 1 }, // visa
      { id: "6-5", order: 2 }, // settings
      { id: "6-6", order: 3 }, // about
    ],
  },
  { id: "5", order: 7 }, // setup
  { id: "5", order: 8 }, // finalise
  { id: "5", order: 9 }, // cancelation
  { id: "5", order: 10 }, // lock
  { id: "5", order: 11 }, // rewards
];

const DivisionMenuOrders = {
  [Division.AuPair]: AuPairOrders,
  [Division.Internship]: InternshipOrders,
  [Division.Remote]: RemoteOrders,
  [Division.Study]: StudyOrders,
  [Division.SummerCamp]: SummerCampOrders,
  [Division.Superlite]: SuperliteOrders,
  [Division.Teach]: TeachOrders,
  [Division.Tour]: TourOrders,
  [Division.Tutor]: TutorOrders,
  [Division.Volunteer]: VolunteerOrders,
  [Division.WorkingHoliday]: WorkingHolidayOrders,
};

type OrderType = {
  id: string;
  order: number;
  children?: Array<{ id: string; order: number }>;
};

const useDivisionMenuOrder = (trip?: Trip | null) => {
  const getOrder = (routeId: string, subRouteId?: string) => {
    if (!trip?.division) return undefined;
    const currentMenuOrders = DivisionMenuOrders[trip.division];
    const currentMenu = currentMenuOrders.find((each) => each.id === routeId);
    if (subRouteId) {
      const currentSubMenu = currentMenu?.children?.find(
        (each) => each.id === subRouteId
      );
      return currentSubMenu?.order;
    } else {
      return currentMenu?.order;
    }
  };
  return { getOrder };
};

export default useDivisionMenuOrder;
