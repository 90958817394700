import { FC, useMemo, useCallback } from "react";
import cx from "classnames";
import { useDropzone } from "react-dropzone";
import theme from "@/theme/theme";
import { useResponsive } from "@/hooks";
import styles from "./Uploader.module.scss";

export const UPLOADER_ACCEPT_TYPES = {
  Image: { "image/*": [] },
  PDF: { "application/pdf": [] },
  Video: { "video/*": [] },
  Text: { "text/plain": [".txt"] },
  Word: {
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
};

export interface UploaderFile extends File {
  preview?: string;
}

export type UploaderBaseProps = {
  maxSize?: number; // in bytes
  maxFiles?: number;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  multiple?: boolean;
  onChangeFile: (acceptedFiles: Array<UploaderFile>) => void;
  accept?: Array<{ [key: string]: Array<string> }>;
};

const Uploader: FC<UploaderBaseProps> = ({
  maxSize,
  maxFiles = 1,
  accept,
  onChangeFile,
  disabled = false,
  multiple = false,
  className,
  style,
}) => {
  const { isMobile } = useResponsive();

  const onDrop = useCallback(
    (acceptedFiles: Array<UploaderFile>) => {
      onChangeFile(acceptedFiles);
    },
    [onChangeFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    noClick: !isMobile,
    accept: (accept || [])?.reduce((obj, curr) => ({ ...obj, ...curr }), {}),
    disabled,
    multiple,
  });

  const BasicStyle = {
    flex: 1,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 72px",
    borderRadius: theme.shape.borderRadius,
    borderWidth: `2px`,
    borderStyle: "dashed",
    borderColor: disabled
      ? theme.palette.divider
      : theme.palette.common.blue.light,
    color: theme.palette.text.secondary,
    outline: "none",
    textAlign: "center" as "center",
  };

  const RejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const dropzoneContainerStyle = useMemo(
    () => ({
      ...BasicStyle,
      ...(isDragReject ? RejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragReject, disabled]
  );

  return (
    <div className={className} style={{ ...style }}>
      <div {...getRootProps({ style: { ...dropzoneContainerStyle } })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop here...</p>
        ) : (
          <p>
            {isMobile ? "" : "Drag file or"}
            <button
              role="button"
              className={cx(styles.browseButton, {
                [styles.disableButton]: disabled,
                [styles.mobileText]: isMobile,
              })}
              onClick={isMobile ? undefined : openFilePicker}
            >
              browse
            </button>
            for a file to upload
          </p>
        )}
      </div>
    </div>
  );
};

export default Uploader;
