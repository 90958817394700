import Card from "@/components/Card";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { usePublicProfileQuery } from "@/fetch/profiles/traveller";
import { useAcceptConditionsMutation } from "@/fetch/social";
import { useLock } from "@/hooks";
import { useState } from "react";
import Avatar from "../Avatar";
import Button from "../Button";
import Emoji from "../Emoji";
import styles from "./ConditionCard.module.scss";
import cx from "classnames";
import Typography from "../Typography";
import Grid from "../Grid";

export const communityGuidelines = [
  "Please introduce yourself when you first join Social.",
  "Say hello to help make new arrivals feel welcome.",
  "Feel free to organise in-person local meetups.",
  "Be kind & respectful - Swearing or bullying won't be tolerated.",
  "Inappropriate content or negativity will be removed (& you may be suspended).",
  "Report any issues, site bugs or ideas/tips for local events to your Community Manager.",
];

export const superLiteCommunityGuideLines = [
  "Please introduce yourself and your travel plans.",
  "Say hello to other travellers to make them feel welcome.",
  "Feel free to organise in-person local meetups.",
  "Be kind & respectful - Swearing or bullying won't be tolerated.",
  "Inappropriate content or negativity will be removed (& you may be suspended).",
  "Report any issues, site bugs or ideas/tips for local events to your Community Manager.",
];

// export const coordinatorCid = 21102769732; // Olivia's cid
export const coordinatorCid = 23060533791; // Emma's cid

const ConditionCard: React.FC<{ className?: string }> = ({ className }) => {
  const [isShow, setIsShow] = useState(true);
  const { data: personalDetails } = usePersonalDetailsQuery();
  const { mutateAsync: acceptConditions } = useAcceptConditionsMutation();
  const { data: coordinatePublicProfile } = usePublicProfileQuery({
    cid: coordinatorCid,
  });
  const coordinatorProfilePicturePath =
    coordinatePublicProfile?.[0]?.s3_profile_pic;

  const nickname = personalDetails?.traveller.nickname;
  const firstName = personalDetails?.traveller.first_name;

  const { isSuperliteTrip } = useLock();

  const onClick = async () => {
    try {
      setIsShow(false);
      await acceptConditions();
    } catch (error) {
      setIsShow(true);
      console.error(error);
    }
  };

  const tripGuideLines = isSuperliteTrip
    ? superLiteCommunityGuideLines
    : communityGuidelines;

  return isShow ? (
    <Grid item xs={12}>
      <Card className={cx(styles.root, className)}>
        <div className={styles.titleContainer}>
          <Avatar cloudinaryPath={coordinatorProfilePicturePath} size="l" />
          <Typography variant="body1" sx={{ pl: "12px" }}>
            {`Welcome ${nickname || firstName || ""}`}
          </Typography>

          <Emoji shortcodes=":partying_face:" size="1.2em" />
        </div>
        <div>
          {isSuperliteTrip ? (
            <Typography>
              Who says you have to travel solo these days? Social allows you to
              meet other Global Travellers heading to the same destination as
              you. Meet up with other like-minded travellers, make new friends,
              organise meet-ups, plan a road trip, get travel recommendations,
              or even sell your unused items.
            </Typography>
          ) : (
            <Typography>
              Who says you have to travel solo these days? Now that you’ve
              arrived, gWorld Social allows you to meet other Global Travellers
              in the same region as you, doing any of our trips here. Meet up
              with someone else who’s just landed, make new friends, grab a
              drink, plan a road trip, get travel recommendations or even sell
              your unused items.
            </Typography>
          )}
          <br />
          <Typography>Community Guidelines:</Typography>
          <ul className={styles.rulesList}>
            {tripGuideLines.map((rule) => (
              <Typography key={rule} component="li" color="text.secondary">
                {rule}
              </Typography>
            ))}
          </ul>

          <br />
          <Typography>
            {isSuperliteTrip
              ? "Enjoy your travels!"
              : "Contact your Coordinator for all trip support & enjoy your travels!"}
          </Typography>
        </div>
        <Button
          size="large"
          fullWidth
          className={styles.closeButton}
          onClick={onClick}
        >
          Got it
        </Button>
      </Card>
    </Grid>
  ) : null;
};

export default ConditionCard;
