import SvgIcon, { SvgIconProps } from "./SvgIcon";

const RedirectIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M7 3C7.55228 3 8 3.44772 8 4C8 4.55228 7.55228 5 7 5H5V19H19V17C19 16.4477 19.4477 16 20 16C20.5523 16 21 16.4477 21 17V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H7ZM11.8834 3.00673L12 3H20L20.0752 3.00279L20.2017 3.02045L20.3134 3.05014L20.3782 3.07397L20.504 3.13603L20.5822 3.18685L20.6214 3.21638L20.7073 3.29305C20.7427 3.32845 20.7748 3.36585 20.8038 3.40487L20.8754 3.51613L20.918 3.60279L20.9642 3.73422L20.9933 3.88338L21 4V12C21 12.5523 20.5523 13 20 13C19.4872 13 19.0645 12.614 19.0067 12.1166L19 12V6.412L9.70199 15.7073C9.31138 16.0977 8.67822 16.0976 8.28778 15.7069C7.92738 15.3464 7.89978 14.7791 8.20489 14.3869L8.2881 14.2927L17.584 5H12C11.4872 5 11.0645 4.61396 11.0067 4.11662L11 4C11 3.48716 11.386 3.06449 11.8834 3.00673Z" />
    </SvgIcon>
  );
};

export default RedirectIcon;
