import { FC } from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { useDeleteCommentMutation } from "@/fetch/social";
import { useTrackers } from "@/hooks";
import { CircularProgress } from "@/components/Loader";

const PostCommentDeleteConfirmationDialog: FC<{
  onClose: () => void;
  commentId: number;
  postId: number;
}> = ({ onClose, postId, commentId }) => {
  const { mutateAsync: deleteCommentMutation, isLoading } =
    useDeleteCommentMutation();
  const { track } = useTrackers();
  const onDeleteConfirm = async () => {
    try {
      await deleteCommentMutation({ postId, commentId });
      track("Comment Deleted", {
        commentId,
        postId,
        eventId: "comment-deleted",
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle onClose={onClose}>Delete confirmation</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="text.secondary">
          Do you want to delete this comment?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="info"
          size="small"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onDeleteConfirm}
          color="error"
          size="small"
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress color="inherit" />}
        >
          Delete comment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostCommentDeleteConfirmationDialog;
