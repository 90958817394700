import { ReactNode, useState } from "react";
import { GroupIcon, PlusIcon } from "@/components/Icon";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemText, ListItemIcon } from "@/components/List";
import styles from "./Header.module.scss";
import { IconButton } from "@/components/Button";
import Divider from "@/components/Divider";
import Image from "next/image";
import theme from "@/theme/theme";
import { useTrackers } from "@/hooks";

type ExternalLink = {
  title: string;
  subtitle?: string;
  url: string | URL;
  imageUrl?: string;
  icon?: ReactNode;
  onClick?: () => void;
};

const ExternalLinkItem: React.FC<{
  externalLink: ExternalLink;
  hideDivider?: boolean;
}> = ({ externalLink, hideDivider = false }) => {
  const { url, title, subtitle, imageUrl, icon, onClick } = externalLink;
  const hasAvatar = Boolean(imageUrl || icon);
  return (
    <>
      <MenuItem
        className={styles.linkItem}
        onClick={() => {
          onClick?.();
          window && window.open(url, "_blank");
        }}
      >
        {hasAvatar && (
          <ListItemIcon className={styles.linkIcon}>
            {Boolean(icon)
              ? icon
              : imageUrl && (
                  <Image alt="dd" src={imageUrl} height={22} width={22} />
                )}
          </ListItemIcon>
        )}
        <ListItemText primary={title} secondary={subtitle} />
      </MenuItem>
      {!hideDivider && <Divider variant="middle" />}
    </>
  );
};

const ExternalLinksMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { track } = useTrackers();

  const externalLinks: Array<ExternalLink> = [
    {
      title: "Book a new trip",
      subtitle:
        "Add a new trip & keep on exploring. Get a special repeat discount too!",
      icon: <PlusIcon htmlColor={theme.palette.common.blue.medium} />,
      url: "https://globalworkandtravel.com",
      onClick: () =>
        track("External Link Opened", {
          eventId: "external-link-opened",
          link: "website",
        }),
    },
    {
      title: "Global Travel Cover",
      subtitle: "Travel & medical insurance designed for Global Travellers",
      imageUrl: "/images/global.svg",
      url: "https://globaltravelcover.com/",
      onClick: () =>
        track("External Link Opened", {
          eventId: "external-link-opened",
          link: "travel-cover",
        }),
    },
    {
      title: "DealsAway.com",
      subtitle: "The home of amazing holidays",
      imageUrl: "/images/dealsaway.svg",
      url: "https://dealsaway.com/",
      onClick: () =>
        track("External Link Opened", {
          eventId: "external-link-opened",
          link: "dealsaway",
        }),
    },
    {
      title: "Global Animal Welfare Fund",
      subtitle:
        "Our charity helping alleviate animal suffering & neglect around the world",
      imageUrl: "/images/animal-welfare.svg",
      url: "https://www.globalworkandtravel.com/global-animal-welfare-fund",
      onClick: () =>
        track("External Link Opened", {
          eventId: "external-link-opened",
          link: "animal-welfare-fund",
        }),
    },
    {
      title: "Rescue P.A.W.S.",
      subtitle: "Shaping a better brighter safer  future for Thailand's strays",
      imageUrl: "/images/logo-paw.png",
      url: "https://rescuepawsthailand.org/",
      onClick: () =>
        track("External Link Opened", {
          eventId: "external-link-opened",
          link: "rescue-paws",
        }),
    },
  ];

  return (
    <>
      <IconButton
        onClick={(event: any) => handleClick(event)}
        className={styles.iconButton}
        data-hide-mobile
      >
        <GroupIcon />
      </IconButton>
      <Menu
        elevation={3}
        id="links-select-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          className: styles.linksMenu,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {externalLinks?.map((link, index) => (
          <ExternalLinkItem
            key={String(link.url)}
            externalLink={link}
            hideDivider={index === externalLinks.length - 1}
          />
        ))}
      </Menu>
    </>
  );
};

export default ExternalLinksMenu;
