import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type DeleteInterestedJobVariables = {
  interestedJobId: number;
};

type DeleteInterestedJobResult = {
  success: boolean;
};

type QueryError = {};

const useDeleteInterestedJobMutation = (
  options?: UseMutationOptions<
    DeleteInterestedJobResult,
    QueryError,
    DeleteInterestedJobVariables
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useMutation<
    DeleteInterestedJobResult,
    QueryError,
    DeleteInterestedJobVariables
  >(
    async ({ interestedJobId }: DeleteInterestedJobVariables) => {
      if (!interestedJobId) throw new Error("id not found");
      const url = `${TRIP_ENDPOINT}/interested-jobs/${interestedJobId}`;
      return fetchWithAuth<DeleteInterestedJobResult>(url, {
        method: "DELETE",
      });
    },
    {
      ...options,
    }
  );
};

export default useDeleteInterestedJobMutation;
