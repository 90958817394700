import { useState } from "react";

const useBrowserSettings = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isGapp = navigator.userAgent.includes("gApp");
  const [isGappVersionOne] = useState(
    new RegExp(/gApp\/.*\/1/).test(navigator.userAgent)
  );

  const [isGappAndroid] = useState(
    new RegExp(/gApp\/android\/1/).test(navigator.userAgent)
  );
  const [isGappIos] = useState(
    new RegExp(/gApp\/ios\/1/).test(navigator.userAgent)
  );
  const [isAndroid] = useState(new RegExp(/Android/).test(navigator.userAgent));
  return {
    isSafari,
    isAndroid,
    isGapp,
    isGappVersionOne,
    isGappAndroid,
    isGappIos,
  };
};

export default useBrowserSettings;
