import { FC } from "react";
import MuiTableCell, {
  TableCellProps as MuiTableCellProps,
} from "@mui/material/TableCell";

type TableCellProps = MuiTableCellProps & {};

const TableCell: FC<TableCellProps> = ({ children, ...props }) => {
  return <MuiTableCell {...props}>{children} </MuiTableCell>;
};

export default TableCell;
