/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/display-name */
import styles from "./RichEditor.module.scss";
import cx from "classnames";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import {
  convertToRaw,
  KeyBindingUtil,
  getDefaultKeyBinding,
  DraftHandleValue,
  EditorState,
} from "draft-js";
const { hasCommandModifier } = KeyBindingUtil;
import Emoji from "@/components/Emoji";

import createEmojiPlugin from "@draft-js-plugins/emoji";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaceIcon } from "@/components/Icon";

import dynamic from "next/dynamic";
import addEmoji from "./addEmoji";
import { FormControl, FormHelperText } from "@/components/FormController";

const EmojiPicker = dynamic<any>(() => import("./EmojiPicker"), {
  ssr: false,
  loading: () => <div />,
});

const emojiPlugin = createEmojiPlugin({
  emojiImage: (props) => {
    if (props.unicode) {
      return (
        <Emoji
          native={props.unicode}
          className={styles.suggestedEmoji}
          id={props.unicode}
        />
      );
    } else {
      return <span />;
    }
  },
  emojiInlineText: (props) => {
    if (props.decoratedText) {
      const offsetKey = (props as any)?.children?.[0]?.props?.offsetKey || "";
      return (
        <span data-offset-key={offsetKey}>
          <Emoji native={props.decoratedText} id={props.decoratedText} />
          <span data-text="true" style={{ fontSize: 0 }}>
            {props.decoratedText}
          </span>
        </span>
      );
    } else {
      return <span />;
    }
  },
  selectButtonContent: <FaceIcon />,
});
const { EmojiSuggestions } = emojiPlugin;

const linkifyPlugin = createLinkifyPlugin();

const RichEditor = forwardRef(
  (
    {
      onChange,
      touched,
      error,
      disabled,
      placeholder = "Write here",
      shouldEmptyField,
      classes,
      hasSuggestions = false,
      closeEmojiPickerOnSelect = true,
      handleReturn,
      newLineOnReturn = true,
      onKeyDown,
    }: {
      onChange: (value: string) => void;
      touched?: boolean;
      error?: string;
      placeholder?: string;
      disabled?: boolean;
      shouldEmptyField?: boolean;
      classes?: { emojiPickerButton?: string; root?: string; editor?: string };
      hasSuggestions?: boolean;
      closeEmojiPickerOnSelect?: boolean;
      handleReturn?: (clearInput: () => void) => void;
      newLineOnReturn?: boolean;
      onKeyDown?: (event: any) => void;
    },
    ref: any
  ) => {
    const [value, setValue] = useState(createEditorStateWithText(""));
    const [isFocused, setIsFocused] = useState(false);
    const editorRef = useRef<Editor>(null);
    useImperativeHandle(ref, () => editorRef.current);

    const onChangeHandle = (editorState: any) => {
      if (disabled) return;
      setValue(editorState);
    };

    const focus = () => {
      // reset the anchor to the last place (before blur)
      if (disabled) return;
      setValue((oldValue) => {
        const currentSelection = value.getSelection();
        return EditorState.forceSelection(oldValue, currentSelection);
      });
    };

    useEffect(() => {
      if (disabled) return;
      const data = convertToRaw(value.getCurrentContent());
      const result = data?.blocks?.map((item) => item.text).join("\n");
      onChange?.(result);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
      if (!shouldEmptyField) return;
      setValue(createEditorStateWithText(""));
    }, [shouldEmptyField]);

    const onEmojiSelect = (emoji: any) => {
      if (!editorRef.current || disabled) return;
      setValue((oldValue) => addEmoji(oldValue, emoji.native));
      setIsFocused(true);
    };

    const onEditorFocus = () => {
      if (disabled) return;
      setIsFocused(true);
    };

    const keyBindingFn = (e: any): string | null => {
      onKeyDown?.(e);

      if (e.keyCode === 13 && (hasCommandModifier(e) || e.shiftKey === true)) {
        return "split-block";
      }
      if (e.keyCode === 13 && !newLineOnReturn) {
        return "return";
      }

      return getDefaultKeyBinding(e);
    };

    const clearInput = () => {
      const stateWithContent = createEditorStateWithText("");
      const newState = EditorState.moveSelectionToEnd(
        EditorState.moveFocusToEnd(stateWithContent)
      );
      editorRef?.current?.blur();
      editorRef?.current?.forceUpdate(() => {
        editorRef?.current?.focus();
      });

      setValue(newState);
    };

    const handleKeyCommand = (command: string): DraftHandleValue => {
      if (command === "return" && Boolean(handleReturn)) {
        handleReturn?.(clearInput);
        return "handled";
      }
      return "not-handled";
    };

    return (
      <div className={cx(styles.editorContainer, classes?.root)}>
        <FormControl fullWidth>
          <div
            className={cx(styles.editor, classes?.editor)}
            data-focused={isFocused}
            data-disabled={disabled}
            onClick={focus}
          >
            <Editor
              onFocus={onEditorFocus}
              onBlur={() => setIsFocused(false)}
              editorState={value}
              onChange={onChangeHandle}
              plugins={[emojiPlugin, linkifyPlugin]}
              ref={editorRef}
              placeholder={placeholder}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFn}
            />
            {hasSuggestions && <EmojiSuggestions />}
            <EmojiPicker
              onEmojiSelect={onEmojiSelect}
              disabled={disabled}
              className={cx(styles.emojiButton, classes?.emojiPickerButton)}
              closeOnSelect={closeEmojiPickerOnSelect}
            />
          </div>

          {Boolean(touched) && (
            <FormHelperText error={touched && Boolean(error)}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
);

export default RichEditor;
