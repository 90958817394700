import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export enum INTERVIEW_STATUS {
  PLACED = "Placed",
  ACTIVE = "Active",
  MISSED = "Missed",
  UNSUCCESSFUL = "Unsuccessful",
  INACTIVE = "Inactive",
  NO_SHOW = "No Show",
  CANCELLED = "Cancelled",
  DECLINED_AFTER_THE_INTERVIEW = "Declined after the interview",
  DECLINED_PRE_INTERVIEW = "Declined Pre-interview",
}

export type Interview = Partial<{
  companyAddress: string;
  companyName: string;
  companyPhone: string;
  companyWebsite: string;
  id: number;
  interviewDate: string;
  interviewDateMonth: string;
  interviewDateRaw: string;
  interviewDateYear: string;
  interviewStatus: INTERVIEW_STATUS;
  interviewTime: string;
  interviewType: string;
  interviewer: string;
  jobId: number;
  jobName: string;
  partnerId: number;
  organized_by_partner: number;
  perksAndBenefits: string;
  staffAccomodation: string;
  startDate: string;
  traveller_time_zone: string;
  traveller_time_zone_display: string;
  tripId: number;
  manager: Array<{
    email: string;
    id: number;
    is_emergency: number;
    landline: string;
    mobile: string;
    name: string;
    partner_id: number;
    position: string;
    skype: string;
    _ca: number;
    _cb: number;
    _lma: any;
    _lmb: any;
  }>;
}>;

type JobInterviews = Partial<{
  displayData: boolean;
  pastInterviews: Array<Interview>;
  placedInterviews: Array<Interview>;
  upcommingInterviews: Array<Interview>;
  organizedByPartnerInterviews: Array<Interview>;
}>;
type QueryError = {};

const useJobInterviewsQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<JobInterviews, QueryError, JobInterviews, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/job-opportunities/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<JobInterviews, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<JobInterviews>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useJobInterviewsQuery;
