import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CommentIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M20 3C21.1046 3 22 3.89543 22 5V16C22 17.1046 21.1046 18 20 18H12L8.63684 20.778C8.21103 21.1297 7.58072 21.0696 7.22901 20.6438C7.08098 20.4646 7 20.2394 7 20.007V18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H20Z" />
      ) : (
        <path d="M20 3C21.1046 3 22 3.89543 22 5V16C22 17.1046 21.1046 18 20 18H12L8.63684 20.778C8.21103 21.1297 7.58072 21.0696 7.22901 20.6438C7.08098 20.4646 7 20.2394 7 20.007V18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H20ZM20 5H4V16H9V17.883L11.2808 16H20V5Z" />
      )}
    </SvgIcon>
  );
};

export default CommentIcon;
