import { FC } from "react";
import cx from "classnames";
import Typography from "@/components/Typography";
import RichContentPreview from "@/components/RichContentPreview";
import styles from "./EmptyJobsList.module.scss";

type Props = {
  variant:
    | "search"
    | "interested"
    | "saved"
    | "applied"
    | "offered"
    | "not-available"
    | "error";
  className?: string;
};

const EmptyJobsList: FC<Props> = ({ variant, className }) => {
  const imageSource = {
    search: "/images/empty-search-jobs-result.svg",
    interested: "/images/empty-interested-jobs.svg",
    saved: "/images/empty-saved-jobs.svg",
    applied: "/images/empty-applied-jobs.svg",
    offered: "/images/empty-offered-jobs.svg",
    "not-available": "/images/empty-search-jobs-result.svg",
    error: "/images/empty-search-jobs-result.svg",
  };

  const titleText = {
    search: "No matching jobs found",
    interested: "Let's find something interesting!",
    saved: "Save jobs to come back to later",
    applied: "It's a match!",
    offered: "You're hired!",
    "not-available": "No matching jobs available!",
    error: "We have faced an error! ",
  };

  const descriptionText = {
    search: "Try using different keywords or adjust your filters.",
    interested:
      "Search the available job opportunities to find something that you feel could be suitable. Once you express your interest in a certain opportunity, it'll be shown here.",
    saved:
      "Keep track of potential opportunities you like by using the Save function. They'll show here.",
    applied: `After you've expressed your interest in a job opportunity and if the business is also interested in you, it's a match! An interview will happen & the details will show here, congrats!`,
    offered:
      "... that's what you'll be when your job matches turn into jobs you're hired for. We'll keep track of past & present jobs here.",
    "not-available":
      "Please try again later or contact your arrival coordinator if this continues.",
    error:
      "Please try again later in a few minutes. If this continues, please contact your arrival coordinator.",
  };

  return (
    <div className={cx(styles.container, className)}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={imageSource[variant]} alt="icon" />

      <Typography variant="h6" className={styles.title}>
        {titleText[variant]}
        {/* {variant === "applied" && (
          <RichContentPreview content=" 🎉" className={styles.emoji} />
        )} */}
      </Typography>
      <Typography
        color="text.secondary"
        variant="body2"
        className={styles.description}
      >
        {descriptionText[variant]}
      </Typography>
    </div>
  );
};

export default EmptyJobsList;
