import { CountryCode } from "@/hooks";
import { UploaderFile } from "@/components/Uploader";
import { Nationality } from "@/hooks/useNationalities";

export type ProfileInfoFormType = Partial<{
  profileInfoNickname: string;
  profileInfoBio: string;
  profileInfoProfilePictureFile?: UploaderFile | undefined;
  profileInfoProfilePictureUrl?: string | undefined;
}>;

export type GeneralInfoFormType = Partial<{
  generalInfoFirstName: string;
  generalInfoMiddleName: string;
  generalInfoLastName: string;
  generalInfoDateOfBirth: number | null;
  generalInfoNationality: Nationality;
  generalInfoGender: string;
}>;

export type ContactDetailsFormType = Partial<{
  contactDetailsEmail: string;
  contactDetailsPrimaryPhone: string;
  contactDetailsPrimaryCountryCode: CountryCode;
  contactDetailsSecondaryPhone: string;
  contactDetailsSecondaryCountryCode: CountryCode;
  contactDetailsTravelPhone: string;
  contactDetailsTravelCountryCode: CountryCode;
  contactDetailsStreet: string;
  contactDetailsSuburb: string;
  contactDetailsState: string;
  contactDetailsCountry: CountryCode;
  contactDetailsPostCode: string;
}>;

export type GuardianDetailsFormType = Partial<{
  guardianDetailsName: string;
  guardianDetailsRelationSelect: string;
  guardianDetailsRelationTextField: string;
  guardianDetailsEmail: string;
  guardianDetailsPrimaryPhone: string;
  guardianDetailsPrimaryCountryCode: CountryCode;
  guardianDetailsSecondaryPhone: string;
  guardianDetailsSecondaryCountryCode: CountryCode;
}>;

export type EmergencyContactFormType = Partial<{
  emergencyContactName: string;
  emergencyContactRelationSelect: string;
  emergencyContactRelationTextField: string;
  emergencyContactEmail: string;
  emergencyContactPrimaryPhone: string;
  emergencyContactPrimaryCountryCode: CountryCode;
  emergencyContactSecondaryPhone: string;
  emergencyContactSecondaryCountryCode: CountryCode;
}>;

export enum Status {
  Yes = "yes",
  No = "no",
}

export type PassportFormType = Partial<{
  passportStatus: Status;
  passportExpiryDate: number | null;
  passportIssueDate: number | null;
  passportIssueLocation: string;
  passportNationality: Nationality;
  passportNumber: string;
}>;

export enum ConsumeType {
  Never = "never",
  Regularly = "regularly",
  Socially = "socially",
}

export type PersonalDetailsFormType = Partial<{
  personalDetailsHeight: number | null;
  personalDetailsWeight: number | null;
  personalDetailsAlcohol: ConsumeType | null;
  personalDetailsSmoke: ConsumeType | null;
  personalDetailsIllicit: ConsumeType | null;

  personalDetailsCovidStatus: Status | null;
  personalDetailsCovidDescription: string;

  personalDetailsDietStatus: Status | null;
  personalDetailsDietDescription: string;

  personalDetailsMedicationStatus: Status | null;
  personalDetailsMedicationDescription: string;

  personalDetailsMentalHealthStatus: Status | null;
  personalDetailsMentalHealthDescription: string;

  personalDetailsPhysicalLimitationsStatus: Status | null;
  personalDetailsPhysicalLimitationsDescription: string;

  personalDetailsTattoosPiercingsStatus: Status | null;
  personalDetailsTattoosPiercingsDescription: string;

  personalDetailsCriminalStatus: Status | null;
  personalDetailsCriminalDate: number | null;
  personalDetailsCriminalDescription: string;

  personalDetailsDrinkDrivingStatus: Status | null;
  personalDetailsDrinkDrivingDate: number | null;
  personalDetailsDrinkDrivingBac: number;
  personalDetailsDrinkDrivingDescription: string;

  personalDetailsDrivingOffencesStatus: Status | null;
  personalDetailsDrivingOffencesDate: number | null;
  personalDetailsDrivingOffencesDescription: string;

  personalDetailsWorkingHolidayVisaStatus: Status | null;
  personalDetailsWorkingHolidayVisaDate: number | null;
  personalDetailsWorkingHolidayVisaDescription: string;
}>;

export type WorkingHolidayVisaFormType = {
  workingHolidayVisaStatus: Status | null;
  workingHolidayVisaDate: number | null;
  workingHolidayVisaDescription: string;
};

export type DepartureDateFormType = Partial<{
  departureDate: number | null;
}>;

export const EmergencyRelationItems = [
  "Mother",
  "Father",
  "Partner",
  "Friend",
  "Other",
];
export const GuardianRelationItems = ["Mother", "Father", "Guardian", "Other"];
