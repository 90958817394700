import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import cx from "classnames";
import styles from "./Button.module.scss";

const Button: React.FC<MuiButtonProps> = ({
  variant = "contained",
  color = "primary",
  className,
  ...props
}) => {
  return (
    <MuiButton
      disableElevation
      variant={variant}
      color={color}
      className={cx({ [styles.grayButton]: color == "info" }, className)}
      {...props}
    />
  );
};

export default Button;
