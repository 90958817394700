import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { SubmissionItem } from "./useSubmissionsListQuery";

export type SubmissionsList = {
  result: SubmissionItem;
  success: boolean;
};

type QueryError = {};

const useSubmissionQuery = (
  submissionId?: number,
  options?: UseQueryOptions<
    SubmissionsList,
    QueryError,
    SubmissionsList,
    QueryKey
  >
) => {
  const URL = `${REWARD_ENDPOINT}/submission/${submissionId}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<SubmissionsList, QueryError>(
    cacheKey,
    async () => {
      if (!submissionId) throw new Error();
      const res = await fetchWithAuth<SubmissionsList>(URL);
      return res;
    },
    {
      enabled: !!submissionId,
      ...options,
    }
  );
};
export default useSubmissionQuery;
