import Autocomplete from "@/components/Autocomplete";
import { useNationalities } from "@/hooks";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { SyntheticEvent, useEffect } from "react";
import { ListItem, ListItemText } from "@/components/List";
import TextField, { FieldLockStatement } from "@/components/TextField";
import styles from "./NationalitySelector.module.scss";
import { Nationality } from "@/hooks/useNationalities";

const NationalitySelector: React.FC<{
  disabled?: boolean;
  onChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: unknown,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<unknown> | undefined
      ) => void)
    | undefined;
  value?: unknown;
  touched?: boolean;
  error?: string;
  onNationalitiesLoaded?: (nationalities?: Array<Nationality>) => void;
  label?: string;
  locked?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
}> = ({
  disabled,
  onChange,
  value,
  touched,
  error,
  onNationalitiesLoaded,
  label = "Nationality",
  locked = false,
  disableClearable = true,
  placeholder,
}) => {
  const { data: nationalities, isLoading: isNationalitiesLoading } =
    useNationalities();

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => (option as Nationality)?.label,
  });

  useEffect(() => {
    if (!nationalities) return;
    onNationalitiesLoaded?.(nationalities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalities]);

  return (
    <Autocomplete
      classes={{ popper: styles.codeAutocompletePopper }}
      disabled={disabled}
      value={value}
      blurOnSelect="mouse"
      disableListWrap
      disableClearable={disableClearable}
      onChange={(event, value, reason, details) => {
        if (reason === "clear") onChange?.(event, {}, reason, details);
        else {
          onChange?.(event, value, reason, details);
        }
      }}
      getOptionLabel={(option) => (option as Nationality).label}
      options={nationalities || []}
      loading={isNationalitiesLoading}
      filterOptions={filterOptions}
      renderOption={(props, option) => {
        const item = option as Nationality;
        return (
          <ListItem {...props}>
            <ListItemText primary={item.label} />
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={touched && Boolean(error)}
          helperText={touched && error}
          InputProps={{
            ...params.InputProps,
            endAdornment: locked ? (
              <FieldLockStatement className={styles.lockIcon} />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
    />
  );
};

export default NationalitySelector;
