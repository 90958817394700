import List, {
  ListItem,
  ListItemButton,
  ListItemText,
} from "@/components/List";
import { ChevronIcon } from "@/components/Icon";
import { Skeleton } from "@/components/Loader";
import Link from "next/link";
import { Subroute, useTrackers } from "@/hooks";

const SidebarNavigator: React.FC<{
  sections?: Array<Array<Subroute>>;
  currentPath?: string;
  isLoading?: boolean;
  loaderCount?: number;
}> = ({ sections, currentPath, isLoading, loaderCount = 4 }) => {
  const { track } = useTrackers();
  const isCurrent = (path: string) => path?.startsWith(currentPath || "");
  const onItemClick = (subroute: Subroute) => {
    track("Sidebar Item Clicked", {
      eventId: "sidebar-item-clicked",
      path: subroute.path,
      name: subroute.name,
    });
  };
  if (!sections?.length) return <div />;
  return isLoading ? (
    <List>
      {Array(loaderCount)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            height="28px"
            width="200px"
            key={i}
            style={{ margin: "12px 8px" }}
          />
        ))}
    </List>
  ) : (
    <>
      {sections?.map((routes, i) => (
        <List key={i}>
          {routes.map((subroute) => (
            <ListItem
              key={subroute.path}
              disablePadding
              selected={isCurrent(subroute.path)}
              onClick={() => onItemClick(subroute)}
            >
              <Link href={subroute.path} passHref>
                <ListItemButton component="a" disabled={subroute.disabled}>
                  <ListItemText primary={subroute.name} />
                  {isCurrent(subroute.path) && <ChevronIcon />}
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      ))}
    </>
  );
};

export default SidebarNavigator;
