import { useRouter } from "next/router";
import { useEffect } from "react";
import useCurrentTrip from "./useCurrentTrip";
import useResponsive from "./useResponsive";
import useRoutes from "./useRoutes";

const useRestriction = () => {
  const { hasAccessToRoute, isLoading: isRoutesLoading } = useRoutes();
  const { isMobile } = useResponsive();
  const router = useRouter();
  const { currentTrip } = useCurrentTrip();
  const hasSocialAccess = hasAccessToRoute("Social");
  const hasMarketplaceAccess = hasAccessToRoute("Marketplace");
  const hasAcademyAccess = hasAccessToRoute("Academy");

  useEffect(() => {
    /* To prevent routing through pages when is not accessible */
    const handleRouteChange = (url: string) => {
      if (url.includes("tripId")) {
        /* Allow navigate to the page when tripId is provided in the url */
        return;
      }

      if (!hasSocialAccess && url.includes("/social/")) {
        if (url.includes("/social/messages") && isMobile) {
          /* Allow navigate to the social/messages in mobile view when 
          clicking on the chat button  */
          return;
        }
        throw "Access denied, Social is not available";
      }

      if (!hasAcademyAccess && url.includes("/academy/")) {
        throw "Access denied, Academy is not available";
      }

      if (!hasMarketplaceAccess && url.includes("/marketplace/")) {
        throw "Access denied, Marketplace is not available";
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [
    router.events,
    hasSocialAccess,
    router,
    isMobile,
    hasAcademyAccess,
    hasMarketplaceAccess,
  ]);

  useEffect(() => {
    /* redirect to the right page based on different scenarios */

    if (isRoutesLoading) return;
    if (
      ((!hasSocialAccess && router.pathname.includes("/social")) ||
        (!hasAcademyAccess && router.pathname.includes("/academy")) ||
        (!hasMarketplaceAccess && router.pathname.includes("/marketplace"))) &&
      router.query.tripId === currentTrip?.id?.toString()
    ) {
      /* Show the restrict page dialog when trip changed by query param 
      and also group query is not loading anymore and visiting social
      when no group is available.
      */
      router.replace({
        pathname: "/experience/dashboard",
        query: { dialog: "restricted-page" },
      });
    }
  }, [
    hasSocialAccess,
    hasAcademyAccess,
    hasMarketplaceAccess,
    isRoutesLoading,
    router,
    currentTrip?.id,
  ]);
};

export default useRestriction;
