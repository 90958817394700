import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

type Weather = Partial<{
  country: string;
  city: string;
  countryCode: string;
  sunrise: number;
  sunset: number;
  timezone: string;
  forecasts: Array<Forecast>;
}>;

type Forecast = {
  tempMin: number;
  tempMax: number;
  type: string;
  time: number;
  tempAvg: number;
  icon: string;
  class: string;
};
type QueryError = {};

const useWeather = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<Weather, QueryError, Weather, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trips/weather/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<Weather, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<Weather>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useWeather;
