import { useMutation, UseMutationOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type MediaInput = {
  file_name: string;
  mime: string;
  size: number;
  is_profile: number;
  is_feature: number;
};

type CreateMediaBatchVariables = {
  medias: Array<MediaInput>;
  submissionId: number;
};

type MediaResult = {
  media: {
    id: number;
    affectedRows: number;
  };
  preSignedPostUrl: {
    url: string;
    fields: {
      [key: string]: string;
    };
  };
};

type CreateMediaBatchResult = {
  success: boolean;
  result: Array<MediaResult>;
};

type QueryError = {};

const useCreateMediaBatchMutation = (
  options?: UseMutationOptions<
    CreateMediaBatchResult,
    QueryError,
    CreateMediaBatchVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      CreateMediaBatchResult,
      QueryError,
      CreateMediaBatchVariables
    >(
      async ({ submissionId, medias }: CreateMediaBatchVariables) => {
        const url = `${REWARD_ENDPOINT}/media/submission/${submissionId}/batch`;

        return fetchWithAuth<CreateMediaBatchResult>(url, {
          method: "POST",
          body: { medias },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateMediaBatchMutation;
