import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ChatIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M20 3C21.1046 3 22 3.89543 22 5V16C22 17.1046 21.1046 18 20 18H12L8.63684 20.778C8.21103 21.1297 7.58072 21.0696 7.22901 20.6438C7.08098 20.4646 7 20.2394 7 20.007V18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H20ZM13 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11ZM17 7H7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7Z" />
      ) : (
        <path d="M20 3C21.1046 3 22 3.89543 22 5V16C22 17.1046 21.1046 18 20 18H12L8.63684 20.778C8.21103 21.1297 7.58072 21.0696 7.22901 20.6438C7.08098 20.4646 7 20.2394 7 20.007V18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H20ZM20 5H4V16H9V17.883L11.2808 16H20V5ZM13 11C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H13ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H7C6.44772 9 6 8.55228 6 8C6 7.44772 6.44772 7 7 7H17Z" />
      )}
    </SvgIcon>
  );
};

export default ChatIcon;
