import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { CANCELLATION_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type Cancellation = Partial<{
  id: number;
  fname: string;
  lname: string;
  email: string;
  phone: string;
  dob: string;
  booking_country: string;
  payment_stage: unknown;
  trip: string;
  tc_accepted: number;
  cancel_customer_reason: string;
  cancel_status: string;
  cancel_reason: string;
  cancel_submit_date: string;
  internal_comments: string;
  tid: number;
  case_closed: number | boolean;
}>;

type CancellationsList = Array<Cancellation>;

type CancellationResult = {
  success: boolean;
  result: CancellationsList;
};
type QueryError = {
  response: {
    code: number;
    message: string;
    success: boolean;
  };
};

const useCancellationQuery = (
  options?: UseQueryOptions<
    CancellationsList,
    QueryError,
    CancellationsList,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tripId = currentTrip?.id;
  const url = `${CANCELLATION_ENDPOINT}/trip/${tripId}`;
  const cacheKey = [url, tripId];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<CancellationsList, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error("trip id not found");
      const response = await fetchWithAuth<CancellationResult>(url);
      return response?.result;
    },
    {
      ...options,
      enabled: options?.enabled && Boolean(tripId),
    }
  );
};
export default useCancellationQuery;
