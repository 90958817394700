import React, { forwardRef } from "react";
import styles from "./RichContentPreview.module.scss";
import cx from "classnames";
import Linkify from "linkify-react";
import twemoji from "twemoji";
import Typography, { TypographyProps } from "@/components/Typography";
import Emoji, {
  COLONS_REGEX,
  IMAGE_TAG_REGEX,
  decomposeEmoji,
} from "@/components/Emoji";

type RichContentPreviewProps = React.HTMLAttributes<HTMLParagraphElement> &
  TypographyProps & {
    content?: string;
    className?: string;
  };

function getAltFromImage(htmlString: string) {
  var div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes.
  return (div?.firstChild as any)?.alt;
}

// eslint-disable-next-line react/display-name
const RichContentPreview = forwardRef<
  HTMLParagraphElement,
  RichContentPreviewProps
>(({ content, className, children, ...props }, ref) => {
  /* turn unicode emojis into image element*/
  const twemojiResult = twemoji.parse(content || "", {
    folder: "svg",
    ext: ".svg",
  });
  /* split into array of granular elements */
  const decomposedText = decomposeEmoji(twemojiResult) || [];

  return (
    <Typography
      className={cx(styles.typography, className)}
      {...props}
      ref={ref}
    >
      <Linkify
        options={{
          target: "_blank",
        }}
      >
        {decomposedText.map((item, index) => {
          if (item.match(COLONS_REGEX) && !item.includes("://")) {
            const colonEmoji = (
              <Emoji key={`${item}-${index}`} shortcodes={item} />
            );

            return colonEmoji;
          } else if (item.match(IMAGE_TAG_REGEX)) {
            const emojiUnicode = getAltFromImage(item);

            return (
              <Emoji
                key={`${item}-${index}`}
                native={emojiUnicode}
                id={emojiUnicode}
              />
            );
          } else {
            return item;
          }
        })}
      </Linkify>
      {children}
    </Typography>
  );
});

export default RichContentPreview;
