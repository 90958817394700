import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type GeneralSettingType = {
  id: number;
  tid: number;
  type: string;
  name: string;
  value: boolean | string | number;
  _ca: number;
  _cb: number;
  _lma: any;
  _lmb: any;
};

type GeneralSettingsResult = {
  success: boolean;
  result: Array<GeneralSettingType>;
};
type QueryError = {};

const useGeneralSettingsQuery = (
  options?: UseQueryOptions<
    GeneralSettingsResult,
    QueryError,
    GeneralSettingsResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${TRIP_ENDPOINT}/general-settings/trip/${tid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<GeneralSettingsResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      const res = await fetchWithAuth<GeneralSettingsResult>(url);
      return res;
    },
    {
      enabled: !!tid,
      ...options,
    }
  );
};
export default useGeneralSettingsQuery;
