import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CameraIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M14.4648 3C15.0778 3 15.6536 3.28082 16.031 3.75619L16.1289 3.8906L17.535 5.999L20 6C21.0016 6 21.8313 6.73633 21.9772 7.69723L21.9945 7.85074L22 8V19C22 20.0544 21.1841 20.9182 20.1493 20.9945L20 21H4C2.94564 21 2.08183 20.1841 2.00549 19.1493L2 19V8C2 6.94564 2.81588 6.08183 3.85074 6.00549L4 6L6.464 5.999L7.87108 3.8906C8.18019 3.42694 8.66537 3.11757 9.20549 3.02733L9.36905 3.0069L9.53518 3H14.4648ZM12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9ZM12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11Z" />
      ) : (
        <path d="M14.4648 3C15.0778 3 15.6536 3.28082 16.031 3.75619L16.1289 3.8906L17.535 5.999L20 6C21.0016 6 21.8313 6.73633 21.9772 7.69723L21.9945 7.85074L22 8V19C22 20.0544 21.1841 20.9182 20.1493 20.9945L20 21H4C2.94564 21 2.08183 20.1841 2.00549 19.1493L2 19V8C2 6.94564 2.81588 6.08183 3.85074 6.00549L4 6L6.464 5.999L7.87108 3.8906C8.18019 3.42694 8.66537 3.11757 9.20549 3.02733L9.36905 3.0069L9.53518 3H14.4648ZM14.4648 5H9.53518L7.83205 7.5547C7.66977 7.79812 7.41043 7.95649 7.12414 7.99228L7 8H4V19H20V8H17C16.7074 8 16.4318 7.87206 16.2432 7.65371L16.1679 7.5547L14.4648 5ZM12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9ZM12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11Z" />
      )}
    </SvgIcon>
  );
};

export default CameraIcon;
