import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";
import { ContactMethod } from "./useCreateContactMethodMutation";

type UpdateContactMethodVariables = {
  data: ContactMethod;
  correlationId: string;
};

type UpdateContactMethodResult = {
  success: boolean;
  result: { changedRows: number; insertId: number };
};

type QueryError = {};

const useUpdateContactMethodMutation = (
  options?: UseMutationOptions<
    UpdateContactMethodResult,
    QueryError,
    UpdateContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      UpdateContactMethodResult,
      QueryError,
      UpdateContactMethodVariables
    >(
      async (input: UpdateContactMethodVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/contact-method/${data.id}/traveller/${cid}`;
        delete data.id;
        delete data.method;
        return fetchWithAuth<UpdateContactMethodResult>(url, {
          method: "PATCH",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateContactMethodMutation;
