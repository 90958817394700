import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type VisaDetails = {
  id: number;
  ref: string;
  ref_id: number;
  page_id: any;
  content_config_id: number;
  value: string;
  country_code: any;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
  category: string;
  category_public_name: string;
  type: string;
  config_ref: string;
};

type VisasResult = Partial<{
  success: boolean;
  result: VisaDetails;
}>;
type QueryError = {};

const useVisaQuery = (
  visaId: number,
  options?: UseQueryOptions<VisasResult, QueryError, VisasResult, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/visas/${visaId}/content`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<VisasResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<VisasResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useVisaQuery;
