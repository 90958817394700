import { Trip } from "@/fetch/gworld";
import axios from "axios";
import { useQuery } from "react-query";

const getBannerImage = async (trip: Trip | undefined | null) => {
  if (!trip) return null;

  const assetURL = "https://dvhvji4glt0rj.cloudfront.net";
  const innerName = (name: string | undefined) =>
    typeof name === "string" ? name.toLowerCase().split(" ").join("-") : null;
  const destination = innerName(trip.destination);
  const region = innerName(trip.region) || "default";
  const imgByDestination = `${assetURL}/img/destinations/${
    destination || region
  }/banner.png`;
  const imgByRegion = `${assetURL}/img/destinations/${region}/banner.png`;
  const imgDefault = `${assetURL}/img/destinations/default/banner.png`;
  let bannerImg = imgByDestination;
  try {
    const res = await axios.head(imgByDestination);
    if (res.status !== 200) {
      const regionRes = await axios.head(imgByRegion);
      if (regionRes.status !== 200) {
        bannerImg = imgDefault;
      } else {
        bannerImg = imgByRegion;
      }
    }
  } catch (e) {
    try {
      const regionRes = await axios.head(imgByDestination);
      if (regionRes.status !== 200) {
        bannerImg = imgDefault;
      } else {
        bannerImg = imgByRegion;
      }
    } catch (error) {
      bannerImg = imgDefault;
    }
  }

  return bannerImg;
};

const useTripBannerImage = (trip: Trip | undefined | null) => {
  const { data: imageUrl, isLoading } = useQuery(
    ["banner", trip?.trip_id],
    async () => {
      return await getBannerImage(trip);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return { imageUrl, isLoading };
};

export default useTripBannerImage;
