/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Chat.module.scss";
import { useCurrentTrip, useResponsive } from "@/hooks";
import { useTwilioConversation } from "./hooks";
import ConversationsList from "./ConversationsList";
import Conversation from "./Conversation";
import NewConversation from "./NewConversation";
import Typography from "@/components/Typography";
import Badge from "@/components/Badge";
import { IconButton } from "@/components/Button";
import { ArrowIcon, ChevronIcon, NewIcon } from "@/components/Icon";
import Divider from "@/components/Divider";
import { CircularProgress } from "@/components/Loader";
import cx from "classnames";
import Tooltip from "../Tooltip";
import { useRouter } from "next/router";

const Chat: React.FC<{
  isOpen: boolean;
  onTogglePopover?: () => void;
  twoColumnLayout?: boolean;
  showUnreadMessagesBadge?: boolean;
  showBackButton?: boolean;
}> = ({
  isOpen,
  onTogglePopover,
  twoColumnLayout = false,
  showUnreadMessagesBadge = true,
  showBackButton = false,
}) => {
  const {
    state,
    isConversationsLoading,
    updateConversations,
    toggleChatSidebar,
    currentConversationSid,
    setCurrentConversationSid,
    overlayLoading,
    changePopoverState,
    sessionsToDisplay,
    totalUnreadConversationCount,
    setShowTravellersList,
    showTravellersList,
  } = useTwilioConversation();
  const router = useRouter();
  const { isMobile } = useResponsive();
  const { currentTrip } = useCurrentTrip();
  const thisUserCid = currentTrip?.cid;

  useEffect(() => {
    /* update conversation every time the chat has opened */
    if (isOpen && !isConversationsLoading)
      updateConversations(!state?.sessions?.length);
  }, [isOpen]);

  useEffect(() => {
    /* initially update the conversations*/
    updateConversations(!state?.sessions?.length);
  }, []);

  useEffect(() => {
    if (!isOpen) setShowTravellersList?.(false);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setCurrentConversationSid?.(undefined);
      changePopoverState(false);
    };
  }, []);

  const hasConnectionError = state?.connectionStatus === "error";
  const showMessagesPage =
    currentConversationSid !== undefined &&
    !showTravellersList &&
    !hasConnectionError;

  const showConversationsList = !currentConversationSid && !showTravellersList;

  const showNewMessagePage = showTravellersList && !currentConversationSid;

  const isTwoColumnLayout = twoColumnLayout && !isMobile;

  const onCreateNewConversationClicked = () => {
    if (isOpen) {
      toggleChatSidebar();
    }
    setCurrentConversationSid?.(undefined);
    setShowTravellersList?.(true);
  };

  const overlayLoadingLayer = (
    <div className={styles.overlayLoader}>
      <CircularProgress />
    </div>
  );

  const newConversationList = (
    <NewConversation onExit={() => setShowTravellersList?.(false)} />
  );

  const conversationsListComponent = (
    <>
      <div
        role="button"
        className={cx(styles.header, {
          [styles.clickableChatHeader]: Boolean(onTogglePopover),
        })}
        onClick={() => onTogglePopover?.()}
      >
        {showBackButton && (
          <IconButton onClick={() => router.back()}>
            <ArrowIcon dir="left" />
          </IconButton>
        )}
        <Typography
          variant="subtitle1"
          component="button"
          className={styles.title}
        >
          Chat
          <Badge
            badgeContent={totalUnreadConversationCount}
            color="error"
            componentsProps={{ badge: { className: styles.badge } }}
            invisible={
              !Boolean(totalUnreadConversationCount) || !showUnreadMessagesBadge
            }
          />
        </Typography>
        <Tooltip title="New message" placement="top" arrow>
          <IconButton
            disabled={hasConnectionError}
            className={styles.newConversationButton}
            onClick={onCreateNewConversationClicked}
          >
            <NewIcon />
          </IconButton>
        </Tooltip>
        {Boolean(onTogglePopover) && (
          <IconButton className={styles.collapseButton}>
            <ChevronIcon dir={isOpen ? "down" : "up"} />
          </IconButton>
        )}
      </div>
      <Divider className={styles.divider} />
      <ConversationsList
        hasError={hasConnectionError}
        onReload={() => updateConversations(true)}
        sessions={sessionsToDisplay}
        isConversationsLoading={isConversationsLoading}
        onConversationClick={(sid) => setCurrentConversationSid?.(sid)}
        selectedConversationSid={currentConversationSid}
      />
    </>
  );

  const conversationComponent = (
    <Conversation
      onTogglePopover={onTogglePopover}
      conversationSid={currentConversationSid || ""}
      onExit={() => setCurrentConversationSid?.(undefined)}
    />
  );

  if (isTwoColumnLayout) {
    return (
      <div className={styles.chatRootTwoColumnLayout}>
        {Boolean(overlayLoading) && overlayLoadingLayer}
        <div className={styles.leftContainer}>
          {!showTravellersList && conversationsListComponent}
          {showTravellersList && newConversationList}
        </div>
        <Divider orientation="vertical" />
        <div className={styles.rightContainer}>
          {showMessagesPage && conversationComponent}
          {!showMessagesPage && (
            <div className={styles.notSelectedConversation}>
              <Typography variant="body1" color="text.secondary">
                Start a new conversation <br />
                or continue an existing one
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.chatRoot}>
      {Boolean(overlayLoading) && overlayLoadingLayer}

      {showMessagesPage && conversationComponent}
      {showNewMessagePage && newConversationList}
      {showConversationsList && conversationsListComponent}
    </div>
  );
};

export default Chat;
