import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/social/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { UploaderFile } from "@/components/Uploader";
import useChunkedUploader from "react-chunked-uploader";

type QueryError = {};
export enum UploadMediaType {
  Image = "Image",
  Video = "Video",
}

export type SignedUrlMutationResult =
  | Array<{ key: string; value: string }>
  | undefined;

type SignedUrlResult = Partial<{
  data: SignedUrlMutationResult;
  statusCode: number;
  success: boolean;
}>;
type SignedUrlVariable = { postId: number; type: UploadMediaType };

const useUploadPostMedia = (
  options?: UseMutationOptions<
    SignedUrlMutationResult,
    QueryError,
    SignedUrlVariable
  >
) => {
  const {
    uploadFile,
    cancelUpload,
    isLoading: isUploading,
    progress,
  } = useChunkedUploader();
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  const signedUrlEndpoint = `${GATEWAY_URL}/media`;

  const { mutateAsync: getSignedUrl, isLoading: isGettingSignedUr } =
    useMutation<SignedUrlMutationResult, QueryError, SignedUrlVariable>(
      async ({ postId, type }) => {
        if (!postId) {
          throw new Error("post id not found");
        }
        const result = await fetchWithAuth<SignedUrlResult>(signedUrlEndpoint, {
          method: "POST",
          body: { post_id: postId, type },
        });
        return result.data;
      },
      options
    );

  const uploadProfilePicture = async ({
    file,
    postId,
    type,
  }: {
    file: UploaderFile;
    postId: number;
    type: UploadMediaType;
  }) => {
    try {
      const signedUrlData = await getSignedUrl({ postId, type });

      const uploadUrl = signedUrlData?.find(
        (item) => item.key === "post_url"
      )?.value;

      if (!uploadUrl) return;

      const formData = new FormData();
      signedUrlData.forEach(({ key, value }) => {
        formData.append(key, value);
      });

      formData.delete("post_url");

      const result = await uploadFile({ file, url: uploadUrl, data: formData });

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const cancelUploadRequest = () => {
    cancel();
    cancelUpload();
  };

  return {
    uploadProfilePicture,
    cancelUploadRequest,
    progress,
    isLoading: isUploading || isGettingSignedUr,
  };
};

export default useUploadPostMedia;
