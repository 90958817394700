import countryCodeEmoji from "country-code-emoji";
import React from "react";
import styles from "./Flag.module.scss";
import cx from "classnames";
import Emoji from "@/components/Emoji";

const Flag: React.FC<
  React.ComponentProps<typeof Emoji> & {
    countryCode?: string;
  }
> = ({ countryCode, className, size = "1em", native, ...props }) => {
  if (!countryCode) return null;
  return (
    <Emoji
      {...props}
      key={countryCode}
      size={size}
      native={countryCode ? countryCodeEmoji(countryCode) : native}
      className={cx(styles.flagEmoji, className)}
    />
  );
};

export default Flag;
