import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FeedIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <>
          <path d="M16 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V8L16 3Z" />
          <rect x="7" y="7" width="5" height="2" rx="1" fill="white" />
          <rect x="7" y="11" width="10" height="2" rx="1" fill="white" />
          <rect x="7" y="15" width="10" height="2" rx="1" fill="white" />
        </>
      ) : (
        <path
          d="M16 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V8L16 3ZM19 19H5V5H15V9H19V19ZM7 16C7 16.5523 7.44772 17 8 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15H8C7.44772 15 7 15.4477 7 16ZM12 8C12 7.44772 11.5523 7 11 7H8C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9H11C11.5523 9 12 8.55228 12 8ZM7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8C7.44772 11 7 11.4477 7 12Z"
          fill="black"
        />
      )}
    </SvgIcon>
  );
};

export default FeedIcon;
