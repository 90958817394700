import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MarketplaceIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M20 2C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H16V16C16 14.8954 15.1046 14 14 14H10C8.89543 14 8 14.8954 8 16V22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20ZM14 16V20H10V16H14ZM8 4H4V7C4 8.10457 4.89543 9 6 9C7.05436 9 7.91817 8.18412 7.99451 7.14926L8 7V4ZM14 4H10V7C10 8.10457 10.8954 9 12 9C13.0544 9 13.9182 8.18412 13.9945 7.14926L14 7V4ZM20 4H16V7C16 8.10457 16.8954 9 18 9C19.0544 9 19.9182 8.18412 19.9945 7.14926L20 7V4Z" />
      ) : (
        <path d="M20 2C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20ZM8.99904 9.64474L8.89347 9.76187C8.16508 10.5247 7.13804 11 6 11C5.27138 11 4.58825 10.8052 3.99988 10.4648V20H8V16C8 14.8954 8.89543 14 10 14H14C15.1046 14 16 14.8954 16 16V20H20L20.0011 10.4642C19.4125 10.805 18.729 11 18 11C16.8048 11 15.732 10.4758 14.999 9.64474C14.268 10.4758 13.1952 11 12 11C10.8048 11 9.73201 10.4758 8.99904 9.64474ZM14 16H10V20H14V16ZM8 4H4V7C4 8.10457 4.89543 9 6 9C7.05436 9 7.91817 8.18412 7.99451 7.14926L8 7V4ZM14 4H10V7C10 8.10457 10.8954 9 12 9C13.0544 9 13.9182 8.18412 13.9945 7.14926L14 7V4ZM20 4H16V7C16 8.10457 16.8954 9 18 9C19.0544 9 19.9182 8.18412 19.9945 7.14926L20 7V4Z" />
      )}
    </SvgIcon>
  );
};

export default MarketplaceIcon;
