import {
  ContactMethod,
  ContactMethodMethods,
  ContactMethodTypes,
} from "@/fetch/traveller";
import {
  ContactDetailsFormType,
  EmergencyContactFormType,
  GuardianDetailsFormType,
} from "@/components/PersonalDetailsForms";

const useFormatContactMethod = () => {
  const formatContactMethodToGworld = (
    contactMethods: Array<ContactMethod>
  ) => {
    const primaryContact = contactMethods.find((each) => each.priority === 1);
    const secondaryContact = contactMethods.find(
      (each) => each.priority === 2 && each.type === "original"
    );
    const travelContact = contactMethods.find(
      (each) => each.priority === 2 && each.type === "travel"
    );
    const skypeContact = contactMethods.find(
      (each) => each.priority === 2 && each.method === "skype"
    );
    return {
      primary: primaryContact,
      secondary: secondaryContact,
      travel: travelContact,
      skype: skypeContact,
    };
  };

  const formatGuardianContactMethodToGateway = (
    initialContactMethods: Array<ContactMethod>,
    contactMethods: GuardianDetailsFormType
  ) => {
    const initialContacts = formatContactMethodToGworld(initialContactMethods);
    const primaryContact = {
      id: initialContacts.primary?.id || null,
      priority: 1,
      method: ContactMethodMethods.Phone,
      type: ContactMethodTypes.Original,
      reference: Boolean(contactMethods.guardianDetailsPrimaryPhone)
        ? contactMethods.guardianDetailsPrimaryPhone?.toString()
        : null,
      region_code: Boolean(
        contactMethods.guardianDetailsPrimaryCountryCode?.label?.length
      )
        ? contactMethods.guardianDetailsPrimaryCountryCode?.dial_code
        : null,
    };
    const secondaryContact =
      Boolean(initialContacts?.secondary?.id) ||
      (Boolean(contactMethods.guardianDetailsSecondaryPhone) &&
        Boolean(
          contactMethods.guardianDetailsSecondaryCountryCode?.label?.length
        ))
        ? {
            id: initialContacts?.secondary?.id ?? undefined,
            priority: 2,
            method: ContactMethodMethods.Phone,
            type: ContactMethodTypes.Original,
            reference: Boolean(contactMethods.guardianDetailsSecondaryPhone)
              ? contactMethods.guardianDetailsSecondaryPhone?.toString()
              : null,
            region_code: Boolean(
              contactMethods.guardianDetailsSecondaryCountryCode?.label?.length
            )
              ? contactMethods.guardianDetailsSecondaryCountryCode?.dial_code
              : null,
          }
        : undefined;

    const result = [];
    if (primaryContact) result.push(primaryContact);
    if (secondaryContact) result.push(secondaryContact);
    return result;
  };

  const formatEmergencyContactMethodToGateway = (
    initialContactMethods: Array<ContactMethod>,
    contactMethods: EmergencyContactFormType
  ) => {
    const initialContacts = formatContactMethodToGworld(initialContactMethods);
    const primaryContact = {
      id: initialContacts.primary?.id || null,
      priority: 1,
      method: ContactMethodMethods.Phone,
      type: ContactMethodTypes.Original,
      reference: Boolean(contactMethods.emergencyContactPrimaryPhone)
        ? contactMethods.emergencyContactPrimaryPhone?.toString()
        : null,
      region_code: Boolean(
        contactMethods.emergencyContactPrimaryCountryCode?.label?.length
      )
        ? contactMethods.emergencyContactPrimaryCountryCode?.dial_code
        : null,
    };
    const secondaryContact =
      Boolean(initialContacts?.secondary?.id) ||
      (Boolean(contactMethods.emergencyContactSecondaryPhone) &&
        Boolean(
          contactMethods.emergencyContactSecondaryCountryCode?.label?.length
        ))
        ? {
            id: initialContacts?.secondary?.id ?? undefined,
            priority: 2,
            method: ContactMethodMethods.Phone,
            type: ContactMethodTypes.Original,
            reference: Boolean(contactMethods.emergencyContactSecondaryPhone)
              ? contactMethods.emergencyContactSecondaryPhone?.toString()
              : null,
            region_code: Boolean(
              contactMethods.emergencyContactSecondaryCountryCode?.label?.length
            )
              ? contactMethods.emergencyContactSecondaryCountryCode?.dial_code
              : null,
          }
        : undefined;

    const result = [];
    if (primaryContact) result.push(primaryContact);
    if (secondaryContact) result.push(secondaryContact);
    return result;
  };

  const formatContactMethodToGateway = (
    initialContactMethods: Array<ContactMethod>,
    contactMethods: ContactDetailsFormType
  ) => {
    const initialContacts = formatContactMethodToGworld(initialContactMethods);
    const primaryContact = {
      id: initialContacts.primary?.id || null,
      priority: 1,
      method: ContactMethodMethods.Phone,
      type: ContactMethodTypes.Original,
      reference: Boolean(contactMethods.contactDetailsPrimaryPhone)
        ? contactMethods.contactDetailsPrimaryPhone?.toString()
        : null,
      region_code: Boolean(
        contactMethods.contactDetailsPrimaryCountryCode?.label?.length
      )
        ? contactMethods.contactDetailsPrimaryCountryCode?.dial_code
        : null,
    };
    const secondaryContact =
      Boolean(initialContacts?.secondary?.id) ||
      (Boolean(contactMethods.contactDetailsSecondaryPhone) &&
        Boolean(
          contactMethods.contactDetailsSecondaryCountryCode?.label?.length
        ))
        ? {
            id: initialContacts?.secondary?.id ?? undefined,
            priority: 2,
            method: ContactMethodMethods.Phone,
            type: ContactMethodTypes.Original,
            reference: Boolean(contactMethods.contactDetailsSecondaryPhone)
              ? contactMethods.contactDetailsSecondaryPhone?.toString()
              : null,
            region_code: Boolean(
              contactMethods.contactDetailsSecondaryCountryCode?.label?.length
            )
              ? contactMethods.contactDetailsSecondaryCountryCode?.dial_code
              : null,
          }
        : undefined;
    const travelContact =
      Boolean(initialContacts?.travel?.id) ||
      (Boolean(contactMethods.contactDetailsTravelPhone) &&
        Boolean(contactMethods.contactDetailsTravelCountryCode?.label?.length))
        ? {
            id: initialContacts?.travel?.id ?? undefined,
            priority: 2,
            method: ContactMethodMethods.Phone,
            type: ContactMethodTypes.Travel,
            reference: Boolean(contactMethods.contactDetailsTravelPhone)
              ? contactMethods.contactDetailsTravelPhone?.toString()
              : null,
            region_code: Boolean(
              contactMethods.contactDetailsTravelCountryCode?.label?.length
            )
              ? contactMethods.contactDetailsTravelCountryCode?.dial_code
              : null,
          }
        : undefined;
    const result = [];
    if (primaryContact) result.push(primaryContact);
    if (secondaryContact) result.push(secondaryContact);
    if (travelContact) result.push(travelContact);
    return result;
  };

  return {
    formatContactMethodToGateway,
    formatContactMethodToGworld,
    formatEmergencyContactMethodToGateway,
    formatGuardianContactMethodToGateway,
  };
};

export default useFormatContactMethod;
