import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { GlobalDollarRewardTypes } from "./useGlobalDollarRewardsQuery";

const PAGE_SIZE = 10;

export enum RedeemRequestStatuses {
  PENDING = "PENDING",
  GRANTED = "GRANTED",
  DECLINED = "DECLINED",
}

type RedeemRequestsParam = {
  pageSize?: number;
  pageNum?: number;
};

export type RedeemRequestItem = {
  cid: number;
  tid: number;
  email: string;
  first_name: string;
  global_dollar_amount: number;
  last_name: string;
  middle_name: string;
  reward_title: string;
  reward_type: GlobalDollarRewardTypes;
  id: number;
  global_dollar_reward_id: number;
  status: RedeemRequestStatuses;
  internal_comment: string;
  division: string;
  destination: string;
  trip_name: string;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
};

export type RedeemRequestResult = {
  result: Array<RedeemRequestItem>;
  totalCount: number;
};

export type RecentRedeemRequestsFetchResult = {
  result: RedeemRequestResult;
  success: boolean;
};

type QueryError = {};

const useRecentRedeemRequestListQuery = (
  params?: RedeemRequestsParam,
  options?: UseInfiniteQueryOptions<
    RedeemRequestResult,
    any,
    RedeemRequestResult,
    RedeemRequestResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${REWARD_ENDPOINT}/global-dollar/redeem-request/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, JSON.stringify(params)];

  return useInfiniteQuery<RedeemRequestResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!cid) throw new Error();

      const result = await fetchWithAuth<RecentRedeemRequestsFetchResult>(url, {
        params: {
          ...params,
          pageNum: pageParam,
          pageSize: params?.pageSize ?? PAGE_SIZE,
        },
      });
      return result.result;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if (
          (lastPage?.totalCount || 0) >=
          (params?.pageSize ?? PAGE_SIZE) * pages.length
        )
          return pages?.length + 1;
        return undefined;
      },
    }
  );
};

export default useRecentRedeemRequestListQuery;
