import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import {
  InfoIcon,
  MinusIcon,
  PlusIcon,
  RotateLeftIcon,
  RotateRightIcon,
} from "@/components/Icon";
import styles from "./CropperDialog.module.scss";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import theme from "@/theme/theme";
import getCroppedImage from "./getCroppedImage";
import { Area } from "react-easy-crop/types";
import Slider from "@/components/Slider";
import Grid from "@/components/Grid";
import { IconButton } from "@/components/Button";

const easyCropContainerStyle = {
  backgroundColor: theme.palette.common.neutral.black,
};

export type CropperOptions = { crop: { x: number; y: number }; zoom: number };

const CropperDialog: React.FC<{
  open: boolean;
  onClose?: () => void;
  image?: string;
  onSubmit?: (croppedImage: File) => void;
  options?: CropperOptions;
  onCropperOptionsChange?: (options: CropperOptions) => void;
  label?: string;
}> = ({
  open,
  onClose,
  image,
  onSubmit,
  options,
  onCropperOptionsChange,
  label,
  children,
}) => {
  const cropOption = options?.crop || { x: 0, y: 0 };
  const zoomOption = options?.zoom || 1;
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState(cropOption);
  const [zoom, setZoom] = useState(zoomOption);
  const [rotation, setRotation] = useState(0);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (area, areaPixels) => {
      setCroppedAreaPixels(areaPixels);
      onCropperOptionsChange?.({ crop, zoom });
    },
    [crop, onCropperOptionsChange, zoom]
  );

  const onSubmitCrop = useCallback(async () => {
    if (!image || !croppedAreaPixels) return;
    try {
      setIsLoading(true);
      const cropResultUrl = await getCroppedImage(
        image,
        croppedAreaPixels,
        rotation
      );
      if (!cropResultUrl?.length) {
        throw new Error("Something went wrong with the cropping");
      }
      let blob = await fetch(cropResultUrl).then((r) => r.blob());
      const file = new File([blob], "resume-profile.jpg", {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });
      onSubmit?.(file);
      onClose?.();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [image, croppedAreaPixels, onSubmit, onClose, rotation]);

  const zoomOut = () => {
    const newZoom = zoom - 0.2;

    if (newZoom >= 1) setZoom(newZoom);
    else setZoom(1);
  };
  const zoomIn = () => {
    const newZoom = zoom + 0.2;

    if (newZoom <= 4) setZoom(newZoom);
    else setZoom(4);
  };

  const rotateLeft = () => {
    if (rotation >= 0 && rotation <= 90) setRotation(0);
    if (rotation > 90 && rotation <= 180) setRotation(90);
    if (rotation > 180 && rotation <= 270) setRotation(180);
    if (rotation > 270 && rotation <= 360) setRotation(270);
  };
  const rotateRight = () => {
    if (rotation >= 0 && rotation < 90) setRotation(90);
    if (rotation >= 90 && rotation < 180) setRotation(180);
    if (rotation >= 180 && rotation < 270) setRotation(270);
    if (rotation >= 270 && rotation <= 360) setRotation(360);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: styles.dialogPaperRoot }}
    >
      <DialogTitle onClose={onClose}>Crop {label} Image</DialogTitle>
      <div className={styles.cropperContainer}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          maxZoom={4}
          minZoom={1}
          zoomSpeed={0.5}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          style={{ containerStyle: easyCropContainerStyle }}
        />
      </div>
      <DialogContent className={styles.dialogContent}>
        <Grid container spacing="12px">
          <Grid item xs={12} md={6}>
            <Typography color="text.secondary">Zoom</Typography>
            <div className={styles.controlContainer}>
              <IconButton onClick={zoomOut}>
                <MinusIcon />
              </IconButton>
              <div className={styles.slider}>
                <Slider
                  aria-label="Zoom"
                  value={zoom}
                  onChange={(event, newValue) => setZoom(newValue as number)}
                  min={1}
                  max={4}
                  step={0.1}
                />
              </div>
              <IconButton onClick={zoomIn}>
                <PlusIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="text.secondary">Rotation</Typography>
            <div className={styles.controlContainer}>
              <IconButton onClick={rotateLeft}>
                <RotateLeftIcon />
              </IconButton>
              <div className={styles.slider}>
                <Slider
                  aria-label="Rotation"
                  value={rotation}
                  onChange={(event, newValue) =>
                    setRotation(newValue as number)
                  }
                  min={0}
                  max={360}
                  step={10}
                />
              </div>
              <IconButton onClick={rotateRight}>
                <RotateRightIcon />
              </IconButton>
            </div>
          </Grid>
          {Boolean(children) && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button variant="outlined" color="info" onClick={onClose}>
          Discard
        </Button>
        <Button
          onClick={async () => {
            await onSubmitCrop();
          }}
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CropperDialog;
