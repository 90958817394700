import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type DeleteMediaVariables = {
  mediaId: number;
};

type DeleteMediaResult = {
  successful: boolean;
};

type QueryError = {};

const useDeleteMediaMutation = (
  submissionId: number,
  options?: UseMutationOptions<
    DeleteMediaResult,
    QueryError,
    DeleteMediaVariables
  >
) => {
  const queryClient = useQueryClient();
  const { fetchWithAuth } = useFetchWithAuth();
  const submissionMediaCacheKey = [
    `${REWARD_ENDPOINT}/media/submission/${submissionId}`,
  ];

  return useMutation<DeleteMediaResult, QueryError, DeleteMediaVariables>(
    async ({ mediaId }: DeleteMediaVariables) => {
      if (!mediaId) throw new Error("id not found");
      const url = `${REWARD_ENDPOINT}/media/${mediaId}`;
      return fetchWithAuth<DeleteMediaResult>(url, {
        method: "DELETE",
      });
    },
    {
      ...options,
      // onSuccess: async (
      //   resultData: DeleteMediaResult,
      //   data: DeleteMediaVariables
      // ) => {
      //   queryClient.invalidateQueries(submissionMediaCacheKey);
      // },
    }
  );
};

export default useDeleteMediaMutation;
