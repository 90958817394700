import { FC, forwardRef } from "react";
import cx from "classnames";
import MuiAlert, { AlertProps as MuiAlertProps } from "@mui/material/Alert";
import MuiAlertTitle from "@mui/material/AlertTitle";
import { CheckedIcon, InfoIcon } from "@/components/Icon";
import styles from "./Alert.module.scss";

type AlertProps = Omit<MuiAlertProps, "color"> & {
  title?: string;
  fullWidth?: boolean;
  color?: "info" | "success" | "error" | "warning";
};

// eslint-disable-next-line react/display-name
const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ title, color = "info", fullWidth = true, children, ...props }, ref) => {
    return (
      <MuiAlert
        variant="standard"
        icon={color === "success" ? <CheckedIcon /> : <InfoIcon />}
        color={color}
        classes={{
          root: cx({ [styles.maxContentWidth]: !fullWidth }),
          ...props.classes,
        }}
        {...props}
        ref={ref}
      >
        {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
        {children}
      </MuiAlert>
    );
  }
);

export default Alert;
