import SvgIcon, { SvgIconProps } from "./SvgIcon";

const RotateRightIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.67256 3.30667C4.87137 4.59315 2.02212 9.52818 3.3086 14.3294C4.59508 19.1306 9.53011 21.9798 14.3313 20.6933C14.8648 20.5504 15.1814 20.0021 15.0384 19.4686C14.8955 18.9351 14.3471 18.6185 13.8137 18.7615C10.0794 19.7621 6.24104 17.546 5.24045 13.8117C4.23986 10.0775 6.45593 6.23912 10.1902 5.23853C13.6207 4.31932 17.1391 6.1148 18.4645 9.30813L15.3733 8.07153C14.9 7.88219 14.3677 8.08015 14.1273 8.51504L14.0735 8.62862C13.8841 9.10195 14.0821 9.63424 14.517 9.87466L14.6305 9.92848L19.6305 11.9285C20.1039 12.1178 20.6362 11.9199 20.8766 11.485L20.9304 11.3714L22.9304 6.3714C23.1355 5.85861 22.8861 5.27664 22.3733 5.07153C21.9 4.88219 21.3677 5.08015 21.1273 5.51504L21.0735 5.62862L20.0987 8.06658C18.2539 4.25842 13.9111 2.17096 9.67256 3.30667Z" />
    </SvgIcon>
  );
};

export default RotateRightIcon;
