import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CloseIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M5.61304 4.20981L5.70723 4.29302L11.998 10.586L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0676 4.65338 20.0953 5.22061 19.7903 5.6129L19.7071 5.70711L13.413 12.001L19.7072 18.2979C20.0977 18.6885 20.0976 19.3217 19.707 19.7122C19.3464 20.0726 18.7792 20.1002 18.387 19.7951L18.2928 19.7119L11.999 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.93241 19.3466 3.90468 18.7794 4.2097 18.3871L4.29289 18.2929L10.584 12L4.29277 5.70698C3.90231 5.31639 3.90242 4.68322 4.29302 4.29277C4.65357 3.93235 5.2208 3.90472 5.61304 4.20981Z" />
    </SvgIcon>
  );
};

export default CloseIcon;
