import SvgIcon, { SvgIconProps } from "./SvgIcon";

const GlobalDollarColoredIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <circle
        cx="12"
        cy="12"
        r="9"
        fill="#FFDE31"
        stroke="#CAAA00"
        strokeWidth="2"
      />
      <path
        d="M12.8058 6V7.1433C13.8838 7.26457 14.8155 7.64522 15.5672 8.27392L15.7677 8.45152L14.6207 10.0096C14.1633 9.58519 13.6171 9.2958 13.0499 9.12177L12.8058 9.05419V11.0827L13.1201 11.1651L13.4357 11.2558C13.4882 11.2717 13.5406 11.2881 13.5928 11.3049L13.9036 11.4125C15.0307 11.8318 16 12.5389 16 14.0666C16 15.6399 15.0145 16.8716 13.0304 17.1552L12.8058 17.1828V18H11.441V17.1828C10.0181 17.0593 8.94942 16.5389 8.164 15.8128L8 15.6541L9.13249 14.0225C9.62493 14.548 10.337 15.0241 11.2015 15.2356L11.441 15.2866V13.0083L10.9747 12.8862C10.871 12.8571 10.7675 12.8263 10.6649 12.7936L10.36 12.689C9.25526 12.281 8.31941 11.585 8.31941 10.0684C8.31941 8.6388 9.39649 7.41363 11.2265 7.14108L11.441 7.1139V6H12.8058ZM12.8058 13.3463V15.316C13.5463 15.169 13.8947 14.7427 13.8947 14.2724C13.8947 13.8246 13.5112 13.5898 12.9498 13.3944L12.8058 13.3463ZM11.441 8.96599C10.8022 9.05419 10.4392 9.40697 10.4392 9.90674C10.4392 10.2975 10.7808 10.5153 11.2962 10.6961L11.441 10.7446V8.96599Z"
        fill="#594B00"
      />
    </SvgIcon>
  );
};

export default GlobalDollarColoredIcon;
