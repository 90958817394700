import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { DOCUMENTS_ENDPOINT } from "@/fetch/documents/constants";

type FinalizeBookingResult = {
  message: string;
  success: boolean;
};

type QueryError = {};

const useFinalizeBookingMutation = (
  options?: UseMutationOptions<FinalizeBookingResult, QueryError>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const queryClient = useQueryClient();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const cid = currentTrip?.cid;

  const bookingFormUrl = `${DOCUMENTS_ENDPOINT}/documents/traveller/${cid}/read-only/trip/${tid}/booking`;
  const conditionsFormUrl = `${DOCUMENTS_ENDPOINT}/documents/traveller/${cid}/read-only/trip/${tid}/terms & conditions`;

  return {
    ...useMutation<FinalizeBookingResult, QueryError>(
      async () => {
        const url = `${GATEWAY_URL}/api/finalize-booking`;

        if (!tid) throw new Error("tid not found");
        if (!cid) throw new Error("tid not found");
        return fetchWithAuth<FinalizeBookingResult>(url, {
          method: "POST",
          body: {
            cid,
            tid,
          },
        });
      },
      {
        ...options,
        onSuccess: () => {
          queryClient.invalidateQueries(bookingFormUrl);
          queryClient.invalidateQueries(conditionsFormUrl);
        },
      }
    ),
    cancel,
  };
};

export default useFinalizeBookingMutation;
