import SvgIcon, { SvgIconProps } from "./SvgIcon";

const EditIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.3723 19.5176C9.84622 20.0038 9.19993 20.3408 8.50116 20.496L8.23679 20.5456L5.4451 20.9749C4.3381 21.1451 3.29842 20.4337 3.05298 19.3678L3.0245 19.2203L3.0045 18.9852C2.99903 18.9028 2.99836 18.8425 3.00353 18.7751L3.01579 18.6649L3.05229 18.4318L3.67905 15.7018C3.84064 14.998 4.19086 14.352 4.69074 13.8312L4.86286 13.6623L15.5272 3.80657C16.7037 2.71922 18.5213 2.73874 19.6738 3.81991L19.8088 3.95475L21.1862 5.41857C22.2833 6.5846 22.2636 8.38591 21.1727 9.52806L21.0366 9.66187L10.3723 19.5176ZM16.7052 10.9281L13.9505 8.00047L6.24022 15.1261C5.98657 15.3605 5.79884 15.655 5.69384 15.9806L5.64832 16.1459L5.02155 18.8759L5.02023 18.9134C5.02664 18.9544 5.05727 18.9857 5.09544 18.9952L5.13549 18.997L7.92719 18.5678C8.26991 18.5151 8.59217 18.376 8.86361 18.1651L8.99495 18.0537L16.7052 10.9281ZM19.7091 6.78361L18.3318 5.31979C17.9807 4.94666 17.4092 4.89911 17.0026 5.19048L16.9046 5.27039L15.4275 6.63543L18.1822 9.56308L19.6593 8.19804C20.038 7.84802 20.0843 7.27699 19.7845 6.87356L19.7091 6.78361Z" />
    </SvgIcon>
  );
};

export default EditIcon;
