import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";
import { ContactMethod } from "./useCreateContactMethodMutation";

type UpdateEmergencyContactMethodVariables = {
  obj: {
    data: ContactMethod;
    emergencyContactId: number;
  };
  correlationId: string;
};

type UpdateEmergencyContactMethodResult = {
  success: boolean;
  result: { changedRows: number };
};

type QueryError = {};

const useUpdateEmergencyContactMethodMutation = (
  options?: UseMutationOptions<
    UpdateEmergencyContactMethodResult,
    QueryError,
    UpdateEmergencyContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      UpdateEmergencyContactMethodResult,
      QueryError,
      UpdateEmergencyContactMethodVariables
    >(
      async (input: UpdateEmergencyContactMethodVariables) => {
        const { obj, correlationId } = input;
        const { data, emergencyContactId } = obj;
        const url = `${TRAVELLER_ENDPOINT}/contact-method/${data?.id}/emergency-contact/${emergencyContactId}`;
        delete data.id;
        delete data.method;
        return fetchWithAuth<UpdateEmergencyContactMethodResult>(url, {
          method: "PATCH",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateEmergencyContactMethodMutation;
