import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type InvoiceHistory = {
  amount: number;
  audit_comments: string;
  audit_staff: number;
  audit_staff_name: string;
  audit_status: number;
  audit_time: number;
  card_ending: string;
  card_id: number;
  cid: number;
  collector_staff_id: number;
  comment: string;
  currency: string;
  currency_symbol: string;
  department_id: number;
  deposit: number;
  destination: string;
  division: string;
  duration: string;
  email: string;
  excess_amount: number;
  final: number;
  first_name: string;
  invoice_id: number;
  invoice_type: string;
  last_name: string;
  method: string;
  method_display: string;
  name: string;
  on: string;
  pan_suffix: string;
  processor: string;
  receipt_sent_at: number;
  tid: number;
  trans_id: number;
  transaction_claim_id: string;
  transaction_date: number;
  transaction_id: string;
  refund_status?: string;
};

type Result = {
  histories: Array<InvoiceHistory>;
  statusCode: 200;
  success: true;
};
type QueryError = {};

const useInvoiceHistoryQuery = (
  invoiceId: number | undefined,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/invoice/history/${invoiceId}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!invoiceId) throw new Error();

      const res = await fetchWithAuth<Result>(url, {
        params: { refunds: true },
      });

      return res;
    },
    {
      enabled: !!invoiceId,
      ...options,
    }
  );
};
export default useInvoiceHistoryQuery;
