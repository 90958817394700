import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type VisaType = {
  id: number;
  tid: number;
  status: string;
  type: string;
  issue_date: number;
  exp_date: number;
  note: string;
  user_description: string;
  confirmation_code: string;
  _ca: number;
  _cb: number;
  _lma?: number;
  _lmb?: number;
};

type VisaResult = {
  success: boolean;
  result: VisaType;
};
type QueryError = {};

const useVisaQuery = (
  options?: UseQueryOptions<VisaResult, QueryError, VisaResult, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${TRIP_ENDPOINT}/visa/trip/${tid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<VisaResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      const res = await fetchWithAuth<VisaResult>(url);
      return res;
    },
    {
      enabled: !!tid,
      ...options,
    }
  );
};
export default useVisaQuery;
