import { FC } from "react";
import MuiAvatarGroup, { AvatarGroupProps } from "@mui/material/AvatarGroup";
import Avatar, { AvatarProps } from "./Avatar";

interface Props extends AvatarGroupProps {
  cloudinaryPaths?: Array<string>;
  imageUrls?: Array<string>;
  AvatarProps?: AvatarProps;
}

const AvatarGroup: FC<Props> = ({
  cloudinaryPaths,
  imageUrls,
  AvatarProps,
  ...props
}) => {
  return (
    <MuiAvatarGroup {...props}>
      {Boolean(cloudinaryPaths?.length) &&
        cloudinaryPaths?.map((path, index) => (
          <Avatar
            {...AvatarProps}
            key={String.prototype.concat(path, index.toString())}
            cloudinaryPath={path}
          />
        ))}
      {Boolean(imageUrls?.length) &&
        imageUrls?.map((path, index) => (
          <Avatar
            {...AvatarProps}
            key={String.prototype.concat(path, index.toString())}
            src={path}
          />
        ))}
    </MuiAvatarGroup>
  );
};

export default AvatarGroup;
