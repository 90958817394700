import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type SignificantDate = {
  confirmed_at: any;
  confirmed_by: any;
  date: number;
  duration: any;
  epoch_offset_dest: number;
  epoch_offset_src: number;
  id: number;
  other: any;
  tid: number;
  timezone_dest: any;
  timezone_src: any;
  traveller_zone: any;
  type: string;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
};

export type TripDetails = Partial<{
  cid: number;
  data: {
    flights: [];
    accommodations: [];
    insurances: [];
    tours: [];
    charities: [];
    promotions: [];
    significantDates: Partial<{
      trip_start: SignificantDate;
      tentative_departure: SignificantDate;
      arrival_date: SignificantDate;
    }>;
    transfers: [];
  };
  deposit_complete: number;
  destination: string;
  division: string;
  duration: string;
  id: number;
  region: string;
  sales_register_id: number;
  sales_register_status: string;
  status: string;
  travellers: null;
  trip_code: string;
  trip_group_id: null;
  trip_id: number;
  trip_name: string;
  under_age: number;
  url: string;
}>;
type QueryError = {};

const useTrip = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<TripDetails, QueryError, TripDetails, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trips/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<TripDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<TripDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useTrip;
