import Dialog, { DialogContent } from "@/components/Dialog";
import { useBrowserSettings, useOpenExternalURL } from "@/hooks";
import Button from "../Button";
import { Logo } from "../Header";
import Typography from "../Typography";
import styles from "./GappForceUpdateDialog.module.scss";
import { useTrackers } from "@/hooks";
import { useEffect } from "react";

const GappForceUpdateDialog: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { isGappAndroid, isGappIos } = useBrowserSettings();
  const openExternalURL = useOpenExternalURL();
  const { track } = useTrackers();
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };
  useEffect(() => {
    track("Gapp Update Dialog Opened", {
      eventId: "gapp-update-dialog-opened",
    });
  }, []);

  const handleBackdropClick = () => {
    //these fail to keep the modal open
    return false;
  };

  const handleUpdateClick = () => {
    if (isGappAndroid) {
      track("Gapp Update Clicked", {
        eventId: "gapp-update-clicked",
        os: "android",
      });
      openExternalURL(
        "https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp"
      );
    } else if (isGappIos) {
      track("Gapp Update Clicked", {
        eventId: "gapp-update-clicked",
        os: "ios",
      });
      openExternalURL(
        "https://apps.apple.com/us/app/global-work-travel/id1535421676?itsct=apps_box_link&itscg=30200"
      );
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      PaperProps={{ className: styles.dialogRoot }}
      BackdropProps={{ className: styles.backdrop }}
    >
      <DialogContent className={styles.container}>
        <Logo className={styles.logo} />
        <Typography variant="h5" className={styles.title}>
          New Version Available
        </Typography>
        <Typography color="text.secondary">
          Looks like you are using an old version of the app.
        </Typography>
        <Typography color="text.secondary">
          Please upgrade to get the latest features &amp; best experience.
        </Typography>

        {(isGappIos || isGappAndroid) && (
          <Button
            fullWidth
            className={styles.upgradeButton}
            onClick={handleUpdateClick}
          >
            Update now
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GappForceUpdateDialog;
