import { useMutation, UseMutationOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type UpdateFirebaseConnectionMutationVariables = {
  token: string;
  deviceId: string;
  expiresIn?: number;
};

type UpdateFirebaseConnectionResult = {
  acknowledged: boolean | null;
  matchedCount: number | null;
  modifiedCount: number | null;
  upsertedCount: number | null;
  upsertedId: number | null;
};

type QueryError = {};

const useUpdateFirebaseConnectionMutation = (
  options?: UseMutationOptions<
    UpdateFirebaseConnectionResult,
    QueryError,
    UpdateFirebaseConnectionMutationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      UpdateFirebaseConnectionResult,
      QueryError,
      UpdateFirebaseConnectionMutationVariables
    >(
      async (token: UpdateFirebaseConnectionMutationVariables) => {
        const url = `${ENDPOINT}/firebase-connection`;

        return fetchWithAuth<UpdateFirebaseConnectionResult>(url, {
          method: "POST",
          body: {
            connection: token,
          },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateFirebaseConnectionMutation;
