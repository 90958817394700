import Autocomplete from "@/components/Autocomplete";
import { CountryCode, useCountryCodes } from "@/hooks";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { SyntheticEvent, useEffect } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@/components/List";
import TextField, { FieldLockStatement } from "@/components/TextField";
import Flag from "@/components/Flag";
import styles from "./CountryCodeSelector.module.scss";

const CountryCodeSelector: React.FC<{
  disabled?: boolean;
  onChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: unknown,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<unknown> | undefined
      ) => void)
    | undefined;
  value?: unknown;
  touched?: boolean;
  error?: string;
  onCountriesLoaded?: (countries?: Array<CountryCode>) => void;
  label?: string;
  variant?: "dialCode" | "country";
  locked?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
}> = ({
  disabled,
  onChange,
  value,
  touched,
  error,
  onCountriesLoaded,
  label = "Code",
  variant = "dialCode",
  locked = false,
  disableClearable = true,
  placeholder,
}) => {
  const { data: countries, isLoading: isCountriesLoading } = useCountryCodes();
  const getFormattedCountryCode = (countryCode: CountryCode) => {
    if (variant === "country")
      return countryCode.label ? countryCode.label : "";
    return (
      (countryCode.dial_code ? countryCode.dial_code : "") +
      (countryCode.code ? ` (${countryCode.code})` : "")
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => (option as CountryCode)?.label,
  });

  const filterMethod = (
    options: Array<unknown>,
    state: { inputValue: string }
  ) => {
    const { inputValue } = state;
    const filteredOptions = inputValue
      ? (options as Array<CountryCode>).filter(
          (eachOption) =>
            eachOption.label.toLowerCase().indexOf(inputValue.toLowerCase()) ===
              0 || eachOption.dial_code.slice(1).indexOf(inputValue) === 0
        )
      : options;
    return filteredOptions;
  };

  // We want to show featured countries on top of the list
  // Here are the codes of featured country codes
  const featuredCountryCodes = ["AU", "NZ", "US", "CA", "GB", "IE"];

  const featuredCountries = featuredCountryCodes.map((each) =>
    (countries || []).find((eachCountry) => eachCountry.code === each)
  );

  const notFeaturedCountries = (countries || []).filter(
    (eachCountry) =>
      !featuredCountries.find((each) => each?.code === eachCountry.code)
  );

  const alphabeticalSortedCountries = notFeaturedCountries.sort(
    (firstCountry, secondCountry) => {
      if (firstCountry.label < secondCountry.label) {
        return -1;
      }
      if (firstCountry.label > secondCountry.label) {
        return 1;
      }
      return 0;
    }
  );

  const showingCountries = [
    ...featuredCountries,
    ...alphabeticalSortedCountries,
  ];

  useEffect(() => {
    if (!countries) return;
    onCountriesLoaded?.(countries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  return (
    <Autocomplete
      classes={{ popper: styles.codeAutocompletePopper }}
      disabled={disabled}
      value={value}
      blurOnSelect="mouse"
      disableListWrap
      disableClearable={disableClearable}
      onChange={(event, value, reason, details) => {
        if (reason === "clear") onChange?.(event, {}, reason, details);
        else {
          onChange?.(event, value, reason, details);
        }
      }}
      getOptionLabel={(option) =>
        getFormattedCountryCode(option as CountryCode)
      }
      options={showingCountries || []}
      loading={isCountriesLoading}
      filterOptions={filterMethod}
      renderOption={(props, option) => {
        const item = option as CountryCode;
        return (
          <ListItem {...props}>
            <ListItemIcon>
              <Flag
                countryCode={item.code}
                className={styles.flag}
                size="1.5em"
              />
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              secondary={
                variant === "dialCode" ? `(${item.dial_code})` : undefined
              }
            />
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={touched && Boolean(error)}
          helperText={touched && error}
          InputProps={{
            ...params.InputProps,
            startAdornment: variant === "country" &&
              Boolean(Object(value)?.code) && (
                <Flag
                  countryCode={Object(value)?.code}
                  className={styles.flag}
                />
              ),
            endAdornment: locked ? (
              <FieldLockStatement className={styles.lockIcon} />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
    />
  );
};

export default CountryCodeSelector;
