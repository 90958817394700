import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import useGroupQuery from "./useGroupQuery";

const PAGE_SIZE = 30;

export type Member = Partial<{
  cid: number;
  nickname: string;
  division: string | null;
  bio: string | null;
  s3_profile_pic?: string | null;
  s3_resume_pic?: string | null;
  country: string | null;
  country_code: string | null;
  last_user_ip: string | null;
  tid: string | null;
  is_admin: number;
  admin_title?: string | null;
  is_suspended: number;
  last_location?: {
    location: {
      longitude: number;
      latitude: number;
    };
  };
}>;

type GroupMembersParam = {
  pageSize: number;
  pageNum?: number;
  searchParam?: string;
};

type GroupMembersResult = {
  results: Array<Member>;
  totalCount: number;
};

type GroupMembersFetchResult = {
  data: GroupMembersResult;
  statusCode: number;
  success: boolean | string;
};

type QueryError = {};

export type InfinityResult = {
  pages: Array<GroupMembersResult>;
  pageParam: any[];
};

const useGroupMembersQuery = (
  params: GroupMembersParam = { pageSize: PAGE_SIZE, pageNum: 1 },
  options?: UseInfiniteQueryOptions<
    GroupMembersResult,
    any,
    GroupMembersResult,
    GroupMembersResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { data: group, isLoading: isGroupLoading } = useGroupQuery();
  const groupId = group?.id;

  const url = `${GATEWAY_URL}/group/${groupId}`;
  const cacheKey = [url, JSON.stringify(params)];

  const queryResult = useInfiniteQuery<GroupMembersResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!groupId) throw new Error("Group id not found");

      const result = await fetchWithAuth<GroupMembersFetchResult>(url, {
        params: {
          ...params,
          pageNum: pageParam,
        },
      });
      return result.data;
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(groupId),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.totalCount > params.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult, isLoading: queryResult.isLoading || isGroupLoading };
};
export default useGroupMembersQuery;
