import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Resource } from "./useResourcesQuery";

// What is known as resource in gWorld is actually what is called "page" in database and in gHub
// but since we don't have page concept in gWorld we renamed it to resource but in apis we see that we get pages
// from gWorld gateway and in pages we have some resources. here what is called resourceId is actually a pageId
// which we use to get the page to show its contents

type ResourceResult = Partial<{
  success: boolean;
  page: Resource;
}>;
type QueryError = {};

const useResourceQuery = (
  resourceId: number,
  options?: UseQueryOptions<
    ResourceResult,
    QueryError,
    ResourceResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/resources/trip/${tid}/resource/page/${resourceId}`;
  const cacheKey = [url, resourceId];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<ResourceResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      return fetchWithAuth<ResourceResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useResourceQuery;
