import { FC } from "react";
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";

type TooltipProps = MuiTooltipProps & { value?: any };

const Tooltip: FC<TooltipProps> = MuiTooltip;

export default Tooltip;
