import MuiMenu from "@mui/material/Menu";
import MuiMenuList from "@mui/material/MenuList";
import MuiMenuItem from "@mui/material/MenuItem";

const Menu = MuiMenu;

const MenuItem = MuiMenuItem;

const MenuList = MuiMenuList;

export default Menu;

export { MenuItem, MenuList };
