import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FileIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M20.7071 7.29289C20.7285 7.31432 20.7488 7.33647 20.7679 7.35927C20.5842 7.13958 20.3084 7 20 7H16C15.4477 7 15 6.55228 15 6V2C15 1.72386 14.8881 1.47386 14.7071 1.29289L20.7071 7.29289ZM13 1V6L13.0051 6.17627C13.0963 7.75108 14.4023 9 16 9H21V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1H13Z" />
      ) : (
        <path d="M5 1H14L14.0752 1.00279L14.2007 1.02024L14.3121 1.04974L14.4232 1.09367L14.5207 1.146L14.6255 1.21969L14.7071 1.29289L20.7071 7.29289C20.7425 7.32829 20.7747 7.36567 20.8037 7.40469L20.8753 7.51594L20.9288 7.62866L20.9642 7.73401L20.9931 7.8819L21 8V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1ZM13 3H5V21H19V9H16C14.4023 9 13.0963 7.75108 13.0051 6.17627L13 6V3ZM17.585 7L15 4.415V6C15 6.55228 15.4477 7 16 7H17.585Z" />
      )}
    </SvgIcon>
  );
};

export default FileIcon;
