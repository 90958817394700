import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { Member } from "./useGroupMembersQuery";
import { useCurrentTrip } from "@/hooks";

const PAGE_SIZE = 300;

type AllGroupMembersParam = {
  pageSize: number;
  pageNum?: number;
  searchParam?: string;
};

export type AllGroupMember = Member & {
  is_suspendeds: Array<string>;
  is_admins: Array<{
    group_id: number;
    is_admin: boolean | number;
    admin_title: string;
    is_suspended?: number;
  }>;
};

type AllGroupMembersQueryResult = {
  results?: Array<AllGroupMember>;
  totalCount?: number;
};

type AllGroupMembersFetchResult = {
  data: AllGroupMembersQueryResult;
  statusCode: number;
  success: boolean | string;
};

export type InfinityResult = {
  pages: Array<AllGroupMembersQueryResult>;
  pageParam: any[];
};
type QueryError = {};

const useAllGroupMembersQuery = (
  params: AllGroupMembersParam = { pageSize: PAGE_SIZE, pageNum: 1 },
  options?: UseInfiniteQueryOptions<
    AllGroupMembersQueryResult,
    any,
    AllGroupMembersQueryResult,
    AllGroupMembersQueryResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  const url = `${GATEWAY_URL}/traveller/${cid}/all-groups-members`;
  const cacheKey = [url, JSON.stringify(params)];

  const queryResult = useInfiniteQuery<AllGroupMembersQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!cid) throw new Error("cid not found");

      const result = await fetchWithAuth<AllGroupMembersFetchResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
      return result.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.totalCount || 0) >= params.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult };
};
export default useAllGroupMembersQuery;
