import { useSnackbar as originalUseSnackbar, OptionsObject } from "notistack";
import SnackbarContent, {
  NotificationSnackbarContent,
} from "@/components/SnackbarContent";
import { Notification } from "@/fetch/notifications";

const useSnackbar = () => {
  const { enqueueSnackbar: originalEnqueueSnackbar, closeSnackbar } =
    originalUseSnackbar();

  const enqueueSnackbar = (message: string, options?: OptionsObject) => {
    return originalEnqueueSnackbar(message, {
      ...options,
      content: (key, message) => (
        <SnackbarContent
          id={key}
          message={message}
          variant={options?.variant}
        />
      ),
    });
  };

  const enqueueNotificationSnackbar = (
    notification: Notification,
    options?: OptionsObject
  ) => {
    return originalEnqueueSnackbar(notification.title, {
      ...options,
      content: (key, message) => (
        <NotificationSnackbarContent
          id={key}
          notification={notification}
          onClose={() => closeSnackbar(key)}
        />
      ),
    });
  };

  return { enqueueSnackbar, enqueueNotificationSnackbar, closeSnackbar };
};

export default useSnackbar;
