import Dialog, { DialogContent, DialogTitle } from "@/components/Dialog";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import styles from "./AppointmentDialog.module.scss";

type Props = { onClose: (queries: { [key: string]: any }) => void };

const AppointmentDialog = ({ onClose }: Props) => {
  const containerHeight = 800;
  const { data: personalDetails } = usePersonalDetailsQuery();
  const queryParamsToDeleteAfterClose = { staffId: 0 };
  const onCloseDialog = () => onClose(queryParamsToDeleteAfterClose);
  const officeId = personalDetails?.traveller.office_id || undefined;

  const appointmentIds: any = {
    "1": "AcZssZ0ojW1CbAlx0RAP3P_yB5r9wl8SCQBJ7-1ag_hstDtEmemJ33DPQlxg58OqnsHu8IrHHm6BydAu",
    "2": "AcZssZ2HK0yfHQ6IR9BYa9DSty4hxom3pXA_Ftz5wJeTmisaJ0FC5tfeRcceS1IaapcAvfoc39uiHAJs",
    "3": "AcZssZ30afUD7oyRGc_iUDTZztxrwszJhu24naqinj6a6_U2pvwgRhFfYqFuBdz6SvdWg5kC7GXkL2vc",
  };
  const appointmentId = appointmentIds[`${officeId}`];

  return (
    <Dialog
      open={true}
      classes={{ paper: styles.dialogPaper }}
      onClose={onCloseDialog}
    >
      <DialogTitle onClose={onCloseDialog} className={styles.title} />
      <DialogContent className={styles.content}>
        <div
          className={styles.iframe}
          style={{
            height: containerHeight,
          }}
        >
          {officeId && appointmentId && (
            <iframe
              src={`https://calendar.google.com/calendar/appointments/schedules/${appointmentId}?cs?gv=true`}
              className={styles.iframe}
              width="100%"
              height={containerHeight}
              frameBorder="0"
            ></iframe>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentDialog;
