import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import * as Sentry from "@sentry/nextjs";

export class FetchError extends Error {
  response = null;

  constructor(message: string) {
    super(message);

    // Because we are extending a built-in class
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

const useFetchWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [abortController, setAbortController] = useState<AbortController>(
    new AbortController()
  );

  const fetchWithAuth = async <T>(
    url: string,
    options?: {
      method?: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
      body?: any;
      params?: any;
      customHeaders?: any;
    }
  ) => {
    const token = await getAccessTokenSilently();

    const response = await fetch(
      `${url}${options?.params ? "?" : ""}${new URLSearchParams(
        options?.params
      )}`,
      {
        method: options?.method || "GET",
        body: options?.body && JSON.stringify(options?.body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ...options?.customHeaders,
        },
        signal: abortController.signal,
      }
    );
    if (!response.ok) {
      if (response.status >= 500) {
        const status = response.status || "";
        const message = `${url.replace(/\d{2,20}/g, "<ID>")} -- ${status}`;
        console.error(message);
        Sentry.captureException(message);
      }

      let errorMessage = `Got error with status ${response.status} from ${url}`;
      const res = await response.json();
      if (res?.data?.message) errorMessage = res.data.message;
      if (res?.error?.message) errorMessage = res.error.message;
      if (res?.message) errorMessage = res.message;

      const err = new FetchError(errorMessage);
      err.response = { ...res, status: response.status };
      throw err;
    }
    try {
      const res = await response.json();
      return res as T;
    } catch (error) {
      console.error(error);
      return {} as T;
    }
  };

  const cancel = () => {
    abortController.abort();
    setAbortController(new AbortController());
  };

  return {
    fetchWithAuth,
    cancel,
  };
};

export default useFetchWithAuth;
