import SvgIcon, { SvgIconProps } from "./SvgIcon";

const StarIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 18.0737L17.7851 21.415C18.1662 21.6351 18.6252 21.2911 18.521 20.8636L16.994 14.6L22.0544 10.405C22.3996 10.1188 22.2227 9.55795 21.7758 9.52166L15.091 8.97895L12.4572 3.0322C12.2815 2.63551 11.7185 2.63551 11.5428 3.0322L8.909 8.97895L2.22424 9.52166C1.77731 9.55795 1.60039 10.1188 1.9456 10.405L7.006 14.6L5.47904 20.8636C5.37481 21.2911 5.83383 21.6351 6.21488 21.415L12 18.0737Z" />
      ) : (
        <path d="M22.0581 10.4033C22.4026 10.1172 22.2261 9.55719 21.7798 9.52036L15.091 8.96842L12.4574 3.03117C12.2815 2.6347 11.7188 2.63485 11.5432 3.03143L8.909 8.97895L2.22424 9.52166C1.77731 9.55795 1.60039 10.1188 1.9456 10.405L7.006 14.6L5.47904 20.8636C5.37481 21.2911 5.83383 21.6351 6.21488 21.415L12 18.0737L17.7871 21.4161C18.1678 21.636 18.6266 21.2928 18.5231 20.8654L17.005 14.6L22.0581 10.4033ZM12.05 16.1342C12.0191 16.1163 11.9809 16.1163 11.9499 16.1342L8.0667 18.3776C7.99047 18.4217 7.89864 18.3528 7.91952 18.2673L8.94917 14.0502C8.95824 14.0131 8.94533 13.974 8.9159 13.9495L5.50035 11.1142C5.43143 11.057 5.46669 10.9451 5.55594 10.9376L10.13 10.5579L11.9087 6.52302C11.9437 6.44352 12.0565 6.44345 12.0916 6.5229L13.857 10.5141C13.8718 10.5476 13.9037 10.5703 13.9402 10.5733L18.4551 10.9482C18.5443 10.9556 18.5796 11.0676 18.5107 11.1248L15.0951 13.9601C15.0657 13.9845 15.0528 14.0236 15.0618 14.0607L16.0914 18.2776C16.1123 18.3632 16.0204 18.432 15.9442 18.3879L12.05 16.1342Z" />
      )}
    </SvgIcon>
  );
};

export default StarIcon;
