import { useMutation, UseMutationOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { TripStatus } from "@/fetch/gworld";
import { CancellationOptions } from "@/fetch/cancellation";

type UpdateTripVariables = Partial<{
  status: TripStatus | CancellationOptions;
  user_ip: boolean;
}>;

type UpdateTripResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useUpdateTripMutation = (
  options?: UseMutationOptions<
    UpdateTripResult,
    QueryError,
    UpdateTripVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<UpdateTripResult, QueryError, UpdateTripVariables>(
      async (input: UpdateTripVariables) => {
        if (!tid) throw new Error("Trip id not found");
        const url = `${TRIP_ENDPOINT}/trip/${tid}`;

        return fetchWithAuth<UpdateTripResult>(url, {
          method: "PATCH",
          body: input,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateTripMutation;
