import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

export enum ContactMethodMethods {
  Phone = "phone",
  Skype = "skype",
}

export enum ContactMethodTypes {
  Original = "original",
  Travel = "travel",
}

export type ContactMethod = Partial<{
  id?: number | null;
  method: ContactMethodMethods | null;
  priority: number | null;
  reference: string | null;
  region_code: string | null;
  type: ContactMethodTypes | null;
}>;

type CreateContactMethodVariables = {
  data: ContactMethod;
  correlationId: string;
};

type CreateContactMethodResult = {
  success: boolean;
  result: { affectedRows: number; insertId: number };
};

type QueryError = {};

const useCreateContactMethodMutation = (
  options?: UseMutationOptions<
    CreateContactMethodResult,
    QueryError,
    CreateContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      CreateContactMethodResult,
      QueryError,
      CreateContactMethodVariables
    >(
      async (input: CreateContactMethodVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/contact-method/traveller/${cid}`;

        delete data.id;
        return fetchWithAuth<CreateContactMethodResult>(url, {
          method: "POST",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateContactMethodMutation;
