import { FC } from "react";
import { DialogContent, DialogTitle, DialogActions } from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import RichContentPreview from "@/components/RichContentPreview";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import styles from "./ClaimedReward.module.scss";

type Props = {
  onClose: () => void;
  globalDollarAmount: number;
};

const ClaimedReward: FC<Props> = ({ onClose, globalDollarAmount }) => {
  return (
    <>
      <DialogTitle hasCloseButton onClose={onClose}></DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.content}>
          <Typography
            variant="h6"
            className={styles.text}
          >{`Congratulations!`}</Typography>
          <div className={styles.textContainer}>
            <Typography className={styles.text}>You have received</Typography>
            <GlobalDollarColoredIcon className={styles.globalDollarIcon} />x
            <Typography className={styles.text}>
              {globalDollarAmount} for this submission.
            </Typography>
            {/* <RichContentPreview content={` 🥳`} /> */}
          </div>
        </div>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          className={styles.button}
        >
          Got It
        </Button>
      </DialogContent>
    </>
  );
};

export default ClaimedReward;
