import * as Sentry from "@sentry/browser";
import { useCallback } from "react";
import { useCurrentTrip, useFeatureFlags } from ".";
import { useGroupQuery } from "@/fetch/social";

type EventName = string;

type Page = (
  category?: string,
  name?: string,
  properties?: { [key: string]: any }
) => void;

type Identify = (properties: {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
}) => void;

type Track = (event: EventName, properties: { [key: string]: any }) => void;

type RudderAnalytics = {
  page: Page;
  identify: (id: string, props: { [key: string]: any }) => void;
  track: Track;
};

declare global {
  interface Window {
    rudderanalytics: RudderAnalytics | undefined;
  }
}

const useTrackers = () => {
  const rudderstack = global.window?.rudderanalytics;
  const { currentTrip } = useCurrentTrip();
  const { flagsmith, flags, isLoading } = useFeatureFlags();
  const { data: group } = useGroupQuery();

  const sharedProperties = {
    platform: "gworld",
    version: process.env.NEXT_PUBLIC_VERSION,
    majorVersion: "5",
    flags,
    trip: {
      id: currentTrip?.id?.toString(),
      division: currentTrip?.division,
      destination: currentTrip?.destination,
      crmTripId: currentTrip?.trip_id?.toString(),
      tripCode: currentTrip?.trip_code,
      status: currentTrip?.status,
      socialGroupId: group?.id?.toString() ?? undefined,
    } as any,
  };

  const page: Page = (category, name, properties) => {
    return rudderstack?.page(category, name, {
      ...(properties || {}),
      ...sharedProperties,
    });
  };
  const track: Track = (event, properties) => {
    return rudderstack?.track(event, {
      ...(properties || {}),
      ...sharedProperties,
    });
  };

  const identify: Identify = useCallback(
    (props: any) => {
      const { email, firstName, lastName, id } = props;
      rudderstack?.identify(email, { email, firstName, lastName });
      Sentry.setUser(props);

      if (currentTrip) {
        flagsmith.identify(`${id}_${currentTrip.id}`, {
          firstName,
          lastName,
          cid: id,
          email,
        });
      }
    },
    [currentTrip, flagsmith, rudderstack]
  );

  const captureException = (e: any) => {
    return Sentry.captureException(e);
  };

  return { page, identify, track, captureException, isLoading };
};

export default useTrackers;
