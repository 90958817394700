import { FC, useState, useEffect } from "react";
import dayjs from "dayjs";
import Dialog, { DialogContent, DialogTitle } from "@/components/Dialog";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import Button, { IconButton } from "@/components/Button";
import {
  FacebookIcon,
  TwitterIcon,
  MailIcon,
  GlobalDollarColoredIcon,
  InfoIcon,
} from "@/components/Icon";
import { Skeleton } from "@/components/Loader";
import {
  useDialog,
  useOpenExternalURL,
  useTrackers,
  useResponsive,
} from "@/hooks";
import { useCreateReferralCodeMutation } from "@/fetch/promotion";
import {
  useTripCurrencyInformationQuery,
  useTravellerSubmissionsListQuery,
  SubmissionStatuses,
  SubmissionItem,
  useAllClaimablesListQuery,
  ClaimableItemTypes,
} from "@/fetch/rewards";
import { WEBSITE_ENDPOINT } from "@/fetch/website";
import { GWORLD_URL } from "@/fetch/gworld";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import DataTable from "@/components/DataTable";
import Tooltip from "@/components/Tooltip";
import styles from "./InvitationDialog.module.scss";
import Image from "next/image";

type Props = {
  onClose: () => void;
};

const InvitationDialog: FC<Props> = ({ onClose }) => {
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy");

  const {
    mutateAsync: createCode,
    isLoading: codeLoading,
    data: createCodeData,
    cancel,
  } = useCreateReferralCodeMutation();
  const { data: profileData } = usePersonalDetailsQuery();
  const { data: currencyInformation, isLoading: isLoadingCurrency } =
    useTripCurrencyInformationQuery();

  const currency = currencyInformation?.result.currency || "";

  const {
    data: referralSubmissionsData,
    isLoading: isLoadingReferralSubmissions,
  } = useTravellerSubmissionsListQuery({
    type: 4,
  });

  const { data: claimableList } = useAllClaimablesListQuery();

  const referralSubmissions = referralSubmissionsData?.result || [];

  const receivedSubmissions = referralSubmissions.filter(
    (each) => each.status === SubmissionStatuses.APPROVED
  );

  const referralClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.REFERRAL
  );

  const referralAmount = referralClaimable?.global_dollar_amount || "100";

  const openExternalURL = useOpenExternalURL();
  const { track } = useTrackers();

  const bannerImg =
    "https://res.cloudinary.com/gwatco/image/upload/v1667226154/f_auto,q_auto,ar_100:30,w_3000,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/Referral.jpg";

  let timerId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    const getCode = async () => {
      try {
        const cid = profileData?.traveller.id;
        const name = profileData?.traveller.first_name;
        const inputObj = { code: name || "", referrer_cid: cid || 0 };
        await createCode(inputObj);
      } catch (error) {
        //todo
      }
    };
    if (profileData) getCode();
    return () => {
      cancel();
      clearTimeout(timerId);
    };
  }, [profileData]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleCopyCode = () => {
    navigator.clipboard.writeText(createCodeData?.data?.code || "");
    setCopyButtonText("Copied");
    timerId = setTimeout(() => setCopyButtonText("Copy"), 1000);
  };

  const { isMobile } = useResponsive();

  const statusColumns = [
    {
      headerName: "Booking Date",
      selector: (row: SubmissionItem, index: number) =>
        dayjs((row?._ca || 0) * 1000)
          .utc()
          .startOf("day")
          .format("D MMM YYYY"),
      style: { paddingLeft: 0, minWidth: isMobile ? 120 : 148 },
    },
    {
      headerName: "Global Dollars",
      selector: (row: SubmissionItem, index: number) => referralAmount,
      style: { paddingLeft: 0, minWidth: isMobile ? 120 : 148 },
    },
    {
      headerName: "Status",
      selector: (row: SubmissionItem, index: number) =>
        row.status === SubmissionStatuses.APPROVED ? (
          "Approved"
        ) : (
          <div className={styles.statusCell}>
            <span>Pending</span>
            <Tooltip title="Once your unique code referral meets our standard trip deposit amount, your Global Dollars will be paid out.">
              <span>
                <InfoIcon variant="outlined" className={styles.tableInfoIcon} />
              </span>
            </Tooltip>
          </div>
        ),
      style: { paddingLeft: 0, minWidth: isMobile ? 120 : 148 },
    },
  ];

  const shareText = `I'm heading abroad with Global Work & Travel and I want YOU to come too!
Change your life and join me on the adventure of a lifetime; you'll receive ${referralAmount} off any trip with Global Work & Travel when you click the link below and book.`;

  const codeUrl = `${WEBSITE_ENDPOINT}/?promo=${createCodeData?.data?.code}`;

  const handleShareTwitter = () => {
    const queryParams = new URLSearchParams();
    queryParams.set("text", shareText);
    queryParams.set("url", codeUrl);
    const url = "https://twitter.com/intent/tweet?";
    openExternalURL(`${url}${queryParams.toString()}`);
    track("Share Code Twitter Clicked", {
      eventId: "share-code-twitter-clicked",
    });
  };

  const handleShareFacebook = () => {
    const facebookAppId = "591893657819798";
    const queryParams = new URLSearchParams();
    queryParams.set("display", "popup");
    queryParams.set("app_id", facebookAppId);
    queryParams.set(
      "redirect_uri",
      `${GWORLD_URL}/rewards?tab=reward-center&dialog=invitation`
    );
    queryParams.set("quote", shareText);
    queryParams.set("href", codeUrl);
    queryParams.set("link", codeUrl);
    openExternalURL(
      `https://facebook.com/dialog/share?${queryParams.toString()}`,
      "popup",
      "width=600,height=400"
    );
    track("Share Code Facebook Clicked", {
      eventId: "share-code-facebook-clicked",
    });
  };

  const handleShareMain = () => {
    const emailSubject = `Join me and get ${referralAmount} off Global Work & Travel!`;
    const emailBody =
      encodeURIComponent(`I'm heading abroad with Global Work & Travel and I want YOU to come too! 

Change your life and join me on the adventure of a lifetime or choose from their range of incredible trips to destinations all over the world. 
But wait there's more! You'll receive ${referralAmount} off any trip with Global Work & Travel when you click the link below and book.
      
${codeUrl}`);
    const emailHref = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${emailBody}`;
    openExternalURL(emailHref, undefined, undefined, true);
    track("Share Code Email Clicked", {
      eventId: "share-code-email-clicked",
    });
  };

  return (
    <Dialog open onClose={onClose} classes={{ paper: styles.dialogPaper }}>
      <DialogTitle hasCloseButton onClose={onClose}>
        Get {referralAmount}, Give {referralAmount}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.imageContainer}>
          <Image src={bannerImg} objectFit="cover" alt="banner" layout="fill" />
        </div>
        <div className={styles.content}>
          <Typography variant="body2" color="text.secondary">
            With our Get {referralAmount}, Give {referralAmount} reward, you get
            to hook your buddy up with a $/€/£{referralAmount} discount and
            score yourself {referralAmount} Global Dollars when you refer
            someone who books any trip with us and pays the standard trip
            deposit. Just have them pop in the code at checkout, and boom! You
            and your friend are both in for some awesome perks. Don&apos;t
            forget to check out the &apos;Claim Reward&apos; tab to see all the
            cool stuff you can exchange your Global Dollars for!
          </Typography>
          <div className={styles.sharingContainer}>
            <div className={styles.sharingCodeContainer}>
              <Typography variant="body1" color="text.secondary">
                Share your code
              </Typography>
              {codeLoading ? (
                <Skeleton
                  variant="rectangular"
                  height="40px"
                  width="196px"
                  className={styles.codeLoading}
                />
              ) : (
                <div className={styles.codeContainer} role="button">
                  <Typography variant="body1">
                    {createCodeData?.data?.code || "Not available, try again"}
                  </Typography>
                  {Boolean(createCodeData?.data?.code) && (
                    <Button
                      variant="text"
                      size="small"
                      onClick={handleCopyCode}
                    >
                      {copyButtonText}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className={styles.sharingActionContainer}>
              <IconButton
                className={styles.linkButton}
                onClick={handleShareFacebook}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                className={styles.linkButton}
                onClick={handleShareTwitter}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                className={styles.linkButton}
                onClick={handleShareMain}
              >
                <MailIcon variant="outlined" />
              </IconButton>
            </div>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.referralContainer}>
            {isLoadingReferralSubmissions ? (
              <>
                <Skeleton
                  height="56px"
                  width="112px"
                  className={styles.referralLoading}
                />
                <Skeleton height="56px" width="112px" />
              </>
            ) : (
              <div className={styles.referralContent}>
                <div className={styles.referralRow}>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={styles.referralText}
                  >
                    Friends Referred
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={styles.referralText}
                  >
                    Total Global Dollars
                    <Tooltip title="Earned through friend referral">
                      <span>
                        <InfoIcon
                          variant="outlined"
                          className={styles.infoIcon}
                        />
                      </span>
                    </Tooltip>
                  </Typography>
                </div>

                <div className={styles.referralRow}>
                  <Typography variant="h6" className={styles.referralDetails}>
                    {receivedSubmissions.length || 0}
                  </Typography>
                  <div className={styles.tokenContainer}>
                    {isLoadingCurrency ? (
                      <Skeleton width="60px" variant="text" />
                    ) : (
                      <div className={styles.earnedGlobalDollarContainer}>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                          src={`/images/global-dollar.svg`}
                          alt="icon"
                          className={styles.icon}
                        />
                        <Typography variant="h6">
                          {(receivedSubmissions.length || 0) *
                            Number(referralAmount)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {referralSubmissions.length > 0 && (
            <DataTable
              columns={statusColumns}
              rows={referralSubmissions || []}
              className={styles.referralTable}
              isLoading={isLoadingReferralSubmissions}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvitationDialog;
