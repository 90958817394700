import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useMemo } from "react";

const regions = [
  { name: "Asia Pacific", value: "Australia", id: 1 },
  { name: "The Americas", value: "Canada", id: 2 },
  { name: "Europe", value: "United Kingdom", id: 3 },
];

const getRegionByOfficeId = (officeId: number = 1) =>
  regions.find((region) => region.id === officeId);

const useMarketplaceRegions = () => {
  const { data: personalDetails, isLoading } = usePersonalDetailsQuery();
  const officeId = personalDetails?.traveller.office_id || undefined;

  const defaultRegion = useMemo(
    () => getRegionByOfficeId(officeId),
    [officeId]
  );

  return { regions, defaultRegion, isLoading };
};

export default useMarketplaceRegions;
