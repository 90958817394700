import { useState } from "react";
import { BellIcon } from "@/components/Icon";
import Menu from "@/components/Menu";
import Badge from "@/components/Badge";
import styles from "./Header.module.scss";

import { IconButton } from "@/components/Button";
import { NotificationPanel } from "../Notification";
import { useNotificationStatisticsQuery } from "@/fetch/notifications";

const NotificationMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { data: notificationStatistics } = useNotificationStatisticsQuery();
  const unseenNotificationsCount = notificationStatistics?.unseen || 0;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(event: any) => handleClick(event)}
        className={styles.iconButton}
        data-hide-mobile
      >
        <Badge
          max={99}
          badgeContent={unseenNotificationsCount}
          hidden={unseenNotificationsCount === 0}
          color="error"
        >
          <BellIcon />
        </Badge>
      </IconButton>
      <Menu
        elevation={3}
        id="notification-select-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        classes={{
          list: styles.notificationMenuList,
          paper: styles.notificationMenuPaper,
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <NotificationPanel onExit={() => setAnchorEl(null)} />
      </Menu>
    </>
  );
};

export default NotificationMenu;
