import { useNotificationsTokenQuery } from "@/fetch/notifications";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import useWebSocket from "react-use-websocket";
import useNotificationsQuery, {
  cacheKey as notificationsCacheKey,
} from "./useNotificationsQuery";
import useNotificationStatisticsQuery, {
  cacheKey as statisticsCacheKey,
} from "./useNotificationStatisticsQuery";
import produce from "immer";
import { Notification } from "@/fetch/notifications";
import { useSnackbar } from "@/hooks";

const enum Action {
  NEW = "notification",
}

type NotificationServiceData = {
  action: Action;
  results: any;
};

const useNotificationsSocket = () => {
  const { data: notificationsService } = useNotificationsTokenQuery();
  const { enqueueNotificationSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [wsUrl, setWsUrl] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");

  const { refetch: invalidateNotifications } = useNotificationsQuery();
  const { refetch: invalidateStatistics } = useNotificationStatisticsQuery();

  const revalidate = () => {
    invalidateNotifications();
    invalidateStatistics();
  };

  useWebSocket(wsUrl, {
    queryParams: { Authorization: token },
    reconnectInterval: 1,
    retryOnError: true,
    onOpen: () => {
      revalidate();
    },
    onError: (e) => console.error("WS Error", e),
    onMessage: (message) => {
      const serviceData = <NotificationServiceData>JSON.parse(message.data);
      switch (serviceData.action) {
        case Action.NEW:
          queryClient.cancelQueries(statisticsCacheKey);
          queryClient.setQueryData(statisticsCacheKey, (current: any) => {
            return { unseen: (current?.unseen && current.unseen + 1) || 1 };
          });

          queryClient.cancelQueries(notificationsCacheKey);
          queryClient.setQueryData(notificationsCacheKey, (current: any) => {
            if (current?.pages?.[0] === undefined) return current;

            return produce(current, (draft: any) => {
              draft.pages[0].unshift(serviceData.results);
            });
          });

          if ((serviceData.results as Notification)?.preview) {
            enqueueNotificationSnackbar(serviceData.results);
          }
          revalidate();
          break;

        default:
          break;
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    const url = notificationsService?.wsUrl;
    const token = notificationsService?.token;
    if (url && token) {
      setWsUrl(url);
      setToken(token);
    }
  }, [notificationsService]);
};

export default useNotificationsSocket;
