import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { usePersonalDetailsQuery } from "@/fetch/profiles";

export type Card = {
  archived: boolean;
  cid: number;
  currency: string;
  cvc: string;
  expiry_month: number;
  expiry_year: number;
  holder_name: string;
  id: number;
  number: string;
  payment_gateway: string;
  payment_plan_nominated: boolean;
  provider: string;
  status: number;
  status_name: string;
  suffix: string;
  type: string;
  visible: boolean;
  legal: {
    declaration: string;
    id: number;
  };
};
type Result = {
  cards: Array<Card>;
  statusCode: 200;
  success: true;
};
type QueryError = {};

const useCardsQuery = (
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;
  const url = `${PAYMENT_ENDPOINT}/card/${cid}`;
  const cacheKey = [url];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error();
      const res = await fetchWithAuth<Result>(url);

      return res;
    },
    {
      enabled: !!cid,
      ...options,
    }
  );
};
export default useCardsQuery;
