import { FC } from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { CircularProgress } from "@/components/Loader";

const ConversationDeleteConfirmationDialog: FC<{
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
}> = ({ onClose, onDelete, isLoading }) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle onClose={onClose}>Delete confirmation</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="text.secondary">
          Do you want to delete this chat?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="info"
          size="small"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          color="error"
          size="small"
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress color="inherit" />}
        >
          Delete chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConversationDeleteConfirmationDialog;
