import MuiLinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import React from "react";

const LinearProgress: React.FC<LinearProgressProps> = ({ ...props }) => {
  return <MuiLinearProgress {...props} />;
};

export default LinearProgress;
