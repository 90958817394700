import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Variables = {
  invoiceId: number | undefined;
  options?: Array<{
    interval: number;
    unit: number;
    count: number;
    offset: number;
  }>;
};

export type PlanOption = {
  instalments: Array<number>;
  option: {
    count: number;
    interval: number;
    unit: "month" | "week" | "day";
    offset: number;
    promotion: number;
    promotionCode: string;
    startDateFormatted: string;
    startDate: number;
    maxStartDateFormatted: string;
    maxStartDate: number;
  };
};
type Result = {
  invoice: {
    tid: number;
    cid: number;
    invoiceId: number;
    departureDate: number;
    departureDateFormatted: string;
    currency: string;
    total: number;
    discount: number;
    paid: number;
    outstanding: number;
    deposit: number;
    depositPaidAt: number;
    type: "trip" | "travel";
    status: string;
    canCallCoordinator: boolean;
    maxCloseDate: number;
    maxAllowedCount: number;
    minInstalmentAmount: number;
  };
  paymentOptions: Array<PlanOption>;
  message?: string;
  success: true;
};
type QueryError = {};

const usePlanOptionsQuery = (
  variables: Variables,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/paymentplan/options`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, JSON.stringify(variables)];
  const { invoiceId } = variables;
  let hasOptions = false;

  const query = useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!invoiceId) throw new Error();

      const res = await fetchWithAuth<Result>(url, {
        method: "POST",
        body: variables,
      });

      return res;
    },
    {
      enabled: !!invoiceId,
      ...options,
    }
  );

  const { data } = query;
  if (data?.paymentOptions) {
    hasOptions = true;
  }

  return { ...query, hasOptions };
};
export default usePlanOptionsQuery;
