/* eslint-disable @next/next/no-img-element */
import { ChatSession, useTwilioConversation } from "./hooks";
import styles from "./ConversationsList.module.scss";
import ConversationItem, { ConversationItemLoader } from "./ConversationItem";
import List from "@/components/List";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import { NewIcon } from "@/components/Icon";

const ConversationsList: React.FC<{
  sessions: Array<ChatSession>;
  isConversationsLoading?: boolean;
  onConversationClick: (conversationSid: string) => void;
  selectedConversationSid?: string | undefined;
  hasError?: boolean;
  onReload?: () => void;
  smallAvatar?: boolean;
}> = ({
  sessions,
  isConversationsLoading,
  onConversationClick,
  selectedConversationSid,
  hasError = false,
  onReload,
  smallAvatar = false,
}) => {
  const {
    setCurrentConversationSid,
    setIsChatPopOverOpen,
    setShowTravellersList,
  } = useTwilioConversation();
  const hasSession = Boolean(sessions?.length);
  const onStartNewConversation = () => {
    setIsChatPopOverOpen?.(true);
    setCurrentConversationSid?.(undefined);
    setShowTravellersList?.(true);
  };
  return (
    <List className={styles.conversationsListRoot}>
      {!isConversationsLoading && !hasSession && !hasError && (
        <div className={styles.emptyConversationList}>
          <img
            src="/images/empty-chat.svg"
            alt="lock"
            className={styles.emptyListImage}
          />
          <Typography variant="subtitle1">No chat available</Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.helperText}
          >
            Click the
            <NewIcon />
            button to start your conversation
          </Typography>
        </div>
      )}
      {hasError && (
        <div className={styles.emptyConversationList}>
          <Typography color="error"> Something went wrong</Typography>
          <Button
            onClick={() => onReload?.()}
            variant="text"
            size="small"
            className={styles.reloadButton}
          >
            Reload
          </Button>
        </div>
      )}
      {isConversationsLoading &&
        Array(2)
          .fill(null)
          .map((_, index) => <ConversationItemLoader key={index} />)}
      {!isConversationsLoading &&
        hasSession &&
        sessions?.map((session) => (
          <ConversationItem
            selected={selectedConversationSid === session?.conversationSid}
            key={session.conversationSid}
            session={session}
            onClick={() => onConversationClick(session.conversationSid)}
            smallAvatar={smallAvatar}
          />
        ))}
    </List>
  );
};

export default ConversationsList;
