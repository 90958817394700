import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

type SignificantDate = Partial<{
  tentative_departure: Partial<{
    confirmed_at: number;
    confirmed_by: number;
    date: number;
    duration: string;
    epoch_offset_dest: number;
    epoch_offset_src: number;
    id: number;
    other: any;
    tid: number;
    timezone_dest: any;
    timezone_src: any;
    traveller_zone: any;
    type: string;
    _ca: number;
    _cb: number;
    _lma: number;
    _lmb: number;
  }>;
  arrival_date: Partial<{
    confirmed_at: number;
    confirmed_by: number;
    date: number;
    duration: string;
    epoch_offset_dest: number;
    epoch_offset_src: number;
    id: number;
    other: any;
    tid: number;
    timezone_dest: any;
    timezone_src: any;
    traveller_zone: any;
    type: string;
    _ca: number;
    _cb: number;
    _lma: number;
    _lmb: number;
  }>;
  orientation: Partial<{
    confirmed_at: number;
    confirmed_by: number;
    date: number;
    duration: string;
    epoch_offset_dest: number;
    epoch_offset_src: number;
    id: number;
    other: any;
    tid: number;
    timezone_dest: any;
    timezone_src: any;
    traveller_zone: any;
    type: string;
    _ca: number;
    _cb: number;
    _lma: number;
    _lmb: number;
  }>;
}>;

type TripDetails = Partial<{
  significantDate: SignificantDate;
  transferInfo: {
    comments: any;
    date_from: number;
    date_to: number;
    description: string;
    drop_off_location: any;
    drop_off_time: any;
    id: number;
    is_included: number;
    is_return: number;
    pick_up_location: any;
    pick_up_time: any;
    status: string;
    supplier: string;
    tid: number;
    _ca: number;
    _cb: number;
    _lma: number;
    _lmb: number;
  };
}>;
type QueryError = {};

const useTripArrivalQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<TripDetails, QueryError, TripDetails, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/arrival/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<TripDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<TripDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useTripArrivalQuery;
