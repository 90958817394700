import SvgIcon, { SvgIconProps } from "./SvgIcon";

const getRotate = (dir: string): number => {
  if (dir === "up") return -90;
  if (dir === "left") return 180;
  if (dir === "down") return 90;
  else return 0;
};

const ArrowIcon: React.FC<SvgIconProps> = ({ dir = "right", ...props }) => {
  const rotate = getRotate(dir);
  return (
    <SvgIcon {...props} style={{ transform: `rotate(${rotate}deg)` }}>
      <path d="M11.2929 4.29289C10.9324 4.65338 10.9047 5.22061 11.2097 5.6129L11.2929 5.70711L16.586 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H16.586L11.2929 18.2929C10.9324 18.6534 10.9047 19.2206 11.2097 19.6129L11.2929 19.7071C11.6534 20.0676 12.2206 20.0953 12.6129 19.7903L12.7071 19.7071L19.7071 12.7071C20.0676 12.3466 20.0953 11.7794 19.7903 11.3871L19.7071 11.2929L12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289Z" />
    </SvgIcon>
  );
};

export default ArrowIcon;
