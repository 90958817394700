import SvgIcon, { SvgIconProps } from "./SvgIcon";

const BellIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 18C10.5 16.8954 11.3955 16 12.5 16C13.6046 16 14.5 16.8954 14.5 18C14.5 19.1046 13.6046 20 12.5 20C11.3955 20 10.5 19.1046 10.5 18ZM10.5 12C10.5 10.8954 11.3955 10 12.5 10C13.6046 10 14.5 10.8954 14.5 12C14.5 13.1046 13.6046 14 12.5 14C11.3955 14 10.5 13.1046 10.5 12ZM10.5 6C10.5 4.89543 11.3955 4 12.5 4C13.6046 4 14.5 4.89543 14.5 6C14.5 7.10457 13.6046 8 12.5 8C11.3955 8 10.5 7.10457 10.5 6Z"
      />
    </SvgIcon>
  );
};

export default BellIcon;
