/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useState, useRef } from "react";
import cx from "classnames";
import Dialog, { DialogContent } from "@/components/Dialog";
import { usePostQuery } from "@/fetch/social";
import Button, { IconButton } from "@/components/Button";
import {
  CloseIcon,
  HeartIcon,
  CommentIcon,
  BinIcon,
  MoreIcon,
  InfoIcon,
} from "@/components/Icon";
import Divider from "@/components/Divider";
import Avatar from "@/components/Avatar";
import Typography from "@/components/Typography";
import {
  useBrowserSettings,
  useCurrentTrip,
  useDialog,
  useResponsive,
  useTrackers,
} from "@/hooks";
import {
  useCreateCommentMutation,
  useCreateLikeMutation,
  useDeleteLikeMutation,
} from "@/fetch/social";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemIcon, ListItemText } from "@/components/List";
import RichEditor from "@/components/RichEditor";
import PostDeleteConfirmationDialog from "./PostDeleteConfirmationDialog";
import PostCommentsSection from "./PostCommentsSection";
import PostDialogMediaSection from "./PostDialogMediaSection";
import styles from "./PostDialog.module.scss";
import RichContentPreview from "@/components/RichContentPreview";
import TravellerNameLink from "./TravellerNameLink";
import dayjs from "dayjs";
import DotSeparator from "../DotSeparator";
import theme from "@/theme/theme";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
import { Skeleton } from "../Loader";
import ReportDialog from "./ReportDialog";
import PostLikesDialog from "./PostLikesDialog";
import SocialAvatar from "./SocialAvatar";
import TextField from "../TextField";

const PostDialog: FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const editorRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [commentMessage, setCommentMessage] = useState<string>("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isPostLikesDialogOpen, setIsPostLikesDialogOpen] = useState(false);
  const [shouldEmptyField, setShouldEmptyField] = useState<boolean>(false);

  const { onClose: onDialogClose, query } = useDialog();
  const { data: personalDetailsData } = usePersonalDetailsQuery();

  const { isAndroid } = useBrowserSettings();

  const { currentTrip } = useCurrentTrip();
  const { isMobile } = useResponsive();
  const { track, captureException } = useTrackers();
  const id = parseInt(query.id as string, 10);
  const {
    data: post,
    refetch: refetchPost,
    isLoading: isPostQueryLoading,
    isError: hasError,
    error,
  } = usePostQuery(
    { postId: id },
    {
      retry: (failureCount, error: any) => {
        if (error?.response?.statusCode === 404) {
          return false;
        }
        return true;
      },
    }
  );

  useEffect(() => {
    if (!post?.id) return;
    track("Post Opened", {
      postId: post?.id,
      eventId: "post-opened",
    });
  }, [post?.id]);

  const isPostDeleted =
    hasError && (error as any)?.response?.statusCode === 404;

  const isPostLoading = isPostQueryLoading;
  const selectedMediaId =
    parseInt(query?.selectedMediaId as string, 10) || undefined;

  const isMenuOpen = Boolean(anchorEl);
  const userCid = currentTrip?.cid;
  const profilePicturePath = post?.s3_profile_pic || "";
  const userProfilePicturePath = personalDetailsData?.traveller?.s3_profile_pic;
  const nickname = post?.nickname || "";
  const division = post?.division || "";
  const cid = post?.cid;
  const hasMedia = Boolean(post?.media?.length);
  const hasComments = Boolean(post?.comments?.total_comments);
  const formattedDate = dayjs((post?._ca || 0) * 1000)?.format(
    "dddd, DD MMMM YYYY - HH:mm"
  );

  const isAdmin = Boolean(post?.is_admin) || false;
  const adminTitle = post?.admin_title || null;
  const isLiked = Boolean(
    post?.likes?.sample_likes.find((each) => each.cid === userCid)
  );
  const firstRender = useRef(true);
  const isPastTraveller = post?.trip_status === TripStatus.Complete;

  const currentLike = post?.likes?.sample_likes.find(
    (each) => each.cid === userCid
  );

  const { mutateAsync: createComment, isLoading } = useCreateCommentMutation(
    post?.id as number
  );
  const { mutateAsync: createLike, isLoading: isCreateLikeLoading } =
    useCreateLikeMutation(post?.id as number);
  const { mutateAsync: deleteLike, isLoading: isDeleteLikeLoading } =
    useDeleteLikeMutation({
      postId: post?.id as number,
      likeId: currentLike?.id || 0,
    });

  const handleLike = async () => {
    try {
      if (isLiked) {
        await deleteLike();
        track("Post Like Removed", {
          postId: post?.id,
          eventId: "post-like-removed",
        });
      } else {
        await createLike();
        track("Post Liked", { postId: post?.id, eventId: "post-liked" });
      }
      refetchPost();
    } catch (error) {
      captureException(error);
    }
  };

  const handleFocusCommentInput = () => {
    // focus on text input
  };

  useEffect(() => {
    if (shouldEmptyField) {
      setShouldEmptyField(false);
    }
    firstRender.current = false;
  }, [shouldEmptyField]);

  const handleCreateComment = async () => {
    try {
      const newComment = {
        comment: commentMessage,
      };
      await createComment(newComment);
      track("Comment Added", {
        postId: post?.id,
        commentMessage,
        eventId: "comment-added",
      });

      setCommentMessage("");
      setShouldEmptyField(true);
      refetchPost();
    } catch (error) {
      //todo
    }
  };

  const menuList =
    userCid === post?.cid
      ? [
          {
            title: "Delete post",
            icon: <BinIcon color="inherit" />,
            action: () => {
              setIsDeleteDialogOpen(true);
              setAnchorEl(null);
            },
          },
        ]
      : [
          {
            title: "Report",
            icon: <InfoIcon color="inherit" variant="outlined" />,
            action: () => {
              setIsReportDialogOpen(true);
              setAnchorEl(null);
            },
          },
        ];

  const onCloseHandler = () => {
    onDialogClose({ id, selectedMediaId, tripId: query.tripId });
    onClose?.();
  };

  const isSuspended = Boolean(post?.is_suspended);

  const travellerTitle = isPastTraveller
    ? "Past Traveller"
    : isSuspended
    ? "Deactivated"
    : division;
  const travellerTitleColor =
    isPastTraveller || isSuspended
      ? theme.palette.warning.main
      : "text.secondary";

  const profileHeader = (
    <>
      <div className={styles.topBar}>
        <IconButton onClick={onCloseHandler}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.profileContainer}>
        <SocialAvatar
          cloudinaryPath={profilePicturePath}
          isAdmin={isAdmin}
          adminTitle={adminTitle}
        />
        <div className={styles.profileContent}>
          {isPostLoading ? (
            <Skeleton width="200px" />
          ) : (
            <div className={styles.titleContainer}>
              <TravellerNameLink variant="body1" cid={cid} nickname={nickname}>
                {!isAdmin && (
                  <Typography
                    variant="body2"
                    color={travellerTitleColor}
                    className={styles.division}
                  >
                    <DotSeparator color="info" size="0.25rem" />
                    {travellerTitle}
                  </Typography>
                )}
              </TravellerNameLink>
            </div>
          )}
          {isPostLoading ? (
            <Skeleton width="150px" />
          ) : (
            <Typography variant="body2" color="text.secondary">
              {formattedDate}
            </Typography>
          )}
        </div>
        {Boolean(menuList?.length) && !isPostLoading && (
          <IconButton
            className={styles.moreButton}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreIcon color="info" className={styles.moreIcon} />
          </IconButton>
        )}
      </div>
    </>
  );

  return (
    <Dialog
      open
      onClose={onCloseHandler}
      classes={{
        paper: cx(styles.dialogPaper, {
          [styles.noMediaDialogPaper]: !hasMedia,
        }),
      }}
    >
      {isPostDeleted ? (
        <DialogContent className={styles.errorDialogContent}>
          <div className={styles.topBar}>
            <IconButton onClick={onCloseHandler}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.errorContainer}>
            <img src="/images/empty-social-post.svg" alt="icon" />
            <Typography variant="h5">This post has been deleted</Typography>
            <Button variant="outlined" onClick={onCloseHandler}>
              Close
            </Button>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className={styles.dialogContent}>
          {isMobile && profileHeader}
          {hasMedia && (
            <PostDialogMediaSection
              selectedMediaId={selectedMediaId}
              media={post?.media || []}
              postId={post?.id as number}
            />
          )}

          <div
            className={cx(styles.container, {
              [styles.noMediaContainer]: !hasMedia,
            })}
          >
            {!isMobile && profileHeader}

            <div className={styles.content}>
              {isPostLoading ? (
                <div className={styles.postText}>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="40%" />
                </div>
              ) : (
                <RichContentPreview
                  className={styles.postText}
                  content={post?.content || ""}
                />
              )}

              {hasComments && <Divider className={styles.contentDivider} />}
              <PostCommentsSection
                postId={post?.id as number}
                totalComments={post?.comments?.total_comments || 0}
              />
            </div>
            <div className={styles.actionContainer}>
              <Divider className={styles.divider} />
              <div className={styles.bottomContainer}>
                <IconButton
                  onClick={handleLike}
                  className={styles.likeButton}
                  disabled={
                    isPostLoading || isCreateLikeLoading || isDeleteLikeLoading
                  }
                >
                  <HeartIcon
                    variant={isLiked ? "contained" : "outlined"}
                    htmlColor={
                      isPostLoading
                        ? "inherit"
                        : isLiked
                        ? theme.palette.common.red.medium
                        : theme.palette.common.neutral.black
                    }
                  />
                </IconButton>
                {isPostLoading ? (
                  <Skeleton className={styles.likeNumber} />
                ) : (
                  <Typography
                    variant="body2"
                    className={styles.likeNumber}
                    onClick={() => setIsPostLikesDialogOpen(true)}
                  >
                    {post?.likes?.total_likes || ""}
                  </Typography>
                )}
                <IconButton
                  onClick={handleFocusCommentInput}
                  className={styles.likeButton}
                  disabled={isPostLoading}
                >
                  <CommentIcon
                    variant="outlined"
                    className={styles.commentIcon}
                  />
                </IconButton>
                {isPostLoading ? (
                  <Skeleton className={styles.likeNumber} />
                ) : (
                  <Typography variant="body2">
                    {post?.comments?.total_comments || ""}
                  </Typography>
                )}
              </div>

              <div className={styles.inputCommentContainer}>
                <Avatar cloudinaryPath={userProfilePicturePath} />
                {isPostLoading ? (
                  <Skeleton
                    variant="rectangular"
                    height="54px"
                    width="100%"
                    style={{ marginLeft: "12px" }}
                  />
                ) : isAndroid ? (
                  <TextField
                    sx={{ width: "100%", ml: "8px" }}
                    multiline
                    placeholder="Add a comment..."
                    disabled={isLoading}
                    value={commentMessage}
                    onChange={(event) => setCommentMessage(event.target.value)}
                  />
                ) : (
                  <RichEditor
                    onChange={(newText: string) => setCommentMessage(newText)}
                    classes={{
                      emojiPickerButton: styles.emojiButton,
                      root: styles.editorRoot,
                      editor: styles.commentEditor,
                    }}
                    placeholder="Add a comment..."
                    ref={(ref) => (editorRef.current = ref)}
                    shouldEmptyField={shouldEmptyField}
                    disabled={isLoading}
                  />
                )}
                <Button
                  variant="text"
                  color="primary"
                  className={styles.sendButton}
                  onClick={handleCreateComment}
                  disabled={isLoading || !Boolean(commentMessage?.length)}
                >
                  Post
                </Button>
              </div>
            </div>
            <Menu
              elevation={3}
              id={`post-${post?.id}-select-menu`}
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {menuList?.map((item) => (
                <MenuItem key={item.title} onClick={item.action}>
                  <ListItemIcon sx={{ color: "text.primary" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
            {isDeleteDialogOpen && post?.id && (
              <PostDeleteConfirmationDialog
                onClose={() => setIsDeleteDialogOpen(false)}
                postId={post?.id}
              />
            )}
            {isReportDialogOpen && post?.id && (
              <ReportDialog
                onClose={() => setIsReportDialogOpen(false)}
                postId={post.id}
              />
            )}
            {isPostLikesDialogOpen && post?.id && (
              <PostLikesDialog
                onClose={() => setIsPostLikesDialogOpen(false)}
                postId={post.id}
              />
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default PostDialog;
