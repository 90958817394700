import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CheckedIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM17.7592 8.34784C17.3997 7.92855 16.7684 7.88006 16.3491 8.23953L10.051 13.638L7.70691 11.2943L7.61268 11.2112C7.22031 10.9063 6.65308 10.9342 6.2927 11.2947C5.90228 11.6854 5.90246 12.3185 6.29309 12.7089L9.29309 15.7073L9.38986 15.7924C9.75988 16.0779 10.2873 16.0709 10.6509 15.7592L17.6509 9.7579L17.741 9.67034C18.0752 9.30256 18.091 8.73488 17.7592 8.34784Z" />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM17.7592 8.34784C18.091 8.73488 18.0752 9.30256 17.741 9.67034L17.6509 9.7579L10.6509 15.7592C10.2873 16.0709 9.75988 16.0779 9.38986 15.7924L9.29309 15.7073L6.29309 12.7089C5.90246 12.3185 5.90228 11.6854 6.2927 11.2947C6.65308 10.9342 7.22031 10.9063 7.61268 11.2112L7.70691 11.2943L10.051 13.638L16.3491 8.23953C16.7684 7.88006 17.3997 7.92855 17.7592 8.34784Z" />
      )}
    </SvgIcon>
  );
};

export default CheckedIcon;
