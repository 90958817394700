import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useEffect } from "react";

type Result = {
  wsUrl: string;
  receiver: {
    type: "traveller";
    id: number;
  };
  token: string;
  tokenExpire: number;
};
type QueryError = {};

const useNotificationTokenQuery = (
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${ENDPOINT}/ws/token`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  const useQueryResult = useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<Result>(url);
    },
    {
      staleTime: Infinity,
      ...options,
    }
  );

  const { refetch: renewToken, data: notificationsService } = useQueryResult;

  useEffect(() => {
    const token = notificationsService?.token;
    if (token) {
      const secondsLeft =
        notificationsService?.tokenExpire - new Date().getTime() / 1000;
      const timer = setTimeout(() => {
        renewToken();
      }, secondsLeft * 1000);
      return () => clearTimeout(timer);
    }
  }, [notificationsService, renewToken]);

  return useQueryResult;
};
export default useNotificationTokenQuery;
