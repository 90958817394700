import { useMutation, UseMutationOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Variables = {
  interval: number;
  unit: "week" | "day" | "month";
  count: number;
  startDate: number;
  invoiceId: number;
  cardId: number;
};

type Result = {
  statusCode: 200;
  success: true;
};

type QueryError = {};

const useGeneratePlanMutation = (
  options?: UseMutationOptions<Result, QueryError, Variables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<Result, QueryError, Variables>(
      async (variables: Variables) => {
        const url = `${PAYMENT_ENDPOINT}/paymentplan`;
        return fetchWithAuth<Result>(url, { method: "POST", body: variables });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useGeneratePlanMutation;
