import Typography from "@/components/Typography";
import styles from "./Sidebar.module.scss";
import { Subroute } from "@/hooks";
import cx from "classnames";
import SidebarNavigator from "./SidebarNavigator";

const Sidebar: React.FC<{
  sections?: Array<Array<Subroute>>;
  currentPath?: string;
  isLoading?: boolean;
  title?: string;
  className?: string;
}> = ({ sections, currentPath, isLoading, title, className, children }) => {
  return (
    <aside className={cx(styles.container, className)}>
      {Boolean(title) && (
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
      )}
      <nav aria-label="sections" className={styles.navigation}>
        {Boolean(children) ? (
          children
        ) : (
          <SidebarNavigator
            isLoading={isLoading}
            sections={sections}
            currentPath={currentPath}
          />
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
