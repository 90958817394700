import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { SubmissionItem } from ".";

export type SubmissionsList = {
  result: Array<SubmissionItem>;
  success: boolean;
};

type QueryError = {};

const useTravellerSubmissionsListQuery = (
  params?: any,
  options?: UseQueryOptions<
    SubmissionsList,
    QueryError,
    SubmissionsList,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const URL = `${REWARD_ENDPOINT}/submission/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL, JSON.stringify(params)];

  return useQuery<SubmissionsList, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error();
      const res = await fetchWithAuth<SubmissionsList>(URL, { params });
      return res;
    },
    {
      ...options,
    }
  );
};
export default useTravellerSubmissionsListQuery;
