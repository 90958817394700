import { FC, Fragment, useEffect, useState } from "react";
import cx from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import Image from "next/image";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import Alert from "@/components/Alert";
import Button, { IconButton } from "@/components/Button";
import Uploader, {
  UploaderFile,
  UPLOADER_ACCEPT_TYPES,
} from "@/components/Uploader";
import TextField from "@/components/TextField";
import Checkbox from "@/components/Checkbox";
import {
  FileIcon,
  BinIcon,
  DownloadIcon,
  GlobalDollarColoredIcon,
} from "@/components/Icon";
import Rating from "@/components/Rating";
import {
  useDialog,
  useCurrentTrip,
  useTrackers,
  useSnackbar,
  useOpenExternalURL,
} from "@/hooks";
import {
  RewardItem,
  useCreateSubmissionHandler,
  useSubmissionQuery,
  useDeleteMediaMutation,
  useSubmissionMediaListQuery,
  MediaItem,
  useUpdateSubmissionHandler,
  SubmissionStatuses,
  useAllClaimablesListQuery,
} from "@/fetch/rewards";
import { LinearProgress } from "@/components/Loader";
import RichContentPreview from "@/components/RichContentPreview";
import { ListItem } from "@/components/List";
import { Skeleton } from "@/components/Loader";
import PendingReward from "./PendingReward";
import ClaimedReward from "./ClaimedReward";
import SuccessfulSubmission from "./SuccessfulSubmission";
import styles from "./VideoDialog.module.scss";

type VideoSubmissionFormType = {
  rating: number | null;
  rewards_id: number | undefined;
  media_description: string;
  condition: boolean;
  photos: Array<UploaderFile | MediaItem>;
  videos: Array<UploaderFile | MediaItem>;
};

const validationSchema = yup.object({
  rating: yup
    .string()
    .required(`Rate your time abroad with us out of 5 stars.`)
    .typeError("Rate your time abroad with us out of 5 stars."),
  media_description: yup.string(),
  condition: yup
    .bool()
    .oneOf([true], "Please tick to prove you have read the above and agree."),
  photos: yup.array(),
  videos: yup.array().required().min(1, `Please upload your video here.`),
});

type Props = {};

const VideoDialog: FC<Props> = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [removingMedias, setRemovingMedias] = useState<Array<number>>([]);

  const rating = ["1", "2", "3", "4", "5"];

  const bannerImg =
    "https://res.cloudinary.com/gwatco/image/upload/f_auto,q_auto,ar_100:30,w_3000,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/Reward.jpg";
  const baseRewardUrl =
    "https://dvhvji4glt0rj.cloudfront.net/img/reward-centre/";
  const { onClose, query } = useDialog();
  const { currentTrip } = useCurrentTrip();
  const {
    handleCreateSubmission,
    isCreatingMedias,
    isCreatingSubmission,
    isFinalizing,
    isUploading,
  } = useCreateSubmissionHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { captureException, track } = useTrackers();
  const openExternalLink = useOpenExternalURL();

  const {
    handleUpdateSubmission,
    isCreatingMedias: isCreatingMediasResubmission,
    isUpdatingSubmission,
    isUploading: isUploadingSubmission,
  } = useUpdateSubmissionHandler();

  const submissionId = Number(query?.submissionId);
  const hasSubmission = Boolean(submissionId);
  const { data: submissionData, isLoading: isLoadingSubmission } =
    useSubmissionQuery(submissionId);
  const { data: claimableList } = useAllClaimablesListQuery();

  const videoClaimable = claimableList?.result.find((each) => each.id === 2);

  const currentSubmission = submissionData?.result;

  const isSubmissionApproved =
    currentSubmission?.status === SubmissionStatuses.APPROVED;
  const isSubmissionDeclined =
    currentSubmission?.status === SubmissionStatuses.CHANGE_REQUESTED;
  const isSubmissionClaimed =
    currentSubmission?.status === SubmissionStatuses.CLAIMED;
  const isSubmissionPending =
    currentSubmission?.status === SubmissionStatuses.PENDING;

  const { data: submissionMediaData, isLoading: isLoadingSubmissionMedia } =
    useSubmissionMediaListQuery(submissionId);

  const submissionMedias = submissionMediaData?.result || [];
  const mediaPhotos = submissionMedias.filter((each) =>
    each.mime?.includes("image")
  );
  const mediaVideos = submissionMedias.filter(
    (each) => !each.mime?.includes("image")
  );

  const { mutateAsync: deleteMedia, isLoading: isDeletingMedia } =
    useDeleteMediaMutation(submissionId as number);

  const isLoading =
    isCreatingMedias ||
    isCreatingSubmission ||
    isFinalizing ||
    isUploading ||
    isCreatingMediasResubmission ||
    isUpdatingSubmission ||
    isUploadingSubmission;

  const onSubmit = async () => {
    try {
      if (hasSubmission) {
        const data = {
          submissionId: currentSubmission?.id as number,
          media_description:
            formik.values.media_description || "video description",
          rating: `${formik.values.rating}`,
          type: 2,
          experience: "video experience", // it is required for creating submission
        };
        const newFiles = [
          ...formik.values.photos.filter(
            (each) => !Boolean((each as MediaItem)?.id)
          ),
          ...formik.values.videos.filter(
            (each) => !Boolean((each as MediaItem)?.id)
          ),
        ];
        await handleUpdateSubmission({
          files: newFiles as Array<UploaderFile>,
          submissionInput: data,
        });
        track("Video Reward Resubmitted", {
          eventId: "video-reward-resubmitted",
          numberOfPhotos: formik.values.photos.length,
          numberOfVideos: formik.values.videos.length,
        });
      } else {
        const data = {
          tid: currentTrip?.id,
          cid: currentTrip?.cid,
          media_description:
            formik.values.media_description || "video description",
          rating: `${formik.values.rating}`,
          type: 2,
          experience: "video experience", // it is required for creating submission
        };
        await handleCreateSubmission({
          files: [
            ...formik.values.photos,
            ...formik.values.videos,
          ] as Array<UploaderFile>,
          submissionInput: data,
        });
        track("Video Reward Submitted", {
          eventId: "video-reward-submitted",
          numberOfPhotos: formik.values.photos.length,
          numberOfVideos: formik.values.videos.length,
        });
      }

      setShowSuccess(true);
    } catch (error) {
      captureException(error);
      console.error(error);
      enqueueSnackbar("Sorry, could not upload files.", {
        variant: "error",
      });
    }
  };

  const initialValues = {
    rating: currentSubmission?.rating || null,
    media_description: currentSubmission?.media_description || "",
    condition: hasSubmission,
    photos: mediaPhotos || [],
    videos: mediaVideos || [],
  } as VideoSubmissionFormType;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!currentSubmission) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubmission, isLoadingSubmissionMedia]);

  const handleSelectVideosFile = (files: Array<UploaderFile>) => {
    formik.setValues((values) => ({
      ...values,
      videos: values.videos.concat(files),
    }));
  };

  const handleRemoveVideoFile = (index: number) => {
    formik.setValues((values) => ({
      ...values,
      videos: values.videos.filter((each, idx) => idx !== index),
    }));
  };

  const isLoadingExistingSubmission =
    hasSubmission && (isLoadingSubmission || isLoadingSubmissionMedia);

  const openMedia = (media: MediaItem) => {
    openExternalLink(media.preSignedGetUrl as string);
  };

  const handleRemoveMedia = async (
    media: MediaItem,
    type: "video" | "photo"
  ) => {
    const mediaId = media.id;
    try {
      setRemovingMedias((prev) => prev.concat(mediaId as number));
      await deleteMedia({ mediaId: mediaId as number });
      if (type === "video") {
        formik.setValues((values) => ({
          ...values,
          videos: values.videos.filter(
            (each) => (each as MediaItem)?.id !== mediaId
          ),
        }));
      } else {
        formik.setValues((values) => ({
          ...values,
          photos: values.photos.filter(
            (each) => (each as MediaItem)?.id !== mediaId
          ),
        }));
      }
      setRemovingMedias((prev) => prev.filter((each) => each !== mediaId));
      enqueueSnackbar("File removed successfully.", {
        variant: "info",
      });
    } catch (error) {
      captureException(error);
      setRemovingMedias((prev) => prev.filter((each) => each !== mediaId));
      enqueueSnackbar("Sorry, could not remove your file. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    onClose({ submissionId });
  };

  const goToTiktokUrl = () => {
    const tiktokLink =
      "https://www.tiktok.com/@globalworkandtravel/video/7024384155093880066";

    openExternalLink(tiktokLink);
  };

  return (
    <Dialog
      open
      onClose={isLoading ? undefined : handleClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      {isLoadingExistingSubmission && (
        <>
          <DialogTitle hasCloseButton onClose={handleClose}>
            <Skeleton variant="text" width="60%" />
          </DialogTitle>
          <DialogContent>
            <Skeleton variant="rectangular" height={200} />
          </DialogContent>
        </>
      )}
      {!(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission &&
        !showSuccess && (
          <DialogTitle hasCloseButton={!isLoading} onClose={handleClose}>
            {`Make an Ad`}
          </DialogTitle>
        )}
      {isLoading && (
        <>
          <LinearProgress />
          <DialogContent className={styles.loadingContent}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/reward-loading.svg`}
              alt="icon"
              className={styles.loadingIcon}
            />
            <Typography>
              {`You're nearly there, please wait while we upload your files!`}
            </Typography>
          </DialogContent>
        </>
      )}
      {!isLoading &&
        !showSuccess &&
        !(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission && (
          <>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.imageContainer}>
                <Image
                  src={bannerImg}
                  objectFit="cover"
                  alt="banner"
                  layout="fill"
                />
              </div>
              <div className={styles.content}>
                {hasSubmission && (
                  <>
                    {isLoadingExistingSubmission ? (
                      <Skeleton
                        variant="rectangular"
                        height="96px"
                        className={styles.reasonAlert}
                      />
                    ) : (
                      <Alert
                        variant="standard"
                        color="error"
                        className={styles.reasonAlert}
                      >
                        {currentSubmission?.reason || ""}
                      </Alert>
                    )}
                  </>
                )}
                <Typography variant="body1">
                  Please follow our simple guidelines when making an Ad
                </Typography>
                <div>
                  <ListItem className={styles.listItem}>
                    Create a 30-second video, including clips of you talking
                    into the camera as well as highlights from your trip – the
                    destination, activities, and all the fun you had! Let your
                    personality shine and tell the world what travel means to
                    you. Remember, the video should be high energy and reflect
                    the joy of your journey.
                    <br />
                    <br /> See a sample ad&nbsp;
                    <Typography component="a" onClick={() => goToTiktokUrl()}>
                      here
                    </Typography>
                  </ListItem>
                  <ListItem className={styles.listItem}>
                    Make sure your video is accompanied by royalty-free music
                    (if any) and is void of any captions. If you&apos;re
                    selected as a winner, we&apos;ll handle the captioning.
                  </ListItem>
                </div>

                <Typography variant="body1">
                  How was your experience with us?
                </Typography>
                <div className={styles.ratingContainer}>
                  {isLoadingExistingSubmission ? (
                    <Skeleton
                      variant="rectangular"
                      height="32px"
                      width="240px"
                    />
                  ) : (
                    <Rating
                      value={formik.values.rating}
                      onChange={(event, newValue) =>
                        formik.setFieldValue("rating", newValue)
                      }
                    />
                  )}
                </div>
                {Boolean(formik.errors.rating) &&
                  Boolean(formik.touched.rating) && (
                    <Typography
                      variant="subtitle2"
                      color="error"
                      className={styles.photosErrorText}
                    >
                      {formik.errors.rating}
                    </Typography>
                  )}
                <Typography variant="body1">Upload your video</Typography>

                {isLoadingExistingSubmission ? (
                  <Skeleton variant="rectangular" height="60px" />
                ) : (
                  formik.values.videos.map((eachVideo, idx) => (
                    <Fragment
                      key={`${(eachVideo as UploaderFile)?.name}-${idx}`}
                    >
                      <div className={styles.uploadFile}>
                        <div className={styles.fileNameContainer}>
                          <FileIcon
                            variant="outlined"
                            className={styles.fileIcon}
                          />
                          <Typography variant="body2">
                            {Boolean((eachVideo as MediaItem)?.id)
                              ? (eachVideo as MediaItem)?.file_name
                              : (eachVideo as UploaderFile)?.name}
                          </Typography>
                        </div>
                        <div>
                          {Boolean((eachVideo as MediaItem)?.id) && (
                            <IconButton
                              onClick={() => openMedia(eachVideo)}
                              disabled={
                                isDeletingMedia &&
                                Boolean(
                                  removingMedias.find(
                                    (each) =>
                                      each === (eachVideo as MediaItem)?.id
                                  )
                                )
                              }
                            >
                              <DownloadIcon variant="outlined" />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => {
                              if (Boolean((eachVideo as MediaItem)?.id)) {
                                handleRemoveMedia(
                                  eachVideo as MediaItem,
                                  "video"
                                );
                              } else {
                                handleRemoveVideoFile(idx);
                              }
                            }}
                            disabled={
                              isDeletingMedia &&
                              Boolean(
                                removingMedias.find(
                                  (each) =>
                                    each === (eachVideo as MediaItem)?.id
                                )
                              )
                            }
                          >
                            <BinIcon variant="outlined" />
                          </IconButton>
                        </div>
                      </div>
                      {idx !== formik.values.videos.length - 1 && <Divider />}
                    </Fragment>
                  ))
                )}
                {isLoadingExistingSubmission ? (
                  <div className={styles.uploader} />
                ) : (
                  <Uploader
                    multiple
                    accept={[UPLOADER_ACCEPT_TYPES.Video]}
                    onChangeFile={handleSelectVideosFile}
                    className={styles.uploader}
                    maxFiles={0}
                    disabled={isDeletingMedia}
                  />
                )}
                {Boolean(formik.errors.videos) && formik.touched.videos && (
                  <Typography
                    variant="subtitle2"
                    color="error"
                    className={styles.photosErrorText}
                  >
                    {formik.errors.videos}
                  </Typography>
                )}

                {isLoadingExistingSubmission ? (
                  <Skeleton variant="text" />
                ) : (
                  <div className={styles.conditionContainer}>
                    <Checkbox
                      name="condition"
                      checked={formik.values.condition}
                      onChange={formik.handleChange}
                      className={styles.checkbox}
                      color="primary"
                    />
                    <Typography variant="subtitle2">
                      {`You confirm you have read and agree to our`}{" "}
                      <a
                        href={`https://www.globalworkandtravel.com/terms-and-conditions?tab=ca`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className={styles.termsCondition}>
                          {`Media Release & Copyright Agreement`}
                        </span>
                      </a>{" "}
                      {`in conjunction with your booking terms and conditions.`}
                    </Typography>
                  </div>
                )}
                {Boolean(formik.errors.condition) && formik.touched.condition && (
                  <Typography
                    variant="subtitle2"
                    color="error"
                    className={styles.errorText}
                  >
                    {formik.errors.condition}
                  </Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions className={styles.actionContainer}>
              <Button
                onClick={() => formik.handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isLoadingExistingSubmission || isDeletingMedia}
              >
                {hasSubmission ? "Resubmit" : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      {!isLoading &&
        showSuccess &&
        !(isSubmissionClaimed || isSubmissionPending) && (
          <SuccessfulSubmission
            onClose={handleClose}
            globalDollarAmount={videoClaimable?.global_dollar_amount || 0}
          />
        )}
      {!isLoadingExistingSubmission &&
        !showSuccess &&
        (isSubmissionClaimed || isSubmissionPending) && (
          <PendingReward onClose={handleClose} />
        )}
      {!isLoadingExistingSubmission && isSubmissionApproved && (
        <ClaimedReward
          onClose={handleClose}
          globalDollarAmount={videoClaimable?.global_dollar_amount || 0}
        />
      )}
    </Dialog>
  );
};

export default VideoDialog;
