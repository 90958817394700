import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type FlightDetailsType = Partial<{
  additional_information: any;
  airline: string;
  airline_iata: any;
  airport_from: string;
  airport_from_iata: any;
  airport_to: string;
  airport_to_iata: any;
  arrival_city: string;
  arrival_date_time: any;
  booking_class: string;
  cancellation_changes: any;
  comments: any;
  currency: any;
  department_id: any;
  departure_city: string;
  departure_date: string;
  departure_date_time: any;
  deposit: any;
  deposit_paid_at: any;
  description: any;
  discount: any;
  document: any;
  donations: any;
  expires_at: any;
  flight_id: any;
  flight_number: any;
  id: number;
  inclusion_id: number;
  invoice_id: number;
  is_expired: number;
  is_included: number;
  is_return: number;
  return_date: number;
  markup: any;
  net: any;
  order_num: number;
  outstanding: any;
  paid: any;
  pnr_ref: any;
  show_additional_information_in_invoice: number;
  show_cancellation_changes_in_invoice: number;
  status: any;
  tax: any;
  tentative_departure_date: number;
  tid: any;
  time_remaining: any;
  total: any;
  type: any;
  use_excess: any;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
}>;

type TripFlightDetails = Partial<{
  flight: FlightDetailsType;
  flistPreference: Partial<{
    departure_city: string;
    departure_country: string;
    departure_date: number;
    destination_country: string;
    id: number;
    is_return: number;
    pref_diet: string;
    pref_seat: string;
    return_date: number;
    status: any;
    stay_length: string;
    tid: number;
    _ca: number;
    _cb: number;
    _lma: number;
    _lmb: number;
  }>;
  isPaid: boolean;
}>;

type QueryError = {};

const useFlightDetailsQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<
    TripFlightDetails,
    QueryError,
    TripFlightDetails,
    QueryKey
  >
) => {
  const url = `${GATEWAY_URL}/api/trip-details/flight-details/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<TripFlightDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<TripFlightDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useFlightDetailsQuery;
