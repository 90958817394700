import { useUpdateFirebaseConnectionMutation } from "@/fetch/notifications";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useBrowserSettings from "./useBrowserSettings";
import useFcmToken from "./useFcmToken";

type TokenDetail = { token: string; deviceId: string; title: string };

const useFcm = () => {
  const { mutateAsync: updateConnections } =
    useUpdateFirebaseConnectionMutation();
  const { pathname } = useRouter();
  const { isGapp } = useBrowserSettings();
  const { setFcmToken } = useFcmToken();

  useEffect(() => {
    // notify the gApp webview about the NextJs's route changes to renew the FCM token
    if (isGapp)
      (window as any)?.ReactNativeWebView?.postMessage?.("route-changed");
  }, [isGapp, pathname]);

  useEffect(() => {
    const saveFCMToken = async (tokenDetail: TokenDetail) => {
      const expiresIn = dayjs().unix() + 60 * 60 * 24 * 7; // valid for 7 days after update
      const fcmToken = {
        token: tokenDetail?.token,
        deviceId: tokenDetail?.deviceId,
        expiresIn,
      };
      setFcmToken(fcmToken);
      // Save the FCM token and device ID pair in the database
      try {
        await updateConnections(fcmToken);
      } catch (error) {
        console.error(error);
      }
    };

    if (isGapp) {
      // Start the FCM token event listener that published by the gApp native side
      document?.addEventListener("save-token", (e: any) =>
        saveFCMToken(e.detail)
      );
    }

    return () => {
      if (isGapp) {
        // Stop listener
        document?.removeEventListener("save-token", (e: any) =>
          saveFCMToken(e.detail)
        );
      }
    };
  }, [isGapp, updateConnections]);
};

export default useFcm;
