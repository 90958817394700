import theme from "@/theme/theme";
import { ListItemButton } from "@/components/List";
import Typography from "@/components/Typography";
import styles from "./NotificationItem.module.scss";
import cx from "classnames";
import Badge from "@/components/Badge";
import Divider from "@/components/Divider";
import { CheckedIcon, WarningIcon, CrossIcon } from "@/components/Icon";
import dayjs from "dayjs";
import { useNotificationAction, useTrackers } from "@/hooks";
import { Notification } from "@/fetch/notifications";
import { useRouter } from "next/router";
import { Skeleton } from "@/components/Loader";

const NotificationItem: React.FC<{
  notification: Notification;
  onClick?: (notificationId: string) => void;
  variant?: "panel" | "preview";
}> = ({ notification, onClick: propOnClick, variant = "panel" }) => {
  const { track } = useTrackers();
  const { title, type, body, createdAt, status, _id } = notification;
  const router = useRouter();
  const { translateNotification } = useNotificationAction();
  const { Icon, path, onClick } = translateNotification(notification);
  const date = dayjs(createdAt).fromNow(true);
  const isNotClickable = !onClick && !path;
  const onNotificationClick = async () => {
    if (isNotClickable) {
      return;
    }
    track("Notification Item Clicked", {
      eventId: "notification-item-clicked",
      trigger: variant,
      intent: notification.intent,
    });
    onClick?.();
    path && router.push(path);
    if (_id) propOnClick?.(_id);
  };
  const hasSeen = variant === "preview" || status?.seen;

  return (
    <>
      <ListItemButton
        disabled={isNotClickable}
        onClick={onNotificationClick}
        className={cx(styles.root, {
          [styles.seenRoot]: hasSeen,
          [styles.notificationPreview]: variant === "preview",
        })}
      >
        <div
          className={cx(styles.iconContainer, {
            [styles.seenIconContainer]: hasSeen,
          })}
        >
          {Boolean(Icon) && (
            <Icon
              variant="outlined"
              htmlColor={
                hasSeen
                  ? theme.palette.common.blue.darker
                  : theme.palette.common.neutral.white
              }
            />
          )}
          {type === "error" && (
            <CrossIcon
              variant="contained"
              color="error"
              className={styles.typeIcon}
            />
          )}
          {type === "success" && (
            <CheckedIcon
              variant="contained"
              color="success"
              className={styles.typeIcon}
            />
          )}
          {type === "warning" && (
            <WarningIcon
              variant="contained"
              color="warning"
              className={styles.typeIcon}
            />
          )}
        </div>
        <div className={styles.contentContainer}>
          <Typography>{title}</Typography>
          {Boolean(body?.length) && (
            <Typography
              color="text.accent"
              variant="body2"
              className={styles.body}
            >
              {body}
            </Typography>
          )}
        </div>
        {variant === "panel" && (
          <div className={styles.indicatorsContainer}>
            {Boolean(date) && (
              <Typography variant="body2" color="text.secondary">
                {date}
              </Typography>
            )}
            {!hasSeen && (
              <Badge
                variant="dot"
                color="primary"
                componentsProps={{
                  badge: { className: styles.badge },
                  root: { className: styles.badgeRoot },
                }}
              />
            )}
          </div>
        )}
      </ListItemButton>
      {variant === "panel" && <Divider />}
    </>
  );
};

const NotificationItemLoader: React.FC = () => {
  return (
    <div className={styles.notificationItemLoader}>
      <Skeleton variant="circular" width="48px" height="48px" />
      <div className={styles.notificationItemLoaderContent}>
        <Skeleton variant="text" width="150px" />
        <Skeleton variant="text" width="80px" />
      </div>
    </div>
  );
};

export default NotificationItem;
export { NotificationItemLoader };
