import { FC, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import {
  MobileDatePicker as MuiMobileDatePicker,
  MobileDatePickerProps as MuiMobileDatePickerProps,
} from "@mui/x-date-pickers/MobileDatePicker";
import { CalendarIcon } from "@/components/Icon";
import { useResponsive } from "@/hooks";
import styles from "./DatePicker.module.scss";
import cx from "classnames";

type DatePickerProps = MuiDatePickerProps & MuiMobileDatePickerProps;

const DatePicker: FC<DatePickerProps> = ({ ...props }) => {
  const { isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDayjs}>
      {isMobile ? (
        <MuiMobileDatePicker
          {...props}
          components={{
            OpenPickerIcon: () => <CalendarIcon variant="outlined" />,
            ...props.components,
          }}
          DialogProps={{
            PaperProps: {
              elevation: 4,
              className: cx(
                styles.datePickerPaper,
                props.DialogProps?.PaperProps
              ),
              ...props.DialogProps?.PaperProps,
            },
            ...props.DialogProps,
          }}
        />
      ) : (
        <MuiDatePicker
          {...props}
          allowSameDateSelection={props.allowSameDateSelection ?? true}
          components={{
            OpenPickerIcon: () => <CalendarIcon variant="outlined" />,
            ...props.components,
          }}
          PaperProps={{
            elevation: 4,
            className: cx(
              styles.datePickerPaper,
              props.DialogProps?.PaperProps
            ),
            ...props.PaperProps,
          }}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            props.onClose?.();
          }}
          renderInput={(params) =>
            props.renderInput({
              ...params,
              onClick: () => !props.disabled && setIsOpen(true),
            })
          }
          InputProps={{
            onKeyDown: (e) => e.preventDefault(),
            ...props.InputProps,
          }}
        />
      )}
    </MuiLocalizationProvider>
  );
};

export default DatePicker;
