import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum ClaimableItemTypes {
  PHOTO = "photo",
  VIDEO_AD = "videoAd",
  BLOG = "blog",
  REFERRAL = "referral",
  YOUTUBE = "youtube",
}

export type ClaimableItem = Partial<{
  id: number;
  title: string;
  type: ClaimableItemTypes;
  global_dollar_amount: number;
  enabled: number;
  expiry_date: number | null;
  metadata: any;
  _ca: number;
  _cb: number;
  _lma: number | null;
  _lmb: null | number;
}>;

export type ClaimablesList = {
  result: Array<ClaimableItem>;
  success: boolean;
};

type QueryError = {};

const useAllClaimablesListQuery = (
  options?: UseQueryOptions<
    ClaimablesList,
    QueryError,
    ClaimablesList,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tripId = currentTrip?.trip_id;
  const URL = `${REWARD_ENDPOINT}/global-dollar/claimable/trip/${tripId}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<ClaimablesList, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<ClaimablesList>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useAllClaimablesListQuery;
