import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreateTwilioTokenMutationResult = {
  message?: string;
  accessToken?: string;
};
export type CreateTwilioTokenFetchResult = {
  data: CreateTwilioTokenMutationResult;
  statusCode: number;
  success: boolean;
};
type QueryError = {};

const useCreateTwilioTokenMutation = (
  options?: UseMutationOptions<CreateTwilioTokenMutationResult, QueryError>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/chat/create-access-token`;

  return {
    ...useMutation<CreateTwilioTokenMutationResult, QueryError>(async () => {
      if (!cid) throw new Error(`Could not get twilio token, cid:${cid}`);

      const result = await fetchWithAuth<CreateTwilioTokenFetchResult>(url, {
        method: "POST",
        body: {
          cid,
        },
      });
      return result.data;
    }, options),
    cancel,
  };
};
export default useCreateTwilioTokenMutation;
