import styles from "./Header.module.scss";
import cx from "classnames";
import { useRouter } from "next/router";
const Logo: React.FC<{ className?: string }> = ({ className }) => {
  const router = useRouter();
  return (
    <span
      className={cx(styles.logo, className)}
      onClick={() => {
        router.push("/experience/dashboard");
      }}
    />
  );
};

export default Logo;
