import { useMutation, UseMutationOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type RemoveFirebaseConnectionMutationVariables = {
  deviceId: string;
};

type RemoveFirebaseConnectionResult = {
  acknowledged: boolean | null;
  matchedCount: number | null;
  modifiedCount: number | null;
  upsertedCount: number | null;
  upsertedId: number | null;
};

type QueryError = {};

const useRemoveFirebaseConnectionMutation = (
  options?: UseMutationOptions<
    RemoveFirebaseConnectionResult,
    QueryError,
    RemoveFirebaseConnectionMutationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      RemoveFirebaseConnectionResult,
      QueryError,
      RemoveFirebaseConnectionMutationVariables
    >(
      async ({ deviceId }: RemoveFirebaseConnectionMutationVariables) => {
        const url = `${ENDPOINT}/firebase-connection/${deviceId}`;

        return fetchWithAuth<RemoveFirebaseConnectionResult>(url, {
          method: "DELETE",
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useRemoveFirebaseConnectionMutation;
