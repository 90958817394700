/* eslint-disable @next/next/no-img-element */
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import styles from "./CreatePostDialog.module.scss";
import RichEditor from "@/components/RichEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  UploadMediaType,
  useCreatePostMutation,
  useFinalizePostMutation,
  useRecentPostsQuery,
  useUploadPostMedia,
} from "@/fetch/social";
import Button, { IconButton } from "@/components/Button";
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@/components/Loader";
import { MediaUploader, UploaderFile } from "@/components/Uploader";
import Grid from "@/components/Grid";
import { Typography } from "@mui/material";
import { BinIcon } from "@/components/Icon";
import { useBrowserSettings, useTrackers } from "@/hooks";
import CreatePostDialogCloseConfirmationDialog from "./CreatePostDialogCloseConfirmationDialog";
import TextField from "../TextField/TextField";

type FormType = {
  content: string;
  media: Array<{ type: UploadMediaType; file: UploaderFile }>;
};

const MediaPreviewItem: React.FC<{
  preview: string | undefined;
  type: UploadMediaType;
  onDelete: () => void;
  disabled: boolean;
}> = ({ preview, type, onDelete, disabled }) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.editRibbon}>
        {/* <IconButton className={styles.editButton} size="small">
          <EditIcon fontSize="small" />
        </IconButton> */}
        <IconButton
          className={styles.editButton}
          size="small"
          onClick={onDelete}
          disabled={disabled}
        >
          <BinIcon
            variant="outlined"
            fontSize="small"
            color={disabled ? "inherit" : "error"}
          />
        </IconButton>
      </div>
      {type === UploadMediaType.Image ? (
        <img
          width="160px"
          height="160px"
          alt="image"
          src={preview}
          className={styles.mediaPreview}
        />
      ) : (
        <>
          <video
            width="160px"
            height="160px"
            src={preview}
            className={styles.mediaPreview}
          />
          <span className={styles.videoPreviewItemOverlay} />
        </>
      )}
    </div>
  );
};

const CreatePostDialog: React.FC<{
  onClose: () => void;
  onCreatePost?: () => Promise<any>;
  mediaPickerInitiallyOpen?: boolean;
}> = ({ onClose, onCreatePost, mediaPickerInitiallyOpen = false }) => {
  const editorRef = useRef<any>(null);
  const [isRefetchingPosts, setIsRefetchingPosts] = useState(false);
  const [isCloseAlertDialogOpen, setIsCloseAlertDialogOpen] = useState(false);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0);
  const {
    mutateAsync: createPost,
    isLoading: isPosting,
    cancel: cancelCreatePost,
  } = useCreatePostMutation();
  const {
    mutateAsync: finalizePost,
    isLoading: isFinalizing,
    cancel: cancelFinalizePost,
  } = useFinalizePostMutation();
  const { refetch: refetchRecentPosts } = useRecentPostsQuery(
    { pinned: false },
    {
      enabled: false,
    }
  );
  const {
    uploadProfilePicture,
    cancelUploadRequest,
    isLoading: isUploading,
    progress,
  } = useUploadPostMedia();
  const { track } = useTrackers();
  const { isAndroid } = useBrowserSettings();

  const isLoading =
    isPosting || isFinalizing || isRefetchingPosts || isUploading;

  const initialValues = {
    content: "",
    media: [],
  } as FormType;

  const validationSchema = yup.object({
    content: yup.string().required("Post content can not be empty."),
  });

  const onSubmit = async (values: FormType) => {
    try {
      const createdPostResult = await createPost({ content: values.content });

      if (createdPostResult?.id) {
        for (let index = 0; index < values.media.length; index++) {
          setCurrentUploadIndex(index + 1);
          const medium = values.media[index];
          await uploadProfilePicture({
            file: medium.file,
            type: medium.type,
            postId: createdPostResult?.id,
          });
        }

        await finalizePost({
          id: createdPostResult.id,
          content: createdPostResult.content,
        });
        track("Post Created", {
          postId: createdPostResult?.id,
          eventId: "post-created",
          numberOfMedia: values.media.length || 0,
        });
      }
      setIsRefetchingPosts(true);
      await refetchRecentPosts();
      if (onCreatePost) {
        await onCreatePost?.();
      }
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefetchingPosts(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onFileSelect = (acceptedFiles: UploaderFile[]) => {
    const transformedFiles = acceptedFiles.map((item) => ({
      file: item,
      type: item.type.includes("video")
        ? UploadMediaType.Video
        : UploadMediaType.Image,
    }));

    formik.setValues((values) => {
      const newMedia = values.media.concat(transformedFiles);
      return {
        ...values,
        media: newMedia,
      };
    });
  };

  useEffect(() => {
    return () => {
      cancelUploadRequest();
      cancelCreatePost();
      cancelFinalizePost();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorChange = (text: string) => {
    formik.setValues((values) => ({ ...values, content: text }));
  };

  const onMediaDelete = (index: number) => {
    formik.setValues((values) => {
      const newMedia = values.media.filter((item, i) => index !== i);
      return {
        ...values,
        media: newMedia,
      };
    });
  };

  const onDialogCloseTrigger = () => {
    if (formik.values.content.length || formik.values.media.length)
      setIsCloseAlertDialogOpen(true);
    else onClose();
  };

  return (
    <Dialog
      onClose={onDialogCloseTrigger}
      open
      PaperProps={{ className: styles.dialogRoot }}
    >
      <DialogTitle onClose={onDialogCloseTrigger}>Create New Post</DialogTitle>
      <DialogContent className={styles.formDialogContent}>
        {isAndroid ? (
          <TextField
            sx={{ width: "100%" }}
            multiline
            placeholder="Start typing your post here..."
            rows={5}
            error={formik.touched.content && Boolean(formik.errors.content)}
            helperText={formik.touched.content && formik.errors.content}
            disabled={isLoading}
            value={formik.values.content}
            onChange={(event) => onEditorChange(event.target.value)}
          />
        ) : (
          <RichEditor
            placeholder="Start typing your post here..."
            hasSuggestions
            disabled={isLoading}
            onChange={onEditorChange}
            error={formik.touched.content ? formik.errors.content : ""}
            touched={formik.touched.content}
            ref={(ref) => (editorRef.current = ref)}
            classes={{
              emojiPickerButton: styles.emojiButton,
              editor: styles.editorRoot,
            }}
          />
        )}

        <Grid container spacing="16px" className={styles.mediaGridContainer}>
          {formik.values.media?.map((medium, index) => (
            <Grid key={index} item>
              <MediaPreviewItem
                preview={medium.file.preview}
                type={medium.type}
                onDelete={() => onMediaDelete(index)}
                disabled={isLoading}
              />
            </Grid>
          ))}
          <Grid item>
            <MediaUploader
              disabled={isLoading}
              onChangeFile={onFileSelect}
              pickerOpen={mediaPickerInitiallyOpen}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {Boolean(currentUploadIndex) && (
          <div className={styles.uploadIndicator}>
            <Typography color="text.secondary">Uploading</Typography>
            <CircularProgress
              isDark
              size="lg"
              variant="determinate"
              value={progress}
              hasProgressText
              progressText={`${currentUploadIndex}/${formik.values.media.length}`}
            />
          </div>
        )}
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={isLoading || !Boolean(formik.values.content.length)}
          startIcon={isLoading && <CircularProgress color="inherit" />}
        >
          {isLoading ? "Posting..." : "Post"}
        </Button>
      </DialogActions>
      {isCloseAlertDialogOpen && (
        <CreatePostDialogCloseConfirmationDialog
          onClose={() => setIsCloseAlertDialogOpen(false)}
          onConfirm={onClose}
        />
      )}
    </Dialog>
  );
};

export default CreatePostDialog;
