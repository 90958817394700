import { useMutation, UseMutationOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { Health } from "../usePersonalDetailsQuery";

type UpdateHealthVariables = {
  health: Health;
  correlationId: string;
};

type UpdateHealthResult = {
  result: Array<{ message: string }>;
  success: boolean;
};

type QueryError = {};

const useUpdateHealthMutation = (
  options?: UseMutationOptions<
    UpdateHealthResult,
    QueryError,
    UpdateHealthVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return {
    ...useMutation<UpdateHealthResult, QueryError, UpdateHealthVariables>(
      async ({ health, correlationId }: UpdateHealthVariables) => {
        const url = `${GATEWAY_URL}/traveller/${cid}/health`;

        return fetchWithAuth<UpdateHealthResult>(url, {
          method: "POST",
          body: { ...health, contexts: { active_trip: currentTrip?.id } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateHealthMutation;
