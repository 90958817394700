const getRemainingCharactersText = (
  value: string | undefined | null,
  max: number
) => {
  if (!value || !value?.length) return false;
  if (value?.length <= max) {
    return `${max - value?.length} / ${max}`;
  }
};

export { getRemainingCharactersText };
