import MuiInputAdornment, {
  InputAdornmentProps,
} from "@mui/material/InputAdornment";

import { LockIcon } from "@/components/Icon";
import theme from "@/theme/theme";

const FieldLockStatement: React.FC<
  Omit<InputAdornmentProps, "position"> & { position?: "start" | "end" }
> = ({ position = "end", ...props }) => {
  return (
    <MuiInputAdornment {...props} position={position}>
      <LockIcon variant="outlined" htmlColor={theme.palette.common.grey[250]} />
    </MuiInputAdornment>
  );
};

export default FieldLockStatement;
