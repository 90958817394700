import { useEffect, useState } from "react";
import { usePublicProfileQuery } from "@/fetch/profiles/traveller";
import { Skeleton } from "@/components/Loader";
import Button, { IconButton } from "@/components/Button";
import {
  ArrowIcon,
  EditIcon,
  LocationIcon,
  MoreIcon,
  CalendarIcon,
} from "@/components/Icon";
import styles from "./TravellerProfile.module.scss";
import Typography from "@/components/Typography";
import { useRouter } from "next/router";
import { useGroupQuery, useTravellerPostsQuery } from "@/fetch/social";
import { useInView } from "react-intersection-observer";
import compact from "lodash/compact";
import Divider from "@/components/Divider";
import Grid from "@/components/Grid";
import { CreatePostCard, PostCard } from "@/components/Social";
import { useCurrentTrip, useDialog, useResponsive } from "@/hooks";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemIcon, ListItemText } from "@/components/List";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import RichContentPreview from "@/components/RichContentPreview";
import ImagePreviewDialog from "./ImagePreviewDialog";
import cx from "classnames";
import { useTwilioConversation } from "@/components/Chat/hooks";
import Emoji from "@/components/Emoji";
import { EmptySocialPost } from "@/components/EmptyList";
import DotSeparator from "../DotSeparator";
import theme from "@/theme/theme";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
import { useTripDetailsQuery, Division } from "@/fetch/gworld";
import PostDialog from "./PostDialog";
import SocialAvatar from "./SocialAvatar";
import dayjs from "dayjs";

const postLoader = <Skeleton variant="rectangular" height="140px" />;

const TravellerProfile: React.FC<{ cid?: number }> = ({ cid }) => {
  const router = useRouter();
  const { currentTrip } = useCurrentTrip();
  const { isMobile } = useResponsive();
  const {
    openTopLevelDialog,
    openDialog,
    onClose: onDialogClose,
    isOpen: isImagePreviewDialogOpen,
  } = useDialog("profile-preview");
  const { isOpen: isPostDialogOpen, query } = useDialog("post");
  const { openChatPopup } = useTwilioConversation();
  const [isOpeningChat, setIsOpeningChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const thisUserCid = currentTrip?.cid || 0;
  const { data: publicProfileData, isLoading: isProfileLoading } =
    usePublicProfileQuery({ cid });

  const { data: group, isLoading: isGroupLoading } = useGroupQuery();
  const {
    data: travellerPosts,
    isLoading: isPostsLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: refetchPosts,
  } = useTravellerPostsQuery({ cid });
  const { ref: loaderContainerRef, inView: isLoaderInView } = useInView({
    threshold: 0,
  });
  const { data: tripDetails, isLoading: isLoadingTrip } = useTripDetailsQuery(
    currentTrip?.id
  );

  const openChat = async (travellerCid?: number) => {
    try {
      setIsOpeningChat(true);
      await openChatPopup(travellerCid, "Profile Page");
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpeningChat(false);
    }
  };

  const currentSocialGroupId = group?.id;
  const user = publicProfileData?.[0];
  const nickname = user?.nickname || "";
  const country = user?.country || "";
  const division =
    user?.division === Division.Superlite
      ? "SuperLite App"
      : user?.division || "";
  const isPastTraveller = user?.trip_status === TripStatus.Complete;
  const bio = user?.bio || "";
  const isAdmin =
    Boolean(
      user?.is_admins?.find((item) => item.group_id === currentSocialGroupId)
        ?.is_admin
    ) || false;
  const isSuspended =
    Boolean(
      user?.is_admins?.find((item) => item.group_id === currentSocialGroupId)
        ?.is_suspended
    ) || false;
  const adminTitle =
    user?.is_admins?.find((item) => item.group_id === group?.id)?.admin_title ||
    null;
  const isLoading = !Boolean(user) && isProfileLoading && isGroupLoading;
  const isUserNotFound = !Boolean(user) && !isProfileLoading && !isGroupLoading;

  const departureDate =
    tripDetails?.data?.significantDates.trip_start?.date ??
    tripDetails?.data?.significantDates.arrival_date?.date ??
    tripDetails?.data?.significantDates.tentative_departure?.date ??
    null;

  const formattedDepartureDate = departureDate
    ? dayjs(Number(departureDate) * 1000)
        .utc()
        .startOf("day")
        .format("MMM, YYYY")
    : "N/A";

  useEffect(() => {
    if (!isLoaderInView || !hasNextPage || isPostsLoading || isFetchingNextPage)
      return;

    fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoaderInView,
    isPostsLoading,
  ]);

  const posts = compact(
    travellerPosts?.pages?.map((page) => page?.results)?.flat(2)
  );
  const hasPosts = Boolean(posts?.length);

  const isThisUserProfile = cid === thisUserCid;

  const onCreatePost = async () => {
    try {
      await refetchPosts();
    } catch (error) {
      throw error;
    }
  };

  const menuList: Array<{ title: string; action: () => void; icon: any }> =
    isThisUserProfile ? [] : [];

  const travellerTitle = isPastTraveller
    ? "Past Traveller"
    : isSuspended
    ? "Deactivated"
    : division;
  const travellerTitleColor =
    isPastTraveller || isSuspended
      ? theme.palette.warning.main
      : "text.secondary";

  const actionButton = isLoading ? (
    <Skeleton variant="rectangular" height={48} className={styles.action} />
  ) : isThisUserProfile ? (
    <Button
      fullWidth
      className={styles.action}
      variant="outlined"
      color="info"
      onClick={() => openTopLevelDialog(TopLevelDialogNames.EditProfile)}
      startIcon={<EditIcon />}
    >
      Edit profile
    </Button>
  ) : (
    <Button
      className={styles.action}
      variant="outlined"
      color="info"
      onClick={() => openChat(user?.cid)}
      disabled={isOpeningChat}
    >
      {isOpeningChat ? (
        "Initializing..."
      ) : (
        <>
          <Emoji shortcodes=":wave::skin-tone-2:" size="1.2em" />
          Say hi
        </>
      )}
    </Button>
  );

  return (
    <div>
      {!isThisUserProfile && (
        <div className={styles.profileHeader}>
          <IconButton onClick={() => router.back()}>
            <ArrowIcon dir="left" />
          </IconButton>
        </div>
      )}
      {isUserNotFound ? (
        <Typography
          color="text.secondary"
          variant="h6"
          textAlign="center"
          className={styles.listInfo}
        >
          User not found
        </Typography>
      ) : (
        <>
          <div className={styles.intro}>
            {isLoading ? (
              <Skeleton variant="circular" width="136px" height="136px" />
            ) : (
              <SocialAvatar
                isAdmin={isAdmin}
                adminTitle={adminTitle}
                badgeContentClassName={styles.adminBadgeContent}
                badgeClassName={styles.adminBadge}
                cloudinaryPath={user?.s3_profile_pic}
                size="huge"
                onClick={() =>
                  Boolean(user?.s3_profile_pic?.length) && openDialog()
                }
                className={cx({
                  [styles.clickableAvatar]: Boolean(
                    user?.s3_profile_pic?.length
                  ),
                })}
              />
            )}
            <div className={styles.info}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <RichContentPreview content={nickname} variant="h6" />
              )}
              {isLoading ? (
                <Skeleton width={100} className={styles.locationAndDivision} />
              ) : (
                <div className={styles.details}>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={styles.locationAndDivision}
                  >
                    {Boolean(country) && (
                      <LocationIcon variant="outlined" fontSize="small" />
                    )}
                    {country}
                  </Typography>
                  {!isAdmin && (
                    <>
                      {Boolean(country) && (
                        <DotSeparator color="info" size="0.25rem" />
                      )}
                      <Typography variant="body1" color={travellerTitleColor}>
                        {travellerTitle}
                      </Typography>
                    </>
                  )}
                </div>
              )}
              {isLoading ? (
                <Skeleton width={100} className={styles.locationAndDivision} />
              ) : (
                <div className={styles.details}>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={styles.locationAndDivision}
                  >
                    <CalendarIcon variant="outlined" fontSize="small" />
                    {formattedDepartureDate}
                  </Typography>
                </div>
              )}
              {!isMobile && !isPastTraveller && !isSuspended && actionButton}
            </div>
            {Boolean(menuList?.length) && (
              <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className={styles.moreMenuButton}
              >
                <MoreIcon />
              </IconButton>
            )}
          </div>
          {isLoading ? (
            <div className={styles.bio}>
              {Array(3)
                .fill(null)
                .map((item, index) => (
                  <Skeleton
                    key={index}
                    style={{ margin: "4px 0px" }}
                    width="100%"
                  />
                ))}
            </div>
          ) : (
            <RichContentPreview
              content={bio}
              variant="body1"
              className={styles.bio}
            />
          )}
          {isMobile && !isPastTraveller && !isSuspended && (
            <div className={styles.actionButtonContainer}>{actionButton}</div>
          )}
          <Divider className={styles.divider} />

          {isThisUserProfile &&
            (isProfileLoading ? (
              <Skeleton
                variant="rectangular"
                height="140px"
                className={styles.createPostCard}
              />
            ) : (
              <CreatePostCard
                className={styles.createPostCard}
                onCreatePost={onCreatePost}
              />
            ))}

          {isProfileLoading ? (
            <Skeleton width="150px" />
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              className={styles.momentsText}
            >
              {isThisUserProfile ? "Your " : `${nickname}'s `}
              Moments
            </Typography>
          )}

          <Grid
            container
            spacing={{ md: "24px", xs: "16px" }}
            className={styles.gridContainer}
          >
            {!hasPosts && !isPostsLoading && (
              <Grid item xs={12}>
                <EmptySocialPost
                  subtitle={
                    isThisUserProfile
                      ? "What’s going on today? Create your first post today!"
                      : ""
                  }
                />
              </Grid>
            )}

            {!hasPosts && isPostsLoading && (
              <Grid item xs={12}>
                {postLoader}
              </Grid>
            )}

            {posts?.map((post) => (
              <Grid item xs={12} key={post?.id}>
                <PostCard post={post} />
              </Grid>
            ))}

            {hasPosts && (
              <Grid item xs={12}>
                {hasNextPage ? (
                  <div ref={loaderContainerRef}>
                    {isFetchingNextPage && postLoader}
                  </div>
                ) : (
                  <Typography
                    color="text.secondary"
                    textAlign="center"
                    className={styles.listInfo}
                  >
                    You&apos;ve seen it all!
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          <Menu
            elevation={3}
            id="profile-options"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {menuList?.map((item) => (
              <MenuItem key={item.title} onClick={item.action}>
                <ListItemIcon sx={{ color: "text.primary" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText>{item.title}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
          {isImagePreviewDialogOpen && (
            <ImagePreviewDialog
              onClose={() => onDialogClose()}
              cloudinaryPath={user?.s3_profile_pic}
            />
          )}

          {isPostDialogOpen && query.id && <PostDialog />}
        </>
      )}
    </div>
  );
};

export default TravellerProfile;
