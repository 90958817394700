import { Trip } from "@/fetch/gworld";
import create from "zustand";
import { persist } from "zustand/middleware";

type CurrentTripState = {
  currentTrip: Trip | null | undefined;
  setCurrentTrip: (current: Trip | null | undefined) => void;
};

const useCurrentTripStore = create(
  persist<CurrentTripState>(
    (set) => ({
      currentTrip: null,
      setCurrentTrip: (currentTrip) => set({ currentTrip }),
    }),
    {
      name: "current-trip",
    }
  )
);

const useCurrentTrip = () => {
  const { currentTrip, setCurrentTrip } = useCurrentTripStore();

  return { setCurrentTrip, currentTrip };
};

export default useCurrentTrip;
