import { useGroupMembersQuery } from "@/fetch/social";
import {
  useCurrentTrip,
  useDialog,
  useSwiperSlidesPerView,
  useTrackers,
} from "@/hooks";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import Card from "@/components/Card";
import Typography from "@/components/Typography";
import styles from "./MeetOtherTravellerCard.module.scss";
import TravellerCard from "./TravellerCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper/types/swiper-class";
import { Navigation } from "swiper";
import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import { TopLevelDialogNames } from "../TopLevelDialogs";
import { Skeleton } from "@/components/Loader";

const MeetOtherTravellerCard: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [swiperObj, setSwiperObj] = useState<SwiperType | undefined>(undefined);

  const { slidesPerView } = useSwiperSlidesPerView({
    containerRef: containerRef,
    slideWidth: 200,
  });

  const { openTopLevelDialog } = useDialog();

  const { currentTrip } = useCurrentTrip();
  const { track } = useTrackers();
  const { data: groupMembersData, isLoading: isGroupMembersLoading } =
    useGroupMembersQuery();

  const cid = currentTrip?.cid;
  const members =
    uniqBy(
      compact(groupMembersData?.pages?.map((page) => page?.results)?.flat(2)),
      "cid"
    )
      .filter((item) => item.cid !== cid)
      .slice(0, 6) || [];
  const hasMembers = Boolean(
    (groupMembersData?.pages?.[0]?.totalCount || 0) > 0
  );

  const handleOpenGroupMembersDialog = () => {
    track("Travellers List Opened", { eventId: "travellers-list-opened" });
    openTopLevelDialog(TopLevelDialogNames.GroupMembers);
  };

  useEffect(() => {
    if (hasMembers && !isGroupMembersLoading && swiperObj) {
      swiperObj?.slideTo?.(0, 100);
    }
  }, [hasMembers, isGroupMembersLoading, swiperObj]);

  return !isGroupMembersLoading ? (
    <Card className={styles.cardRoot}>
      <div className={styles.title}>
        <Typography>Meet other travellers</Typography>
        <Button
          variant="text"
          size="small"
          className={styles.seeAll}
          onClick={handleOpenGroupMembersDialog}
        >
          See all
        </Button>
      </div>
      {hasMembers && !isGroupMembersLoading && (
        <div ref={containerRef}>
          <Swiper
            initialSlide={0}
            onSwiper={setSwiperObj}
            slidesPerView={slidesPerView}
            spaceBetween={16}
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            modules={[Navigation]}
            className={styles.swiperContainer}
            navigation
          >
            {members?.map((member) => (
              <SwiperSlide key={member.cid}>
                <TravellerCard key={member.cid} member={member} />
              </SwiperSlide>
            ))}
            <SwiperSlide>
              <div className={styles.seeAllButtonContainer}>
                <Button
                  variant="outlined"
                  className={styles.seeAllSlideButton}
                  onClick={() =>
                    openTopLevelDialog(TopLevelDialogNames.GroupMembers)
                  }
                >
                  See all
                </Button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      {!hasMembers && !isGroupMembersLoading && (
        <Typography className={styles.emptyList} color="text.secondary">
          No member to show
        </Typography>
      )}
    </Card>
  ) : (
    <Skeleton
      variant="rectangular"
      height="300px"
      className={styles.cardRoot}
    />
  );
};

export default MeetOtherTravellerCard;
