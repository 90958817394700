import { useEffect, useState } from "react";

const useSwiperSlidesPerView = ({
  containerRef,
  slideWidth,
}: {
  containerRef: any;
  slideWidth: number;
}) => {
  const [cardWidth, setCardWidth] = useState(
    containerRef.current?.offsetWidth || 0
  );

  useEffect(() => {
    function handleResize() {
      setCardWidth(containerRef.current?.offsetWidth || 0);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleResize() {
      setCardWidth(containerRef.current?.offsetWidth || 0);
    }
    handleResize();
  });

  const slidesPerView = cardWidth / slideWidth;

  return { slidesPerView };
};

export default useSwiperSlidesPerView;
