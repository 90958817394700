import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type MembershipItemType = {
  type: string;
  member_number: number;
  member_status: string;
  date_expires: number;
  member_name: string;
};

export type FlightDetailsVariables = {
  is_return: number;
  stay_length: string;
  departure_country: string;
  departure_city: string;
  departure_date: number;
  destination_country: string;
  destination_city: string;
  pref_seat?: string;
  return_date?: number;
  pref_diet?: string;
  members: Array<MembershipItemType>;
};

type FlightDetailsResult = {
  response: {
    affectedRows: number;
    changedRows: number;
    fieldCount: number;
    insertId: number;
    message: string;
    protocol41: boolean;
    serverStatus: number;
    warningCount: number;
  };
  statusCode: number;
  success: boolean;
};

type QueryError = {};

const useFlightDetailsMutation = (
  options?: UseMutationOptions<
    FlightDetailsResult,
    QueryError,
    FlightDetailsVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const queryClient = useQueryClient();
  const flightDetailsCacheKey = `${GATEWAY_URL}/api/trip-details/flight-details/${tid}`;

  return {
    ...useMutation<FlightDetailsResult, QueryError, FlightDetailsVariables>(
      async (inputObj: FlightDetailsVariables) => {
        const url = `${GATEWAY_URL}/api/trip-details/flight-details`;

        return fetchWithAuth<FlightDetailsResult>(url, {
          method: "POST",
          body: inputObj,
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(flightDetailsCacheKey);
        },
        ...options,
      }
    ),
    cancel,
  };
};

export default useFlightDetailsMutation;
