import { FC, useState, useEffect, useRef, useMemo } from "react";
// @ts-ignore
import { Image } from "cloudinary-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronIcon } from "@/components/Icon";
import { IconButton } from "@/components/Button";
import Typography from "@/components/Typography";
import { Media } from "@/fetch/social";
import styles from "./PostDialogMediaSection.module.scss";
import SwiperType from "swiper/types/swiper-class";
import { CircularProgress } from "@/components/Loader";
import cx from "classnames";
import Player, {
  APITypes,
  getVideoSources,
  MediaType,
  PlayerProps,
} from "@/components/Player";
import { useResponsive, useTrackers } from "@/hooks";

const VideoPreview: React.FC<
  Omit<PlayerProps, "source"> & {
    media: Media;
    isFocused?: boolean;
  }
> = ({ media, isFocused, ...props }) => {
  const plyrRef = useRef<APITypes | null>(null);

  const source = useMemo(() => {
    const videoSources = getVideoSources(media?.public_id || "");
    const sources = videoSources.video;
    return {
      type: "video" as MediaType,
      sources,
    };
  }, [media]);

  useEffect(() => {
    const pauseVideo = () => {
      if (!plyrRef?.current?.plyr) return;
      plyrRef?.current?.plyr?.pause?.();
    };

    if (!isFocused) {
      pauseVideo();
    }

    return () => {
      pauseVideo();
    };
  }, [isFocused]);

  return (
    <Player
      {...props}
      ref={plyrRef}
      source={source}
      trackerData={{
        content_asset_id: `social_${media?.post_id}_${media?.id}`,
      }}
    />
  );
};

type Props = {
  media: Array<Media>;
  selectedMediaId?: number;
  postId: number;
};

const PostDialogMediaSection: FC<Props> = ({
  media,
  selectedMediaId,
  postId,
}) => {
  const [swiperObj, setSwiperObj] = useState<SwiperType | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loadedMedia, setLoadedMedia] = useState<string[]>([]);
  const { isMobile } = useResponsive();
  const { track } = useTrackers();

  const isOne = media.length === 1;

  const onMediaLoaded = (id?: string) => {
    if (id?.length) setLoadedMedia((prev) => prev.concat(id));
  };

  useEffect(() => {
    if (!swiperObj || !selectedMediaId) return;
    const mediaIndex = media.findIndex((each) => each.id === selectedMediaId);
    swiperObj.slideTo(mediaIndex);
    if (media?.[mediaIndex].type === "Video") {
      swiperObj.allowTouchMove = false;
    } else {
      swiperObj.allowTouchMove = true;
    }
    setCurrentIndex(mediaIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaId, swiperObj]);

  const onSlideChange = (swiper: SwiperType) => {
    setCurrentIndex(swiper.activeIndex);
    const activeMedia = media?.[swiper.activeIndex];
    track("Media Viewed", {
      eventId: "media-viewed",
      media: activeMedia,
      postId,
    });
    if (activeMedia.type === "Video") {
      swiper.allowTouchMove = false;
    } else {
      swiper.allowTouchMove = true;
    }
    setSwiperObj(swiper);
  };

  return (
    <div className={styles.root}>
      <div className={styles.swiperContainer}>
        <Swiper
          className={styles.swiperRoot}
          slidesPerView={1}
          keyboard
          onSlideChange={onSlideChange}
          onSwiper={setSwiperObj}
        >
          {media?.map((medium, index) => (
            <SwiperSlide key={medium.id} draggable={false}>
              <div className={styles.itemContainer} key={medium.id}>
                {medium.type === "Image" && (
                  <Image
                    cloudName="gwatco"
                    publicId={medium.public_id}
                    crop="scale"
                    alt=""
                    className={styles.imageContainer}
                    onLoad={() => onMediaLoaded(medium.public_id)}
                  ></Image>
                )}
                {medium.type === "Video" && (
                  <VideoPreview
                    media={medium}
                    // autoPlay={Boolean(medium.id === selectedMediaId)}
                    isFocused={Boolean(index === currentIndex)}
                  />
                )}
              </div>
              {medium.type === "Image" &&
                !loadedMedia.includes(medium.public_id || "") && (
                  <div className={styles.loading}>
                    <CircularProgress size="lg" />
                  </div>
                )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {!isOne && (
        <div
          className={cx(styles.paginationContainer, {
            [styles.mobileVideoNavigation]:
              isMobile && media[currentIndex].type === "Video",
          })}
        >
          <IconButton
            className={cx({
              [styles.hideControl]: swiperObj?.isBeginning,
            })}
            onClick={() => swiperObj?.slidePrev()}
            disabled={swiperObj?.isBeginning}
          >
            <ChevronIcon
              dir="left"
              className={styles.chevronIcon}
              fontSize="large"
            />
          </IconButton>
          <Typography variant="h5" color="text.contrast">
            {`${currentIndex + 1}/${media.length}`}
          </Typography>
          <IconButton
            className={cx({
              [styles.hideControl]: swiperObj?.isEnd,
            })}
            onClick={() => swiperObj?.slideNext()}
            disabled={swiperObj?.isEnd}
          >
            <ChevronIcon
              dir="right"
              className={styles.chevronIcon}
              fontSize="large"
            />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default PostDialogMediaSection;
