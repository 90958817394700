import { useMutation, UseMutationOptions } from "react-query";
import { TRAVELLER_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type DeleteContactMethodVariables = {
  contactId: number;
};

type DeleteContactMethodResult = {
  success: boolean;
  result: {
    affectedRows: number;
  };
};

type QueryError = {};

const useDeleteContactMethodMutation = (
  options?: UseMutationOptions<
    DeleteContactMethodResult,
    QueryError,
    DeleteContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return {
    ...useMutation<
      DeleteContactMethodResult,
      QueryError,
      DeleteContactMethodVariables
    >(
      async ({ contactId }: DeleteContactMethodVariables) => {
        if (!contactId) throw new Error("id not found");
        const url = `${TRAVELLER_ENDPOINT}/contact-method/${contactId}/traveller/${cid}`;
        return fetchWithAuth<DeleteContactMethodResult>(url, {
          method: "DELETE",
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useDeleteContactMethodMutation;
