import {
  AcademyIcon,
  BellIcon,
  ChatIcon,
  GlobalIcon,
  MarketplaceIcon,
} from "@/components/Icon";
import { SvgIconProps } from "@/components/Icon/SvgIcon";
import groupBy from "lodash/groupBy";
import { useRouter } from "next/router";
import useCurrentTrip from "@/hooks/useCurrentTrip";
import { Division, Trip } from "@/fetch/gworld";
import { SocialMobileHeader, SocialSidebar } from "@/components/Social";
import useLock from "./useLock";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
import { useGroupQuery } from "@/fetch/social";
import { useEffect } from "react";
import { useCancellationQuery } from "@/fetch/cancellation";
import useOrderedMenuByTripDivision from "./useOrderedMenuByTripDivision";

export enum RouteFeature {
  Sidebar = "Sidebar",
  Tab = "Tab",
  MobileTab = "MobileTab",
  SidebarTitle = "SidebarTitle",
  HasMobileTripSelector = "HasMobileTripSelector",
  HideSuggestionBanner = "HideSuggestionBanner",
}

export enum SubrouteFeature {
  HideSuggestionBanner = "HideSuggestionBanner",
  HideChatPopover = "HideChatPopover",
}

export type Route = {
  name: string;
  path: string;
  children?: Array<Subroute>;
  features?: Array<RouteFeature>;
  icon?: React.FC<SvgIconProps>;
  customMobileHeader?: React.ReactNode;
  customSidebar?: React.ReactNode;
  disabled?: boolean;
  rootClassName?: string;
  tooltipMessage?: string;
  isAvailable?: boolean;
  id?: string;
  order?: number;
};

export type Subroute = {
  name: string;
  path: string;
  children?: Array<Subroute>;
  features?: Array<SubrouteFeature>;
  groupIndex?: number;
  isAvailable?: boolean;
  hideChatPopover?: boolean;
  disabled?: boolean;
  id?: string;
  order?: number;
};

const useRoutes = () => {
  const { pathname } = useRouter();
  const { currentTrip } = useCurrentTrip();
  const { isPaymentLockEnabled } = useLock();
  const {
    isLoading: isGroupLoading,
    refetch: refetchGroup,
    isError,
  } = useGroupQuery({
    enabled: false,
    retry: false,
  });
  const { refetch: refetchCancellations } = useCancellationQuery({
    retry: (count, error) => {
      if (error?.response?.code === 404) return false;
      else if (count < 3) return true;
      return false;
    },
    enabled: false,
  });

  const tripId = currentTrip?.trip_id || 0;
  const isAfricanRegion = currentTrip?.region === "Africa";

  const hasSocialGroup = !isGroupLoading && !isError;

  useEffect(() => {
    /* Refetch the group and cancellations query whenever trip is changed  */
    refetchGroup();
    refetchCancellations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const routes = useOrderedMenuByTripDivision({
    trip: currentTrip,
    hasSocialGroup,
    isPaymentLockEnabled,
    isTripCompleted: currentTrip?.status === TripStatus.Complete,
    isAfricanRegion,
  });

  const getMatchedRoute = (
    overrideRoutes: Array<Route> = routes
  ): { route: Route | undefined; index: number } => {
    const firstPart = pathname.split("/")?.[1];
    const index = overrideRoutes.findIndex((route) =>
      route.path.startsWith(`/${firstPart}`)
    );
    return { route: overrideRoutes[index], index };
  };

  const getMatchedSubroute = (overrideRoutes: Array<Route> = routes) => {
    const { route } = getMatchedRoute(overrideRoutes);
    const subroute = route?.children?.find(
      (sub) => pathname.startsWith(sub.path.slice(0, sub.path.indexOf("?"))) // Remove query if there is any before comparing pathname and sub route
    );
    return subroute;
  };

  const groupSubroutesByIndex = () => {
    const { route } = getMatchedRoute();
    if (!route) return [];
    let subroutes = groupBy(route?.children, "groupIndex");
    return Object.values(subroutes);
  };

  const hasAccessToRoute = (routeName: string, subRouteName?: string) => {
    const matchedRoute = routes.find((route) => route.name === routeName);
    if (!matchedRoute) return false;

    const matchedSubroute = routes
      .find((route) => route.name === routeName)
      ?.children?.find((sub) => sub.name === subRouteName);
    if (subRouteName && !matchedSubroute) return false;

    const isDisabled =
      matchedSubroute?.disabled ?? matchedRoute.disabled ?? false;
    const isAvailable =
      matchedSubroute?.isAvailable ?? matchedRoute.isAvailable ?? true;

    return !isDisabled && isAvailable;
  };

  return {
    hasAccessToRoute,
    isLoading: isGroupLoading,
    groupSubroutesByIndex,
    getMatchedSubroute,
    getMatchedRoute,
    routes,
  };
};

export default useRoutes;
