import SvgIcon, { SvgIconProps } from "./SvgIcon";

const WorkIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5839 20.4577C2.97315 20.8497 3.43867 21.0457 3.98045 21.0457H20.7261C21.2718 21.0457 21.7406 20.8497 22.1327 20.4577C22.5247 20.0656 22.7207 19.5968 22.7207 19.0511V12.2368L12.5168 14.8986C12.1706 14.989 11.8066 14.9847 11.4625 14.8865L2 12.1829V19.0511C2 19.5968 2.19463 20.0656 2.5839 20.4577ZM2 10.1028V7.30547C2 6.76369 2.19463 6.29817 2.5839 5.9089C2.97315 5.51963 3.43867 5.325 3.98045 5.325H8.02825V2.99458C8.02825 2.44891 8.22288 1.98006 8.61215 1.58803C9.00142 1.19601 9.46694 1 10.0087 1H14.6978C15.2396 1 15.7051 1.19601 16.0944 1.58803C16.4837 1.98006 16.6783 2.44891 16.6783 2.99458V5.325H20.7261C21.2718 5.325 21.7406 5.51963 22.1327 5.9089C22.5247 6.29817 22.7207 6.76369 22.7207 7.30547V10.1698L12.012 12.9634L2 10.1028ZM14.6978 5.325H10.0087V2.99458H14.6978V5.325Z"
        />
      ) : (
        <>
          <path d="M3.62718 21.3957C3.0854 21.3957 2.61988 21.1997 2.23063 20.8076C1.84136 20.4156 1.64673 19.9467 1.64673 19.4011V7.65545C1.64673 7.11367 1.84136 6.64814 2.23063 6.25888C2.61988 5.86961 3.0854 5.67498 3.62718 5.67498H7.67498V3.34455C7.67498 2.79888 7.86961 2.33003 8.25888 1.938C8.64815 1.54598 9.11367 1.34998 9.65545 1.34998H14.3446C14.8863 1.34998 15.3519 1.54598 15.7411 1.938C16.1304 2.33003 16.325 2.79888 16.325 3.34455V5.67498H20.3728C20.9185 5.67498 21.3873 5.86961 21.7794 6.25888C22.1714 6.64814 22.3674 7.11367 22.3674 7.65545V19.4011C22.3674 19.9467 22.1714 20.4156 21.7794 20.8076C21.3873 21.1997 20.9185 21.3957 20.3728 21.3957H3.62718ZM3.62718 19.4011H20.3728V7.65545H3.62718V19.4011ZM9.65545 5.67498H14.3446V3.34455H9.65545V5.67498Z" />
          <path
            d="M3 11L11.6838 13.8946C11.889 13.963 12.111 13.963 12.3162 13.8946L21 11"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </>
      )}
    </SvgIcon>
  );
};

export default WorkIcon;
