import compact from "lodash/compact";
// @ts-ignore
import { Image, Video, Transformation } from "cloudinary-react";
import cx from "classnames";
import { useRouter } from "next/router";
import { Post, useRecentPostsQuery } from "@/fetch/social";
import Typography from "@/components/Typography";
import { Skeleton } from "@/components/Loader";
import { useDialog, useTrackers } from "@/hooks";
import PostDialog from "./PostDialog";
import styles from "./RecentMedia.module.scss";
import { PlayIcon } from "../Icon";

type Props = {
  variant?: "SocialSideBar" | "SuperliteDashboard";
};

const RecentMedia: React.FC<Props> = ({ variant = "SocialSideBar" }) => {
  const { data: recentMediaData, isLoading } = useRecentPostsQuery({
    pageSize: variant === "SocialSideBar" ? 6 : 4,
    pageNum: 1,
    withMedia: true,
  });
  const {
    openDialog: openPostDetailsDialog,
    isOpen: isPostDialogOpen,
    query,
  } = useDialog("post");
  const { push } = useRouter();
  const { track } = useTrackers();

  const medias = compact(
    recentMediaData?.pages?.map((page) => page?.results)?.flat(2)
  );

  const hasMedias = Boolean((recentMediaData?.pages?.[0]?.totalCount || 0) > 0);
  const loadingArray = new Array(6).fill(1);

  const handleOpenDialog = (post: Post) => {
    if (variant === "SocialSideBar") {
      openPostDetailsDialog({ id: post.id });
    } else {
      track("SuperLite dashboard Recent Media  Clicked", {
        eventId: "superlite-dashboard-recent-media-clicked",
      });
      push(`/social/timeline?dialog=post&id=${post.id}`);
    }
  };

  return !isLoading && hasMedias ? (
    <div className={styles.root}>
      <Typography variant="subtitle1" color="text.secondary">
        {variant === "SocialSideBar" ? "Recent Media" : "Traveller experiences"}
      </Typography>
      <div
        className={cx(styles.mediaContainer, {
          [styles.dashboardMediaContainer]: variant === "SuperliteDashboard",
        })}
      >
        {isLoading &&
          loadingArray.map((each, idx) => (
            <Skeleton key={idx} className={styles.loadingItem} />
          ))}
        {!isLoading &&
          medias.map((eachPost) => {
            const firstMedia = eachPost.media?.[0];
            return (
              <div
                className={cx(styles.mediaItem, {
                  [styles.dashboardMediaItem]: variant === "SuperliteDashboard",
                })}
                key={eachPost.id}
                role="presentation"
                tabIndex={0}
                onClick={() => handleOpenDialog(eachPost)}
              >
                {firstMedia?.type === "Image" && (
                  <Image
                    cloudName="gwatco"
                    publicId={firstMedia?.public_id}
                    alt=""
                  >
                    <Transformation
                      height={variant === "SocialSideBar" ? 56 : 84}
                      width={variant === "SocialSideBar" ? `${56}` : `${84}`}
                      crop="thumb"
                      gravity="auto"
                    />
                  </Image>
                )}
                {firstMedia?.type === "Video" && (
                  <>
                    <PlayIcon className={styles.playIcon} htmlColor="white" />
                    <Video
                      cloudName="gwatco"
                      publicId={firstMedia?.public_id}
                      alt=""
                      controls={false}
                      height={variant === "SocialSideBar" ? 56 : 84}
                      width={variant === "SocialSideBar" ? 56 : 84}
                      crop="thumb"
                    >
                      <Transformation aspectRatio="1.0" gravity="auto" />
                    </Video>
                  </>
                )}
              </div>
            );
          })}
        {!isLoading && !hasMedias && (
          <Typography variant="caption" color="text.secondary">
            No recent media
          </Typography>
        )}
      </div>
      {isPostDialogOpen && query.id && <PostDialog />}
    </div>
  ) : null;
};

export default RecentMedia;
