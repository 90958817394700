/* eslint-disable no-param-reassign */
import produce from "immer";
import { createContext, Dispatch, useContext } from "react";
import {
  Client,
  Conversation,
  Participant,
  User,
  Paginator,
  Message,
} from "@twilio/conversations";

type TravellerInfo = {
  name?: string;
  cid?: number;
};

type MessageStatus = "sending" | "sent" | "delivered" | "notSent";

type ConnectionStatus = "connected" | "error";

type OutgoingMessage = {
  body?: string;
  dateCreated: Date;
  status?: MessageStatus;
};

type ChatSession = {
  conversation: Conversation;
  conversationSid: string;
  messages?: Message[];
  outgoingMessages?: OutgoingMessage[];
  messagesPaginator?: Paginator<Message>;
  unreadMessageCount?: number | null;
  lastMessage?: Message;
  participant?: Participant;
  user?: User;
  travellerInfo?: TravellerInfo;
  isDetailsLoading?: boolean;
};

type ChatState = {
  client?: Client | null;
  sessions: ChatSession[];
  conversationsPaginator?: Paginator<Conversation>;
  connectionStatus?: ConnectionStatus;
};

type ChatActions =
  | {
      type: "updateConversations";
      conversationsPaginator?: Paginator<Conversation>;
    }
  | {
      type: "updateClient";
      client?: Client;
    }
  | {
      type: "updateConnectionStatus";
      status?: ConnectionStatus;
    }
  | {
      type: "updateUser";
      user?: User;
    }
  | {
      type: "updateParticipant";
      participant?: Participant;
    }
  | {
      type: "updateLastMessage";
      lastMessage?: Message;
      unreadMessageCount?: number | null;
      conversationSid: string;
    }
  | {
      type: "updateConversationDetails";
      user?: User;
      travellerInfo?: TravellerInfo;
      unreadMessageCount?: number | null;
      participant?: Participant;
      conversationSid: string;
      lastMessage?: Message;
      isDetailsLoading?: boolean;
    }
  | {
      type: "updateConversationDetailsLoading";
      conversationSid: string;
      isLoading?: boolean;
    }
  | {
      type: "addInitialMessages";
      messages?: Message[];
      conversationSid?: string;
      messagesPaginator?: Paginator<Message>;
    }
  | {
      type: "addPaginatedMessages";
      messages?: Message[];
      conversationSid?: string;
      messagesPaginator?: Paginator<Message>;
    }
  | {
      type: "addNewMessages";
      messages?: Message[];
      conversationSid?: string;
      messagesPaginator?: Paginator<Message>;
    }
  | {
      type: "addToOutgoingMessages";
      outgoingMessage?: OutgoingMessage;
      conversationSid?: string;
    }
  | {
      type: "removeFromOutgoingMessages";
      date?: Date;
      conversationSid?: string;
    }
  | {
      type: "removeSession";
      conversationSid?: string;
    };

type ChatContextType = {
  state: ChatState;
  dispatch?: Dispatch<ChatActions>;
  openChat?: ({
    conversationSid,
    toggleState,
    loading,
  }: {
    conversationSid?: string;
    toggleState?: boolean;
    loading?: boolean;
  }) => Promise<boolean> | undefined;
  changePopoverState?: (open?: boolean | undefined) => void;
  overlayLoading?: boolean;
  setOverlayLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isChatPopOverOpen?: boolean;
  setIsChatPopOverOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  currentConversationSid?: string | undefined;
  setCurrentConversationSid?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  showTravellersList?: boolean;
  setShowTravellersList?: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialState: ChatState = {
  conversationsPaginator: undefined,
  sessions: [],
  client: null,
};
const ChatContext = createContext<ChatContextType>({
  state: initialState,
  dispatch: undefined,
});

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context) return context;
  return null;
};

const chatReducer = (state: ChatState, action: ChatActions) => {
  switch (action.type) {
    case "updateConversations": {
      const newState = produce(state, (draftedState: ChatState) => {
        draftedState.conversationsPaginator = action.conversationsPaginator;

        const conversations = action.conversationsPaginator?.items;
        const currentSids = draftedState.sessions.map(
          (session) => session.conversationSid
        );

        conversations?.map((conversation) => {
          if (!currentSids.includes(conversation.sid))
            draftedState.sessions.push({
              conversationSid: conversation.sid,
              conversation,
            });
        });
      });

      return newState;
    }

    case "updateClient": {
      const newState = produce(state, (draftedState: ChatState) => {
        draftedState.client = action?.client;
      });
      return newState;
    }

    case "updateConnectionStatus": {
      const newState = produce(state, (draftedState: ChatState) => {
        draftedState.connectionStatus = action?.status;
      });
      return newState;
    }

    case "updateUser": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.user?.identity === action.user?.identity
        );

        if (foundSession) foundSession.user = action?.user;
      });
      return { ...newState };
    }

    case "updateParticipant": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) =>
            session?.participant?.identity === action.participant?.identity
        );
        if (foundSession) {
          foundSession.participant = action?.participant;
        }
      });

      return { ...newState };
    }

    case "updateLastMessage": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action.conversationSid
        );
        if (foundSession) {
          foundSession.lastMessage = action?.lastMessage;
          foundSession.unreadMessageCount = action?.unreadMessageCount;
        }
      });
      return newState;
    }

    case "updateConversationDetails": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action.conversationSid
        );
        if (foundSession) {
          foundSession.participant = action.participant;
          foundSession.user = action.user;
          foundSession.unreadMessageCount = action.unreadMessageCount;
          foundSession.travellerInfo = action.travellerInfo;
          foundSession.lastMessage = action.lastMessage;
          foundSession.isDetailsLoading = action.isDetailsLoading;
        }
      });
      return newState;
    }

    case "updateConversationDetailsLoading": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action.conversationSid
        );
        if (foundSession?.travellerInfo?.cid) return;
        if (foundSession) {
          foundSession.isDetailsLoading = action.isLoading;
        }
      });
      return newState;
    }

    case "addInitialMessages": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action?.conversationSid
        );
        if (foundSession && action?.messages) {
          foundSession.messages = [...action.messages];
          foundSession.messagesPaginator = action.messagesPaginator;
        }
      });

      return newState;
    }

    case "addPaginatedMessages": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action?.conversationSid
        );
        if (foundSession && action?.messages) {
          foundSession.messages?.unshift(...action.messages);
          foundSession.messagesPaginator = action.messagesPaginator;
        }
      });

      return newState;
    }
    case "addNewMessages": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action?.conversationSid
        );
        if (foundSession && action?.messages) {
          foundSession.messages = foundSession.messages?.concat(
            action.messages
          );
        }
      });

      return newState;
    }

    case "addToOutgoingMessages": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action?.conversationSid
        );
        if (foundSession && action?.outgoingMessage) {
          if (foundSession.outgoingMessages?.length) {
            foundSession.outgoingMessages?.push(action?.outgoingMessage);
          } else {
            foundSession.outgoingMessages = [action?.outgoingMessage];
          }
        }
      });

      return newState;
    }

    case "removeFromOutgoingMessages": {
      const newState = produce(state, (draftedState: ChatState) => {
        const foundSession = draftedState?.sessions?.find(
          (session) => session?.conversationSid === action?.conversationSid
        );
        if (foundSession && foundSession.outgoingMessages && action?.date) {
          foundSession.outgoingMessages = foundSession.outgoingMessages?.filter(
            (item) => item?.dateCreated !== action?.date
          );
        }
      });

      return newState;
    }

    case "removeSession": {
      const newState = produce(state, (draftedState: ChatState) => {
        if (action?.conversationSid) {
          draftedState.sessions = draftedState?.sessions?.filter(
            (session) => session?.conversationSid !== action?.conversationSid
          );
        }
      });

      return newState;
    }

    default:
      throw new Error();
  }
};

export default useChatContext;
export { ChatContext, chatReducer, initialState };
export type {
  ChatState,
  ChatActions,
  ChatContextType,
  ChatSession,
  OutgoingMessage,
  MessageStatus,
  ConnectionStatus,
};
