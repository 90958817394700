import { FC, useState, ReactNode } from "react";
import dayjs from "dayjs";
import compact from "lodash/compact";
import Avatar from "@/components/Avatar";
import { IconButton } from "@/components/Button";
import {
  useDeleteCommentMutation,
  useRecentCommentsQuery,
  useCreateCommentMutation,
} from "@/fetch/social";
import Typography from "@/components/Typography";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemIcon, ListItemText } from "@/components/List";
import { BinIcon, InfoIcon, MoreIcon } from "@/components/Icon";
import { useCurrentTrip } from "@/hooks";
import { Skeleton } from "@/components/Loader";
import styles from "./PostCommentsSection.module.scss";
import RichContentPreview from "@/components/RichContentPreview";
import PostCommentDeleteConfirmationDialog from "./PostCommentDeleteConfirmationDialog";
import TravellerNameLink from "./TravellerNameLink";
import ReportDialog from "./ReportDialog";

type CommentsSectionProps = {
  postId: number;
  totalComments: number;
};

type MenuListItemType = {
  title: string;
  icon: ReactNode;
  action: (commentId: number) => void;
};

const CommentsSection: FC<CommentsSectionProps> = ({
  postId,
  totalComments,
}: CommentsSectionProps) => {
  const {
    data: recentComments,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useRecentCommentsQuery(postId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [commentIdToDelete, setCommentIdToDelete] = useState(-1);
  const [commentIdToOpenMenu, setCommentIdToOpenMenu] = useState(-1);
  const [commentIdToReport, setCommentIdToReport] = useState(-1);

  const { currentTrip } = useCurrentTrip();

  const userCid = currentTrip?.cid;
  const comments = compact(
    recentComments?.pages?.map((page) => page?.data.results)?.flat(2)
  );
  const hasComments = Boolean(
    (recentComments?.pages?.[0]?.data.totalCount || 0) > 0
  );

  const onDeleteCommentDialogClose = () => {
    setCommentIdToDelete(-1);
  };

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const authorMenuList = [
    {
      title: "Delete Comment",
      icon: <BinIcon color="inherit" />,
      action: (commentId: number) => {
        setCommentIdToDelete(commentId);
        setCommentIdToOpenMenu(-1);
      },
    },
  ];

  const menuList: Array<MenuListItemType> = [
    {
      title: "Report",
      icon: <InfoIcon color="inherit" variant="outlined" />,
      action: (commentId: number) => {
        setCommentIdToReport(commentId);
        setCommentIdToOpenMenu(-1);
      },
    },
  ]; //menu items for other user

  const isMenuOpen = Boolean(anchorEl);

  const onMoreButtonClick = (event: any, id: number) => {
    setCommentIdToOpenMenu(id);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {comments?.map((eachComment) => {
        const menuListItems =
          eachComment.cid === userCid ? authorMenuList : menuList;

        const date = dayjs(eachComment._ca * 1000);
        return (
          <div key={eachComment.id} className={styles.container}>
            <Avatar cloudinaryPath={eachComment?.s3_profile_pic} />
            <div className={styles.messageContainer}>
              <div className={styles.infoContainer}>
                <TravellerNameLink
                  variant="body1"
                  color="text.secondary"
                  cid={eachComment?.cid}
                  nickname={eachComment?.nickname}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={styles.commentDate}
                >
                  {eachComment._ca === 0 ? "" : date.fromNow(true)}
                </Typography>
              </div>

              <RichContentPreview content={eachComment.content} />
            </div>
            {Boolean(menuListItems?.length) && (
              <IconButton
                className={styles.moreButton}
                onClick={(event) => onMoreButtonClick(event, eachComment.id)}
              >
                <MoreIcon color="info" />
              </IconButton>
            )}
            {Boolean(commentIdToDelete === eachComment.id) && (
              <PostCommentDeleteConfirmationDialog
                onClose={onDeleteCommentDialogClose}
                postId={postId}
                commentId={eachComment.id}
              />
            )}
            {Boolean(commentIdToReport === eachComment.id) && (
              <ReportDialog
                type="comment"
                onClose={() => setCommentIdToReport(-1)}
                postId={postId}
                commentId={eachComment.id}
              />
            )}
            <Menu
              elevation={3}
              id={`comment-${eachComment.id}-select-menu`}
              anchorEl={anchorEl}
              open={isMenuOpen && eachComment.id === commentIdToOpenMenu}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {menuListItems?.map((item) => (
                <MenuItem
                  key={item.title}
                  onClick={() => item.action(eachComment.id)}
                >
                  <ListItemIcon sx={{ color: "text.primary" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      })}
      {(isFetchingNextPage || isLoading) && totalComments !== 0 && (
        <div className={styles.container}>
          <Skeleton variant="circular" className={styles.avatarLoading} />
          <Skeleton className={styles.loading} />
        </div>
      )}
      {hasNextPage && !isFetchingNextPage && !isLoading && (
        <span
          className={styles.loadMoreText}
          role="presentation"
          onClick={handleLoadMore}
        >
          Load more comments
        </span>
      )}
    </>
  );
};

export default CommentsSection;
