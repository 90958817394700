import { FC, Fragment, useState, useEffect } from "react";
import { useFormik } from "formik";
import cx from "classnames";
import * as yup from "yup";
import Image from "next/image";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Typography from "@/components/Typography";
import Divider from "@/components/Divider";
import Button, { IconButton } from "@/components/Button";
import Alert from "@/components/Alert";
import Uploader, {
  UploaderFile,
  UPLOADER_ACCEPT_TYPES,
} from "@/components/Uploader";
import TextField from "@/components/TextField";
import Checkbox from "@/components/Checkbox";
import {
  BinIcon,
  FileIcon,
  DownloadIcon,
  GlobalDollarColoredIcon,
} from "@/components/Icon";
import {
  useDialog,
  useCurrentTrip,
  useTrackers,
  useSnackbar,
  useOpenExternalURL,
} from "@/hooks";
import {
  RewardItem,
  useCreateSubmissionHandler,
  useSubmissionQuery,
  useDeleteMediaMutation,
  useSubmissionMediaListQuery,
  MediaItem,
  useUpdateSubmissionHandler,
  SubmissionStatuses,
  useAllClaimablesListQuery,
} from "@/fetch/rewards";
import { LinearProgress } from "@/components/Loader";
import RichContentPreview from "@/components/RichContentPreview";
import Rating from "@/components/Rating";
import { Skeleton } from "@/components/Loader";
import { ListItem } from "@/components/List";
import PendingReward from "./PendingReward";
import ClaimedReward from "./ClaimedReward";
import SuccessfulSubmission from "./SuccessfulSubmission";
import styles from "./PhotosDialog.module.scss";

type PhotosSubmissionFormType = {
  rating: number | null;
  experience: string;
  rewards_id: number | undefined;
  media_description: string;
  condition: boolean;
  photos: Array<UploaderFile | MediaItem>;
};

const validationSchema = yup.object({
  rating: yup
    .string()
    .required(`Rate your time abroad with us out of 5 stars.`)
    .typeError("Rate your time abroad with us out of 5 stars."),
  experience: yup
    .string()
    .required(`Tell us about your 3 favourite travel experiences!`),
  media_description: yup
    .string()
    .required(`Tell us a little about what's going on in these photos!`),
  condition: yup
    .bool()
    .oneOf([true], "Please tick to prove you have read the above and agree."),
  photos: yup
    .array()
    .min(
      10,
      "Please attach 10 happy snaps. Don't forget to include yourself in 5 of these!"
    ),
});

type Props = {};

const PhotosDialog: FC<Props> = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [removingMedias, setRemovingMedias] = useState<Array<number>>([]);

  const rating = ["1", "2", "3", "4", "5"];

  const bannerImg =
    "https://res.cloudinary.com/gwatco/image/upload/f_auto,q_auto,ar_100:30,w_3000,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/Reward.jpg";
  const baseRewardUrl =
    "https://dvhvji4glt0rj.cloudfront.net/img/reward-centre/";
  const { onClose, query } = useDialog();
  const { currentTrip } = useCurrentTrip();
  const { track, captureException } = useTrackers();
  const { enqueueSnackbar } = useSnackbar();
  const openExternalLink = useOpenExternalURL();
  const {
    handleCreateSubmission,
    isCreatingSubmission,
    isFinalizing,
    isUploading,
    isCreatingMedias,
  } = useCreateSubmissionHandler();

  const {
    handleUpdateSubmission,
    isCreatingMedias: isCreatingMediasResubmission,
    isUpdatingSubmission,
    isUploading: isUploadingResubmission,
  } = useUpdateSubmissionHandler();

  const submissionId = Number(query?.submissionId);
  const hasSubmission = Boolean(submissionId);
  const { data: submissionData, isLoading: isLoadingSubmission } =
    useSubmissionQuery(submissionId);
  const { data: claimableList } = useAllClaimablesListQuery();

  const photoClaimable = claimableList?.result.find((each) => each.id === 1);

  const currentSubmission = submissionData?.result;

  const isSubmissionApproved =
    currentSubmission?.status === SubmissionStatuses.APPROVED;
  const isSubmissionDeclined =
    currentSubmission?.status === SubmissionStatuses.CHANGE_REQUESTED;
  const isSubmissionClaimed =
    currentSubmission?.status === SubmissionStatuses.CLAIMED;
  const isSubmissionPending =
    currentSubmission?.status === SubmissionStatuses.PENDING;

  const { data: submissionMediaData, isLoading: isLoadingSubmissionMedia } =
    useSubmissionMediaListQuery(submissionId);

  const submissionMedias = submissionMediaData?.result || [];

  const { mutateAsync: deleteMedia, isLoading: isDeletingMedia } =
    useDeleteMediaMutation(submissionId as number);

  const isLoading =
    isCreatingSubmission ||
    isCreatingMedias ||
    isUploading ||
    isFinalizing ||
    isCreatingMediasResubmission ||
    isUpdatingSubmission ||
    isUploadingResubmission;

  const onSubmit = async () => {
    try {
      if (hasSubmission) {
        const data = {
          rating: `${formik.values.rating}`,
          experience: formik.values.experience,
          media_description: formik.values.media_description,
          type: 1,
          submissionId: currentSubmission?.id as number,
        };
        const newFiles = formik.values.photos.filter(
          (each) => !Boolean((each as MediaItem)?.id)
        );
        await handleUpdateSubmission({
          files: newFiles as Array<UploaderFile>,
          submissionInput: data,
        });
        track("Photo Reward Resubmitted", {
          eventId: "photo-reward-resubmitted",
          numberOfPhotos: formik.values.photos.length,
        });
      } else {
        const data = {
          tid: currentTrip?.id,
          cid: currentTrip?.cid,
          rating: `${formik.values.rating}`,
          experience: formik.values.experience,
          media_description: formik.values.media_description,
          type: 1,
        };
        await handleCreateSubmission({
          files: formik.values.photos as Array<UploaderFile>,
          submissionInput: data,
        });
        track("Photo Reward Submitted", {
          eventId: "photo-reward-submitted",
          numberOfPhotos: formik.values.photos.length,
        });
      }

      setShowSuccess(true);
    } catch (error) {
      captureException(error);
      enqueueSnackbar("Sorry, could not upload files.", {
        variant: "error",
      });
    }
  };

  const initialValues = {
    rating: currentSubmission?.rating || null,
    experience: currentSubmission?.experience || "",
    media_description: currentSubmission?.media_description || "",
    condition: hasSubmission,
    photos: submissionMedias || [],
  } as PhotosSubmissionFormType;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!currentSubmission) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubmission, isLoadingSubmissionMedia]);

  const handleSelectFiles = (files: Array<UploaderFile>) => {
    formik.setValues((values) => ({
      ...values,
      photos: values.photos.concat(files),
    }));
  };

  const handleRemoveFile = (index: number) => {
    formik.setValues((values) => ({
      ...values,
      photos: values.photos.filter((each, idx) => idx !== index),
    }));
  };

  const isLoadingExistingSubmission =
    hasSubmission && (isLoadingSubmission || isLoadingSubmissionMedia);

  const openMedia = (media: MediaItem) => {
    openExternalLink(media.preSignedGetUrl as string);
  };

  const handleRemoveMedia = async (media: MediaItem) => {
    const mediaId = media.id;
    try {
      setRemovingMedias((prev) => prev.concat(mediaId as number));
      await deleteMedia({ mediaId: mediaId as number });

      formik.setValues((values) => ({
        ...values,
        photos: values.photos.filter(
          (each) => (each as MediaItem)?.id !== mediaId
        ),
      }));
      setRemovingMedias((prev) => prev.filter((each) => each !== mediaId));
      enqueueSnackbar("File removed successfully.", {
        variant: "info",
      });
    } catch (error) {
      captureException(error);
      setRemovingMedias((prev) => prev.filter((each) => each !== mediaId));
      enqueueSnackbar("Sorry, could not remove your file. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    onClose({ submissionId });
  };

  return (
    <Dialog
      open
      onClose={isLoading ? undefined : handleClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      {isLoadingExistingSubmission && (
        <>
          <DialogTitle hasCloseButton onClose={handleClose}>
            <Skeleton variant="text" width="60%" />
          </DialogTitle>
          <DialogContent>
            <Skeleton variant="rectangular" height={200} />
          </DialogContent>
        </>
      )}
      {!(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission &&
        !showSuccess && (
          <DialogTitle hasCloseButton={!isLoading} onClose={handleClose}>
            {`Share Your Photos`}
          </DialogTitle>
        )}
      {isLoading && (
        <>
          <LinearProgress />
          <DialogContent className={styles.loadingContent}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/reward-loading.svg`}
              alt="icon"
              className={styles.loadingIcon}
            />
            <Typography>
              {`You're nearly there, please wait while we upload your files!`}
            </Typography>
          </DialogContent>
        </>
      )}
      {!isLoading &&
        !showSuccess &&
        !(isSubmissionApproved || isSubmissionClaimed || isSubmissionPending) &&
        !isLoadingExistingSubmission && (
          <>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.imageContainer}>
                <Image
                  src={bannerImg}
                  objectFit="cover"
                  alt="banner"
                  layout="fill"
                />
              </div>
              <div className={styles.content}>
                {hasSubmission && (
                  <>
                    {isLoadingExistingSubmission ? (
                      <Skeleton
                        className={styles.reasonAlert}
                        variant="rectangular"
                        height="96px"
                      />
                    ) : (
                      <Alert
                        variant="standard"
                        color="error"
                        className={styles.reasonAlert}
                      >
                        {currentSubmission?.reason || ""}
                      </Alert>
                    )}
                  </>
                )}
                <Typography variant="body1">
                  Please follow our simple guidelines when submitting your
                  photos and review.
                </Typography>
                <div>
                  <ListItem className={styles.listItem}>
                    {`Make sure your face is clearly visible in at least 10 photos (but don’t forget about showing us those beautiful views behind you too)`}
                  </ListItem>
                  <ListItem className={styles.listItem}>
                    {`Please ensure photo quality is high (this isn’t your mum’s Facebook account, these pictures should make even Kim K envious)`}
                  </ListItem>
                  <ListItem
                    className={styles.listItem}
                  >{`Review must be a minimum of 50 words`}</ListItem>
                </div>
                <Typography variant="body1">
                  How was your experience with us?
                </Typography>
                <div className={styles.ratingContainer}>
                  {isLoadingExistingSubmission ? (
                    <Skeleton
                      variant="rectangular"
                      height="32px"
                      width="240px"
                    />
                  ) : (
                    <Rating
                      value={formik.values.rating}
                      onChange={(event, newValue) =>
                        formik.setFieldValue("rating", newValue)
                      }
                    />
                  )}
                </div>
                {Boolean(formik.errors.rating) &&
                  Boolean(formik.touched.rating) && (
                    <Typography
                      variant="subtitle2"
                      color="error"
                      className={styles.photosErrorText}
                    >
                      {formik.errors.rating}
                    </Typography>
                  )}
                <Typography variant="body1">
                  Share a review of your travel experience
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={styles.description}
                >
                  {`Tell us about your trip experiences and favourite moments as a Global Traveller. Have you been on any exciting adventures? Did you feel well prepared for the trip? Is everything what you expected? What’s been your favourite experience so far? Spare no detail, we’d love to hear it all!`}
                </Typography>
                {isLoadingExistingSubmission ? (
                  <Skeleton
                    variant="rectangular"
                    height="154px"
                    className={styles.textfield}
                  />
                ) : (
                  <TextField
                    id="experience"
                    name="experience"
                    value={formik.values.experience}
                    placeholder="Write a review..."
                    onChange={formik.handleChange}
                    className={styles.textfield}
                    multiline
                    rows={6}
                    error={
                      Boolean(formik.errors.experience) &&
                      formik.touched.experience
                    }
                    helperText={formik.errors.experience}
                  />
                )}
                <Typography variant="body1">Upload your photos</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={styles.description}
                >
                  {`Submit at least 10 photos from your time abroad and make sure you are featured in at least 5 of them. Feel free to send as many snaps as you please, we love feeling like we were there ourselves!`}
                </Typography>
                {isLoadingExistingSubmission ? (
                  <Skeleton variant="rectangular" height="60px" />
                ) : (
                  formik.values.photos.map((eachPhoto, idx) => (
                    <Fragment
                      key={`${(eachPhoto as UploaderFile)?.name}-${idx}`}
                    >
                      <div className={styles.uploadFile}>
                        <div className={styles.fileNameContainer}>
                          <FileIcon
                            variant="outlined"
                            className={styles.fileIcon}
                          />
                          <Typography variant="body2">
                            {Boolean((eachPhoto as MediaItem)?.id)
                              ? (eachPhoto as MediaItem)?.file_name
                              : (eachPhoto as UploaderFile)?.name}
                          </Typography>
                        </div>
                        <div>
                          {Boolean((eachPhoto as MediaItem)?.id) && (
                            <IconButton
                              onClick={() => openMedia(eachPhoto)}
                              disabled={
                                isDeletingMedia &&
                                Boolean(
                                  removingMedias.find(
                                    (each) =>
                                      each === (eachPhoto as MediaItem)?.id
                                  )
                                )
                              }
                            >
                              <DownloadIcon variant="outlined" />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => {
                              if (Boolean((eachPhoto as MediaItem)?.id)) {
                                handleRemoveMedia(eachPhoto as MediaItem);
                              } else {
                                handleRemoveFile(idx);
                              }
                            }}
                            disabled={
                              isDeletingMedia &&
                              Boolean(
                                removingMedias.find(
                                  (each) =>
                                    each === (eachPhoto as MediaItem)?.id
                                )
                              )
                            }
                          >
                            <BinIcon variant="outlined" />
                          </IconButton>
                        </div>
                      </div>
                      {idx !== formik.values.photos.length - 1 && <Divider />}
                    </Fragment>
                  ))
                )}
                {isLoadingExistingSubmission ? (
                  <div className={styles.uploader} />
                ) : (
                  <Uploader
                    multiple
                    accept={[UPLOADER_ACCEPT_TYPES.Image]}
                    onChangeFile={handleSelectFiles}
                    className={styles.uploader}
                    maxFiles={0}
                    disabled={isDeletingMedia}
                  />
                )}
                {Boolean(formik.errors.photos) && formik.touched.photos && (
                  <Typography
                    variant="subtitle2"
                    color="error"
                    className={styles.photosErrorText}
                  >
                    {formik.errors.photos}
                  </Typography>
                )}
                <Typography variant="body1">
                  Tell us about your photos
                </Typography>
                {isLoadingExistingSubmission ? (
                  <Skeleton
                    variant="rectangular"
                    height="154px"
                    className={styles.textfield}
                  />
                ) : (
                  <TextField
                    id="media_description"
                    name="media_description"
                    value={formik.values.media_description}
                    placeholder="Write a review..."
                    onChange={formik.handleChange}
                    className={styles.textfield}
                    multiline
                    rows={6}
                    error={
                      Boolean(formik.errors.media_description) &&
                      formik.touched.media_description
                    }
                    helperText={formik.errors.media_description}
                  />
                )}

                {isLoadingExistingSubmission ? (
                  <Skeleton variant="text" />
                ) : (
                  <div className={styles.conditionContainer}>
                    <Checkbox
                      color="primary"
                      checked={formik.values.condition}
                      onChange={(event) =>
                        formik.setFieldValue("condition", event.target.checked)
                      }
                      className={styles.checkbox}
                    />
                    <Typography variant="subtitle2">
                      {`You confirm you have read and agree to our`}{" "}
                      <a
                        href={`https://www.globalworkandtravel.com/terms-and-conditions?tab=ca`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className={styles.termsCondition}>
                          {`Media Release & Copyright Agreement`}
                        </span>
                      </a>{" "}
                      {`in conjunction with your booking terms and conditions.`}
                    </Typography>
                  </div>
                )}
                {Boolean(formik.errors.condition) && formik.touched.condition && (
                  <Typography
                    variant="subtitle2"
                    color="error"
                    className={styles.errorText}
                  >
                    {formik.errors.condition}
                  </Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions className={styles.actionContainer}>
              <Button
                onClick={() => formik.handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isLoadingExistingSubmission || isDeletingMedia}
              >
                {hasSubmission ? "Resubmit" : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      {!isLoading &&
        showSuccess &&
        !(isSubmissionClaimed || isSubmissionPending) && (
          <SuccessfulSubmission
            onClose={handleClose}
            globalDollarAmount={photoClaimable?.global_dollar_amount || 0}
          />
        )}
      {!isLoadingExistingSubmission &&
        !showSuccess &&
        (isSubmissionClaimed || isSubmissionPending) && (
          <PendingReward onClose={handleClose} />
        )}
      {!isLoadingExistingSubmission && isSubmissionApproved && (
        <ClaimedReward
          onClose={handleClose}
          globalDollarAmount={photoClaimable?.global_dollar_amount || 0}
        />
      )}
    </Dialog>
  );
};

export default PhotosDialog;
