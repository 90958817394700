/* eslint-disable react/display-name */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Plyr, { APITypes, PlyrProps, PlyrSource } from "plyr-react";
import styles from "./Player.module.scss";
import { useTrackers } from "@/hooks";

type MediaType = PlyrSource["type"];

const options = {
  controls: [
    "play",
    "progress",
    "current-time",
    "duration",
    "volume",
    "mute",
    "fullscreen",
    "settings",
    "play-large",
  ],
};

export type PlayerProps = Omit<PlyrProps, "onTimeUpdate"> & {
  trackerData?: { [key: string]: string | number | undefined };
  onTimeUpdate?: (event: Plyr.PlyrEvent) => void;
};

const Player = forwardRef(
  ({ trackerData, onTimeUpdate, ...props }: PlayerProps, ref: any) => {
    const playerRef = useRef<APITypes>(null);
    useImperativeHandle(ref, () => playerRef.current);
    const { track } = useTrackers();
    const [update, setUpdate] = useState(false);

    useEffect(() => {
      if (!playerRef?.current?.plyr?.on) return;

      const getTrackerDetails = (event: Plyr.PlyrEvent, eventId: string) => ({
        eventId,
        position: event.detail.plyr.currentTime,
        total_length: event.detail.plyr.duration,
        ...trackerData,
      });

      const onPlayerPlay = (plyr: Plyr.PlyrEvent) => {
        track("Video Playback Started", {
          ...getTrackerDetails(plyr, "video-playback-started"),
        });
      };
      const onPlayerPause = (plyr: Plyr.PlyrEvent) => {
        track("Video Playback Paused", {
          ...getTrackerDetails(plyr, "video-playback-paused"),
        });
      };
      const onPlayerStalled = (plyr: Plyr.PlyrEvent) => {
        track("Video Playback Interrupted", {
          ...getTrackerDetails(plyr, "video-playback-interrupted"),
        });
      };
      const onPlayerSeeked = (plyr: Plyr.PlyrEvent) => {
        track("Video Playback Seek Completed", {
          ...getTrackerDetails(plyr, "video-playback-seek-completed"),
        });
      };
      // const onPlayerSeeking = (plyr: Plyr.PlyrEvent) => {
      //   track("Video Playback Seek Started", {
      //     ...getTrackerDetails(plyr, "video-playback-seek-started"),
      //   });
      // };
      const onPlayerTimeUpdate = (plyr: Plyr.PlyrEvent) => {
        onTimeUpdate?.(plyr);
      };

      playerRef.current?.plyr?.on("play", onPlayerPlay);
      playerRef.current?.plyr?.on("pause", onPlayerPause);
      playerRef.current?.plyr?.on("stalled", onPlayerStalled);
      playerRef.current?.plyr?.on("timeupdate", onPlayerTimeUpdate);
      playerRef.current?.plyr?.on("seeked", onPlayerSeeked);
      // playerRef.current?.plyr?.on("seeking", onPlayerSeeking);

      return () => {
        playerRef.current?.plyr?.off("play", onPlayerPlay);
        playerRef.current?.plyr?.off("pause", onPlayerPause);
        playerRef.current?.plyr?.off("stalled", onPlayerStalled);
        playerRef.current?.plyr?.off("timeupdate", onPlayerTimeUpdate);
        playerRef.current?.plyr?.off("seeked", onPlayerSeeked);
        // playerRef.current?.plyr?.off("seeking", onPlayerSeeking);
      };
    }, [playerRef?.current?.plyr.on, track, trackerData, onTimeUpdate, update]);

    return (
      <div className={styles.player}>
        <Plyr
          {...props}
          ref={playerRef}
          playsInline
          options={options}
          onLoadedData={(e) => setUpdate((v) => !v)}
        />
      </div>
    );
  }
);

export default Player;
export type { APITypes, PlyrProps, MediaType };
