import { useEffect, useState, forwardRef, MouseEventHandler } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField, { FieldLockStatement } from "@/components/TextField";
import { FormActions } from "@/components/FormController";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import { PersonalDetailsFormType } from "@/components/PersonalDetailsForms";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
} from "@/components/PersonalDetailsForms/hooks";
import dayjs from "dayjs";
import {
  PersonalDetailsDates,
  AnswerItem,
  TripleAnswerItem,
} from "./PersonalDetailsQuestionHelper";
import { useSnackbar, useCurrentTrip } from "@/hooks";
import { Division } from "@/fetch/gworld";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  titleClassName?: string;
  hasVisaForm?: boolean;
}

function PersonalDetailsFormTemplateWithRef<T extends PersonalDetailsFormType>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    hasVisaForm = false,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const onDateChange = (
    date: dayjs.Dayjs | null,
    key: PersonalDetailsDates
  ) => {
    formik.setValues((values) => ({
      ...values,
      [key]: date ? date.utc().startOf("day").unix() : null,
    }));
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} md={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.personalDetailsHeight)
              }
              fullWidth
              id="personalDetailsHeight"
              name="personalDetailsHeight"
              label="Height (cm)"
              type="number"
              value={formik.values.personalDetailsHeight}
              onChange={formik.handleChange}
              error={
                formik.touched.personalDetailsHeight &&
                Boolean(formik.errors.personalDetailsHeight)
              }
              helperText={
                formik.touched.personalDetailsHeight &&
                formik.errors.personalDetailsHeight
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues?.personalDetailsHeight
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.personalDetailsWeight)
              }
              fullWidth
              id="personalDetailsWeight"
              name="personalDetailsWeight"
              label="Weight (kg)"
              type="number"
              value={formik.values.personalDetailsWeight}
              onChange={formik.handleChange}
              error={
                formik.touched.personalDetailsWeight &&
                Boolean(formik.errors.personalDetailsWeight)
              }
              helperText={
                formik.touched.personalDetailsWeight &&
                formik.errors.personalDetailsWeight
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues?.personalDetailsWeight
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing="12px"
        columnSpacing="12px"
        className={styles.personalDetailsGridContainer}
      >
        <TripleAnswerItem
          question="Do you smoke?"
          name="personalDetailsSmoke"
          value={formik.values.personalDetailsSmoke}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          locked={Boolean(formik.initialValues?.personalDetailsSmoke?.length)}
          error={
            formik.touched.personalDetailsSmoke &&
            (formik.errors.personalDetailsSmoke as string)
          }
        />
        <TripleAnswerItem
          question="Do you drink alcohol?"
          name="personalDetailsAlcohol"
          value={formik.values.personalDetailsAlcohol}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          locked={Boolean(formik.initialValues?.personalDetailsAlcohol?.length)}
          error={
            formik.touched.personalDetailsAlcohol &&
            (formik.errors.personalDetailsAlcohol as string)
          }
        />
        <TripleAnswerItem
          question="Do you take any illicit substances?"
          name="personalDetailsIllicit"
          value={formik.values.personalDetailsIllicit}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          locked={Boolean(formik.initialValues?.personalDetailsIllicit?.length)}
          error={
            formik.touched.personalDetailsIllicit &&
            (formik.errors.personalDetailsIllicit as string)
          }
        />
        <AnswerItem
          question="Do you take any medication?"
          name="personalDetailsMedicationStatus"
          value={formik.values.personalDetailsMedicationStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsMedicationStatus &&
            (formik.errors.personalDetailsMedicationStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsMedicationStatus?.length
          )}
          description={{
            question: "What medications do you take?",
            name: "personalDetailsMedicationDescription",
            value: formik.values.personalDetailsMedicationDescription,
            error: formik.errors.personalDetailsMedicationDescription as string,
            touched: formik.touched
              .personalDetailsMedicationDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsMedicationDescription?.length
            ),
          }}
        />
        <AnswerItem
          question="Do you have any condition, disorders, issues, either mental, physical or otherwise that may impact your trip?"
          name="personalDetailsMentalHealthStatus"
          value={formik.values.personalDetailsMentalHealthStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsMentalHealthStatus &&
            (formik.errors.personalDetailsMentalHealthStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsMentalHealthStatus?.length
          )}
          description={{
            question: "What mental health conditions or disorders do you have?",
            name: "personalDetailsMentalHealthDescription",
            value: formik.values.personalDetailsMentalHealthDescription,
            error: formik.errors
              .personalDetailsMentalHealthDescription as string,
            touched: formik.touched
              .personalDetailsMentalHealthDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsMentalHealthDescription
                ?.length
            ),
          }}
        />
        <AnswerItem
          question="Do you have any physical limitations?"
          name="personalDetailsPhysicalLimitationsStatus"
          value={formik.values.personalDetailsPhysicalLimitationsStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsPhysicalLimitationsStatus &&
            (formik.errors.personalDetailsPhysicalLimitationsStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsPhysicalLimitationsStatus
              ?.length
          )}
          description={{
            question: "What physical limitations do you have?",
            name: "personalDetailsPhysicalLimitationsDescription",
            value: formik.values.personalDetailsPhysicalLimitationsDescription,
            error: formik.errors
              .personalDetailsPhysicalLimitationsDescription as string,
            touched: formik.touched
              .personalDetailsPhysicalLimitationsDescription as boolean,
            locked: Boolean(
              formik.initialValues
                ?.personalDetailsPhysicalLimitationsDescription?.length
            ),
          }}
        />
        <AnswerItem
          question="Do you have any dietary requirements?"
          name="personalDetailsDietStatus"
          value={formik.values.personalDetailsDietStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsDietStatus &&
            (formik.errors.personalDetailsDietStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsDietStatus?.length
          )}
          description={{
            question: "What are your dietary requirements?",
            name: "personalDetailsDietDescription",
            value: formik.values.personalDetailsDietDescription,
            error: formik.errors.personalDetailsDietDescription as string,
            touched: formik.touched.personalDetailsDietDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsDietDescription?.length
            ),
          }}
        />
        <AnswerItem
          question="Do you have any tattoos/piercings?"
          name="personalDetailsTattoosPiercingsStatus"
          value={formik.values.personalDetailsTattoosPiercingsStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsTattoosPiercingsStatus &&
            (formik.errors.personalDetailsTattoosPiercingsStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsTattoosPiercingsStatus?.length
          )}
          description={{
            question: "What tattoos/piercings do you have?",
            name: "personalDetailsTattoosPiercingsDescription",
            value: formik.values.personalDetailsTattoosPiercingsDescription,
            error: formik.errors
              .personalDetailsTattoosPiercingsDescription as string,
            touched: formik.touched
              .personalDetailsTattoosPiercingsDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsTattoosPiercingsDescription
                ?.length
            ),
          }}
        />
        <AnswerItem
          question="Do you have any drink driving convictions?"
          name="personalDetailsDrinkDrivingStatus"
          value={formik.values.personalDetailsDrinkDrivingStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsDrinkDrivingStatus &&
            (formik.errors.personalDetailsDrinkDrivingStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsDrinkDrivingStatus?.length
          )}
          description={{
            question: "What was your offence?",
            name: "personalDetailsDrinkDrivingDescription",
            value: formik.values.personalDetailsDrinkDrivingDescription,
            error: formik.errors
              .personalDetailsDrinkDrivingDescription as string,
            touched: formik.touched
              .personalDetailsDrinkDrivingDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsDrinkDrivingDescription
                ?.length
            ),
          }}
          date={{
            name: "personalDetailsDrinkDrivingDate",
            value: formik.values.personalDetailsDrinkDrivingDate,
            error: formik.errors.personalDetailsDrinkDrivingDate as string,
            touched: formik.touched.personalDetailsDrinkDrivingDate as boolean,
            onChange: onDateChange,
            locked: Boolean(
              formik.initialValues?.personalDetailsDrinkDrivingDate
            ),
          }}
          bac={{
            name: "personalDetailsDrinkDrivingBac",
            value: formik.values.personalDetailsDrinkDrivingBac,
            error: formik.errors.personalDetailsDrinkDrivingBac as string,
            touched: formik.touched.personalDetailsDrinkDrivingBac as boolean,
            locked: Number.isInteger(
              formik.initialValues?.personalDetailsDrinkDrivingBac
            ),
          }}
        />
        <AnswerItem
          question="Do you have any driving offences?"
          name="personalDetailsDrivingOffencesStatus"
          value={formik.values.personalDetailsDrivingOffencesStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsDrivingOffencesStatus &&
            (formik.errors.personalDetailsDrivingOffencesStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsDrivingOffencesStatus?.length
          )}
          description={{
            question: "What was your offence?",
            name: "personalDetailsDrivingOffencesDescription",
            value: formik.values.personalDetailsDrivingOffencesDescription,
            error: formik.errors
              .personalDetailsDrivingOffencesDescription as string,
            touched: formik.touched
              .personalDetailsDrivingOffencesDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsDrivingOffencesDescription
                ?.length
            ),
          }}
          date={{
            name: "personalDetailsDrivingOffencesDate",
            value: formik.values.personalDetailsDrivingOffencesDate,
            error: formik.errors.personalDetailsDrivingOffencesDate as string,
            touched: formik.touched
              .personalDetailsDrivingOffencesDate as boolean,
            onChange: onDateChange,
            locked: Boolean(
              formik.initialValues?.personalDetailsDrivingOffencesDate
            ),
          }}
        />
        <AnswerItem
          question="Do you have any criminal convictions?"
          name="personalDetailsCriminalStatus"
          value={formik.values.personalDetailsCriminalStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsCriminalStatus &&
            (formik.errors.personalDetailsCriminalStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsCriminalStatus?.length
          )}
          description={{
            question: "What was your offence?",
            name: "personalDetailsCriminalDescription",
            value: formik.values.personalDetailsCriminalDescription,
            error: formik.errors.personalDetailsCriminalDescription as string,
            touched: formik.touched
              .personalDetailsCriminalDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsCriminalDescription?.length
            ),
          }}
          date={{
            name: "personalDetailsCriminalDate",
            value: formik.values.personalDetailsCriminalDate,
            error: formik.errors.personalDetailsCriminalDate as string,
            touched: formik.touched.personalDetailsCriminalDate as boolean,
            onChange: onDateChange,
            locked: Boolean(formik.initialValues?.personalDetailsCriminalDate),
          }}
        />

        <AnswerItem
          question="Have you received a COVID-19 vaccine?"
          name="personalDetailsCovidStatus"
          value={formik.values.personalDetailsCovidStatus}
          onChange={formik.handleChange}
          disabled={isFormSubmitting}
          isLoading={isFormDataLoading}
          error={
            formik.touched.personalDetailsCovidStatus &&
            (formik.errors.personalDetailsCovidStatus as string)
          }
          locked={Boolean(
            formik.initialValues?.personalDetailsCovidStatus?.length
          )}
          description={{
            question: "What vaccine type?",
            name: "personalDetailsCovidDescription",
            value: formik.values.personalDetailsCovidDescription,
            error: formik.errors.personalDetailsCovidDescription as string,
            touched: formik.touched.personalDetailsCovidDescription as boolean,
            locked: Boolean(
              formik.initialValues?.personalDetailsCovidDescription?.length
            ),
          }}
        />

        {hasVisaForm && (
          <AnswerItem
            question="Do you have an approved Working Holiday Visa?"
            name="personalDetailsWorkingHolidayVisaStatus"
            value={formik.values.personalDetailsWorkingHolidayVisaStatus}
            onChange={formik.handleChange}
            disabled={isFormSubmitting}
            isLoading={isFormDataLoading}
            error={
              formik.touched.personalDetailsWorkingHolidayVisaStatus &&
              (formik.errors.personalDetailsWorkingHolidayVisaStatus as string)
            }
            locked={Boolean(
              formik.initialValues?.personalDetailsWorkingHolidayVisaDescription
                ?.length
            )}
            description={{
              question: "Name/Visa category",
              name: "personalDetailsWorkingHolidayVisaDescription",
              value: formik.values.personalDetailsWorkingHolidayVisaDescription,
              error: formik.errors
                .personalDetailsWorkingHolidayVisaDescription as string,
              touched: formik.touched
                .personalDetailsWorkingHolidayVisaDescription as boolean,
              locked: Boolean(
                formik.initialValues
                  ?.personalDetailsWorkingHolidayVisaDescription?.length
              ),
            }}
            date={{
              name: "personalDetailsWorkingHolidayVisaDate",
              value: formik.values.personalDetailsWorkingHolidayVisaDate,
              error: formik.errors
                .personalDetailsWorkingHolidayVisaDate as string,
              touched: formik.touched
                .personalDetailsWorkingHolidayVisaDate as boolean,
              onChange: onDateChange,
              label: "Date of Issue",
              locked: Boolean(
                formik.initialValues?.personalDetailsWorkingHolidayVisaDate
              ),
            }}
          />
        )}
      </Grid>
    </div>
  );
}

const PersonalDetailsForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
  isSetupForm?: boolean;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  isSetupForm = false,
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();

  const { personalDetailsValidationSchema } = usePersonalDetailsFormValidation({
    isInSetupPage: isSetupForm,
  });

  const { submitPersonalDetailsForm, isUpdatingPersonalDetails } =
    usePersonalDetailsFormSubmission();

  const { personalDetailsInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingPersonalDetails || isRefetchingForm;

  const { currentTrip } = useCurrentTrip();
  const isWorkingHolidayTrip =
    currentTrip?.division === Division.WorkingHoliday;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const onSubmit = async (values: PersonalDetailsFormType) => {
    try {
      if (formik.dirty) {
        await submitPersonalDetailsForm(values, correlationId);
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);

      onSubmitted?.();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Could not update personal details!`, {
        variant: "error",
      });
    } finally {
      setRefetchingForm(false);
    }
  };

  const initialValues = personalDetailsInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: personalDetailsValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <PersonalDetailsFormTemplate
        formik={formik}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
        hasVisaForm={isSetupForm && isWorkingHolidayTrip}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const PersonalDetailsFormTemplate = forwardRef(
  PersonalDetailsFormTemplateWithRef
);

export { PersonalDetailsFormTemplate };
export default PersonalDetailsForm;
