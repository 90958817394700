import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type Visa = Partial<{
  id: number;
  name: string;
  requirements: string;
  overview: any;
  url: string;
  content: string;
  country_code: null;
}>;

type VisasResult = Partial<{
  success: boolean;
  result: Array<Visa>;
}>;
type QueryError = {};

const useVisasQuery = (
  options?: UseQueryOptions<VisasResult, QueryError, VisasResult, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.trip_id;
  const url = `${GATEWAY_URL}/api/visas/trips/${tid}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<VisasResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<VisasResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useVisasQuery;
