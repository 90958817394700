import List, { ListItem, ListItemIcon, ListItemText } from "../List";
import Typography from "@/components/Typography";
import styles from "./NotificationPanel.module.scss";
import NotificationItem, { NotificationItemLoader } from "./NotificationItem";
import { MoreIcon, VisibleIcon } from "@/components/Icon";
import { IconButton } from "@/components/Button";
import React, { useEffect, useState } from "react";
import Menu, { MenuItem } from "@/components/Menu";
import {
  useMakeAllNotificationSeenMutation,
  useMakeNotificationSeenMutation,
  useNotificationsQuery,
  useNotificationStatisticsQuery,
} from "@/fetch/notifications";
import compact from "lodash/compact";
import Divider from "../Divider";
import { useInView } from "react-intersection-observer";
import cx from "classnames";

const Loader = () => (
  <>
    {Array(3)
      .fill(null)
      .map((item, index) => (
        <React.Fragment key={index}>
          <NotificationItemLoader />
          <Divider />
        </React.Fragment>
      ))}
  </>
);

const NotificationPanel: React.FC<{
  classes?: { list?: string };
  className?: string;
  onExit?: () => void;
}> = ({ classes, className, onExit }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const { mutateAsync: makeAllAsSeen } = useMakeAllNotificationSeenMutation();
  const { mutateAsync: makeAsSeen } = useMakeNotificationSeenMutation();
  const { refetch: refetchNotificationStatistics } =
    useNotificationStatisticsQuery();
  const { ref: loaderContainerRef, inView: isLoaderInView } = useInView({
    threshold: 0,
  });
  const {
    data: notificationsData,
    isLoading: isNotificationsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchNotifications,
  } = useNotificationsQuery();

  const notifications = compact(notificationsData?.pages?.flat(2));
  const hasNotifications = Boolean(notifications?.length > 0);

  useEffect(() => {
    if (
      !isLoaderInView ||
      !hasNextPage ||
      isNotificationsLoading ||
      isFetchingNextPage
    )
      return;

    fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoaderInView,
    isNotificationsLoading,
  ]);

  const onSeenAll = async () => {
    try {
      setMenuAnchorEl(null);
      makeAllAsSeen();
    } catch (error) {
      console.error(error);
    }
  };

  const onNotificationItemClick = async (notificationId: string) => {
    onExit?.();
    try {
      await makeAsSeen({ notificationId });
      refetchNotifications();
      refetchNotificationStatistics();
    } catch (error) {
      console.error();
    }
  };

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.header}>
        <Typography>Notifications</Typography>
        <IconButton onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
          <MoreIcon />
        </IconButton>
        <Menu
          elevation={3}
          id="conversation-menu"
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={() => setMenuAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={onSeenAll}
            disabled={!hasNotifications || isNotificationsLoading}
          >
            <ListItemIcon sx={{ color: "text.primary" }}>
              <VisibleIcon variant="outlined" />
            </ListItemIcon>
            <ListItemText>Mark all as seen</ListItemText>
          </MenuItem>
        </Menu>
      </div>
      <Divider />
      <List className={cx(styles.notificationsListRoot, classes?.list)}>
        {!hasNotifications && !isNotificationsLoading && (
          <ListItem className={styles.textItem}>
            <Typography color="text.secondary" align="center" variant="body2">
              You have no notifications yet
            </Typography>
          </ListItem>
        )}

        {!hasNotifications && isNotificationsLoading && <Loader />}

        {notifications?.map((item) => (
          <NotificationItem
            key={item?._id}
            notification={item}
            onClick={onNotificationItemClick}
          />
        ))}

        {hasNotifications && hasNextPage && (
          <div ref={loaderContainerRef}>
            {isFetchingNextPage && <NotificationItemLoader />}
          </div>
        )}

        {hasNotifications && !hasNextPage && (
          <ListItem className={styles.textItem}>
            <Typography color="text.secondary" align="center" variant="body2">
              That&apos;s all your notifications
            </Typography>
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default NotificationPanel;
