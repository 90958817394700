import SvgIcon, { SvgIconProps } from "./SvgIcon";

const GroupIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M9 13C10.0544 13 10.9182 13.8159 10.9945 14.8507L11 15V19C11 20.0544 10.1841 20.9182 9.14926 20.9945L9 21H5C3.94564 21 3.08183 20.1841 3.00549 19.1493L3 19V15C3 13.9456 3.81588 13.0818 4.85074 13.0055L5 13H9ZM19 13C20.0544 13 20.9182 13.8159 20.9945 14.8507L21 15V19C21 20.0544 20.1841 20.9182 19.1493 20.9945L19 21H15C13.9456 21 13.0818 20.1841 13.0055 19.1493L13 19V15C13 13.9456 13.8159 13.0818 14.8507 13.0055L15 13H19ZM9 15H5V19H9V15ZM19 15H15V19H19V15ZM9 3C10.0544 3 10.9182 3.81588 10.9945 4.85074L11 5V9C11 10.0544 10.1841 10.9182 9.14926 10.9945L9 11H5C3.94564 11 3.08183 10.1841 3.00549 9.14926L3 9V5C3 3.94564 3.81588 3.08183 4.85074 3.00549L5 3H9ZM19 3C20.0544 3 20.9182 3.81588 20.9945 4.85074L21 5V9C21 10.0544 20.1841 10.9182 19.1493 10.9945L19 11H15C13.9456 11 13.0818 10.1841 13.0055 9.14926L13 9V5C13 3.94564 13.8159 3.08183 14.8507 3.00549L15 3H19ZM9 5H5V9H9V5ZM19 5H15V9H19V5Z" />
    </SvgIcon>
  );
};

export default GroupIcon;
