import useTrackers from "./useTrackers";
import useDialog from "./useDialog";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";

// Task Intents are used for handling task card actions.
// So if you are changing these make sure you are reflecting
// the changes in the below places as well:
// https://bitbucket.org/gwat/progress/src/master/modules/config/progress-v2.js
// https://bitbucket.org/gwat/progress/src/master/modules/config/explore.js

export type TaskIntentSchema = Partial<
  | {
      intent: "gworld/appointment";
    }
  | {
      intent: "gworld/experience/resume";
    }
  | {
      intent: "gworld/experience/payments/invoice/trip";
    }
  | {
      intent: "gworld/experience/payments/invoices";
    }
  | {
      intent: "gworld/experience/payments/invoice";
      data: { invoiceId: number };
    }
  | {
      intent: "gworld/experience/document";
    }
  | {
      intent: "gworld/marketplace";
    }
  | {
      intent: "gworld/rewards/invite";
    }
  | {
      intent: "gworld/rewards";
    }
  | {
      intent: "gworld/experience/interviews";
    }
  | {
      intent: "gworld/experience/accommodation";
    }
  | {
      intent: "gworld/experience/arrival";
    }
  | {
      intent: "gworld/experience/host";
    }
  | {
      intent: "gworld/account/personal";
    }
  | {
      intent: "gworld/experience/au-pair-profile";
    }
  | {
      intent: "gworld/social";
    }
>;

type TranslatedIntent = Partial<{
  path: string;
  image: string;
  onClick: () => void;
}>;

const useTaskAction = () => {
  const { track } = useTrackers();
  const { openTopLevelDialog } = useDialog();

  const translateTaskIntent = (schema: TaskIntentSchema): TranslatedIntent => {
    const onClick = () =>
      track("Task Card Clicked", {
        eventId: "task-card-clicked",
        intent: schema.intent,
      });

    switch (schema.intent) {
      case "gworld/appointment":
        return {
          path: "/experience/dashboard?dialog=appointment",
          onClick,
        };

      case "gworld/experience/payments/invoice/trip":
        return {
          path: `/experience/payments/invoice/trip`,
          image: "payment.svg",
          onClick,
        };

      case "gworld/experience/payments/invoices":
        return {
          path: `/experience/payments/invoices`,
          image: "payment.svg",
          onClick,
        };

      case "gworld/experience/accommodation":
        return {
          path: `/experience/accommodation`,
          onClick,
        };

      case "gworld/experience/interviews":
        return {
          path: `/experience/jobs?tab=job-opportunities`,
          onClick,
        };

      case "gworld/experience/arrival":
        return {
          path: "/experience/arrival",
          onClick,
        };

      case "gworld/experience/host":
        return {
          path: `/experience/host`,
          onClick,
        };

      case "gworld/experience/payments/invoice":
        return {
          path: `/experience/payments/invoice/${schema.data?.invoiceId}`,
          image: "payment.svg",
          onClick,
        };

      case "gworld/social":
        return {
          path: "/social",
          onClick,
        };

      case "gworld/experience/resume":
        return {
          path: "/experience/resume",
          image: "resume.svg",
          onClick,
        };

      case "gworld/experience/document":
        return {
          path: "/experience/documents",
          image: "documents.svg",
          onClick,
        };

      case "gworld/marketplace":
        return {
          path: "/marketplace",
          image: "marketplace.svg",
          onClick,
        };

      case "gworld/rewards/invite":
        return {
          image: "referral.svg",
          onClick: () => {
            onClick();
            openTopLevelDialog(TopLevelDialogNames.Invitation);
          },
        };

      case "gworld/rewards":
        return {
          path: "/rewards?tab=reward-center",
          image: "referral.svg",
          onClick,
        };

      case "gworld/experience/au-pair-profile":
        return {
          path: "/experience/au-pair-profile",
          onClick,
          image: "resume.svg",
        };

      case "gworld/account/personal":
        return {
          path: "/account/personal",
          onClick,
        };
      case "gworld/experience/arrival":
        return {
          path: "/experience/arrival",
          onClick,
        };

      default:
        return {
          onClick,
        };
    }
  };

  return { translateTaskIntent };
};

export default useTaskAction;
