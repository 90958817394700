import { FC } from "react";
import MuiTableFooter, {
  TableFooterProps as MuiTableFooterProps,
} from "@mui/material/TableFooter";
import TableRow from "./TableRow";
import TablePagination from "./TablePagination";

type TableFooterProps = MuiTableFooterProps & {
  count: number;
  page: number;
  onChangePage: (page: number) => void;
  rowsPerPage: number;
  colSpan: number;
};

const TableFooter: FC<TableFooterProps> = ({
  count,
  page,
  onChangePage,
  rowsPerPage,
  colSpan,
  ...props
}) => {
  return (
    <MuiTableFooter {...props}>
      <TableRow>
        <TablePagination
          colSpan={colSpan}
          count={count}
          page={page}
          onPageChange={(e, newPage: number) => onChangePage(newPage)}
          rowsPerPage={rowsPerPage}
        />
      </TableRow>
    </MuiTableFooter>
  );
};

export default TableFooter;
