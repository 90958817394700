import { useMemo, useCallback, useEffect } from "react";
import Button from "@/components/Button";
import cx from "classnames";
import { useDropzone } from "react-dropzone";
import theme from "@/theme/theme";
import styles from "./Uploader.module.scss";
import {
  UPLOADER_ACCEPT_TYPES,
  UploaderFile,
  UploaderBaseProps,
} from "./Uploader";
import { Skeleton } from "@/components/Loader";

interface MediaUploaderProps extends UploaderBaseProps {
  pickerOpen?: boolean;
}

const MediaUploader: React.FC<MediaUploaderProps> = ({
  maxSize,
  maxFiles,
  accept = [UPLOADER_ACCEPT_TYPES.Image, UPLOADER_ACCEPT_TYPES.Video],
  onChangeFile,
  disabled = false,
  multiple = true,
  className,
  isLoading,
  style,
  pickerOpen = false,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: Array<UploaderFile>) => {
      const transformedAcceptedFiles = acceptedFiles.map((item) => {
        const preview = URL.createObjectURL(item) || undefined;
        return Object.assign(item, {
          preview: preview,
        });
      });

      onChangeFile(transformedAcceptedFiles);
    },
    [onChangeFile]
  );

  useEffect(() => {
    if (pickerOpen) openFilePicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerOpen]);

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    noClick: true,
    accept: (accept || [])?.reduce((obj, curr) => ({ ...obj, ...curr }), {}),
    disabled,
    multiple,
  });

  const ProfileStyle = {
    width: 160,
    height: 160,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
    borderWidth: `2px`,
    borderStyle: "dashed",
    borderColor: disabled
      ? theme.palette.divider
      : theme.palette.common.blue.light,
    color: theme.palette.text.secondary,
    outline: "none",
    position: "relative" as "relative",
  };

  const RejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const dropzoneContainerStyle = useMemo(
    () => ({
      ...ProfileStyle,
      ...(isDragReject ? RejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragReject, disabled]
  );

  return (
    <div className={cx(styles.profileRoot, className)} style={{ ...style }}>
      {isLoading ? (
        <>
          <Skeleton width="120px" height="120px" variant="circular" />
          <Skeleton width="120px" style={{ marginLeft: "24px" }} />
        </>
      ) : (
        <>
          <div {...getRootProps({ style: { ...dropzoneContainerStyle } })}>
            <input {...getInputProps()} />
            <Button
              size="small"
              variant="text"
              className={styles.mediaPickerButton}
              onClick={openFilePicker}
              disabled={disabled}
            >
              Browse for media
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default MediaUploader;
