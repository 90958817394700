import { useMutation, UseMutationOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type MakeNotificationSeenMutationVariables = {
  notificationId: string;
};

type MakeNotificationSeenResult = {
  acknowledged: boolean;
  modifiedCount: number;
  upsertedId: unknown;
  upsertedCount: number;
  matchedCount: number;
};

type QueryError = {};

const useMakeNotificationSeenMutation = (
  options?: UseMutationOptions<
    MakeNotificationSeenResult,
    QueryError,
    MakeNotificationSeenMutationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      MakeNotificationSeenResult,
      QueryError,
      MakeNotificationSeenMutationVariables
    >(
      async ({ notificationId }: MakeNotificationSeenMutationVariables) => {
        const url = `${ENDPOINT}/v2/seen`;

        return fetchWithAuth<MakeNotificationSeenResult>(url, {
          method: "POST",
          body: {
            notifications: [notificationId],
          },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useMakeNotificationSeenMutation;
