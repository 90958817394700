import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import useAxiosWithAuth from "@/fetch/axiosWithAuth";
import { useCurrentTrip } from "@/hooks";
import { UploaderFile } from "@/components/Uploader";

type QueryError = {};

type SignedUrlResult = Array<{ key: string; value: string }>;
type SignedUrlVariable = { tid: number | undefined };

const useUploadTravellerProfilePicture = (
  options?: UseMutationOptions<SignedUrlResult, QueryError, SignedUrlVariable>
) => {
  const { fetchWithAuth, cancel: cancelFetch } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const {
    axiosWithAuth,
    cancel,
    loading: isUploading,
    progress,
  } = useAxiosWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const cid = personalDetails?.traveller?.id;
  const signedUrlEndpoint = `${GATEWAY_URL}/traveller/${cid}/generate-upload-image-url`;

  const { mutateAsync: getSignedUrl, isLoading: isGettingSignedUr } =
    useMutation<SignedUrlResult, QueryError, SignedUrlVariable>(
      async ({ tid }) => {
        if (!tid) {
          throw new Error("tid not found");
        }
        return fetchWithAuth<Array<{ key: string; value: string }>>(
          signedUrlEndpoint,
          {
            method: "POST",
            body: { tid },
          }
        );
      },
      options
    );

  const uploadProfilePicture = async ({ file }: { file: UploaderFile }) => {
    try {
      const createResponse = await getSignedUrl({ tid });
      const fields = createResponse;
      const form = new FormData();
      fields
        .filter((key) => !["url"].includes(key.key))
        .forEach(({ key, value }) => {
          form.append(key, value);
        });
      form.append("file", file);
      const uploadUrl = fields.find((r) => r.key === "url")?.value || "";
      const result = await axiosWithAuth(uploadUrl, {
        method: "post",
        data: form,
      });

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const cancelUploadRequest = () => {
    cancelFetch();
    cancel();
  };

  return {
    uploadProfilePicture,
    cancelUploadRequest,
    progress,
    isUploading,
    isPreparing: isGettingSignedUr,
  };
};

export default useUploadTravellerProfilePicture;
