import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

type HostDetails = Array<
  Partial<{
    about: string;
    address: string;
    cid: number;
    city: string;
    country: string;
    end_date: number;
    id: number;
    name: string;
    partner_id: number;
    post_code: string;
    start_date: string;
    state: string;
    status: string;
    _ca: number;
    _cb: number;
    _lma: any;
    _lmb: any;
  }>
>;
type QueryError = {};

const useHostDetailsQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<HostDetails, QueryError, HostDetails, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/host-family/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<HostDetails, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<HostDetails>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useHostDetailsQuery;
