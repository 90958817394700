import { useCallback, useEffect, useRef, useState } from "react";
import {
  useCreateTwilioTokenMutation,
  useLeaveTwilioConversationMutation,
  usePrepareTwilioConversationMutation,
} from "@/fetch/social";
import useChatContext, { ConnectionStatus } from "./useChatContext";
import {
  Client as TwilioChatClient,
  Paginator,
  Message,
  Conversation,
  Participant,
  User,
  SendMediaOptions,
  ConversationUpdateReason,
  UserUpdateReason,
  ParticipantUpdateReason,
  ConnectionState,
} from "@twilio/conversations";
import { useCurrentTrip, useTrackers } from "@/hooks";
import dayjs from "dayjs";

const TWILIO_WINDOW_SIZE = 200;

export type UserAttributes = Partial<{
  cid: number;
  nickname: string;
  firstName: string;
  lastName: string;
}>;

export type ConversationAttributes = Partial<{
  participants: Array<
    Partial<{
      nickname: string;
      firstName: string;
      lastName: string;
      cid: number;
      lastReadMessageIndex: number;
    }>
  >;
}>;

const useTwilioConversation = () => {
  const chatContext = useChatContext();
  const { track } = useTrackers();
  const state = chatContext?.state;
  const dispatch = chatContext?.dispatch;
  const openChat = chatContext?.openChat;
  const changeChatPopoverState = chatContext?.changePopoverState;
  const overlayLoading = chatContext?.overlayLoading;
  const setOverlayLoading = chatContext?.setOverlayLoading;
  const currentConversationSid = chatContext?.currentConversationSid;
  const setCurrentConversationSid = chatContext?.setCurrentConversationSid;
  const isChatPopOverOpen = chatContext?.isChatPopOverOpen;
  const setIsChatPopOverOpen = chatContext?.setIsChatPopOverOpen;
  const setShowTravellersList = chatContext?.setShowTravellersList;
  const showTravellersList = chatContext?.showTravellersList;
  const client = useRef<TwilioChatClient | null>(state?.client || null);
  const { currentTrip } = useCurrentTrip();
  const thisUserCid = currentTrip?.cid;
  const [isConversationsLoading, setIsConversationsLoading] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [deletingConversationSid, setDeletingConversationSid] =
    useState<string>();

  const { mutateAsync: createTwilioToken } = useCreateTwilioTokenMutation();
  const { mutateAsync: leaveTwilioConversationMutation } =
    useLeaveTwilioConversationMutation();
  const { mutateAsync: prepareTwilioConversationMutation } =
    usePrepareTwilioConversationMutation();

  const sessionsToDisplay =
    [...(state?.sessions || [])]
      ?.filter((session) => {
        const lastReadMessageIndexByThisUserBeforeLeave = (
          session?.conversation?.attributes as ConversationAttributes
        )?.participants?.find(
          (item) => item?.cid === thisUserCid
        )?.lastReadMessageIndex;
        return Boolean(session.conversation.lastMessage) &&
          Number.isInteger(lastReadMessageIndexByThisUserBeforeLeave)
          ? (session?.conversation?.lastMessage?.index || -1) >
              Number(lastReadMessageIndexByThisUserBeforeLeave)
          : Number.isInteger(session.conversation?.lastMessage?.index);
      })
      .sort(
        (session1, session2) =>
          dayjs(session2?.lastMessage?.dateUpdated).unix() -
          dayjs(session1?.lastMessage?.dateUpdated).unix()
      ) || [];

  const totalUnreadConversationCount = sessionsToDisplay
    ?.map((session) => {
      const lastReadMessageIndexByThisUserBeforeLeave = (
        session?.conversation?.attributes as ConversationAttributes
      )?.participants?.find(
        (item) => item?.cid === thisUserCid
      )?.lastReadMessageIndex;
      const lastMessage =
        !lastReadMessageIndexByThisUserBeforeLeave ||
        (session?.lastMessage?.index || 0) >
          lastReadMessageIndexByThisUserBeforeLeave
          ? session?.lastMessage
          : undefined;
      const isLastMessageFromMe =
        lastMessage?.author === thisUserCid?.toString();
      const unreadMessageCount = session?.unreadMessageCount || 0;
      return (!isLastMessageFromMe && unreadMessageCount ? 1 : 0) as number;
    })
    ?.reduce((unread1, unread2) => unread1 + unread2, 0);

  const initializeTwilio = async (): Promise<TwilioChatClient | null> => {
    try {
      const twilioToken = client?.current?.token || null;
      if (twilioToken) return client?.current;

      const { accessToken: token } = await createTwilioToken();
      if (!token) return null;

      // const newClient = await TwilioChatClient.create(token);
      const newClient = new TwilioChatClient(token);
      client.current = newClient;
      if (dispatch)
        dispatch({
          type: "updateClient",
          client: newClient,
        });
      return newClient;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const processConversations = async (
    conversationPaginator?: Paginator<Conversation>
  ) => {
    if (dispatch && conversationPaginator)
      dispatch({
        type: "updateConversations",
        conversationsPaginator: conversationPaginator,
      });

    if (conversationPaginator?.hasNextPage) {
      const nextPaginator = await conversationPaginator.nextPage();
      const conversationItems: Conversation[] = await processConversations(
        nextPaginator
      );
      return [...nextPaginator?.items, ...conversationItems];
    }

    return conversationPaginator?.items || [];
  };

  const updateConversations = useCallback(
    async (hasLoadingIndicator = false) => {
      try {
        if (hasLoadingIndicator) setIsConversationsLoading(true);

        /* Initial twilio client if needed (just first time) */
        await initializeTwilio();

        const conversationPaginator =
          await client?.current?.getSubscribedConversations();
        const conversations = await processConversations(conversationPaginator);

        conversations?.forEach(async (conversation) => {
          await getConversationAdditionalDetails(conversation);
        });

        return conversations;
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        if (hasLoadingIndicator) setIsConversationsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getConversationAdditionalDetails = async (
    conversation: Conversation
  ) => {
    try {
      if (dispatch)
        dispatch({
          type: "updateConversationDetailsLoading",
          conversationSid: conversation.sid,
          isLoading: true,
        });
      const unreadMessageCount = await conversation.getUnreadMessagesCount();
      //   conversation.advanceLastReadMessageIndex(0);
      const participants = await conversation.getParticipants();
      const lastMessage = (await conversation?.getMessages(1)).items?.[0];

      const otherParticipant = participants?.filter(
        (item) => item?.identity !== thisUserCid?.toString()
      )[0];
      const otherUser = await otherParticipant?.getUser();
      const otherUserAttributes = otherUser?.attributes as UserAttributes;
      const otherTraveller = (
        conversation?.attributes as ConversationAttributes
      )?.participants?.find((item) => item?.cid !== thisUserCid);

      const name = Boolean(otherUser)
        ? otherUserAttributes?.nickname ??
          `${otherUserAttributes?.firstName} ${otherUserAttributes?.lastName}`
        : otherTraveller?.nickname ??
          `${otherTraveller?.firstName} ${otherTraveller?.lastName}`;

      if (dispatch)
        dispatch({
          type: "updateConversationDetails",
          conversationSid: conversation.sid,
          user: otherUser ?? {},
          participant: otherParticipant ?? {},
          unreadMessageCount: unreadMessageCount,
          lastMessage: lastMessage,
          travellerInfo: {
            cid: otherUserAttributes?.cid || otherTraveller?.cid,
            name,
          },
          isDetailsLoading: false,
        });
    } catch (error) {
      console.error(error);
    } finally {
      if (dispatch)
        dispatch({
          type: "updateConversationDetailsLoading",
          conversationSid: conversation.sid,
          isLoading: false,
        });
    }
  };

  const getInitialMessages = async (conversationSid?: string) => {
    if (!conversationSid) return;
    try {
      setIsMessagesLoading(true);
      const conversation = state?.sessions?.find(
        (item) => item?.conversationSid === conversationSid
      )?.conversation;
      const messagesPaginator = await conversation?.getMessages(
        TWILIO_WINDOW_SIZE
      );
      if (dispatch)
        dispatch({
          type: "addInitialMessages",
          conversationSid,
          messages: messagesPaginator?.items,
          messagesPaginator,
        });
      setIsMessagesLoading(false);
      await conversation?.setAllMessagesRead();
    } catch (error) {
      console.error(error);
    }
    setIsMessagesLoading(false);
  };

  const getMoreMessages = async (conversationSid?: string) => {
    if (!conversationSid || isMessagesLoading) return;
    try {
      setIsMessagesLoading(true);
      const session = state?.sessions?.find(
        (item) => item?.conversationSid === conversationSid
      );
      const conversation = session?.conversation;
      if (!session?.messagesPaginator?.hasPrevPage) return;
      const messagesPaginator = await session?.messagesPaginator?.prevPage();
      if (dispatch)
        dispatch({
          type: "addPaginatedMessages",
          conversationSid,
          messages: messagesPaginator?.items,
          messagesPaginator,
        });
      setIsMessagesLoading(false);
      await conversation?.setAllMessagesRead();
    } catch (error) {
      console.error(error);
    }
    setIsMessagesLoading(false);
  };

  const setAllMessagesRead = async (conversationSid?: string) => {
    if (!conversationSid) return;

    const session = state?.sessions?.find(
      (item) => item?.conversationSid === conversationSid
    );
    try {
      await session?.conversation?.setAllMessagesRead();
      const unreadMessageCount =
        await session?.conversation.getUnreadMessagesCount();

      const lastMessage = (await session?.conversation.getMessages(1))
        ?.items?.[0];
      if (dispatch)
        dispatch({
          type: "updateLastMessage",
          lastMessage,
          unreadMessageCount,
          conversationSid: conversationSid,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const addNewMessages = (conversationSid?: string, messages?: Message[]) => {
    if (!conversationSid) return;
    try {
      if (dispatch)
        dispatch({
          type: "addNewMessages",
          conversationSid,
          messages,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = async (
    input: string | FormData | SendMediaOptions | null,
    conversationSid?: string
  ) => {
    if (!conversationSid || !input) return;
    const now = new Date();

    try {
      const session = state?.sessions.find(
        (item) => item?.conversationSid === conversationSid
      );
      const peerCid = session?.travellerInfo?.cid;
      const conversation = session?.conversation;

      if (dispatch)
        dispatch({
          type: "addToOutgoingMessages",
          conversationSid,
          outgoingMessage: {
            body: input?.toString(),
            dateCreated: now,
            status: "sending",
          },
        });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // await conversation?.sendMessage(input);
      await conversation
        ?.prepareMessage()
        .setBody(input.toString())
        .build()
        .send();

      if (dispatch)
        dispatch({
          type: "removeFromOutgoingMessages",
          conversationSid,
          date: now,
        });

      await conversation?.setAllMessagesRead();

      track("Message Sent", {
        eventId: "message-sent",
        travellerCid: thisUserCid,
        peerCid,
      });
    } catch (error) {
      console.error(error);
      if (dispatch) {
        dispatch({
          type: "removeFromOutgoingMessages",
          conversationSid,
          date: now,
        });
        dispatch({
          type: "addToOutgoingMessages",
          conversationSid,
          outgoingMessage: {
            body: input?.toString(),
            dateCreated: now,
            status: "notSent",
          },
        });
      }
    }
  };

  const updateUser = async (user: User) => {
    try {
      if (dispatch && user)
        dispatch({
          type: "updateUser",
          user,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const updateParticipant = async (participant: Participant) => {
    try {
      if (dispatch && participant)
        dispatch({
          type: "updateParticipant",
          participant,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onConversationUpdate = async ({
    conversation,
    updateReasons,
  }: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => {
    try {
      if (!dispatch || !conversation) return;

      if (updateReasons?.includes("lastMessage")) {
        const lastMessage = (await conversation.getMessages(1)).items?.[0];
        const unreadMessageCount = await conversation.getUnreadMessagesCount();
        dispatch({
          type: "updateLastMessage",
          lastMessage,
          unreadMessageCount,
          conversationSid: conversation.sid,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const leaveConversation = async (
    conversationSid?: string,
    peerCid?: number
  ) => {
    try {
      if (!conversationSid) return;
      setDeletingConversationSid(conversationSid);

      const session = state?.sessions?.find(
        (item) => item?.conversationSid === conversationSid
      );
      const lastMessageIndex = session?.lastMessage?.index;

      await leaveTwilioConversationMutation({
        conversationSid,
        lastReadMessageIndex: lastMessageIndex,
      });
      if (dispatch)
        dispatch({
          type: "removeSession",
          conversationSid,
        });

      track("Conversation Deleted", {
        eventId: "conversation-deleted",
        conversationSid,
        peerCid,
        travellerCid: thisUserCid,
      });
    } catch (error) {
      throw error;
    }
    setDeletingConversationSid(undefined);
  };

  const toggleChatSidebar = () => openChat?.({ toggleState: true });

  const openChatPopup = async (
    travellerCid?: number,
    actionSource?: string
  ) => {
    if (!travellerCid || !openChat) return;
    await openChat({ conversationSid: undefined, loading: true });
    const foundConversationSid = state?.sessions?.filter(
      (item) => item?.travellerInfo?.cid === travellerCid
    )?.[0]?.conversationSid;
    if (foundConversationSid) {
      await openChat({ conversationSid: foundConversationSid, loading: false });
    } else {
      try {
        const { conversation_sid } = await prepareTwilioConversationMutation({
          travellerCid,
        });

        if (!conversation_sid) return;

        track("Chat Initiated", {
          eventId: "chat-initiated",
          initiatorCid: thisUserCid,
          peerCid: travellerCid,
          actionSource,
        });
        let twilioClient = client?.current;
        if (!twilioClient) twilioClient = await initializeTwilio();
        await updateConversations();
        await openChat({ conversationSid: conversation_sid, loading: false });
      } catch (error) {
        console.error(error);
        await openChat({ loading: false });
      }
    }
  };

  const changePopoverState = (open?: boolean | undefined) => {
    changeChatPopoverState?.(open);
  };

  const updateLastReadMessage = async (conversation?: Conversation) => {
    try {
      if (!conversation) return;

      const lastReadMessageIndexByThisUserBeforeLeave = (
        conversation?.attributes as ConversationAttributes
      )?.participants?.find((item) => item?.cid === thisUserCid)?.[
        "lastReadMessageIndex"
      ];
      if (
        conversation?.lastReadMessageIndex === null &&
        lastReadMessageIndexByThisUserBeforeLeave
      ) {
        // participant is joining first time after leave

        if (lastReadMessageIndexByThisUserBeforeLeave)
          await conversation?.updateLastReadMessageIndex(
            lastReadMessageIndexByThisUserBeforeLeave
          );
        const lastMessage = (await conversation.getMessages(1)).items?.[0];
        const unreadMessageCount = lastMessage?.index
          ? lastMessage?.index - lastReadMessageIndexByThisUserBeforeLeave
          : 0;

        if (dispatch)
          dispatch({
            type: "updateLastMessage",
            lastMessage,
            unreadMessageCount,
            conversationSid: conversation?.sid,
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateConnectionStatus = (status: ConnectionStatus) => {
    if (dispatch)
      dispatch({
        type: "updateConnectionStatus",
        status,
      });
  };

  const startTwilioListener = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!client.current) return () => {};

      initializeTwilio();

      const updateTwilioToken = async () => {
        try {
          const { accessToken: token } = await createTwilioToken();
          if (token) client?.current?.updateToken(token);
        } catch (error) {
          console.error(error);
        }
      };

      const tokenAboutToExpireListener = client?.current?.on(
        "tokenAboutToExpire",
        updateTwilioToken
      );

      const tokenExpiredListener = client?.current?.on(
        "tokenExpired",
        updateTwilioToken
      );

      const conversationUpdateListener = client?.current?.addListener(
        "conversationUpdated",
        onConversationUpdate
      );

      const joinConversationListener = client?.current?.on(
        "conversationJoined",
        async (conversation: Conversation) => {
          const updatedConversations = await updateConversations();
          const newJoinedConversation = updatedConversations?.find(
            (item) => item.sid === conversation.sid
          );

          await updateLastReadMessage(newJoinedConversation);
        }
      );
      const addConversationListener = client?.current?.on(
        "conversationAdded",
        async () => {
          try {
            updateConversations();
          } catch (error) {
            console.error(error);
          }
        }
      );
      const removeConversationListener = client?.current?.on(
        "conversationRemoved",
        async () => {
          try {
            updateConversations();
          } catch (error) {
            console.error(error);
          }
        }
      );

      const leftConversationListener = client?.current?.addListener(
        "conversationLeft",
        async () => {
          try {
            updateConversations();
          } catch (error) {
            console.error(error);
          }
        }
      );

      const updateUserListener = client?.current?.addListener(
        "userUpdated",
        async ({
          user,
          updateReasons,
        }: {
          user: User;
          updateReasons: UserUpdateReason[];
        }) => {
          try {
            await updateUser(user);
          } catch (error) {
            console.error(error);
          }
        }
      );

      const updateParticipantListener = client?.current?.addListener(
        "participantUpdated",
        async ({
          participant,
          updateReasons,
        }: {
          participant: Participant;
          updateReasons: ParticipantUpdateReason[];
        }) => {
          try {
            await updateParticipant(participant);
          } catch (error) {
            console.error(error);
          }
        }
      );
      const leftParticipantListener = client?.current?.addListener(
        "participantLeft",
        async () => {
          try {
            updateConversations();
          } catch (error) {
            console.error(error);
          }
        }
      );
      const joinParticipantListener = client?.current?.addListener(
        "participantJoined",
        async () => {
          try {
            updateConversations();
          } catch (error) {
            console.error(error);
          }
        }
      );

      const typingStartedListener = client?.current?.addListener(
        "typingStarted",
        async (participant: Participant) => {
          try {
            await updateParticipant(participant);
          } catch (error) {
            console.error(error);
          }
        }
      );

      const typingEndedListener = client?.current?.addListener(
        "typingEnded",
        async (participant: Participant) => {
          try {
            await updateParticipant(participant);
          } catch (error) {
            console.error(error);
          }
        }
      );

      const connectionStateListener = client?.current?.on(
        "connectionStateChanged",
        async (connectionState: ConnectionState) => {
          if (connectionState === "connected") {
            try {
              updateConnectionStatus("connected");
              await updateConversations(!state?.sessions?.length);

              state?.sessions?.forEach(async (session) => {
                getConversationAdditionalDetails(session.conversation);
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      );

      const messageAddedListener = client?.current?.addListener(
        "messageAdded",
        (message: Message) => {
          addNewMessages(message?.conversation?.sid, [message]);
        }
      );

      client.current.on("connectionError", (data) => {
        updateConnectionStatus("error");
      });

      return () => {
        tokenAboutToExpireListener?.removeAllListeners();
        tokenExpiredListener?.removeAllListeners();
        joinConversationListener?.removeAllListeners();
        addConversationListener?.removeAllListeners();
        removeConversationListener?.removeAllListeners();
        leftConversationListener?.removeAllListeners();
        connectionStateListener?.removeAllListeners();
        updateUserListener?.removeAllListeners();
        updateParticipantListener?.removeAllListeners();
        leftParticipantListener?.removeAllListeners();
        joinParticipantListener?.removeAllListeners();
        typingStartedListener?.removeAllListeners();
        typingEndedListener?.removeAllListeners();
        conversationUpdateListener?.removeAllListeners();
        messageAddedListener?.removeAllListeners();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client?.current]);

  return {
    initializeTwilio,
    startTwilioListener,
    prepareTwilioConversationMutation,
    client: client?.current,
    state,
    updateConversations,
    isConversationsLoading,
    deletingConversationSid,
    thisUserCid,
    getInitialMessages,
    getMoreMessages,
    isMessagesLoading,
    setAllMessagesRead,
    sendMessage,
    leaveConversation,
    openChatPopup,
    changePopoverState,
    toggleChatSidebar,
    overlayLoading,
    setOverlayLoading,
    currentConversationSid,
    setCurrentConversationSid,
    totalUnreadConversationCount,
    sessionsToDisplay,
    setIsChatPopOverOpen,
    isChatPopOverOpen,
    showTravellersList,
    setShowTravellersList,
  };
};

export default useTwilioConversation;
