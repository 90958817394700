import { useMutation, UseMutationOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { RedeemRequestItem } from "./useRedeemRequestsQuery";

export type CreateRedeemRequestVariables = {
  global_dollar_reward_id: number;
};

type CreateRedeemRequestResult = {
  result: RedeemRequestItem;
  success: boolean;
};

type QueryError = {};

const useCreateRedeemRequestMutation = (
  options?: UseMutationOptions<
    CreateRedeemRequestResult,
    QueryError,
    CreateRedeemRequestVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;
  return {
    ...useMutation<
      CreateRedeemRequestResult,
      QueryError,
      CreateRedeemRequestVariables
    >(
      async (inputObj: CreateRedeemRequestVariables) => {
        const url = `${REWARD_ENDPOINT}/global-dollar/redeem-request`;

        return fetchWithAuth<CreateRedeemRequestResult>(url, {
          method: "POST",
          body: {
            ...inputObj,
            cid: cid,
            tid: tid,
          },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateRedeemRequestMutation;
