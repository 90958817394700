import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type TourItem = Partial<{
  outstanding: number;
  logoPath: string;
  name: string;
  supplier: string;
  date_from: string;
  date_to: string;
  document: string;
}>;

type Tours = Partial<{
  isPaid: boolean;
  toursList: Array<TourItem>;
}>;
type QueryError = {};

const useTripToursQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<Tours, QueryError, Tours, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/tours-stopovers/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<Tours, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<Tours>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useTripToursQuery;
