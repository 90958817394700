import Typography, { TypographyProps } from "@/components/Typography";
import { useTravellerProfile } from "@/hooks";
import styles from "./TravellerNameLink.module.scss";
import cx from "classnames";
import RichContentPreview from "../RichContentPreview";

const TravellerNameLink: React.FC<
  TypographyProps & {
    nickname?: string;
    cid: number | undefined;
  }
> = ({ nickname, cid, className, children, ...props }) => {
  const { goToTravellerProfile } = useTravellerProfile();
  return (
    <RichContentPreview
      component="button"
      color="text.primary"
      {...(props as any)}
      className={cx(styles.nickname, className)}
      onClick={() => goToTravellerProfile(cid)}
      content={nickname || ""}
    >
      {children}
    </RichContentPreview>
  );
};

export default TravellerNameLink;
