import { FC, SyntheticEvent } from "react";
import TextField, { FieldLockStatement } from "@/components/TextField";
import { MenuItem } from "@/components/Menu";
import { DropdownIcon } from "@/components/Icon";
import { CircularProgress } from "@/components/Loader";
import { useGendersQuery } from "@/fetch/traveller";

type Props = {
  disabled?: boolean;
  onChange?: ((event: SyntheticEvent<Element, Event>) => void) | undefined;
  value?: unknown;
  touched?: boolean;
  error?: string;
  label?: string;
  locked?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
  hasInitialValue?: boolean;
};

const GenderSelector: FC<Props> = ({
  disabled,
  onChange,
  value,
  touched,
  error,
  label,
  locked,
  disableClearable,
  placeholder,
  hasInitialValue,
}) => {
  const { data: gendersData, isLoading: isLoadingGenders } = useGendersQuery();

  const genders = gendersData?.result || [];

  return (
    <TextField
      disabled={disabled || isLoadingGenders}
      fullWidth
      SelectProps={{
        fullWidth: true,
        IconComponent: hasInitialValue ? "span" : DropdownIcon,
      }}
      id="generalInfoGender"
      name="generalInfoGender"
      label={label || "Gender"}
      value={value}
      onChange={onChange}
      select
      error={touched && Boolean(error)}
      helperText={touched && error}
      InputProps={{
        endAdornment: isLoadingGenders ? (
          <CircularProgress />
        ) : locked ? (
          <FieldLockStatement />
        ) : null,
      }}
    >
      {genders.map((each) => (
        <MenuItem key={each} value={each}>
          {each}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default GenderSelector;
