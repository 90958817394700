import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "../constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import type { Notification as NotificationSchemaType } from "@gwat/notification-schema";

export type Channel = "sms" | "email" | "in-app" | "mobile-push" | "web-push";

type NotificationSettingsQueryResult = {
  id: number;
  cid: number;
  settings: Array<{
    intent: NotificationSchemaType["intent"];
    channels: Array<Channel>;
  }>;
};

type NotificationSettingsFetchResult = {
  data: NotificationSettingsQueryResult;
  success: boolean | string;
};

type QueryError = {};

const useNotificationSettingsQuery = (
  options?: UseQueryOptions<
    NotificationSettingsQueryResult,
    QueryError,
    NotificationSettingsQueryResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/traveller/${cid}/notification-settings`;
  const cacheKey = [url];

  return useQuery<NotificationSettingsQueryResult, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error("cid not found");

      const result = await fetchWithAuth<NotificationSettingsFetchResult>(url);
      return result.data;
    },
    {
      ...options,
      enabled: options?.enabled ?? Boolean(cid),
    }
  );
};
export default useNotificationSettingsQuery;
