import { useMutation, UseMutationOptions } from "react-query";
import { CANCELLATION_ENDPOINT } from "@/fetch/cancellation/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { usePersonalDetailsQuery } from "../profiles";
import { useCurrentTrip } from "@/hooks";
import dayjs from "dayjs";

type CancelTripVariables = {
  cancel_customer_reason: string;
  contactNumber: string;
};

type CancelTripResult = {
  success: boolean;
  result: {
    id: number;
  };
};

type QueryError = {
  response: {
    code: number;
    message: string;
    success: boolean;
  };
};

const useCancelTripMutation = (
  options?: UseMutationOptions<
    CancelTripResult,
    QueryError,
    CancelTripVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const travellerInfo = personalDetails?.traveller;
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<CancelTripResult, QueryError, CancelTripVariables>(
      async ({
        cancel_customer_reason,
        contactNumber,
      }: CancelTripVariables) => {
        const url = `${CANCELLATION_ENDPOINT}/create`;
        if (!tid) throw new Error("tid not found");
        if (!travellerInfo?.id) throw new Error("traveller info not found");

        return fetchWithAuth<CancelTripResult>(url, {
          method: "POST",
          body: {
            fname: travellerInfo?.first_name || "",
            lname: travellerInfo?.last_name || "",
            email: travellerInfo?.email || "",
            dob: dayjs((travellerInfo?.date_of_birth || 0) * 1000)
              .utc()
              .startOf("day")
              .format("DD/MM/YYYY"),
            phone: contactNumber,
            booking_country: travellerInfo?.office_name || "",
            trip: `${currentTrip.division} ${currentTrip.destination}`,
            tid,
            cancel_customer_reason,
            cancel_submit_date: dayjs().format("YYYY-MM-DD"),
          },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCancelTripMutation;
