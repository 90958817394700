import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type TransactionProcessFeeType = {
  percent: number;
  conditions: {
    authMethod: string;
    paymentMethod: string;
    operation: "execute" | "initialize";
    currency: Array<string>;
  };
};

type Result = {
  data: Array<TransactionProcessFeeType>;
  statusCode: 200;
  success: true;
};
type QueryError = {};

type Variables = {
  currency?: string;
  operation: "execute" | "initialize";
};

const useTransactionProcessFeesQuery = (
  variables: Variables,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const url = `${PAYMENT_ENDPOINT}/transaction-process-fees`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<Result>(url, {
        params: {
          ...variables,
          authMethod: "gworld_jwt",
          paymentMethod: "card",
        },
      });

      return res;
    },
    {
      ...options,
    }
  );
};
export default useTransactionProcessFeesQuery;
