import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FaceIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM17.4471 12.1055C17.9411 12.3524 18.1414 12.953 17.8945 13.4471C16.7233 15.7906 14.7076 17 12 17C9.29239 17 7.27674 15.7906 6.10549 13.4471C5.85859 12.953 6.05893 12.3524 6.55295 12.1055C7.04697 11.8586 7.64761 12.0589 7.89451 12.5529C8.72235 14.2094 10.04 15 12 15C13.96 15 15.2776 14.2094 16.1055 12.5529C16.3524 12.0589 16.953 11.8586 17.4471 12.1055ZM9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8ZM14.5 8C15.3284 8 16 8.67157 16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5C13 8.67157 13.6716 8 14.5 8Z" />
    </SvgIcon>
  );
};

export default FaceIcon;
