import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CrossIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM16.7059 7.29289C16.3454 6.93241 15.7782 6.90468 15.3859 7.2097L15.2917 7.29289L11.999 10.584L8.70829 7.29407L8.61408 7.21089C8.22179 6.90586 7.65456 6.93359 7.29407 7.29407C6.90355 7.6846 6.90355 8.31776 7.29407 8.70829L10.584 11.999L7.29289 15.2917L7.2097 15.3859C6.90468 15.7782 6.93241 16.3454 7.29289 16.7059C7.65338 17.0664 8.22061 17.0941 8.6129 16.7891L8.70711 16.7059L11.999 13.413L15.2929 16.7071L15.3871 16.7903C15.7794 17.0953 16.3466 17.0676 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.413 11.999L16.7059 8.70711L16.7891 8.6129C17.0941 8.22061 17.0664 7.65338 16.7059 7.29289Z" />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM16.7059 7.29289C17.0664 7.65338 17.0941 8.22061 16.7891 8.6129L16.7059 8.70711L13.413 11.999L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3466 17.0676 15.7794 17.0953 15.3871 16.7903L15.2929 16.7071L11.999 13.413L8.70711 16.7059L8.6129 16.7891C8.22061 17.0941 7.65338 17.0664 7.29289 16.7059C6.93241 16.3454 6.90468 15.7782 7.2097 15.3859L7.29289 15.2917L10.584 11.999L7.29407 8.70829C6.90355 8.31776 6.90355 7.6846 7.29407 7.29407C7.65456 6.93359 8.22179 6.90586 8.61408 7.21089L8.70829 7.29407L11.999 10.584L15.2917 7.29289L15.3859 7.2097C15.7782 6.90468 16.3454 6.93241 16.7059 7.29289Z" />
      )}
    </SvgIcon>
  );
};

export default CrossIcon;
