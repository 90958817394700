import styles from "./FormController.module.scss";
import Button from "@/components/Button";
import { CircularProgress } from "@/components/Loader";
import { MouseEventHandler } from "react";
import cx from "classnames";

const FormActions: React.FC<{
  secondaryButton?: {
    label?: string;
    action?: MouseEventHandler<HTMLButtonElement> | undefined;
    isDisabled?: boolean;
  };
  primaryButton?: {
    label?: string;
    loadingLabel?: string;
    action?: MouseEventHandler<HTMLButtonElement> | undefined;
    isLoading?: boolean;
    isDisabled?: boolean;
  };
  isDialogAction?: boolean;
}> = ({ primaryButton, secondaryButton, isDialogAction }) => {
  return (
    <div
      className={cx(styles.actions, { [styles.dialogAction]: isDialogAction })}
    >
      {Boolean(secondaryButton?.label?.length) && (
        <Button
          variant="outlined"
          color="info"
          onClick={secondaryButton?.action}
          disabled={primaryButton?.isLoading || secondaryButton?.isDisabled}
          className={styles.secondaryActionButton}
        >
          {secondaryButton?.label}
        </Button>
      )}
      <Button
        onClick={primaryButton?.action}
        disabled={primaryButton?.isLoading || primaryButton?.isDisabled}
        startIcon={
          primaryButton?.isLoading && <CircularProgress color="inherit" />
        }
      >
        {primaryButton?.isLoading
          ? primaryButton?.loadingLabel || "Saving..."
          : primaryButton?.label}
      </Button>
    </div>
  );
};

export default FormActions;
