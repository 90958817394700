import dynamic from "next/dynamic";

const Emoji = dynamic(() => import("./Emoji"), {
  ssr: false,
});

export default Emoji;
export {
  COLONS_REGEX,
  IMAGE_TAG_REGEX,
  default as decomposeEmoji,
} from "./decomposeEmoji";
