import { Member } from "@/fetch/social";
import Card from "@/components/Card";
import Typography from "@/components/Typography";
import styles from "./TravellerCard.module.scss";
import Avatar from "@/components/Avatar";
import Button from "@/components/Button";
import Flag from "@/components/Flag";
import TravellerNameLink from "./TravellerNameLink";
import { useTwilioConversation } from "@/components/Chat/hooks";
import { useState } from "react";

const TravellerCard: React.FC<{ member: Member }> = ({ member }) => {
  const { openChatPopup } = useTwilioConversation();
  const [isOpeningChat, setIsOpeningChat] = useState(false);

  const openChat = async (travellerCid?: number) => {
    try {
      setIsOpeningChat(true);
      await openChatPopup(travellerCid, "Traveller Card");
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpeningChat(false);
    }
  };
  return (
    <Card className={styles.memberCardRoot}>
      <div className={styles.avatarContainer}>
        <Avatar size="xl" cloudinaryPath={member.s3_profile_pic} />
        {Boolean(member.country_code) && (
          <Flag
            countryCode={member.country_code || ""}
            className={styles.flag}
            size="1.5em"
          />
        )}
      </div>

      <TravellerNameLink
        variant="body1"
        className={styles.name}
        cid={member.cid}
        nickname={member.nickname}
      />
      <Typography
        className={styles.division}
        variant="body2"
        color="text.secondary"
      >
        {member.division || ""}
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        className={styles.sayHi}
        size="small"
        onClick={() => openChat(member?.cid)}
        disabled={isOpeningChat}
      >
        {isOpeningChat ? "Initializing..." : "Say hi"}
      </Button>
    </Card>
  );
};

export default TravellerCard;
