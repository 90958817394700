import { useRouter } from "next/router";
import { useCurrentTrip, useTrackers } from "@/hooks/";

const useTravellerProfile = () => {
  const router = useRouter();
  const { currentTrip } = useCurrentTrip();
  const { track } = useTrackers();
  const goToTravellerProfile = (cid?: number) => {
    if (!cid) return;
    if (cid === currentTrip?.cid) {
      router.push({ pathname: `/social/profile` });
      return;
    }
    track("Traveller Profile Clicked", {
      eventId: "traveller-profile-clicked",
      travellerCid: cid,
    });
    router.push({ pathname: `/social/traveller/${cid}` });
  };
  const getTravellerProfilePathname = (cid?: number) => {
    if (!cid) return "";
    return `/social/traveller/${cid}`;
  };
  return { goToTravellerProfile, getTravellerProfilePathname };
};

export default useTravellerProfile;
