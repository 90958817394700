import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type NotificationStatisticsResult = { unseen: number };

type QueryError = {};

const url = `${ENDPOINT}/v2/statistics`;
export const cacheKey = [url];

const useNotificationStatisticsQuery = (
  options?: UseQueryOptions<
    NotificationStatisticsResult,
    QueryError,
    NotificationStatisticsResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<NotificationStatisticsResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<NotificationStatisticsResult>(url);
    },
    {
      ...options,
    }
  );
};
export default useNotificationStatisticsQuery;
