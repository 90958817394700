import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import TextField from "@/components/TextField";
import { FormActions } from "@/components/FormController";
import DatePicker from "@/components/DatePicker";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import {
  QuestionTextLoader,
  RadioLoader,
  TextFieldLoader as FormLoader,
} from "./PersonalDetailsFormLoader";
import Radio, { RadioGroup } from "@/components/Radio";
import { FormControlLabel } from "@/components/FormController";
import { PassportFormType, Status } from "@/components/PersonalDetailsForms";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
} from "@/components/PersonalDetailsForms/hooks";
import NationalitySelector from "@/components/NationalitySelector";
import dayjs from "dayjs";
import { Nationality } from "@/hooks/useNationalities";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  onNationalitiesLoaded: (nationalities?: Array<Nationality>) => void;
}

function PassportFormTemplateWithRef<T extends PassportFormType>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    onNationalitiesLoaded,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const onDateChange = (
    date: dayjs.Dayjs | null,
    key: "passportIssueDate" | "passportExpiryDate"
  ) => {
    formik.setValues((values) => ({
      ...values,
      [key]: date ? date.utc().startOf("day").unix() : null,
    }));
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing="8px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} md={6} lg={4}>
          {isFormDataLoading ? (
            QuestionTextLoader
          ) : (
            <Typography variant="subtitle1" className={styles.question}>
              Do you have a passport?
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {isFormDataLoading ? (
            <RadioLoader optionCount={2} />
          ) : (
            <RadioGroup
              row
              aria-labelledby="radio-passport-status"
              name="passportStatus"
              value={formik.values.passportStatus}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value={Status.Yes}
                control={<Radio />}
                label="Yes"
                disabled={isFormSubmitting}
              />
              <FormControlLabel
                value={Status.No}
                control={<Radio />}
                label="No"
                disabled={isFormSubmitting}
              />
            </RadioGroup>
          )}
        </Grid>
      </Grid>

      {(Boolean(formik.values.passportStatus === Status.Yes) ||
        isFormDataLoading) && (
        <Grid
          container
          spacing="24px"
          columnSpacing="12px"
          className={styles.gridContainer}
        >
          <Grid item xs={12} md={6}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <NationalitySelector
                disabled={isFormSubmitting}
                label="Nationality on Passport"
                value={formik.values.passportNationality}
                onChange={(event, newValue) => {
                  formik.setValues((state) => ({
                    ...state,
                    passportNationality: newValue as Nationality,
                  }));
                }}
                error={formik.errors.passportNationality as string}
                touched={formik.touched.passportNationality as boolean}
                onNationalitiesLoaded={onNationalitiesLoaded}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <TextField
                disabled={isFormSubmitting}
                fullWidth
                id="passportNumber"
                name="passportNumber"
                label="Passport Number"
                value={formik.values.passportNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.passportNumber &&
                  Boolean(formik.errors.passportNumber)
                }
                helperText={
                  formik.touched.passportNumber && formik.errors.passportNumber
                }
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <DatePicker
                disableFuture
                clearable
                maxDate={
                  formik.values.passportExpiryDate &&
                  dayjs(Number(formik.values.passportExpiryDate) * 1000)
                }
                label="Date of Issue"
                views={["year", "month", "day"]}
                disabled={isFormSubmitting}
                value={
                  formik.values.passportIssueDate
                    ? dayjs(formik.values.passportIssueDate * 1000)
                        .utc()
                        .startOf("day")
                    : null
                }
                onChange={(date) =>
                  onDateChange(date as dayjs.Dayjs, "passportIssueDate")
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={
                      formik.touched.passportIssueDate &&
                      Boolean(formik.errors.passportIssueDate)
                    }
                    helperText={
                      formik.touched.passportIssueDate &&
                      formik.errors.passportIssueDate
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <DatePicker
                clearable
                minDate={
                  formik.values.passportIssueDate
                    ? dayjs(Number(formik.values.passportIssueDate) * 1000).add(
                        1,
                        "day"
                      )
                    : dayjs()
                }
                label="Date of Expiry"
                views={["year", "month", "day"]}
                disabled={isFormSubmitting}
                value={
                  formik.values.passportExpiryDate
                    ? dayjs(formik.values.passportExpiryDate * 1000)
                        .utc()
                        .startOf("day")
                    : null
                }
                onChange={(date) =>
                  onDateChange(date as dayjs.Dayjs, "passportExpiryDate")
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={
                      formik.touched.passportExpiryDate &&
                      Boolean(formik.errors.passportExpiryDate)
                    }
                    helperText={
                      formik.touched.passportExpiryDate &&
                      formik.errors.passportExpiryDate
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <TextField
                disabled={isFormSubmitting}
                fullWidth
                id="passportIssueLocation"
                name="passportIssueLocation"
                label="Place of Issue"
                value={formik.values.passportIssueLocation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passportIssueLocation &&
                  Boolean(formik.errors.passportIssueLocation)
                }
                helperText={
                  formik.touched.passportIssueLocation &&
                  formik.errors.passportIssueLocation
                }
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const PassportForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();

  const { passportValidationSchema } = usePersonalDetailsFormValidation();

  const { submitPassportForm, isUpdatingPassport } =
    usePersonalDetailsFormSubmission();

  const { passportInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingPassport || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const onSubmit = async (values: PassportFormType) => {
    try {
      if (formik.dirty) {
        await submitPassportForm(values, correlationId);
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const onNationalitiesLoaded = (nationalities?: Array<Nationality>) => {
    if (!nationalities) return;
    formik.setValues((values) => ({
      ...values,
      passportNationality: nationalities?.find(
        (item) =>
          item.label === personalDetailsData?.passport?.passport_nationality
      ),
    }));
  };

  const initialValues = passportInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: passportValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <PassportFormTemplate
        formik={formik}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
        onNationalitiesLoaded={onNationalitiesLoaded}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};
const PassportFormTemplate = forwardRef(PassportFormTemplateWithRef);

export { PassportFormTemplate };
export default PassportForm;
