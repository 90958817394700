import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type ResourceSignedUrlResult = Partial<{
  success: boolean;
  url: string;
}>;
type QueryError = {};

const useResourceSignedUrlQuery = (
  resourceId: number,
  params?: { [key: string]: boolean },
  options?: UseQueryOptions<
    ResourceSignedUrlResult,
    QueryError,
    ResourceSignedUrlResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const url = `${GATEWAY_URL}/api/resources/trip/${tid}/resource/${resourceId}/signed-url`;
  const cacheKey = [url, JSON.stringify(params)];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<ResourceSignedUrlResult, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      return fetchWithAuth<ResourceSignedUrlResult>(url, {
        params: params ? { download: false, ...params } : { download: false },
      });
    },
    {
      ...options,
    }
  );
};
export default useResourceSignedUrlQuery;
