import { useMutation, UseMutationOptions } from "react-query";
import { useCurrentTrip } from "@/hooks";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export enum SignificantDateTypes {
  ARRIVAL_DATE = "arrival_date",
  TENTATIVE_DEPARTURE = "tentative_departure",
  TRIP_START = "trip_start",
  END_DATE = "end_date",
  FINAL_SKYPE_CALL = "final_skype_call",
  EXACT_DEPARTURE = "exact_departure",
  INTRODUCTION_CALL = "introduction_call",
  ORIENTATION = "orientation",
  TRAVEL_BEFORE = "travel_before",
  TRAVEL_PACK_DUE_DATE = "travel_pack_due_date",
  LAST_CONTACT = "last_contact",
  BOOKING_MADE = "booking_made",
  BANK_APPOINTMENT = "bank_appointment",
  TAX_APPOINTMENT = "tax_appointment",
  FINAL_PHONE_CALL = "final_phone_call",
  TRIP_BOOKING_DATE = "trip_booking_date",
  PERSONAL_DETAILS_SUBMISSION = "personal_details_submission",
  DEPOSIT_PAID = "deposit_paid",
  ORIGINAL_TENTATIVE_DEPARTURE = "original_tentative_departure",
}

type UpdateSignificantDateVariables = Partial<{
  tid: number;
  type: SignificantDateTypes;
  confirmed_at: number;
  confirmed_by: number;
  date: number;
  duration: any;
  other: any;
  locked: number;
  timezone_dest: any;
  epoch_offset_dest: number;
  timezone_src: any;
  epoch_offset_src: number;
  traveller_zone: any;
}>;

type UpdateSignificantDateResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useUpdateSignificantDateMutation = (
  options?: UseMutationOptions<
    UpdateSignificantDateResult,
    QueryError,
    UpdateSignificantDateVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();

  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      UpdateSignificantDateResult,
      QueryError,
      UpdateSignificantDateVariables
    >(
      async (data: UpdateSignificantDateVariables) => {
        const url = `${TRIP_ENDPOINT}/significant-date`;

        return fetchWithAuth<UpdateSignificantDateResult>(url, {
          method: "PUT",
          body: { tid, locked: 0, ...data },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateSignificantDateMutation;
