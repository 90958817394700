import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum SubmissionStatuses {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  CLAIMED = "CLAIMED",
  CHANGE_REQUESTED = "CHANGE_REQUESTED",
}

export type SubmissionItem = Partial<{
  id: number;
  cid: string;
  tid: number;
  rewards_id: number;
  type: string | number;
  rating: string;
  experience: string;
  media_description: string;
  tc_accepted: number;
  qoute: string;
  feature: number;
  published: number;
  video_url: string;
  status: SubmissionStatuses;
  upload_status: "FINAL" | "PENDING";
  approved_at: number;
  approved_by: number;
  _cb: number;
  _lmb: number;
  _ca: number;
  _lma: number;
  media_count: number;
  reason: string;
}>;

export type SubmissionsList = {
  result: Array<SubmissionItem>;
  success: boolean;
};

type QueryError = {};

const useSubmissionsListQuery = (
  params?: any,
  options?: UseQueryOptions<
    SubmissionsList,
    QueryError,
    SubmissionsList,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;
  const URL = `${REWARD_ENDPOINT}/submission/trip/${tid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL, JSON.stringify(params)];

  return useQuery<SubmissionsList, QueryError>(
    cacheKey,
    async () => {
      if (!tid) throw new Error();
      const res = await fetchWithAuth<SubmissionsList>(URL, { params });
      return res;
    },
    {
      ...options,
    }
  );
};
export default useSubmissionsListQuery;
