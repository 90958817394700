import { FC, useState } from "react";
import cx from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper/types/swiper-class";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useResponsive } from "@/hooks";
import styles from "./Swiper.module.scss";
import { Variant } from "@mui/material/styles/createTypography";

export const Slider = SwiperSlide;

type Props = {
  slidesPerPage: number;
  spaceBetween: number;
  title: string;
  className?: string;
  roundSlides?: boolean;
  isTopContainerHidden?: boolean;
  titleColor?: any;
  titleVariant?: Variant;
  swiperProps?: any;
  hideSwipeButton?: boolean;
};

const SwiperComponent: FC<Props> = ({
  slidesPerPage,
  title,
  spaceBetween,
  children,
  className,
  roundSlides = true,
  isTopContainerHidden = false,
  titleVariant = "h5",
  titleColor,
  swiperProps = {},
  hideSwipeButton = false,
}) => {
  const [swiperObj, setSwiperObj] = useState<SwiperType | undefined>(undefined);
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const [currentLength, setCurrentLength] = useState<number>(0);

  const { isMobile } = useResponsive();

  const onSlideChange = (swiper: SwiperType) => {
    setSwiperIndex(swiper.activeIndex);
    setSwiperObj(swiper);
  };

  const onSlidesLengthChange = (swiper: SwiperType) => {
    setCurrentLength(swiper.slides.length);
    setSwiperObj(swiper);
  };

  return (
    <div className={cx(styles.root, className)}>
      {!isTopContainerHidden && (
        <div className={styles.topContainer}>
          <Typography
            variant={titleVariant}
            color={titleColor}
            className={styles.title}
          >
            {title}
          </Typography>
          {!hideSwipeButton && (
            <div className={styles.buttonPaper}>
              <div
                role="presentation"
                className={cx(styles.button, {
                  [styles.disbaleButton]: swiperObj?.isBeginning,
                })}
                onClick={() => swiperObj?.slidePrev()}
              >
                <ChevronIcon
                  dir="left"
                  className={cx(styles.chevronIcon, {
                    [styles.disableIcon]: swiperObj?.isBeginning,
                  })}
                  fontSize="large"
                />
              </div>
              <div className={styles.divider} />
              <div
                className={cx(styles.button, {
                  [styles.disbaleButton]: swiperObj?.isEnd,
                })}
                onClick={() => swiperObj?.slideNext()}
              >
                <ChevronIcon
                  dir="right"
                  className={cx(styles.chevronIcon, {
                    [styles.disableIcon]: swiperObj?.isEnd,
                  })}
                  fontSize="large"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className={cx(styles.swiperContainer, "swiperContainer")}>
        <Swiper
          className={styles.swiperRoot}
          slidesPerView={
            roundSlides ? Math.round(slidesPerPage) : slidesPerPage
          }
          slidesPerGroup={
            isMobile
              ? 1
              : roundSlides
              ? Math.round(slidesPerPage)
              : slidesPerPage || 0
          }
          onSlideChange={onSlideChange}
          spaceBetween={spaceBetween}
          onSwiper={setSwiperObj}
          onSlidesLengthChange={onSlidesLengthChange}
          {...swiperProps}
        >
          {children}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperComponent;
