import SvgIcon, { SvgIconProps } from "./SvgIcon";

const IndustryIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <>
          <path d="M7.94542 2H2.66406C2.34047 2 2 2.37562 2 2.69922V4.95312H8.64062V2.69357C8.64062 2.36998 8.26901 2 7.94542 2Z" />
          <path d="M21.7056 7.58551C21.5235 7.48105 21.2995 7.48195 21.1183 7.58797L15.3203 10.9786V8.09375C15.3203 7.88383 15.208 7.68996 15.0259 7.58551C14.8438 7.48105 14.6198 7.48195 14.4386 7.58797L8.64062 10.9786V7H2V21.3047C2 21.6283 2.38734 22 2.71094 22H21.2139C21.5375 22 22 21.5573 22 21.2337V8.09375C22 7.88383 21.8877 7.68996 21.7056 7.58551ZM10.9844 17.8789C10.9844 18.2025 10.722 18.4648 10.3984 18.4648H8.05469C7.73109 18.4648 7.46875 18.2025 7.46875 17.8789V15.5352C7.46875 15.2116 7.73109 14.9492 8.05469 14.9492H10.3984C10.722 14.9492 10.9844 15.2116 10.9844 15.5352V17.8789ZM17.6641 17.8789C17.6641 18.2025 17.4017 18.4648 17.0781 18.4648H14.7344C14.4108 18.4648 14.1484 18.2025 14.1484 17.8789V15.5352C14.1484 15.2116 14.4108 14.9492 14.7344 14.9492H17.0781C17.4017 14.9492 17.6641 15.2116 17.6641 15.5352V17.8789Z" />
        </>
      ) : (
        <>
          <path
            d="M21.7056 7.58551C21.5235 7.48105 21.2995 7.48195 21.1183 7.58797L15.3203 10.9786V8.09375C15.3203 7.88383 15.208 7.68996 15.0259 7.58551C14.8438 7.48105 14.6198 7.48195 14.4386 7.58797L8.64062 10.9786V2.65943C8.64062 2.33584 8.25506 2 7.93146 2H2.67137C2.34778 2 2 2.3907 2 2.71429V21.3747C2 21.6983 2.35645 22 2.68004 22H21.3154C21.639 22 22 21.6556 22 21.332V8.09375C22 7.88383 21.8877 7.68996 21.7056 7.58551ZM3.17188 3.17188H7.46875V5.95312H3.17188V3.17188ZM20.8281 20.8281H3.17188V7.125H7.46875V12C7.46875 12.4522 7.9607 12.7337 8.35047 12.5058L14.1484 9.11516V12C14.1484 12.4522 14.6404 12.7337 15.0302 12.5058L20.8281 9.11516V20.8281Z"
            stroke="black"
            strokeWidth="0.7"
          />
          <path
            d="M10.3984 14.9492H8.05469C7.73109 14.9492 7.46875 15.2116 7.46875 15.5352V17.8789C7.46875 18.2025 7.73109 18.4648 8.05469 18.4648H10.3984C10.722 18.4648 10.9844 18.2025 10.9844 17.8789V15.5352C10.9844 15.2116 10.722 14.9492 10.3984 14.9492ZM9.8125 17.293H8.64062V16.1211H9.8125V17.293Z"
            stroke="black"
            strokeWidth="0.4"
          />
          <path
            d="M17.0781 14.9492H14.7344C14.4108 14.9492 14.1484 15.2116 14.1484 15.5352V17.8789C14.1484 18.2025 14.4108 18.4648 14.7344 18.4648H17.0781C17.4017 18.4648 17.6641 18.2025 17.6641 17.8789V15.5352C17.6641 15.2116 17.4017 14.9492 17.0781 14.9492ZM16.4922 17.293H15.3203V16.1211H16.4922V17.293Z"
            stroke="black"
            strokeWidth="0.4"
          />
        </>
      )}
    </SvgIcon>
  );
};

export default IndustryIcon;
