import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";
import { Post } from "./useRecentPostsQuery";

type PostParams = {
  postId?: number;
};

export type Result = {
  data: { result: Post };
  statusCode: number;
  success: boolean | string;
};

type QueryError = {};

const usePostQuery = (
  params: PostParams,
  options?: UseQueryOptions<Post, QueryError, Post, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  const url = `${GATEWAY_URL}/post/${params?.postId}`;
  const cacheKey = [url];

  return useQuery<Post, QueryError>(
    cacheKey,
    async () => {
      if (!params.postId) throw new Error("post id not found");

      const result = await fetchWithAuth<Result>(url);
      return result.data?.result;
    },
    {
      ...options,
    }
  );
};
export default usePostQuery;
