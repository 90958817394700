import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MenuIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <rect x="4" y="6" rx="1" height="2" width="16" />
      <rect x="4" rx="1" height="2" width="16" y="11" />
      <rect x="4" rx="1" height="2" width="16" y="16" />
    </SvgIcon>
  );
};

export default MenuIcon;
