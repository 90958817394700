import SvgIcon, { SvgIconProps } from "./SvgIcon";

const VideoIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M15 4C16.1046 4 17 4.89543 17 6V8.8L21.3753 5.29976C21.8066 4.95475 22.4359 5.02467 22.7809 5.45593C22.9227 5.63324 23 5.85355 23 6.08063V17.9194C23 18.4717 22.5523 18.9194 22 18.9194C21.7729 18.9194 21.5526 18.8421 21.3753 18.7002L17 15.199V18C17 19.1046 16.1046 20 15 20H3C1.89543 20 1 19.1046 1 18V6C1 4.89543 1.89543 4 3 4H15ZM15 6H3V18H15V6ZM21 8.16L17 11.361V12.639L21 15.839V8.16Z" />
    </SvgIcon>
  );
};

export default VideoIcon;
