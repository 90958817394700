import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MinusIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <rect rx="1" height="2.33333" width="16.3333" y="10.83333" x="3.83335" />
    </SvgIcon>
  );
};

export default MinusIcon;
