import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreateInterestedJobVariables = {
  job_id: number;
  order: number;
};

type CreateInterestedJobResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useCreateInterestedJobMutation = (
  options?: UseMutationOptions<
    CreateInterestedJobResult,
    QueryError,
    CreateInterestedJobVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      CreateInterestedJobResult,
      QueryError,
      CreateInterestedJobVariables
    >(
      async (inputObj: CreateInterestedJobVariables) => {
        const url = `${TRIP_ENDPOINT}/interested-jobs`;
        const inputData = {
          ...inputObj,
          tid,
        };

        return fetchWithAuth<CreateInterestedJobResult>(url, {
          method: "POST",
          body: inputData,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateInterestedJobMutation;
