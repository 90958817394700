import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import { IconButton } from "@/components/Button";
import { CloseIcon } from "@/components/Icon";
import styles from "./Dialog.module.scss";
import cx from "classnames";

const DialogTitle: React.FC<
  DialogTitleProps & {
    hasCloseButton?: boolean;
    onClose?: () => void;
    disableClose?: boolean;
  }
> = ({
  children,
  hasCloseButton = true,
  onClose,
  disableClose = false,
  className,
  ...props
}) => {
  return (
    <MuiDialogTitle
      {...props}
      className={cx(className, { [styles.titleWithClose]: hasCloseButton })}
    >
      {children}
      {hasCloseButton && (
        <IconButton
          className={styles.closeButton}
          onClick={onClose}
          disabled={disableClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
