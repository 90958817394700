import SvgIcon, { SvgIconProps } from "./SvgIcon";

const HeartIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M3.51472 4.75736C5.80461 2.46747 9.48493 2.41542 11.838 4.60123L12 4.75736C14.3431 2.41421 18.1421 2.41421 20.4853 4.75736C21.6569 5.92893 22 7.46447 22 9C22 10.4502 21.6939 11.9005 20.673 13.0441L20.4603 13.2675L13.4235 20.3978C12.6828 21.1482 11.4954 21.1898 10.7052 20.5172L10.5765 20.3977L3.51472 13.2426L3.32704 13.0441C2.30608 11.9005 2 10.4502 2 9C2 7.46447 2.34315 5.92893 3.51472 4.75736Z" />
      ) : (
        <path d="M3.51472 4.75736C5.80461 2.46747 9.48493 2.41542 11.838 4.60123L12 4.75736C14.3431 2.41421 18.1421 2.41421 20.4853 4.75736C21.6569 5.92893 22 7.46447 22 9C22 10.4502 21.6939 11.9005 20.673 13.0441L20.4603 13.2675L13.4235 20.3978C12.6828 21.1482 11.4954 21.1898 10.7052 20.5172L10.5765 20.3977L3.51472 13.2426L3.32704 13.0441C2.30608 11.9005 2 10.4502 2 9C2 7.46447 2.34315 5.92893 3.51472 4.75736ZM4.92893 6.17157C4.29189 6.80862 4 7.71642 4 9C4 10.1166 4.22215 10.9499 4.6652 11.5304L4.78057 11.6703L4.93818 11.8377L12 18.9928L19.0711 11.8284C19.7081 11.1914 20 10.2836 20 9C20 7.71642 19.7081 6.80862 19.0711 6.17157C17.5563 4.65681 15.1289 4.61091 13.5588 6.03387L13.4142 6.17157L12.0258 7.56001L10.4769 6.06657C8.90512 4.60658 6.45138 4.64913 4.92893 6.17157Z" />
      )}
    </SvgIcon>
  );
};

export default HeartIcon;
