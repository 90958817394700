import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";

const CreatePostDialogCloseConfirmationDialog: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
}> = ({ onClose, onConfirm }) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle onClose={onClose}>Delete post</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="text.secondary">
          Are you sure you want to delete this post and leave the page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="info" size="small">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" size="small">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePostDialogCloseConfirmationDialog;
