import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/social/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type ReportVariables = {
  post_id: number;
  comment_id?: number;
  details?: string;
  reason_id: number;
};

type ReportResult = {
  success: boolean;
  statusCode: number;
  data: {
    id: number;
    post_id: number;
    comment_id: number | null | undefined;
    reporter_cid: number;
    reason_id: number;
    status: string;
    details: string | null | undefined;
  };
};

type QueryError = {};

const useReportMutation = (
  options?: UseMutationOptions<ReportResult, QueryError, ReportVariables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return {
    ...useMutation<ReportResult, QueryError, ReportVariables>(
      async (report: ReportVariables) => {
        if (!cid) throw new Error("cid not found");

        const url = `${GATEWAY_URL}/report`;

        return fetchWithAuth<ReportResult>(url, {
          method: "POST",
          body: {
            ...report,
            reporter_cid: cid,
          },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useReportMutation;
