import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import { Staff, useTasksQuery } from "@/fetch/progress";
import Typography from "@/components/Typography";
import Avatar from "@/components/Avatar";
import styles from "./ExpertsDialog.module.scss";
import { Skeleton } from "@/components/Loader";
import { useTrackers } from "@/hooks";
import { phoneData } from "../HelpAndSupportDialog";

const officeNumbers = {
  Australia: {
    phone: phoneData.find((item) => item.office_di === 1)?.phones?.[0],
    tag: "AU",
  },
  Canada: {
    phone: phoneData.find((item) => item.office_di === 2)?.phones?.[0],
    tag: "CA",
  },
  "United Kingdom": {
    phone: phoneData.find((item) => item.office_di === 3)?.phones?.[0],
    tag: "UK",
  },
};

const StaffPhoneNumber: React.FC<{
  staff: Staff;
  onClick?: (phone: string) => void;
}> = ({ staff, onClick }) => {
  const primaryPhoneNumber = staff.phone?.find(
    (phone) => phone.is_primary === true
  );
  const firstPhoneNumber = staff.phone?.[0];
  const office = staff.office?.[0];

  let phone: string | null = null;
  let extension: string | null = null;
  if (primaryPhoneNumber) {
    phone = primaryPhoneNumber.phone?.toString() ?? null;
    extension = primaryPhoneNumber.extension?.toString() ?? null;
  } else if (firstPhoneNumber) {
    phone = firstPhoneNumber.phone?.toString() ?? null;
    extension = firstPhoneNumber.extension?.toString() ?? null;
  } else if (office) {
    phone = officeNumbers[office]?.phone?.name?.toString() ?? null;
    extension = `${officeNumbers[office]?.tag}`;
  }

  if (phone)
    return (
      <Typography variant="body2">
        <a
          href={`tel:${phone}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => onClick?.(phone ?? "")}
        >
          {phone}
        </a>{" "}
        {extension ? `(${extension})` : null}
      </Typography>
    );

  return null;
};

const ExpertsDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { data, isLoading } = useTasksQuery();
  const { track } = useTrackers();
  const onEmailClick = (staff: Staff) => {
    if (staff)
      track("Expert Email Clicked", {
        eventId: "expert-email-clicked",
        departmentName: staff?.department?.name,
        staffEmail: staff?.email,
        staffFirstName: staff?.first_name,
        staffLastName: staff?.last_name,
        office: staff?.office,
        title: staff?.department?.title,
        type: staff?.type,
      });
  };

  const onPhoneClick = (staff: Staff, phoneNumber: string) => {
    if (staff)
      track("Expert Phone Clicked", {
        eventId: "expert-phone-clicked",
        departmentName: staff?.department?.name,
        staffEmail: staff?.email,
        staffFirstName: staff?.first_name,
        staffLastName: staff?.last_name,
        office: staff?.office,
        title: staff?.department?.title,
        type: staff?.type,
        phone: phoneNumber,
      });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle hasCloseButton onClose={onClose}>
        Your Travel Experts
      </DialogTitle>
      <DialogContent className={styles.content}>
        {isLoading ? (
          <div className={styles.person}>
            <Skeleton
              variant="circular"
              width="80px"
              height="80px"
              className={styles.avatar}
            />
            <div className={styles.right}>
              <Typography variant="body1">
                <Skeleton width="100px" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Skeleton width="80px" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="200px" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Skeleton
                  height="100px"
                  style={{ marginTop: 8 }}
                  variant="rectangular"
                />
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {data?.staff.length === 0 && "No travel expert is assigned to you."}
            {data?.staff.map((person) => (
              <div key={person.department.title} className={styles.person}>
                <Avatar
                  src={person.photo_url}
                  size="xl"
                  className={styles.avatar}
                />
                <div className={styles.right}>
                  <Typography variant="body1">
                    {person.department.title}
                  </Typography>
                  {person.type !== "system" && (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {person.first_name} {person.last_name}
                      </Typography>
                      <Typography variant="body2">
                        <a
                          href={`mailto:${person.email}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => onEmailClick(person)}
                        >
                          {person.email}
                        </a>
                      </Typography>
                      <StaffPhoneNumber
                        onClick={(phone) => onPhoneClick(person, phone)}
                        staff={person}
                      />
                    </>
                  )}
                  {person.department.text.map((text, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      color="text.secondary"
                    >
                      {text}
                    </Typography>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ExpertsDialog;
