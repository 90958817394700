import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type DeleteSavedJobVariables = {
  savedJobId: number;
};

type DeleteSavedJobResult = {
  success: boolean;
};

type QueryError = {};

const useDeleteSavedJobMutation = (
  options?: UseMutationOptions<
    DeleteSavedJobResult,
    QueryError,
    DeleteSavedJobVariables
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  return useMutation<DeleteSavedJobResult, QueryError, DeleteSavedJobVariables>(
    async ({ savedJobId }: DeleteSavedJobVariables) => {
      if (!savedJobId) throw new Error("id not found");
      const url = `${TRIP_ENDPOINT}/saved-jobs/${savedJobId}`;
      return fetchWithAuth<DeleteSavedJobResult>(url, {
        method: "DELETE",
      });
    },
    {
      ...options,
    }
  );
};

export default useDeleteSavedJobMutation;
