import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField, { FieldLockStatement } from "@/components/TextField";
import { DropdownIcon, LockIcon } from "@/components/Icon";
import DatePicker from "@/components/DatePicker";
import { MenuItem } from "@/components/Menu";
import { FormActions } from "@/components/FormController";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import dayjs from "dayjs";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
} from "@/components/PersonalDetailsForms/hooks";
import { GeneralInfoFormType } from "@/components/PersonalDetailsForms";
import NationalitySelector from "../NationalitySelector/NationalitySelector";
import { Nationality } from "@/hooks/useNationalities";
import GenderSelector from "@/components/GenderSelector";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  onNationalitiesLoaded: (nationalities?: Array<Nationality>) => void;
  hasGender?: boolean;
  hasNationality?: boolean;
}

function GeneralInfoFormTemplateWithRef<T extends GeneralInfoFormType>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    onNationalitiesLoaded,
    hasGender = true,
    hasNationality = true,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const onDateChange = (date: dayjs.Dayjs | null) => {
    formik.setValues((values) => ({
      ...values,
      generalInfoDateOfBirth: date ? date.utc().startOf("day").unix() : null,
    }));
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} md={4}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.generalInfoFirstName?.length)
              }
              fullWidth
              id="generalInfoFirstName"
              name="generalInfoFirstName"
              label="First Name"
              value={formik.values.generalInfoFirstName}
              onChange={formik.handleChange}
              error={
                formik.touched.generalInfoFirstName &&
                Boolean(formik.errors.generalInfoFirstName)
              }
              helperText={
                formik.touched.generalInfoFirstName &&
                formik.errors.generalInfoFirstName
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues?.generalInfoFirstName?.length
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.generalInfoMiddleName?.length)
              }
              fullWidth
              id="generalInfoMiddleName"
              name="generalInfoMiddleName"
              label="Middle Name (Optional)"
              value={formik.values.generalInfoMiddleName}
              onChange={formik.handleChange}
              error={
                formik.touched.generalInfoMiddleName &&
                Boolean(formik.errors.generalInfoMiddleName)
              }
              helperText={
                formik.touched.generalInfoMiddleName &&
                formik.errors.generalInfoMiddleName
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues.generalInfoMiddleName?.length
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.generalInfoLastName?.length)
              }
              fullWidth
              id="generalInfoLastName"
              name="generalInfoLastName"
              label="Last Name"
              value={formik.values.generalInfoLastName}
              onChange={formik.handleChange}
              error={
                formik.touched.generalInfoLastName &&
                Boolean(formik.errors.generalInfoLastName)
              }
              helperText={
                formik.touched.generalInfoLastName &&
                formik.errors.generalInfoLastName
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues.generalInfoLastName?.length
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <DatePicker
              disableFuture
              clearable
              label="Date of Birth"
              views={["year", "month", "day"]}
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.generalInfoDateOfBirth)
              }
              components={{
                OpenPickerIcon: Boolean(
                  formik.initialValues?.generalInfoDateOfBirth
                )
                  ? () => <LockIcon variant="outlined" />
                  : undefined,
              }}
              value={
                formik.values.generalInfoDateOfBirth
                  ? dayjs(formik.values.generalInfoDateOfBirth * 1000)
                      .utc()
                      .startOf("day")
                  : null
              }
              onChange={(date) => onDateChange(date as dayjs.Dayjs)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={
                    formik.touched.generalInfoDateOfBirth &&
                    Boolean(formik.errors.generalInfoDateOfBirth)
                  }
                  helperText={
                    formik.touched.generalInfoDateOfBirth &&
                    formik.errors.generalInfoDateOfBirth
                  }
                />
              )}
            />
          )}
        </Grid>
        {hasNationality && (
          <Grid item xs={12} md={4}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <NationalitySelector
                disabled={
                  isFormSubmitting ||
                  Boolean(
                    formik.initialValues?.generalInfoNationality?.label?.length
                  )
                }
                value={formik.values.generalInfoNationality}
                onChange={(event, newValue) => {
                  formik.setValues((state) => ({
                    ...state,
                    generalInfoNationality: newValue as Nationality,
                  }));
                }}
                error={(formik.errors.generalInfoNationality as any)?.label}
                touched={formik.touched.generalInfoNationality as boolean}
                onNationalitiesLoaded={onNationalitiesLoaded}
                locked={Boolean(
                  formik.initialValues.generalInfoNationality?.label?.length
                )}
              />
            )}
          </Grid>
        )}
        {hasGender && (
          <Grid item xs={12} md={4}>
            {isFormDataLoading ? (
              FormLoader
            ) : (
              <GenderSelector
                disabled={
                  isFormSubmitting ||
                  Boolean(formik.initialValues?.generalInfoGender?.length)
                }
                hasInitialValue={Boolean(
                  formik.initialValues.generalInfoGender?.length
                )}
                value={formik.values.generalInfoGender}
                onChange={formik.handleChange}
                error={formik.errors.generalInfoGender as any}
                touched={formik.touched.generalInfoGender as boolean}
                locked={Boolean(formik.initialValues.generalInfoGender?.length)}
              />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const GeneralInfoForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  onSubmitting,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();

  const { generalInfoValidationSchema } = usePersonalDetailsFormValidation();

  const {
    submitGeneralInfoForm,
    isUpdatingGeneralInfo,
    submitProfileInfoForm,
    isUpdatingProfileInfo,
  } = usePersonalDetailsFormSubmission();

  const {
    generalInfoInitialValues,
    profileInfoInitialValues,
    isLoading: isInitialDataLoading,
  } = usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting =
    isUpdatingGeneralInfo || isUpdatingProfileInfo || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const onSubmit = async (values: GeneralInfoFormType) => {
    try {
      // we pre-fill the nickname regardless of form being dirty
      // below line will pre-fill the nickname
      await submitProfileInfoForm(
        {
          profileInfoNickname: profileInfoInitialValues.profileInfoNickname,
          profileInfoBio: profileInfoInitialValues.profileInfoBio,
        },
        correlationId
      );
      if (formik.dirty) {
        await submitGeneralInfoForm(values, correlationId);
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const onNationalitiesLoaded = (nationalities?: Array<Nationality>) => {
    if (!nationalities) return;
    formik.setValues((values) => ({
      ...values,
      generalInfoNationality: nationalities?.find(
        (item) => item.label === personalDetailsData?.traveller?.nationality
      ),
    }));
  };

  const initialValues = generalInfoInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: generalInfoValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({
      values: initialValues,
      submitCount: formik.submitCount, // to indicate the "saved" state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <GeneralInfoFormTemplate
        formik={formik}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
        onNationalitiesLoaded={onNationalitiesLoaded}
      />
      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const GeneralInfoFormTemplate = forwardRef(GeneralInfoFormTemplateWithRef);

export { GeneralInfoFormTemplate };
export default GeneralInfoForm;
