import { useState } from "react";
import Dialog, { DialogContent } from "@/components/Dialog";
import { CircularProgress } from "@/components/Loader";
import styles from "./ImagePreviewDialog.module.scss";
// @ts-ignore
import { Image } from "cloudinary-react";
import { CloseIcon } from "@/components/Icon";
import { IconButton } from "@/components/Button";
import Typography from "@/components/Typography";

const ImagePreviewDialog: React.FC<{
  onClose: () => void;
  cloudinaryPath?: string;
}> = ({ onClose, cloudinaryPath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      BackdropProps={{ classes: { root: styles.backdrop } }}
      PaperProps={{ className: styles.dialogPaperRoot, elevation: 0 }}
    >
      <IconButton onClick={onClose} className={styles.closeButton} size="large">
        <CloseIcon color="inherit" fontSize="large" />
      </IconButton>
      <DialogContent className={styles.content}>
        {isLoading && !hasError && (
          <div className={styles.loading}>
            <CircularProgress size="lg" />
          </div>
        )}
        {hasError ? (
          <Typography color="text.contrast">
            Could not load the image
          </Typography>
        ) : (
          <Image
            cloudName="gwatco"
            publicId={cloudinaryPath}
            alt=""
            className={styles.imageContainer}
            onLoad={() => setIsLoading(false)}
            onError={() => setHasError(true)}
          ></Image>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImagePreviewDialog;
