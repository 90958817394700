import theme from "@/theme/theme";
import MuiCircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@/components/Typography";
import React from "react";
import styles from "./Loader.module.scss";
import cx from "classnames";

type CategoricalSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";

const sizes = { xxs: 16, xs: 20, sm: 24, md: 32, lg: 40, xl: 52 };

const CircularProgress: React.FC<
  {
    size?: CategoricalSize;
    customSize?: number;
    isDark?: boolean;
    hasProgressText?: boolean;
    className?: string;
    progressText?: string;
    maskColor?: string;
  } & CircularProgressProps
> = ({
  size = "sm",
  customSize,
  thickness = 4,
  variant,
  value,
  isDark,
  hasProgressText,
  progressText,
  className,
  maskColor,
  ...props
}) => {
  return (
    <div className={cx(styles.circularProgressContainer, className)}>
      <MuiCircularProgress
        variant="determinate"
        sx={{
          color: maskColor ?? theme.palette.common.grey[200],
        }}
        size={customSize ?? sizes[size] ?? 24}
        thickness={thickness}
        value={100}
      />
      <MuiCircularProgress
        variant={variant}
        value={value}
        sx={{
          color: Boolean(!props.color && isDark)
            ? theme.palette.common.blue.darker
            : props.color,
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={customSize ?? sizes[size] ?? 24}
        {...props}
      />
      {hasProgressText && (
        <div className={styles.circularProgressPercents}>
          <Typography variant="body1" component="div">
            {Boolean(progressText?.length)
              ? progressText
              : `${Math.round(value || 0)}%`}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CircularProgress;
