import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { ENDPOINT } from "./constants";

type Variable = {
  code: string;
  referrer_cid: number;
};

type CreateCodeResult = Partial<{
  data: Partial<{
    amount: Partial<{
      AUD: number;
      USD: number;
      CAD: number;
      NZD: number;
      GBP: number;
      EUR: number;
    }>;
    banner_styles: any;
    code: string;
    code_id: number;
    comment: any;
    conditions: Partial<{
      apply_on: string;
      expire_at: number;
      multiple_use: boolean;
    }>;
    ec_banner_text: any;
    is_system_generated: boolean;
    item_name: any;
    referrer_cid: number;
    show_banner_countdown: boolean;
    status: boolean;
    type: string;
    typeId: number;
    _ca: number;
  }>;
  error: any;
  message: string;
  success: boolean;
}>;

type QueryError = {};

const useReferralCodeMutation = (
  options?: UseMutationOptions<CreateCodeResult, QueryError, Variable>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<CreateCodeResult, QueryError, Variable>(
      async (inputObj: Variable) => {
        const url = `${ENDPOINT}/template/REFERRAL`;
        if (!inputObj.referrer_cid || !inputObj.code) throw new Error();
        return fetchWithAuth<CreateCodeResult>(url, {
          method: "POST",
          body: inputObj,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useReferralCodeMutation;
