import { useState, ReactNode } from "react";
import {
  AvatarIcon,
  DropdownIcon,
  EditIcon,
  MenuIcon,
  GlobalDollarColoredIcon,
} from "@/components/Icon";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItem } from "@/components/List";
import styles from "./Header.module.scss";
import { IconButton } from "@/components/Button";
import Divider from "@/components/Divider";
import { Skeleton, CircularProgress } from "@/components/Loader";
import Avatar from "@/components/Avatar";
import MuiLink from "@mui/material/Link";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useBrowserSettings,
  useDialog,
  useFcmToken,
  useLock,
  useOpenExternalURL,
  useResponsive,
  useTrackers,
  useCurrentTrip,
} from "@/hooks";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useGlobalDollarBalanceQuery } from "@/fetch/rewards";
import { useRouter } from "next/router";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import Link from "next/link";
import compact from "lodash/compact";
import cx from "classnames";
import { useTasksQuery } from "@/fetch/progress";
import { useRemoveFirebaseConnectionMutation } from "@/fetch/notifications";
import Typography from "@/components/Typography";

type Page = {
  name?: string;
  url?: string;
  onClick?: () => void;
  disabled?: boolean;
  renderItem?: () => ReactNode;
};

const ProfileMenu: React.FC<{ className?: string }> = ({ className }) => {
  const { isAllowedToNavigate, isSuperliteTrip } = useLock();
  const { isGapp } = useBrowserSettings();
  const { isLoading: isLoadingAuth, logout: auth0Logout } = useAuth0();
  const openExternalURL = useOpenExternalURL();
  const { data: tasksData, isLoading: isTasksLoading } = useTasksQuery();
  const { data: personalDetails, isLoading: isPersonalDetailsLoading } =
    usePersonalDetailsQuery();
  const {
    mutateAsync: removeFirebaseConnections,
    isLoading: isRemoveFirebaseConnectionLoading,
  } = useRemoveFirebaseConnectionMutation();
  const {
    data: globalDollarBalanceData,
    isLoading: isLoadingGlobalDollarBalance,
  } = useGlobalDollarBalanceQuery();

  const balance = globalDollarBalanceData?.result.balance || "0";
  const name = personalDetails?.traveller?.first_name || "";
  const profileShortcutLabel = Boolean(name?.length)
    ? `${name}'s profile`
    : "Your profile";
  const hasAssignedExpert = Boolean(tasksData?.staff?.length);
  const { fcmToken } = useFcmToken();

  const { isMobile } = useResponsive();
  const { track } = useTrackers();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { push } = useRouter();
  const { openTopLevelDialog } = useDialog();

  const { currentTrip } = useCurrentTrip();

  const websiteUrl = "https://globalworkandtravel.com";

  const handleClose = (page?: Page) => {
    setAnchorEl(null);

    if (page?.onClick) {
      page.onClick();
    }

    if (page?.url) {
      push(page.url);
    }
  };

  const pages: Array<Page> = compact([
    hasAssignedExpert && !isSuperliteTrip
      ? {
          name: "Your Travel Experts",
          onClick: () => openTopLevelDialog(TopLevelDialogNames.Experts),
          disabled: !isAllowedToNavigate,
        }
      : null,
    {
      name: "Reward Centre",
      renderItem: () => (
        <div className={styles.rewardMenuItem}>
          Reward Centre
          <div className={styles.globalDollarContainer}>
            <GlobalDollarColoredIcon className={styles.globalDollarIcon} />
            <Typography variant="body2" className={styles.balanceText}>
              {balance}
            </Typography>
          </div>
        </div>
      ),
      url: "/rewards?tab=reward-center",
      disabled: !isAllowedToNavigate,
      onClick: () =>
        track("Reward Center Opened", { eventId: "reward-center-opened" }),
    },
    !isSuperliteTrip
      ? {
          name: "Help & Support",
          onClick: () => {
            openTopLevelDialog(TopLevelDialogNames.Help);
            track("Help Opened", {
              eventId: "help-opened",
              trigger: "Profile menu",
            });
          },
        }
      : null,
    {
      name: "Account",
      url: "/account/general",
      disabled: !isAllowedToNavigate,
    },
    !isSuperliteTrip
      ? {
          name: "gWorld Welcome",
          onClick: () => openTopLevelDialog(TopLevelDialogNames.OnboardingTour),
        }
      : null,
    isMobile
      ? {
          name: "Book a new trip",
          onClick: () => {
            track("External Link Opened", {
              eventId: "external-link-opened",
              link: "website",
            });
            openExternalURL(websiteUrl);
          },
        }
      : null,
  ]);

  const logout = async () => {
    try {
      if (isGapp && fcmToken?.deviceId) {
        await removeFirebaseConnections({ deviceId: fcmToken?.deviceId });
      }
    } catch (error) {
      console.error("FCM: Error with deleting deviceId", error);
    } finally {
      track("Sing Out", { eventId: "sign-out" });
      auth0Logout({
        logoutParams: {
          returnTo: isGapp ? websiteUrl : global.window?.location.origin,
        },
      });
    }
  };

  return (
    <>
      <IconButton
        onClick={(event: any) => handleClick(event)}
        className={cx(styles.iconButton, className)}
      >
        {isMobile ? (
          <MenuIcon />
        ) : (
          <>
            {personalDetails?.traveller?.s3_profile_pic ? (
              <Avatar
                cloudinaryPath={personalDetails?.traveller?.s3_profile_pic}
                size="xs"
                className={styles.avatar}
              />
            ) : (
              <AvatarIcon variant="outlined" />
            )}
            <DropdownIcon />
          </>
        )}
      </IconButton>
      <Menu
        elevation={3}
        id="profile-select-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          className: styles.profileMenuList,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {!isLoadingAuth && !isPersonalDetailsLoading ? (
          <div className={styles.avatarContainer}>
            <IconButton
              color="info"
              size="small"
              disabled={!isAllowedToNavigate}
              className={styles.cropButton}
              onClick={() =>
                openTopLevelDialog(TopLevelDialogNames.EditProfile)
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <Avatar
              cloudinaryPath={personalDetails?.traveller?.s3_profile_pic}
              size="xl"
              className={styles.avatar}
            />
          </div>
        ) : (
          <Skeleton
            width={80}
            height={80}
            variant="circular"
            className={styles.avatar}
          />
        )}
        <ListItem
          className={styles.profileName}
          disabled={!isAllowedToNavigate}
        >
          {!isLoadingAuth && !isPersonalDetailsLoading ? (
            !isAllowedToNavigate ? (
              profileShortcutLabel
            ) : (
              <MuiLink
                variant="button"
                underline="hover"
                color="inherit"
                onClick={() => handleClose({ url: "/account/general" })}
              >
                <Link href="/account/general">{profileShortcutLabel}</Link>
              </MuiLink>
            )
          ) : (
            <Skeleton width={132} />
          )}
        </ListItem>
        <Divider />
        {pages.map((page) => (
          <MenuItem
            key={page.name}
            onClick={() => handleClose(page)}
            disabled={page.disabled}
          >
            {page.renderItem ? page.renderItem() : page.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={logout} disabled={isRemoveFirebaseConnectionLoading}>
          {isRemoveFirebaseConnectionLoading ? (
            <>
              <CircularProgress size="xxs" className={styles.logOutLoader} />
              Logging out...
            </>
          ) : (
            "Log out"
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
