import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { GATEWAY_URL } from "./constants";
import useGroupQuery from "./useGroupQuery";
import { useCurrentTrip } from "@/hooks";

export type DeleteCommentResult = any;
export type DeleteCommentVariables = { commentId: number; postId: number };

type QueryError = {};

const useDeleteCommentMutation = (
  options?: UseMutationOptions<
    DeleteCommentResult,
    QueryError,
    DeleteCommentVariables
  >
) => {
  const queryClient = useQueryClient();
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: group } = useGroupQuery();
  const groupId = group?.id;
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;

  return useMutation<DeleteCommentResult, QueryError, DeleteCommentVariables>(
    async ({ postId, commentId }) => {
      if (!postId)
        throw new Error("Could not delete comment, Post id not found");
      if (!commentId)
        throw new Error("Could not delete comment, Comment id not found");
      const url = `${GATEWAY_URL}/comment/${commentId}`;
      return fetchWithAuth<DeleteCommentResult>(url, {
        method: "DELETE",
      });
    },
    {
      onSuccess: (data, { postId }) => {
        const commentsCacheKey = [`${GATEWAY_URL}/comment/${postId}`];
        const recentPostsCacheKey = [
          `${GATEWAY_URL}/post/${groupId}/${cid}`,
          JSON.stringify({ pinned: false }),
        ];
        const pinnedPostsCacheKey = [
          `${GATEWAY_URL}/post/${groupId}/${cid}`,
          JSON.stringify({ pinned: true, pageSize: 1, pageNum: 1 }),
        ];
        const postCacheKey = [`${GATEWAY_URL}/post/${postId}`];
        const travellerPostsCacheKey = [
          `${GATEWAY_URL}/traveller/${cid}/posts/group/${groupId}/`,
        ];

        queryClient.invalidateQueries(commentsCacheKey);
        queryClient.invalidateQueries(recentPostsCacheKey);
        queryClient.invalidateQueries(pinnedPostsCacheKey);
        queryClient.invalidateQueries(postCacheKey);
        queryClient.invalidateQueries(travellerPostsCacheKey);
      },
      ...options,
    }
  );
};
export default useDeleteCommentMutation;
