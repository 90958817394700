import SvgIcon, { SvgIconProps } from "./SvgIcon";

const BellIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="m20.76473,19.98919l-4.33614,-4.33614c1.18807,-1.30674 1.84144,-3.08876 1.84144,-4.98953c0,-4.21735 -3.38575,-7.6031 -7.60313,-7.6031c-4.21735,0 -7.6031,3.38575 -7.6031,7.6031c0,4.21737 3.38575,7.60313 7.6031,7.60313c1.90077,0 3.62334,-0.71282 4.98953,-1.84144l4.33614,4.33614c0.11878,0.11878 0.23757,0.17823 0.35635,0.17823c0.1189,0 0.29702,-0.05945 0.35647,-0.17823c0.29702,-0.23757 0.29702,-0.59392 0.05934,-0.77216zm-16.57234,-9.32567c0,-3.62336 2.91056,-6.53392 6.53392,-6.53392c3.62338,0 6.53388,2.91056 6.53388,6.53392c0,3.62334 -2.9105,6.53395 -6.53388,6.53395c-3.62336,0 -6.53392,-2.91062 -6.53392,-6.53395z" />
    </SvgIcon>
  );
};

export default BellIcon;
