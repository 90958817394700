import { useMutation, UseMutationOptions } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type UpdateInterestedJobItem = {
  id: number;
  job_id: number;
  order: number;
};

export type UpdateInterestedJobsBulkVariables = Array<UpdateInterestedJobItem>;

type UpdateInterestedJobsBulkResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useUpdateInterestedJobsBulkMutation = (
  options?: UseMutationOptions<
    UpdateInterestedJobsBulkResult,
    QueryError,
    UpdateInterestedJobsBulkVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();

  return {
    ...useMutation<
      UpdateInterestedJobsBulkResult,
      QueryError,
      UpdateInterestedJobsBulkVariables
    >(
      async (input: UpdateInterestedJobsBulkVariables) => {
        const url = `${TRIP_ENDPOINT}/interested-jobs/bulk`;

        return fetchWithAuth<UpdateInterestedJobsBulkResult>(url, {
          method: "PATCH",
          body: input,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateInterestedJobsBulkMutation;
