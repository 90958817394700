import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";
import { ContactMethod } from "./useCreateContactMethodMutation";

type CreateEmergencyContactMethodVariables = {
  obj: {
    data: ContactMethod;
    emergencyContactId: number;
  };
  correlationId: string;
};

type CreateEmergencyContactMethodResult = {
  success: boolean;
  result: { affectedRows: number; insertId: number };
};

type QueryError = {};

const useCreateEmergencyContactMethodMutation = (
  options?: UseMutationOptions<
    CreateEmergencyContactMethodResult,
    QueryError,
    CreateEmergencyContactMethodVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      CreateEmergencyContactMethodResult,
      QueryError,
      CreateEmergencyContactMethodVariables
    >(
      async (input: CreateEmergencyContactMethodVariables) => {
        const { obj, correlationId } = input;
        const { data, emergencyContactId } = obj;
        const url = `${TRAVELLER_ENDPOINT}/contact-method/emergency-contact/${emergencyContactId}`;
        delete data.id;
        return fetchWithAuth<CreateEmergencyContactMethodResult>(url, {
          method: "POST",
          body: { ...data, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateEmergencyContactMethodMutation;
