import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type GlobalDollarBalanceResult = {
  result: { balance: number };
  success: boolean;
};

type QueryError = {};

const useGlobalDollarBalanceQuery = (
  options?: UseQueryOptions<
    GlobalDollarBalanceResult,
    QueryError,
    GlobalDollarBalanceResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const URL = `${REWARD_ENDPOINT}/global-dollar/transaction/traveller/${cid}/balance`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<GlobalDollarBalanceResult, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error();
      const res = await fetchWithAuth<GlobalDollarBalanceResult>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useGlobalDollarBalanceQuery;
