import { useMutation, UseMutationOptions } from "react-query";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { TRAVELLER_ENDPOINT } from "./constants";

export type Traveller = Partial<{
  first_name: string | null;
  last_name: string | null;
  id: number | null;
  age: number | null;
  date_of_birth: number | null;
  email: string | null;
  gender: string | null;
  height: string | null;
  weight: string | null;
  last_location: string | null;
  last_user_ip: number | null;
  middle_name: string | null;
  nationality: string | null;
  preferred_name: string | null;
  office_id: number | null;
  office_name: string | null;
  on_board: number | null;
  referral_code: string | null;
  s3_profile_pic: string | null;
  status: number | null;
  updated_profile: number | null;
  valid_passport: number | null;
  bio: string | null;
  nickname: string | null;
  _ca: number;
  _cb: number;
  _lma: null;
  _lmb: null;
}>;
type CreateTravellerInfoVariables = {
  data: Partial<{
    first_name: string | null;
    last_name: string | null;
    age: number | null;
    date_of_birth: number | null;
    email: string | null;
    gender: string | null;
    height: string | null;
    weight: string | null;
    last_location: string | null;
    last_user_ip: number | null;
    middle_name: string | null;
    nationality: string | null;
    preferred_name: string | null;
    office_id: number | null;
    office_name: string | null;
    on_board: number | null;
    referral_code: string | null;
    s3_profile_pic: string | null;
    status: number | null;
    updated_profile: number | null;
    valid_passport: number | null;
    bio: string | null;
    nickname: string | null;
  }>;
  correlationId: string;
};

type CreateTravellerInfoResult = {
  success: boolean;
  result: Traveller;
};

type QueryError = {};

const useCreateTravellerInfoMutation = (
  options?: UseMutationOptions<
    CreateTravellerInfoResult,
    QueryError,
    CreateTravellerInfoVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const tid = currentTrip?.id;

  return {
    ...useMutation<
      CreateTravellerInfoResult,
      QueryError,
      CreateTravellerInfoVariables
    >(
      async (input: CreateTravellerInfoVariables) => {
        const { data, correlationId } = input;
        const url = `${TRAVELLER_ENDPOINT}/traveller`;

        return fetchWithAuth<CreateTravellerInfoResult>(url, {
          method: "POST",
          body: { ...data, cid: cid, contexts: { tid: tid } },
          customHeaders: { [`X-CORRELATION-ID`]: correlationId },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateTravellerInfoMutation;
