import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from "formik";
import Grid from "@/components/Grid";
import TextField, { FieldLockStatement } from "@/components/TextField";
import LocationSelector, { Address } from "@/components/LocationSelector";
import { FormActions } from "@/components/FormController";
import CountryCodeSelector from "@/components/CountryCodeSelector";
import styles from "./PersonalDetailsForm.module.scss";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { TextFieldLoader as FormLoader } from "./PersonalDetailsFormLoader";
import { ContactDetailsFormType } from "@/components/PersonalDetailsForms";
import {
  usePersonalDetailsFormInitialValues,
  usePersonalDetailsFormSubmission,
  usePersonalDetailsFormValidation,
  useFormatContactMethod,
} from "@/components/PersonalDetailsForms/hooks";
import { CountryCode, useCountryCodes } from "@/hooks";
import cx from "classnames";

interface FormTemplateProp<FormType> {
  isFormSubmitting: boolean;
  formik: FormikState<FormType> &
    FormikComputedProps<FormType> &
    FormikHelpers<FormType> &
    FormikHandlers;
  isFormDataLoading: boolean;
  onCountriesLoaded: (countries?: Array<CountryCode>) => void;
  isSetupForm?: boolean;
  hasAddress?: boolean;
  hasSecondaryContact?: boolean;
}

function ContactDetailsFormTemplateWithRef<T extends ContactDetailsFormType>(
  {
    isFormSubmitting,
    formik,
    isFormDataLoading,
    onCountriesLoaded,
    isSetupForm,
    hasAddress = true,
    hasSecondaryContact = true,
  }: FormTemplateProp<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { data: countries } = useCountryCodes();
  const [cantFindAddress, setCantFindAddress] = useState(false);
  const onAddressSelect = (address: Address) => {
    formik.setValues((values) => ({
      ...values,
      contactDetailsStreet: address?.street ?? "",
      contactDetailsPostCode: address?.postCode ?? "",
      contactDetailsState: address?.state ?? "",
      contactDetailsSuburb: address?.suburb ?? "",
      contactDetailsCountry: address?.country?.name
        ? countries?.find(
            (item) =>
              item.code?.toLowerCase() === address?.country?.code?.toLowerCase()
          )
        : {},
    }));
  };

  const addressFields = [
    formik.values.contactDetailsPostCode,
    formik.values.contactDetailsStreet,
    formik.values.contactDetailsSuburb,
    formik.values.contactDetailsState,
  ];

  // Full Address field become visible when all address fields are empty (initially)
  const isFullAddressFieldVisible =
    !cantFindAddress && addressFields.every((item) => !item?.length);

  const onCantFindAddressClicked = () => {
    setCantFindAddress(true);
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing="24px"
        columnSpacing="12px"
        className={styles.gridContainer}
      >
        <Grid item xs={12} lg={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={
                isFormSubmitting ||
                Boolean(formik.initialValues?.contactDetailsEmail?.length)
              }
              fullWidth
              id="contactDetailsEmail"
              name="contactDetailsEmail"
              label="Email"
              value={formik.values.contactDetailsEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.contactDetailsEmail &&
                Boolean(formik.errors.contactDetailsEmail)
              }
              helperText={
                formik.touched.contactDetailsEmail &&
                formik.errors.contactDetailsEmail
              }
              InputProps={{
                endAdornment: Boolean(
                  formik.initialValues?.contactDetailsEmail?.length
                ) ? (
                  <FieldLockStatement />
                ) : null,
              }}
            />
          )}
        </Grid>
        <Grid item lg={3} xs={5}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <CountryCodeSelector
              label="Code"
              disabled={isFormSubmitting}
              value={formik.values.contactDetailsPrimaryCountryCode}
              onChange={(event, newValue) => {
                formik.setValues((state) => ({
                  ...state,
                  contactDetailsPrimaryCountryCode: newValue as CountryCode,
                }));
              }}
              error={
                (formik.errors.contactDetailsPrimaryCountryCode as any)?.label
              }
              touched={
                formik.touched.contactDetailsPrimaryCountryCode as boolean
              }
              onCountriesLoaded={onCountriesLoaded}
            />
          )}
        </Grid>

        <Grid item lg={4} xs={7}>
          {isFormDataLoading ? (
            FormLoader
          ) : (
            <TextField
              disabled={isFormSubmitting}
              fullWidth
              id="contactDetailsPrimaryPhone"
              name="contactDetailsPrimaryPhone"
              label="Phone Number"
              type="phoneNumber"
              value={formik.values.contactDetailsPrimaryPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.contactDetailsPrimaryPhone &&
                Boolean(formik.errors.contactDetailsPrimaryPhone)
              }
              helperText={
                formik.touched.contactDetailsPrimaryPhone &&
                formik.errors.contactDetailsPrimaryPhone
              }
            />
          )}
        </Grid>

        {hasSecondaryContact && (
          <>
            <Grid item lg={3} xs={5}>
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <CountryCodeSelector
                  label="Code"
                  placeholder="Optional"
                  disabled={isFormSubmitting}
                  disableClearable={false}
                  value={formik.values.contactDetailsSecondaryCountryCode}
                  onChange={(event, newValue) => {
                    formik.setValues((state) => ({
                      ...state,
                      contactDetailsSecondaryCountryCode:
                        newValue as CountryCode,
                    }));
                  }}
                  error={
                    (formik.errors.contactDetailsSecondaryCountryCode as any)
                      ?.label
                  }
                  touched={
                    formik.touched.contactDetailsSecondaryCountryCode as boolean
                  }
                  onCountriesLoaded={onCountriesLoaded}
                />
              )}
            </Grid>
            <Grid item lg={3} xs={7}>
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={isFormSubmitting}
                  fullWidth
                  id="contactDetailsSecondaryPhone"
                  name="contactDetailsSecondaryPhone"
                  label="2nd Contact Number"
                  placeholder="Optional"
                  type="phoneNumber"
                  value={formik.values.contactDetailsSecondaryPhone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsSecondaryPhone &&
                    Boolean(formik.errors.contactDetailsSecondaryPhone)
                  }
                  helperText={
                    formik.touched.contactDetailsSecondaryPhone &&
                    formik.errors.contactDetailsSecondaryPhone
                  }
                />
              )}
            </Grid>
          </>
        )}

        {!isSetupForm &&
          Boolean(
            formik.initialValues.contactDetailsTravelCountryCode?.dial_code
              ?.length
          ) && (
            <Grid item lg={3} xs={5}>
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <CountryCodeSelector
                  placeholder="Optional"
                  label="Code"
                  disabled={true}
                  disableClearable={false}
                  value={formik.values.contactDetailsTravelCountryCode}
                  onChange={(event, newValue) => {
                    formik.setValues((state) => ({
                      ...state,
                      contactDetailsTravelCountryCode: newValue as CountryCode,
                    }));
                  }}
                  error={
                    (formik.errors.contactDetailsTravelCountryCode as any)
                      ?.label
                  }
                  touched={
                    formik.touched.contactDetailsTravelCountryCode as boolean
                  }
                  onCountriesLoaded={onCountriesLoaded}
                />
              )}
            </Grid>
          )}
        {!isSetupForm &&
          Boolean(formik.initialValues.contactDetailsTravelPhone?.length) && (
            <Grid item lg={3} xs={7}>
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={true}
                  fullWidth
                  id="contactDetailsTravelPhone"
                  name="contactDetailsTravelPhone"
                  label="Travel Contact Number"
                  placeholder="Optional"
                  type="phoneNumber"
                  value={formik.values.contactDetailsTravelPhone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsTravelPhone &&
                    Boolean(formik.errors.contactDetailsTravelPhone)
                  }
                  helperText={
                    formik.touched.contactDetailsTravelPhone &&
                    formik.errors.contactDetailsTravelPhone
                  }
                />
              )}
            </Grid>
          )}

        {hasAddress && (
          <>
            <Grid
              item
              xs={12}
              lg={8}
              className={cx({
                [styles.hiddenField]: isFullAddressFieldVisible,
              })}
            >
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={isFormSubmitting}
                  fullWidth
                  id="contactDetailsStreet"
                  name="contactDetailsStreet"
                  label="Address"
                  value={formik.values.contactDetailsStreet}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsStreet &&
                    Boolean(formik.errors.contactDetailsStreet)
                  }
                  helperText={
                    formik.touched.contactDetailsStreet &&
                    formik.errors.contactDetailsStreet
                  }
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              md={7}
              className={cx({
                [styles.hiddenField]: isFullAddressFieldVisible,
              })}
            >
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={isFormSubmitting}
                  fullWidth
                  id="contactDetailsSuburb"
                  name="contactDetailsSuburb"
                  label="Suburb"
                  value={formik.values.contactDetailsSuburb}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsSuburb &&
                    Boolean(formik.errors.contactDetailsSuburb)
                  }
                  helperText={
                    formik.touched.contactDetailsSuburb &&
                    formik.errors.contactDetailsSuburb
                  }
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              lg={4}
              md={5}
              className={cx({
                [styles.hiddenField]: isFullAddressFieldVisible,
              })}
            >
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={isFormSubmitting}
                  fullWidth
                  id="contactDetailsState"
                  name="contactDetailsState"
                  label="State/Province/Region"
                  value={formik.values.contactDetailsState}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsState &&
                    Boolean(formik.errors.contactDetailsState)
                  }
                  helperText={
                    formik.touched.contactDetailsState &&
                    formik.errors.contactDetailsState
                  }
                />
              )}
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              md={7}
              className={cx({
                [styles.hiddenField]: isFullAddressFieldVisible,
              })}
            >
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <CountryCodeSelector
                  label="Country"
                  disabled={isFormSubmitting}
                  value={formik.values.contactDetailsCountry}
                  onChange={(event, newValue) => {
                    formik.setValues((state) => ({
                      ...state,
                      contactDetailsCountry: newValue as CountryCode,
                    }));
                  }}
                  variant="country"
                  error={(formik.errors.contactDetailsCountry as any)?.label}
                  touched={formik.touched.contactDetailsCountry as boolean}
                  onCountriesLoaded={onCountriesLoaded}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              md={5}
              className={cx({
                [styles.hiddenField]: isFullAddressFieldVisible,
              })}
            >
              {isFormDataLoading ? (
                FormLoader
              ) : (
                <TextField
                  disabled={isFormSubmitting}
                  fullWidth
                  id="contactDetailsPostCode"
                  name="contactDetailsPostCode"
                  label="Postcode"
                  value={formik.values.contactDetailsPostCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactDetailsPostCode &&
                    Boolean(formik.errors.contactDetailsPostCode)
                  }
                  helperText={
                    formik.touched.contactDetailsPostCode &&
                    formik.errors.contactDetailsPostCode
                  }
                />
              )}
            </Grid>
            {isFullAddressFieldVisible && (
              <Grid item xs={12}>
                {isFormDataLoading ? (
                  FormLoader
                ) : (
                  <LocationSelector
                    onAddressSelect={onAddressSelect}
                    disabled={isFormSubmitting}
                    error={formik.errors.contactDetailsPostCode as string}
                    touched={formik.touched.contactDetailsPostCode as boolean}
                    onCantFindAddressClicked={onCantFindAddressClicked}
                  />
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

const ContactDetailsForm: React.FC<{
  onSubmitted?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement> | undefined;
  correlationId?: string;
  onSubmitting?: (isFormSubmitting: boolean) => void;
  isSetupForm?: boolean;
}> = ({
  onSubmitted,
  primaryButtonLabel = "Save",
  secondaryButtonLabel,
  secondaryButtonAction,
  correlationId = "",
  onSubmitting,
  isSetupForm,
}) => {
  const [isRefetchingForm, setRefetchingForm] = useState(false);
  const { data: personalDetailsData, refetch: personalDetailsRefetch } =
    usePersonalDetailsQuery();
  const { formatContactMethodToGworld } = useFormatContactMethod();
  const rawContactMethods = personalDetailsData?.contactMethods || [];
  const contactMethods = formatContactMethodToGworld(rawContactMethods);
  const addresses = personalDetailsData?.addresses;

  const { contactDetailsValidationSchema } = usePersonalDetailsFormValidation();

  const { submitContactDetailsForm, isUpdatingContactDetails } =
    usePersonalDetailsFormSubmission();

  const { contactDetailsInitialValues, isLoading: isInitialDataLoading } =
    usePersonalDetailsFormInitialValues();

  const isFormDataLoading = isInitialDataLoading;
  const isFormSubmitting = isUpdatingContactDetails || isRefetchingForm;

  useEffect(() => {
    onSubmitting?.(isFormSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  const onSubmit = async (values: ContactDetailsFormType) => {
    try {
      if (formik.dirty) {
        await submitContactDetailsForm(
          values,
          formik.touched.contactDetailsEmail,
          correlationId
        );
      }

      setRefetchingForm(true);
      await Promise.all([personalDetailsRefetch()]);
      onSubmitted?.();
    } catch (error) {
      console.error(error);
    } finally {
      setRefetchingForm(false);
    }
  };

  const onCountriesLoaded = (countries?: Array<CountryCode>) => {
    if (!countries) return;
    formik.setValues((values) => ({
      ...values,
      contactDetailsPrimaryCountryCode: countries?.find(
        (item) => item.dial_code === contactMethods?.primary?.region_code
      ),
      contactDetailsSecondaryCountryCode: countries?.find(
        (item) => item.dial_code === contactMethods?.secondary?.region_code
      ),
      contactDetailsTravelCountryCode: countries?.find(
        (item) => item.dial_code === contactMethods?.travel?.region_code
      ),
      contactDetailsCountry: countries?.find(
        (item) => item.label === addresses?.country
      ),
    }));
  };

  const initialValues = contactDetailsInitialValues;

  const formik = useFormik({
    initialValues,
    validationSchema: contactDetailsValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!personalDetailsData) return;
    formik.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsData]);

  return (
    <>
      <ContactDetailsFormTemplate
        formik={formik}
        onCountriesLoaded={onCountriesLoaded}
        isFormDataLoading={isFormDataLoading}
        isFormSubmitting={isFormSubmitting}
        isSetupForm={isSetupForm}
      />

      <FormActions
        primaryButton={{
          label: primaryButtonLabel,
          action: () => formik.handleSubmit(),
          isLoading: isFormSubmitting,
          isDisabled: Boolean(onSubmitted) ? isFormSubmitting : !formik.dirty,
        }}
        secondaryButton={{
          label: secondaryButtonLabel,
          action: secondaryButtonAction,
        }}
      />
    </>
  );
};

const ContactDetailsFormTemplate = forwardRef(
  ContactDetailsFormTemplateWithRef
);

export { ContactDetailsFormTemplate };
export default ContactDetailsForm;
