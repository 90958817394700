import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { TRIP_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CreateSavedJobVariables = {
  job_id: number;
};

type CreateSavedJobResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useCreateSavedJobMutation = (
  options?: UseMutationOptions<
    CreateSavedJobResult,
    QueryError,
    CreateSavedJobVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const tid = currentTrip?.id;

  return {
    ...useMutation<CreateSavedJobResult, QueryError, CreateSavedJobVariables>(
      async (inputObj: CreateSavedJobVariables) => {
        const url = `${TRIP_ENDPOINT}/saved-jobs`;
        const inputData = {
          ...inputObj,
          tid,
        };

        return fetchWithAuth<CreateSavedJobResult>(url, {
          method: "POST",
          body: inputData,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCreateSavedJobMutation;
