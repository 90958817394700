import SvgIcon, { SvgIconProps } from "./SvgIcon";

const MailIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M20 4C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20ZM20 6.034L12 10.8338L4 6.034V8.365L10.971 12.5488L11.1198 12.6297C11.6747 12.9018 12.3253 12.9018 12.8802 12.6297L13.029 12.5488L20 8.365V6.034Z" />
      ) : (
        <path d="M20 4C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20ZM4 18H20V8.36551L13.029 12.5488C12.4443 12.8996 11.7252 12.9266 11.1198 12.6297L10.971 12.5488L4 8.36551V18ZM4 6.03351L12 10.8338L20 6.03451V6H4V6.03351Z" />
      )}
    </SvgIcon>
  );
};

export default MailIcon;
