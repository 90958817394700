import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import cx from "classnames";
import styles from "./TextField.module.scss";

export type TextFieldProps = MuiTextFieldProps & {
  type?:
    | React.InputHTMLAttributes<unknown>["type"]
    | "phoneNumber"
    | "floatNumber";
};

const TextField: React.FC<TextFieldProps> = ({ className, type, ...props }) => {
  return (
    <MuiTextField
      {...props}
      type={
        type && ["phoneNumber", "floatNumber"].includes(type) ? "number" : type
      }
      onKeyDown={(e) => {
        if (type === "phoneNumber" && ["e", "-", "+", "."].includes(e.key)) {
          e.preventDefault();
          return;
        }

        if (type === "floatNumber" && ["e", "-", "+"].includes(e.key)) {
          e.preventDefault();
          return;
        }

        props.onKeyDown?.(e);
      }}
      className={cx(className, {
        [styles.labeledInput]: Boolean(props.label),
      })}
    />
  );
};

export default TextField;
