import SvgIcon, { SvgIconProps } from "./SvgIcon";

const PinIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        id="svg_2"
        d="m16.23797,10.92404c0.08646,0.14963 0.23439,0.25355 0.40449,0.28405l2.14842,0.38543c0.1701,0.03051 0.31803,0.13444 0.40437,0.28407l0.25609,0.44346c0.16189,0.28055 0.06581,0.63927 -0.21468,0.80124l-3.39179,1.95827c-0.28049,0.16189 -0.37668,0.52062 -0.21468,0.80123l2.33365,4.04204c0.08083,0.13995 0.1003,0.307 0.05396,0.46173l-0.05549,0.185c-0.08916,0.2975 -0.39475,0.47393 -0.69694,0.40237l-0.18793,-0.04446c-0.1572,-0.03719 -0.2921,-0.1376 -0.37293,-0.27756l-2.33367,-4.04204c-0.16197,-0.28061 -0.5207,-0.37668 -0.80125,-0.21468l-3.39174,1.95814c-0.28054,0.16201 -0.63927,0.06593 -0.80124,-0.21468l-0.26236,-0.45446c-0.08272,-0.14324 -0.10109,-0.31474 -0.05058,-0.47229l0.64074,-1.9985c0.0505,-0.15755 0.03213,-0.32905 -0.05058,-0.47241l-3.37011,-5.83714c-0.16197,-0.28055 -0.52069,-0.37667 -0.80124,-0.2147l-0.03352,0.01936c-0.28054,0.16197 -0.63927,0.06585 -0.80124,-0.2147l-0.09897,-0.17144c-0.16198,-0.28055 -0.06586,-0.63927 0.21469,-0.80124l7.67349,-4.43029c0.28054,-0.16197 0.63927,-0.06586 0.80124,0.21469l0.09897,0.17144c0.16197,0.28055 0.06586,0.63927 -0.21469,0.80124l-0.03352,0.01936c-0.28054,0.16197 -0.37666,0.52069 -0.21469,0.80124l3.36374,5.82622l-0.00001,0.00001zm-5.55352,5.55263l6.54854,-3.78076l-2.15909,3.10963c-0.11164,0.16083 -0.29618,0.25538 -0.49195,0.25198l-3.07892,-0.05338l-0.58924,0.9183l-0.22934,-0.44578l0,0.00001z"
        stroke="null"
      />
      <path
        id="svg_3"
        d="m17.23299,12.6959l-6.54854,3.78076l0.22934,0.44578l0.58924,-0.9183l3.07892,0.05338c0.19577,0.0034 0.38031,-0.09115 0.49195,-0.25198l2.15909,-3.10963l0,-0.00001zm0,0l-1.86676,-0.37347m-2.27732,-8.02585l0.03352,-0.01936c0.28055,-0.16197 0.37666,-0.52069 0.21469,-0.80124l-0.09897,-0.17144c-0.16197,-0.28055 -0.5207,-0.37666 -0.80124,-0.21469l-7.67349,4.43029c-0.28055,0.16197 -0.37667,0.52069 -0.21469,0.80124l0.09897,0.17144c0.16197,0.28055 0.5207,0.37667 0.80124,0.2147l0.03352,-0.01936c0.28055,-0.16197 0.63927,-0.06585 0.80124,0.2147l3.37011,5.83714c0.08272,0.14335 0.10109,0.31486 0.05058,0.47241l-0.64074,1.9985c-0.0505,0.15755 -0.03213,0.32905 0.05058,0.47229l0.26236,0.45446c0.16197,0.28061 0.5207,0.37668 0.80124,0.21468l3.39174,-1.95814c0.28055,-0.16201 0.63928,-0.06593 0.80125,0.21468l2.33367,4.04204c0.08083,0.13995 0.21573,0.24037 0.37293,0.27756l0.18793,0.04446c0.30219,0.07156 0.60778,-0.10488 0.69694,-0.40237l0.05549,-0.185c0.04634,-0.15473 0.02686,-0.32178 -0.05396,-0.46173l-2.33365,-4.04204c-0.16201,-0.28061 -0.06581,-0.63934 0.21468,-0.80123l3.39179,-1.95827c0.28049,-0.16197 0.37657,-0.52069 0.21468,-0.80124l-0.25609,-0.44346c-0.08634,-0.14963 -0.23427,-0.25355 -0.40437,-0.28407l-2.14842,-0.38542c-0.1701,-0.03051 -0.31803,-0.13444 -0.40449,-0.28407l-3.36374,-5.82622c-0.16197,-0.28055 -0.06585,-0.63927 0.21469,-0.80124l0.00001,0z"
        strokeWidth="0.4"
        stroke="inherit"
      />
    </SvgIcon>
  );
};

export default PinIcon;
