import SvgIcon, { SvgIconProps } from "./SvgIcon";

const FilterIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49122 20C10.1805 20 11.6011 18.7892 11.9287 17.193L21.1228 17.193C21.5104 17.193 21.8246 16.8788 21.8246 16.4912C21.8246 16.1037 21.5104 15.7895 21.1228 15.7895L11.9287 15.7895C11.6011 14.1933 10.1805 12.9825 8.49122 12.9825C6.80196 12.9825 5.38136 14.1933 5.05372 15.7895L2.87718 15.7895C2.48962 15.7895 2.17543 16.1037 2.17543 16.4912C2.17543 16.8788 2.48962 17.193 2.87718 17.193L5.05372 17.193C5.38136 18.7892 6.80196 20 8.49122 20ZM8.49122 18.5965C7.32022 18.5965 6.38595 17.6622 6.38595 16.4912C6.38595 15.3202 7.32022 14.386 8.49122 14.386C9.66221 14.386 10.5965 15.3202 10.5965 16.4912C10.5965 17.6622 9.66221 18.5965 8.49122 18.5965Z"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5088 11.0175C17.198 11.0175 18.6186 9.80673 18.9463 8.21053L21.1228 8.21053C21.5104 8.21053 21.8246 7.89634 21.8246 7.50877C21.8246 7.12121 21.5104 6.80702 21.1228 6.80702L18.9463 6.80702C18.6186 5.21082 17.198 4 15.5088 4C13.8195 4 12.3989 5.21082 12.0713 6.80702L2.87718 6.80702C2.48962 6.80702 2.17543 7.12121 2.17543 7.50877C2.17543 7.89634 2.48962 8.21053 2.87718 8.21053L12.0713 8.21053C12.3989 9.80673 13.8195 11.0175 15.5088 11.0175ZM15.5088 9.61404C14.3378 9.61404 13.4035 8.67977 13.4035 7.50877C13.4035 6.33778 14.3378 5.40351 15.5088 5.40351C16.6798 5.40351 17.614 6.33778 17.614 7.50877C17.614 8.67977 16.6798 9.61404 15.5088 9.61404Z"
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default FilterIcon;
