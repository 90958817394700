import SvgIcon, { SvgIconProps } from "./SvgIcon";

const HoldIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM10 8H9C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H10C10.5523 16 11 15.5523 11 15V9C11 8.44772 10.5523 8 10 8ZM15 8H14C13.4477 8 13 8.44772 13 9V15C13 15.5523 13.4477 16 14 16H15C15.5523 16 16 15.5523 16 15V9C16 8.44772 15.5523 8 15 8Z" />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM10 8C10.5523 8 11 8.44772 11 9V15C11 15.5523 10.5523 16 10 16H9C8.44772 16 8 15.5523 8 15V9C8 8.44772 8.44772 8 9 8H10ZM15 8C15.5523 8 16 8.44772 16 9V15C16 15.5523 15.5523 16 15 16H14C13.4477 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8H15Z" />
      )}
    </SvgIcon>
  );
};

export default HoldIcon;
