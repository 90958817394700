import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export type InsuranceItem = Partial<{
  outstanding: number;
  company: string;
  date_from: string;
  date_to: string;
}>;

type Insurances = Partial<{
  isPaid: boolean;
  insuranceList: Array<InsuranceItem>;
}>;
type QueryError = {};

const useTripInsurancesQuery = (
  tripId: number | undefined | null,
  options?: UseQueryOptions<Insurances, QueryError, Insurances, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trip-details/insurance/${tripId}`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<Insurances, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();
      return fetchWithAuth<Insurances>(url);
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useTripInsurancesQuery;
