import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

const PAGE_SIZE = 10;

type RecentTransactionsParam = {
  pageSize?: number;
  pageNum?: number;
  ref?: string;
};

export type TransactionItem = {
  id: number;
  cid: number;
  date: number;
  amount: number;
  message: string;
  ref: string;
  ref_id: number;
  global_dollar_reward_id: number;
  global_dollar_claimable_id: number;
  title: string;
  _ca: number;
  _cb: number;
  _lma: any;
  _lmb: any;
};

export type TransactionsResult = {
  result: Array<TransactionItem>;
  totalCount: number;
};

export type TransactionsFetchResult = {
  result: TransactionsResult;
  success: boolean;
};

type QueryError = {};

const useRecentTransactionsQuery = (
  params?: RecentTransactionsParam,

  options?: UseInfiniteQueryOptions<
    TransactionsResult,
    any,
    TransactionsResult,
    TransactionsResult,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${REWARD_ENDPOINT}/global-dollar/transaction/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, JSON.stringify(params)];

  return useInfiniteQuery<TransactionsResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!cid) throw new Error();

      const result = await fetchWithAuth<TransactionsFetchResult>(url, {
        params: {
          ...params,
          pageNum: pageParam,
          pageSize: params?.pageSize ?? PAGE_SIZE,
        },
      });
      return result.result;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if (
          (lastPage?.totalCount || 0) >=
          (params?.pageSize ?? PAGE_SIZE) * pages.length
        )
          return pages?.length + 1;
        return undefined;
      },
    }
  );
};
export default useRecentTransactionsQuery;
