import { useMutation, UseMutationOptions } from "react-query";
import { PAYMENT_ENDPOINT } from "./constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Variables = { currency: string };

type Result = {
  success: true;
  statusCode: 200;
  id: string;
  object: "setup_intent";
  application: null;
  cancellation_reason: null;
  client_secret: string;
  created: number;
  customer: string;
  description: null;
  last_setup_error: null;
  latest_attempt: null;
  livemode: boolean;
  mandate: null;
  metadata: {};
  next_action: null;
  on_behalf_of: null;
  payment_method: null;
  payment_method_options: {
    card: {
      mandate_options: null;
      network: null;
      request_three_d_secure: "automatic";
    };
  };
  payment_method_types: ["card"];
  single_use_mandate: null;
  status: "requires_payment_method";
  usage: "off_session";
};

type QueryError = {};

const useCardSetupMutation = (
  options?: UseMutationOptions<Result, QueryError, Variables>
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller.id;

  return {
    ...useMutation<Result, QueryError, Variables>(
      async ({ currency }: Variables) => {
        const url = `${PAYMENT_ENDPOINT}/card/intent`;
        const payload = {
          cid,
          currency,
          created_by: 475,
          payment_plan_nominated: true,
        };
        return fetchWithAuth<Result>(url, { method: "POST", body: payload });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useCardSetupMutation;
