import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";

const PAGE_SIZE = 30;

export type Like = Partial<{
  id: number;
  cid: number;
  post_id: number;
  nickname: string;
  email: string;
  s3_profile_pic: string;
  s3_resume_pic: string;
  country: string;
  division: string;
  status: TripStatus;
  is_admin: boolean | number;
  admin_title?: string | null;
}>;

type RecentLikesParam = {
  pageSize: number;
  pageNum?: number;
  searchParam?: string;
};

type RecentLikesResult = {
  results: Array<Like>;
  totalCount: number;
};

type RecentLikesFetchResult = {
  data: RecentLikesResult;
  success: boolean;
  statusCode: number;
};

type QueryError = {};

export type InfinityResult = {
  pages: Array<RecentLikesResult>;
};

const useRecentLikesQuery = (
  postId: number,
  params: RecentLikesParam = { pageSize: PAGE_SIZE, pageNum: 1 },
  options?: UseInfiniteQueryOptions<
    RecentLikesResult,
    any,
    RecentLikesResult,
    RecentLikesResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const url = `${GATEWAY_URL}/like/${postId}`;
  const cacheKey = [url, JSON.stringify(params)];

  return useInfiniteQuery<RecentLikesResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      if (!postId) throw new Error("Post id not found");

      const result = await fetchWithAuth<RecentLikesFetchResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
      return result.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.totalCount || 0) > params.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
};

export default useRecentLikesQuery;
