import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AcademyIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M20 17C20.5128 17 20.9355 17.386 20.9933 17.8834L21 18V20C21 21.0544 20.1841 21.9182 19.1493 21.9945L19 22H5C3.94564 22 3.08183 21.1841 3.00549 20.1493L3 20V18C3 17.4477 3.44772 17 4 17C4.51284 17 4.93551 17.386 4.99327 17.8834L5 18V20H19V18C19 17.4477 19.4477 17 20 17ZM12 2C12.5523 2 13 2.44772 13 3V14.584L16.2896 11.2931C16.65 10.9325 17.2172 10.9046 17.6096 11.2095L17.7038 11.2927C18.0644 11.6531 18.0923 12.2203 17.7874 12.6127L17.7043 12.7069L12.7073 17.7069C12.3469 18.0676 11.7795 18.0954 11.3871 17.7903L11.2929 17.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.65338 10.9324 7.22061 10.9047 7.6129 11.2097L7.70711 11.2929L11 14.587V3C11 2.44772 11.4477 2 12 2Z" />
    </SvgIcon>
  );
};

export default AcademyIcon;
